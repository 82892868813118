import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { User } from '../../models/user/User';
import { GenderCode } from '../../constants/closed-lookup/GenderCode';
import { UsersPermissions } from '../../constants/permissions/UsersPermissions';
import { CarsPermissions } from '../../constants/permissions/CarsPermissions';
import { SchoolLicensesPermissions } from '../../constants/permissions/SchoolLicensesPermissions';
import { CarLicensesPermissions } from '../../constants/permissions/CarLicensesPermissions';
import { TrainerLicensesPermissions } from '../../constants/permissions/TrainerLicensesPermissions';
import { CarInsurancesPermissions } from '../../constants/permissions/CarInsurancesPermissions';
import { SchoolSettingsPermissions } from '../../constants/permissions/SchoolSettingsPermissions';
import { EmployeesPermissions } from '../../constants/permissions/EmployeesPermissions';
import { StudentsPermissions } from '../../constants/permissions/StudentsPermissions';
import { SchoolPaymentsPermissions } from '../../constants/permissions/SchoolPaymentsPermissions';
import { StudentPaymentsPermissions } from '../../constants/permissions/StudentPaymentsPermissions';
import { StudentDiscountsPermissions } from '../../constants/permissions/StudentDiscountsPermissions';
import { StudentTheoreticalExamsPermissions } from '../../constants/permissions/StudentTheoreticalExamsPermissions';
import { StudentPracticalExamsPermissions } from '../../constants/permissions/StudentPracticalExamsPermissions';
import { StudentLessonsPermissions } from '../../constants/permissions/StudentLessonsPermissions';
import { ReceivedPaymentsPermissions } from '../../constants/permissions/ReceivedPaymentsPermissions';
import { UserAuthentication } from '../../utilities/UserAuthentication';
import { AppToast } from '../../utilities/AppToast';
import { AuthenticationContext } from '../../App';
import { DialogInputs } from '../../inputs/DialogInputs';
//import Clock from 'react-live-clock';
import UserProfile from '../user/UserProfile';
import ChangePassword from '../user/ChangePassword';

const AppLayout = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const { authentication } = useContext(AuthenticationContext);

    const [isInlineMenuOpened, setIsInlineMenuOpened] = useState<boolean>(false);

    const [isProfileDialogOpened, setIsProfileDialogOpened] = useState<boolean>(false);
    const [isChangePasswordDialogOpened, setIsChangePasswordDialogOpened] = useState<boolean>(false);

    const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

    const menuItems = [
        {
            Path: "/app",
            Label: "الرئيسية",
            Icon: "home",
            IsShown: true,
            Group: "dashboard"
        },
        {
            Path: "/app/school-settings",
            Label: "الاعدادات",
            Icon: "cog",
            IsShown: UserAuthentication.hasPermissions(SchoolSettingsPermissions.View),
            Group: "school"
        },
        {
            Path: "/app/users",
            Label: "المستخدمين",
            Icon: "user",
            IsShown: UserAuthentication.hasPermissions(UsersPermissions.View),
            Group: "users"
        },
        {
            Path: "/app/cars",
            Label: "المركبات",
            Icon: "car",
            IsShown: UserAuthentication.hasPermissions(CarsPermissions.View),
            Group: "cars"
        },
        {
            Path: "/app/car-licenses",
            Label: "التراخيص",
            Icon: "file",
            IsShown: UserAuthentication.hasPermissions(CarLicensesPermissions.View),
            Group: "cars"
        },
        {
            Path: "/app/car-insurances",
            Label: "التأمينات",
            Icon: "shield",
            IsShown: UserAuthentication.hasPermissions(CarInsurancesPermissions.View),
            Group: "cars"
        },
        {
            Path: "/app/employees",
            Label: "الموظفين",
            Icon: "user",
            IsShown: UserAuthentication.hasPermissions(EmployeesPermissions.View),
            Group: "employees"
        },
        {
            Path: "/app/trainer-licenses",
            Label: "رخص المدربين",
            Icon: "file",
            IsShown: UserAuthentication.hasPermissions(TrainerLicensesPermissions.View),
            Group: "employees"
        },
        {
            Path: "/app/school-payments",
            Label: "الدفعات المدفوعة",
            Icon: "dollar",
            IsShown: UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View),
            Group: "school"
        },
        {
            Path: "/app/received-payments",
            Label: "الدفعات المقبوضة",
            Icon: "dollar",
            IsShown: UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View),
            Group: "school"
        },
        {
            Path: "/app/school-statement",
            Label: "كشف حساب المدرسة",
            Icon: "chart-line",
            IsShown: (UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) && UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) && UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View)),
            Group: "school"
        },
        {
            Path: "/app/school-licenses",
            Label: "التراخيص",
            Icon: "file",
            IsShown: UserAuthentication.hasPermissions(SchoolLicensesPermissions.View),
            Group: "school"
        },
        {
            Path: "/app/audit-logs",
            Label: "حركات النظام",
            Icon: "tags",
            IsShown: (authentication && authentication.IsMain),
            Group: "school"
        },
        {
            Path: "/app/students",
            Label: "الطلاب",
            Icon: "user",
            IsShown: UserAuthentication.hasPermissions(StudentsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/student-theoretical-exams",
            Label: "الاختبارات النظرية",
            Icon: "book",
            IsShown: UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/student-practical-exams",
            Label: "الاختبارات العملية",
            Icon: "map",
            IsShown: UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/student-lessons",
            Label: "الدروس",
            Icon: "directions",
            IsShown: UserAuthentication.hasPermissions(StudentLessonsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/student-payments",
            Label: "الدفعات",
            Icon: "dollar",
            IsShown: UserAuthentication.hasPermissions(StudentPaymentsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/student-discounts",
            Label: "الخصومات",
            Icon: "minus-circle",
            IsShown: UserAuthentication.hasPermissions(StudentDiscountsPermissions.View),
            Group: "students"
        },
        {
            Path: "/app/schools",
            Label: "المدارس",
            Icon: "globe",
            IsShown: (authentication && authentication.IsManagementSchool),
            Group: "management"
        },
        {
            Path: "/app/subscription-requests",
            Label: "طلبات الإشتراك",
            Icon: "megaphone",
            IsShown: (authentication && authentication.IsManagementSchool),
            Group: "management"
        }
    ];

    const menuItemsGroups = [
        {
            Code: "dashboard",
            Label: "الرئيسية",
            Items: menuItems.filter(i => i.Group === "dashboard" && i.IsShown)
        },
        {
            Code: "users",
            Label: "مستخدمين النظام",
            Items: menuItems.filter(i => i.Group === "users" && i.IsShown)
        },
        {
            Code: "employees",
            Label: "الموظفين",
            Items: menuItems.filter(i => i.Group === "employees" && i.IsShown)
        },
        {
            Code: "cars",
            Label: "المركبات",
            Items: menuItems.filter(i => i.Group === "cars" && i.IsShown)
        },
        {
            Code: "students",
            Label: "الطلاب",
            Items: menuItems.filter(i => i.Group === "students" && i.IsShown)
        },
        {
            Code: "school",
            Label: "المدرسة",
            Items: menuItems.filter(i => i.Group === "school" && i.IsShown)
        },
        {
            Code: "management",
            Label: "الادارة",
            Items: menuItems.filter(i => i.Group === "management" && i.IsShown)
        }
    ];


    useEffect(() => {
        let pathname = window.location.pathname;
        pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
        setActiveMenuItem(pathname);
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps


    const toggleMenu = () => {
        let layoutElement = document.getElementById("app-layout");
        if (layoutElement) {
            if (window && window.innerWidth < 992) {
                layoutElement.classList.toggle("layout-menu-mobile-active");
            } else {
                layoutElement.classList.toggle("layout-menu-active");
            }
        }
    }

    const toggleInlineMenu = () => {
        let layoutInlineMenuElement = document.getElementById("layout-inline-menu");
        if (layoutInlineMenuElement) {
            layoutInlineMenuElement.classList.toggle("layout-inline-menu-active");
        }
        setIsInlineMenuOpened(!isInlineMenuOpened);
    }

    const logout = () => {
        UserAuthentication.set(null);
    }

    const handleUserProfileCallback = (user: User | null) => {
        setIsProfileDialogOpened(false);
        if (user && authentication) {
            authentication.UserName = user.Name;
            authentication.UserEmail = user.Email;
            authentication.UserGenderCode = user.GenderCode;
            UserAuthentication.set({ ...authentication });
            AppToast.success("تم تعديل الملف الشخصي بنجاح");
        }
    }

    const goToLandingPage = () => {
        navigate("/");
    }

    return (

        <div id="app-layout" className="layout-wrapper layout-menu-light layout-topbar-blue layout-menu-static layout-menu-active layout-rtl app-layout">
            <div className="layout-topbar shadow-4">
                <div className="layout-topbar-left">
                    <div className="layout-topbar-logo">
                        <img className="cursor-pointer" src="/images/landing-page-icon.png" alt="landing page" onClick={goToLandingPage} />
                        <div className="cursor-pointer pr-2" onClick={goToLandingPage}>
                            <span className="font-medium pl-1">{"تصفح"}</span>
                            <span className="font-medium app-label">{"DSMS"}</span>
                        </div>
                    </div>
                    <button type="button" className="layout-menu-button shadow-6 p-link" onClick={toggleMenu}>
                        <i className="pi pi-chevron-right"></i>
                    </button>
                </div>
                <div className="layout-topbar-right">
                    <div className="layout-topbar-right-content">
                        {/* <div className="clock-container">
                            <Clock
                                format={"YYYY-MM-DD hh:mm:ss A"}
                                timezone={"Asia/Hebron"}
                                ticking={true} />
                        </div> */}
                    </div>
                    {/* <div className="layout-topbar-actions-left"></div>
                    <div className="layout-topbar-actions-right">
                        <ul className="layout-topbar-items">

                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="menu-wrapper">
                <div className="layout-menu-container">
                    <div id="layout-inline-menu" className="layout-inline-menu">
                        <button className="avatar-menu layout-inline-menu-action p-link flex flex-row align-items-center p-3" onClick={toggleInlineMenu}>

                            {authentication?.UserGenderCode === GenderCode.Male &&
                                <img src="/images/male-user.png" className="avatar-image" alt="user" />
                            }

                            {authentication?.UserGenderCode === GenderCode.Female &&
                                <img src="/images/female-user.png" className="avatar-image" alt="user" />
                            }

                            <span className="flex flex-column mr-2 user-info">
                                <span className="user-name pb-1 font-bold" title={authentication?.UserName || null}>{authentication?.UserName || null}</span>
                                <span className="school-name pb-1" title={authentication?.SchoolName || null}>{authentication?.SchoolName || null}</span>
                            </span>
                            <i className="layout-inline-menu-icon pi pi-angle-down mr-auto"></i>
                        </button>
                        {isInlineMenuOpened &&
                            <ul className="layout-inline-menu-action-panel">
                                <li className="layout-inline-menu-action-item tooltip">
                                    <button className="flex flex-row align-items-center p-link" onClick={() => setIsProfileDialogOpened(true)}>
                                        <i className="pi pi-user pi-fw"></i>
                                        <span>الملف الشخصي</span>
                                    </button>
                                </li>

                                <li className="layout-inline-menu-action-item tooltip">
                                    <button className="flex flex-row align-items-center p-link" onClick={() => setIsChangePasswordDialogOpened(true)}>
                                        <i className="pi pi-lock pi-fw"></i>
                                        <span>تغيير كلمة المرور</span>
                                    </button>
                                </li>

                                <li className="layout-inline-menu-action-item tooltip">
                                    <button className="flex flex-row align-items-center p-link" onClick={logout}>
                                        <i className="pi pi-power-off pi-fw"></i>
                                        <span>تسجيل الخروج</span>
                                    </button>
                                </li>
                            </ul>
                        }
                    </div>
                    <ul role="menu" className="layout-menu">
                        <li className="layout-root-menuitem" role="menuitem">
                            {/* <div></div> */}
                            <ul role="menu">
                                {
                                    // menuItems.filter(item => item.IsShown).map((item: any, index: number) => {
                                    //     return <li key={index} role="menuitem">
                                    //         <Link to={item.Path} className={"p-ripple tooltip" + (activeMenuItem === item.Path ? " router-link-active" : "")}>
                                    //             <i className={"layout-menuitem-icon pi pi-fw pi-" + item.Icon}></i>
                                    //             <span className="layout-menuitem-text">{item.Label}</span>
                                    //         </Link>
                                    //     </li>;
                                    // })

                                    menuItemsGroups.filter(itemsGroup => itemsGroup.Items.length > 0).map((itemsGroup: any, itemsGroupIndex: number) => {
                                        return <React.Fragment key={itemsGroupIndex}>

                                            <li>
                                                <span className="menu-item-group-text">
                                                    {itemsGroup.Label}
                                                </span>
                                            </li>

                                            {
                                                itemsGroup.Items.map((item: any, itemIndex: number) => {
                                                    return <li key={itemIndex} role="menuitem">
                                                        <Link to={item.Path} className={"p-ripple tooltip" + (activeMenuItem === item.Path ? " router-link-active" : "")}>
                                                            <i className={"layout-menuitem-icon pi pi-fw pi-" + item.Icon}></i>
                                                            <span className="layout-menuitem-text">{item.Label}</span>
                                                        </Link>
                                                    </li>;
                                                })
                                            }

                                            <li>
                                                <span className="menu-item-group-splitter"></span>
                                            </li>
                                        </React.Fragment>
                                    })
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="layout-main">
                <div className="layout-content">
                    <Outlet />
                </div>
            </div>
            <div className="layout-mask modal-in"></div>

            <Dialog
                header="تغيير كلمة المرور"
                visible={isChangePasswordDialogOpened}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                onHide={() => setIsChangePasswordDialogOpened(false)}>

                <ChangePassword />
            </Dialog>


            <Dialog
                header="الملف الشخصي"
                visible={isProfileDialogOpened}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                onHide={() => setIsProfileDialogOpened(false)}>

                <UserProfile callBack={(user: User | null) => handleUserProfileCallback(user)} />
            </Dialog>
        </div>

    );
};

export default AppLayout;
import React, { useState } from "react";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { SchoolStatementService } from "../../services/SchoolStatementService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { StudentPaymentService } from "../../services/StudentPaymentService";
import { ReceivedPaymentService } from "../../services/ReceivedPaymentService";
import { Field } from "../../models/base/Field";
import { FilterRequest } from "../../models/base/FilterRequest";
import { GetSchoolStatementRequest } from "../../models/school-statement/GetSchoolStatementRequest";
import { SchoolStatement as SchoolStatementModel } from "../../models/school-statement/SchoolStatement";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { FieldType } from "../../constants/base/FieldType";
import { SortDirection } from "../../constants/base/SortDirection";
import { SchoolStatementField } from "../../constants/fields/SchoolStatementField";
import { StudentPaymentField } from "../../constants/fields/StudentPaymentField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { ReceivedPaymentField } from "../../constants/fields/ReceivedPaymentField";
import { SchoolStatementLabel } from "../../constants/labels/SchoolStatementLabel";
import { SchoolStatementFields } from "../../fields/SchoolStatementFields";
import { StudentPaymentFields } from "../../fields/StudentPaymentFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { ReceivedPaymentFields } from "../../fields/ReceivedPaymentFields";
import FieldValue from "../base/FieldValue";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";


const SchoolStatement = () => {

    const schoolStatementService: SchoolStatementService = new SchoolStatementService();

    const [schoolStatementFields] = useState<Field[]>(SchoolStatementFields.get());
    const [isSchoolStatementLoading, setIsSchoolStatementLoading] = useState<boolean>(false);
    const [schoolStatement, setSchoolStatement] = useState<SchoolStatementModel | null>(null);

    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);

    const [errorsModel, setErrorsModel] = useState<any>({});

    const fromDateField: Field = new Field({ Code: "FromDate", Type: FieldType.Date });
    const toDateField: Field = new Field({ Code: "ToDate", Type: FieldType.Date });


    const getSchoolStatementField = (fieldCode: string) => {
        return schoolStatementFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadSchoolStatement = (fromDate: any, toDate: any) => {
        let getSchoolStatementRequest: GetSchoolStatementRequest = new GetSchoolStatementRequest();
        getSchoolStatementRequest.FromDate = fromDate;
        getSchoolStatementRequest.ToDate = toDate;

        setErrorsModel({});
        setIsSchoolStatementLoading(true);
        schoolStatementService.getSchoolStatement(getSchoolStatementRequest).then((schoolStatement) => {
            setSchoolStatement(schoolStatement || null);
            setIsSchoolStatementLoading(false);
        }).catch((error: any) => {
            if (error && error.data && error.data.errors && error.data.title?.toString()?.toLowerCase() === "One or more validation errors occurred.".toLowerCase()) {
                setErrorsModel(error.data.errors);
            }
            setSchoolStatement(null);
            setIsSchoolStatementLoading(false);
        });
    }

    const onFromDateChange = (value: any) => {
        setFromDate(value);
        loadSchoolStatement(value, toDate);
    }

    const onToDateChange = (value: any) => {
        setToDate(value);
        loadSchoolStatement(fromDate, value);
    }

    const getListAdditionalFilters = (dateFieldCode: string) => {
        let fromDateFilterValue = fromDate ? fromDate.toString() : "";
        let toDateFilterValue = toDate ? toDate.toString() : "";
        return [
            new FilterRequest(dateFieldCode, FilterOperator.GreaterThanOrEqual, fromDateFilterValue),
            new FilterRequest(dateFieldCode, FilterOperator.LessThanOrEqual, toDateFilterValue)
        ]
    }

    const schoolStatementFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{SchoolStatementLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isSchoolStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(schoolStatement, getSchoolStatementField(fieldCode))}</div>
            }
        </div>;
    }


    return (
        <Card>

            <div className="mt-3 mb-4">
                <div className="grid">
                    <div className="col-12 md:col-3">
                        <FieldValue
                            label={"من تاريخ"}
                            field={fromDateField}
                            value={fromDate}
                            errors={errorsModel[fromDateField.Code]}
                            isDisabled={isSchoolStatementLoading}
                            isBoldLabel={true}
                            onChange={(value: any) => onFromDateChange(value)} />
                    </div>
                    <div className="col-12 md:col-3">
                        <FieldValue
                            label={"الى تاريخ"}
                            field={toDateField}
                            value={toDate}
                            errors={errorsModel[toDateField.Code]}
                            isDisabled={isSchoolStatementLoading}
                            isBoldLabel={true}
                            onChange={(value: any) => onToDateChange(value)} />
                    </div>
                    <div className="col-12 md:col-6 flex align-items-end">
                        {fromDate && toDate && !isSchoolStatementLoading && schoolStatement &&
                            <Button
                                icon="pi pi-refresh"
                                className="p-button-secondary p-button-sm"
                                onClick={() => loadSchoolStatement(fromDate, toDate)} />
                        }
                    </div>
                </div>
            </div>

            <Panel header={"الحساب الكلي"} className="mb-4">
                <div className="grid">
                    {schoolStatementFieldTemplate(SchoolStatementField.TotalReceived)}
                    {schoolStatementFieldTemplate(SchoolStatementField.TotalPaid)}
                    {schoolStatementFieldTemplate(SchoolStatementField.TotalProfit)}
                    {schoolStatementFieldTemplate(SchoolStatementField.TotalLoss)}
                </div>
            </Panel>

            {!isSchoolStatementLoading && schoolStatement &&
                <React.Fragment>

                    <Panel header={"دفعات الطلاب"} className="mb-4">
                        <List
                            title={"دفعات الطلاب"}
                            hideHeaderTitle={true}
                            size={"small"}
                            service={new StudentPaymentService()}
                            fields={StudentPaymentFields.get()}
                            fieldsCodesExcludedFromList={[
                                StudentPaymentField.CheckDateStatus,
                                StudentPaymentField.Notes
                            ]}
                            additionalFilters={getListAdditionalFilters(StudentPaymentField.PaymentDate)}
                            maxPageRecordsCount={10}
                            noCardContent={true}
                            showTotalRecordsCount={true}
                            isSelectionDisabled={true}
                            defaultSortByField={StudentPaymentField.PaymentDate}
                            defaultSortDirection={SortDirection.Descending} />
                    </Panel>

                    <Panel header={"الدفعات المقبوضة"} className="mb-4">
                        <List
                            title={"الدفعات المقبوضة"}
                            hideHeaderTitle={true}
                            size={"small"}
                            service={new ReceivedPaymentService()}
                            fields={ReceivedPaymentFields.get()}
                            fieldsCodesExcludedFromList={[
                                ReceivedPaymentField.CheckDateStatus,
                                ReceivedPaymentField.Notes
                            ]}
                            additionalFilters={getListAdditionalFilters(ReceivedPaymentField.PaymentDate)}
                            maxPageRecordsCount={10}
                            noCardContent={true}
                            showTotalRecordsCount={true}
                            isSelectionDisabled={true}
                            defaultSortByField={ReceivedPaymentField.PaymentDate}
                            defaultSortDirection={SortDirection.Descending} />
                    </Panel>

                    <Panel header={"دفعات المدرسة"} className="mb-4">
                        <List
                            title={"دفعات المدرسة"}
                            hideHeaderTitle={true}
                            size={"small"}
                            service={new SchoolPaymentService()}
                            fields={SchoolPaymentFields.get()}
                            fieldsCodesExcludedFromList={[
                                SchoolPaymentField.CheckDateStatus,
                                SchoolPaymentField.Notes,
                                SchoolPaymentField.Car_PlateNumber,
                                SchoolPaymentField.Car_CarCompany_Name,
                                SchoolPaymentField.Car_CarType_Name,
                                SchoolPaymentField.CarLicense_StartDate,
                                SchoolPaymentField.CarLicense_EndDate,
                                SchoolPaymentField.CarLicense_Car_PlateNumber,
                                SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                                SchoolPaymentField.CarLicense_Car_CarType_Name,
                                SchoolPaymentField.CarInsurance_StartDate,
                                SchoolPaymentField.CarInsurance_EndDate,
                                SchoolPaymentField.CarInsurance_Car_PlateNumber,
                                SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                                SchoolPaymentField.CarInsurance_Car_CarType_Name,
                                SchoolPaymentField.Employee_Name,
                                SchoolPaymentField.SchoolLicense_StartDate,
                                SchoolPaymentField.SchoolLicense_EndDate,
                                SchoolPaymentField.TrainerLicense_StartDate,
                                SchoolPaymentField.TrainerLicense_EndDate,
                                SchoolPaymentField.TrainerLicense_Employee_Name,
                                SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name
                            ]}
                            additionalFilters={getListAdditionalFilters(SchoolPaymentField.PaymentDate)}
                            maxPageRecordsCount={10}
                            noCardContent={true}
                            showTotalRecordsCount={true}
                            isSelectionDisabled={true}
                            defaultSortByField={SchoolPaymentField.PaymentDate}
                            defaultSortDirection={SortDirection.Descending} />
                    </Panel>

                </React.Fragment>
            }

        </Card>
    );
}

export default SchoolStatement;
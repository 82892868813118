export enum CarLicenseField {
    StartDate = "StartDate",
    EndDate = "EndDate",
    CarId = "CarId",
    Cost = "Cost",
    TotalPaid = "TotalPaid",
    TotalOverpaid = "TotalOverpaid",
    TotalRemaining = "TotalRemaining",
    Car_PlateNumber = "Car.PlateNumber",
    Car_CarCompany_Name = "Car.CarCompany.Name",
    Car_CarType_Name = "Car.CarType.Name",
    ExpirationStatus = "ExpirationStatus"
}
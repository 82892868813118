import { FieldType } from "../../constants/base/FieldType";


type Params = {
    Code: string,
    Type: FieldType,
    IsLtr?: boolean
};

export class FieldMetadata {
    Code: string;
    Type: FieldType;
    IsLtr: boolean;

    constructor(params: Params) {
        this.Code = params.Code;
        this.Type = params.Type;
        this.IsLtr = params.IsLtr === undefined ? false : params.IsLtr;
    }
}
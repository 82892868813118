export class ValueGetter {

    static get(model: any, property: string): any {
        if (model && property) {
            if (property.indexOf(".") === -1) {
                let value = model[property];
                return value === undefined ? null : value;
            }
            else {
                let keys = property.split(".");
                let value = model;
                for (const key of keys) {
                    if (value === null || value === undefined) {
                        return null;
                    }
                    value = value[key];
                }
                return value === undefined ? null : value;
            }
        }
        return null;
    }

}
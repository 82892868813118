import { http } from "../http/Http";
import { Car } from "../models/car/Car";
import { CarStatement } from "../models/car/CarStatement";
import { CreateCarRequest } from "../models/car/CreateCarRequest";
import { UpdateCarRequest } from "../models/car/UpdateCarRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class CarService {

    public EntityCode: EntityCode = EntityCode.Car;

    private Api: string = "Cars";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Car>> => {
        const { data } = await http.post<GetByFiltersResponse<Car>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Car>> => {
        const { data } = await http.post<GetByFiltersResponse<Car>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<Car> => {
        const { data } = await http.get<Car>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<Car> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<Car>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getCarStatement = async (id: number): Promise<CarStatement> => {
        const { data } = await http.get<CarStatement>(this.Api + "/GetCarStatement/" + id.toString());
        return data;
    };

    create = async (createCarRequest: CreateCarRequest): Promise<Car> => {
        const { data } = await http.post<Car>(this.Api, createCarRequest);
        return data;
    };

    update = async (id: number, updateCarRequest: UpdateCarRequest): Promise<Car> => {
        const { data } = await http.put<Car>(this.Api + "/" + id.toString(), updateCarRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
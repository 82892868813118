import { CreateStudentLessonRequest } from "../../models/student-lesson/CreateStudentLessonRequest";
import { UpdateStudentLessonRequest } from "../../models/student-lesson/UpdateStudentLessonRequest";
import { StudentLessonFields } from "../../fields/StudentLessonFields";
import { StudentLessonService } from "../../services/StudentLessonService";
import { StudentLessonsPermissions } from "../../constants/permissions/StudentLessonsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import List from "../base/List";

const StudentLessonsList = () => {

    return (
        <List
            title={"الدروس"}
            service={new StudentLessonService()}
            fields={StudentLessonFields.get()}
            filters={ListFilters.studentLesson()}
            createSuccessMessage={"تم اضافة الدرس بنجاح"}
            editSuccessMessage={"تم تعديل الدرس بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الدرس ؟"}
            deleteSuccessMessage={"تم حذف الدرس بنجاح"}
            createModel={new CreateStudentLessonRequest()}
            editModel={new UpdateStudentLessonRequest()}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(StudentLessonsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(StudentLessonsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(StudentLessonsPermissions.Delete)} />
    );
}

export default StudentLessonsList;
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { SchoolLicenseField } from "../../constants/fields/SchoolLicenseField";
import { SchoolLicense } from "../../models/school-license/SchoolLicense";
import { CreateSchoolLicenseRequest } from "../../models/school-license/CreateSchoolLicenseRequest";
import { UpdateSchoolLicenseRequest } from "../../models/school-license/UpdateSchoolLicenseRequest";
import { SchoolLicenseFields } from "../../fields/SchoolLicenseFields";
import { SchoolLicenseService } from "../../services/SchoolLicenseService";
import { SchoolLicensesPermissions } from '../../constants/permissions/SchoolLicensesPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import SchoolLicenseStatement from "./SchoolLicenseStatement";

const SchoolLicensesList = () => {

    const additionalListButtonsCodes = {
        SchoolLicenseStatement: "SchoolLicenseStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.SchoolLicenseStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedSchoolLicense, setSelectedSchoolLicense] = useState<SchoolLicense | null>(null);

    const [isSchoolLicenseStatementOpened, setIsSchoolLicenseStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.SchoolLicenseStatement]: false
    });

    const onRecordSelect = (schoolLicense: SchoolLicense | null) => {
        setSelectedSchoolLicense(schoolLicense);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.SchoolLicenseStatement]: UserAuthentication.hasPermissions(SchoolLicensesPermissions.View)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, schoolLicense: SchoolLicense | null) => {
        if (schoolLicense) {
            if (buttonCode === additionalListButtonsCodes.SchoolLicenseStatement) {
                setIsSchoolLicenseStatementOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <List
                title={"ترخيص المدرسة"}
                hints={["عند تجديد ترخيص المدرسة قم بإضافة ترخيص جديد بدلاً من تعديل القديم للحفاظ على معلومات التراخيص القديمة"]}
                service={new SchoolLicenseService()}
                fields={SchoolLicenseFields.get()}
                fieldsCodesExcludedFromList={[SchoolLicenseField.ExpirationStatus]}
                filters={ListFilters.schoolLicense()}
                createSuccessMessage={"تم اضافة ترخيص المدرسة بنجاح"}
                editSuccessMessage={"تم تعديل ترخيص المدرسة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف ترخيص المدرسة ؟"}
                deleteSuccessMessage={"تم حذف ترخيص المدرسة بنجاح"}
                createModel={new CreateSchoolLicenseRequest()}
                editModel={new UpdateSchoolLicenseRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(SchoolLicensesPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(SchoolLicensesPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(SchoolLicensesPermissions.Delete)}
                onRecordSelect={(schoolLicense: SchoolLicense | null) => onRecordSelect(schoolLicense)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />


            <Dialog
                header={"حساب ترخيص المدرسة"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isSchoolLicenseStatementOpened}
                onHide={() => setIsSchoolLicenseStatementOpened(false)} >

                {selectedSchoolLicense &&
                    <SchoolLicenseStatement
                        id={selectedSchoolLicense.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default SchoolLicensesList;
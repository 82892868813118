import { http } from "../http/Http";
import { EmployeeJob } from "../models/employee-job/EmployeeJob";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class EmployeeJobService {

    public EntityCode: EntityCode = EntityCode.EmployeeJob;

    private Api: string = "EmployeeJobs";

    getAll = async (): Promise<EmployeeJob[]> => {
        const { data } = await http.get<EmployeeJob[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
import { FieldType } from "../../constants/base/FieldType";
import { DiscountTypeCode } from "../../constants/closed-lookup/DiscountTypeCode";
import { Field } from "../../models/base/Field";
import { Convertor } from "../../utilities/Convertor";
import { ValueGetter } from "../../utilities/ValueGetter";

const FieldTemplate = (record: any, field: Field) => {
    if (record && field) {

        if (field.CustomTemplateCode) {
            switch (field.CustomTemplateCode) {
                case "StudentDiscountAmount":
                    {
                        let value = record.DiscountAmount;
                        if (value !== null && value !== undefined && value !== "") {
                            if (record.DiscountTypeCode === DiscountTypeCode.Fixed) {
                                value = "NIS " + value.toString();
                            }
                            else if (record.DiscountTypeCode === DiscountTypeCode.Percent) {
                                value = "%" + value.toString();
                            }
                        }
                        return <span className={value === null || value === undefined || value === "" ? "visibility-hidden" : ""}>
                            {value === null || value === undefined || value === "" ? "-" : value}
                        </span>;
                    }
                case "CheckDateStatus":
                    {
                        if (record.CheckDateStatus) {
                            let differenceDays = record.CheckDateStatus.DifferenceDays;
                            if (differenceDays !== null && differenceDays !== undefined) {
                                let value: string | null = null;
                                if (differenceDays < -2) {
                                    value = "أستحق قبل " + Convertor.durationStatusToDescription(record.CheckDateStatus, "فترة");
                                }
                                else if (differenceDays === -2) {
                                    value = "أستحق قبل أمس";
                                }
                                else if (differenceDays === -1) {
                                    value = "أستحق بالأمس";
                                }
                                else if (differenceDays === 0) {
                                    value = "أستحق اليوم";
                                }
                                else if (differenceDays === 1) {
                                    value = "يستحق غدا";
                                }
                                else if (differenceDays === 2) {
                                    value = "يستحق بعد غد";
                                }
                                else {
                                    value = "يستحق بعد " + Convertor.durationStatusToDescription(record.CheckDateStatus, "فترة");
                                }
                                return <span className={"font-normal " + (differenceDays < 0 ? "text-red-600" : (differenceDays > 0 ? "text-orange-600" : "text-green-600"))}>
                                    {value}
                                </span>;
                            }
                        }
                        return <span className="visibility-hidden">{"-"}</span>;
                    }
                case "ExpirationStatus":
                    {
                        if (record.ExpirationStatus) {
                            let differenceDays = record.ExpirationStatus.DifferenceDays;
                            if (differenceDays !== null && differenceDays !== undefined) {
                                let isTrainerLicense: boolean = record.TrainerLicenseTypeCode !== null && record.TrainerLicenseTypeCode !== undefined && record.TrainerLicenseTypeCode !== "";
                                let value: string | null = null;
                                if (differenceDays < -2) {
                                    value = (isTrainerLicense ? "انتهت" : "انتهى") + " قبل " + Convertor.durationStatusToDescription(record.ExpirationStatus, "فترة");
                                }
                                else if (differenceDays === -2) {
                                    value = (isTrainerLicense ? "انتهت" : "انتهى") + " قبل أمس";
                                }
                                else if (differenceDays === -1) {
                                    value = (isTrainerLicense ? "انتهت" : "انتهى") + " بالأمس";
                                }
                                else if (differenceDays === 0) {
                                    value = (isTrainerLicense ? "تنتهي" : "ينتهي") + " اليوم";
                                }
                                else if (differenceDays === 1) {
                                    value = (isTrainerLicense ? "تنتهي" : "ينتهي") + " غدا";
                                }
                                else if (differenceDays === 2) {
                                    value = (isTrainerLicense ? "تنتهي" : "ينتهي") + " بعد غد";
                                }
                                else {
                                    value = (isTrainerLicense ? "تنتهي" : "ينتهي") + " بعد " + Convertor.durationStatusToDescription(record.ExpirationStatus, "فترة");
                                }
                                return <span className={"font-normal " + (differenceDays < 0 ? "text-red-600" : (differenceDays > 0 ? "text-orange-600" : "text-green-600"))}>
                                    {value}
                                </span>;
                            }
                        }
                        return <span className="visibility-hidden">{"-"}</span>;
                    }

                default:
                    return <span className="visibility-hidden">{"-"}</span>;
            }
        }



        let value = ValueGetter.get(record, field.Code);
        switch (field.Type) {
            case FieldType.Boolean:
                return field.Code === "IsActive" ?
                    <span><i className={value === true ? "pi pi-check-circle text-green-500" : "pi pi-times-circle text-pink-500"}></i></span> :
                    <span className={value === null || value === undefined ? "visibility-hidden" : ""}>
                        {value === null || value === undefined ? "-" : (value === true ? "نعم" : "لا")}
                    </span>;

            case FieldType.Date:
                let dateValue = Convertor.dateToString(value ? new Date(value) : null, true);
                return <span className={"block direction-ltr text-right" + (dateValue === null || dateValue === undefined || dateValue === "" ? " visibility-hidden" : "")}>
                    {dateValue === null || dateValue === undefined || dateValue === "" ? "-" : dateValue}
                </span>;

            case FieldType.DateTime:
                let dateTimeValue = Convertor.datetimeToString(value ? new Date(value) : null, true);
                return <span className={"block direction-ltr text-right" + (dateTimeValue === null || dateTimeValue === undefined || dateTimeValue === "" ? " visibility-hidden" : "")}>
                    {dateTimeValue === null || dateTimeValue === undefined || dateTimeValue === "" ? "-" : dateTimeValue}
                </span>;

            default:
                {
                    if (value !== null && value !== undefined && value !== "") {
                        if (field.IsCurrency) {
                            value = "NIS " + value.toString();
                        }
                        else if (field.IsPercent) {
                            value = "%" + value.toString();
                        }
                    }

                    return <span className={(field.IsOneLine ? "white-space-nowrap" : "") + (value === null || value === undefined || value === "" ? " visibility-hidden" : "")}>
                        {
                            value === null || value === undefined || value === "" ?
                                "-" :
                                (field.IsLtr ? Convertor.stringToLtrString(value.toString()) : value.toString())
                        }
                    </span>;
                }
        }
    }
    return <span className="visibility-hidden">{"-"}</span>;
}

export default FieldTemplate;
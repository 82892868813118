import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { TrainerLicenseField } from "../../constants/fields/TrainerLicenseField";
import { TrainerLicense } from "../../models/trainer-license/TrainerLicense";
import { CreateTrainerLicenseRequest } from "../../models/trainer-license/CreateTrainerLicenseRequest";
import { UpdateTrainerLicenseRequest } from "../../models/trainer-license/UpdateTrainerLicenseRequest";
import { TrainerLicenseFields } from "../../fields/TrainerLicenseFields";
import { TrainerLicenseService } from "../../services/TrainerLicenseService";
import { TrainerLicensesPermissions } from '../../constants/permissions/TrainerLicensesPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import TrainerLicenseStatement from "./TrainerLicenseStatement";

const TrainerLicensesList = () => {

    const additionalListButtonsCodes = {
        TrainerLicenseStatement: "TrainerLicenseStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.TrainerLicenseStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedTrainerLicense, setSelectedTrainerLicense] = useState<TrainerLicense | null>(null);

    const [isTrainerLicenseStatementOpened, setIsTrainerLicenseStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.TrainerLicenseStatement]: false
    });

    const onRecordSelect = (trainerLicense: TrainerLicense | null) => {
        setSelectedTrainerLicense(trainerLicense);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.TrainerLicenseStatement]: UserAuthentication.hasPermissions(TrainerLicensesPermissions.View)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, trainerLicense: TrainerLicense | null) => {
        if (trainerLicense) {
            if (buttonCode === additionalListButtonsCodes.TrainerLicenseStatement) {
                setIsTrainerLicenseStatementOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <List
                title={"رخص المدربين"}
                hints={["عند تجديد رخصة مدرب قم بإضافة رخصة جديدة بدلاً من تعديل القديمة للحفاظ على معلومات الرخص القديمة"]}
                service={new TrainerLicenseService()}
                fields={TrainerLicenseFields.get()}
                fieldsCodesExcludedFromList={[TrainerLicenseField.ExpirationStatus]}
                filters={ListFilters.trainerLicense()}
                createSuccessMessage={"تم اضافة رخصة المدرب بنجاح"}
                editSuccessMessage={"تم تعديل رخصة المدرب بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف رخصة المدرب ؟"}
                deleteSuccessMessage={"تم حذف رخصة المدرب بنجاح"}
                createModel={new CreateTrainerLicenseRequest()}
                editModel={new UpdateTrainerLicenseRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(TrainerLicensesPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(TrainerLicensesPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(TrainerLicensesPermissions.Delete)}
                onRecordSelect={(trainerLicense: TrainerLicense | null) => onRecordSelect(trainerLicense)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />


            <Dialog
                header={"حساب رخصة المدرب"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isTrainerLicenseStatementOpened}
                onHide={() => setIsTrainerLicenseStatementOpened(false)} >

                {selectedTrainerLicense &&
                    <TrainerLicenseStatement
                        id={selectedTrainerLicense.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default TrainerLicensesList;
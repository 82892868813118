import { http } from "../http/Http";
import { SchoolPayment } from "../models/school-payment/SchoolPayment";
import { CreateSchoolPaymentRequest } from "../models/school-payment/CreateSchoolPaymentRequest";
import { UpdateSchoolPaymentRequest } from "../models/school-payment/UpdateSchoolPaymentRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class SchoolPaymentService {

    public EntityCode: EntityCode = EntityCode.SchoolPayment;

    private Api: string = "SchoolPayments";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<SchoolPayment>> => {
        const { data } = await http.post<GetByFiltersResponse<SchoolPayment>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<SchoolPayment> => {
        const { data } = await http.get<SchoolPayment>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createSchoolPaymentRequest: CreateSchoolPaymentRequest): Promise<SchoolPayment> => {
        const { data } = await http.post<SchoolPayment>(this.Api, createSchoolPaymentRequest);
        return data;
    };

    update = async (id: number, updateSchoolPaymentRequest: UpdateSchoolPaymentRequest): Promise<SchoolPayment> => {
        const { data } = await http.put<SchoolPayment>(this.Api + "/" + id.toString(), updateSchoolPaymentRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
export enum FieldType {
    String = "String",
    Text = "Text",
    Password = "Password",
    Integer = "Integer",
    Decimal = "Decimal",
    Boolean = "Boolean",
    Date = "Date",
    DateTime = "DateTime",
    Lookup = "Lookup",
    ListLookup = "ListLookup",
    ClosedLookup = "ClosedLookup",
    Year = "Year",
    NoType = "NoType"
}
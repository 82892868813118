import { FilterOperator } from "../constants/base/FilterOperator";
import { FieldType } from "../constants/base/FieldType";
import { DropdownOption } from "../models/base/DropdownOption";

export class FilterOperatorOptions {

    static get(fieldType: FieldType): DropdownOption[] {
        let filterOperators: FilterOperator[] = [];

        if (fieldType === FieldType.String || fieldType === FieldType.Text) {
            filterOperators = [
                FilterOperator.Contains,
                FilterOperator.Equals,
                FilterOperator.NotEquals,
                FilterOperator.NotContains,
                FilterOperator.StartsWith,
                FilterOperator.EndsWith
            ];
        }
        else if (fieldType === FieldType.Integer || fieldType === FieldType.Decimal || fieldType === FieldType.Date || fieldType === FieldType.DateTime || fieldType === FieldType.Year) {
            filterOperators = [
                FilterOperator.Equals,
                FilterOperator.NotEquals,
                FilterOperator.GreaterThan,
                FilterOperator.GreaterThanOrEqual,
                FilterOperator.LessThan,
                FilterOperator.LessThanOrEqual
            ];
        }
        else if (fieldType === FieldType.Boolean || fieldType === FieldType.Lookup || fieldType === FieldType.ListLookup || fieldType === FieldType.ClosedLookup) {
            filterOperators = [
                FilterOperator.Equals,
                FilterOperator.NotEquals
            ];
        }

        return filterOperators.map(filterOperator => {
            return new DropdownOption(filterOperator, this.getOperatorName(filterOperator));
        });
    }

    private static getOperatorName(filterOperator: FilterOperator): string {
        switch (filterOperator) {
            case "Equals":
                return "يساوي";
            case "NotEquals":
                return "لا يساوي";
            case "GreaterThan":
                return "أكبر من";
            case "GreaterThanOrEqual":
                return "أكبر من أو يساوي";
            case "LessThan":
                return "أقل من";
            case "LessThanOrEqual":
                return "أقل من أو يساوي";
            case "Contains":
                return "يحتوي على";
            case "NotContains":
                return "لا يحتوي على";
            case "StartsWith":
                return "يبدأ بـ";
            case "EndsWith":
                return "ينتهي بـ";
            default:
                return "";
        }
    }
}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { StudentField } from "../constants/fields/StudentField";
import { StudentLabel } from "../constants/labels/StudentLabel";
import { GenderService } from "../services/GenderService";
import { CityService } from "../services/CityService";

export class StudentFields {

    static get(): Field[] {
        return [

            new Field({
                Code: StudentField.FirstName,
                Label: StudentLabel.FirstName,
                Type: FieldType.String,
                ShowInCreate: true,
                ShowInEdit: true
            }),
            new Field({
                Code: StudentField.SecondName,
                Label: StudentLabel.SecondName,
                Type: FieldType.String,
                ShowInCreate: true,
                ShowInEdit: true
            }),
            new Field({
                Code: StudentField.ThirdName,
                Label: StudentLabel.ThirdName,
                Type: FieldType.String,
                ShowInCreate: true,
                ShowInEdit: true
            }),
            new Field({
                Code: StudentField.LastName,
                Label: StudentLabel.LastName,
                Type: FieldType.String,
                ShowInCreate: true,
                ShowInEdit: true
            }),
            new Field({
                Code: StudentField.FullName,
                Label: StudentLabel.FullName,
                Type: FieldType.String,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentField.GenderCode,
                Label: StudentLabel.GenderCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new GenderService()
            }),

            new Field({
                Code: StudentField.Gender_Name,
                Label: StudentLabel.Gender_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: StudentField.IdentityNumber,
                Label: StudentLabel.IdentityNumber,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: StudentField.CityCode,
                Label: StudentLabel.CityCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsDropdownFilterEnabled: true,
                LookupService: new CityService()
            }),

            new Field({
                Code: StudentField.City_Name,
                Label: StudentLabel.City_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: StudentField.Address,
                Label: StudentLabel.Address,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ WidthPercent: 25 })
            }),

            new Field({
                Code: StudentField.Phone,
                Label: StudentLabel.Phone,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: StudentField.Email,
                Label: StudentLabel.Email,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ WidthPercent: 20 })
            }),

            new Field({
                Code: StudentField.BirthDate,
                Label: StudentLabel.BirthDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),

            new Field({
                Code: StudentField.TotalFees,
                Label: StudentLabel.TotalFees,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: StudentField.TotalFeesAfterDiscounts,
                Label: StudentLabel.TotalFeesAfterDiscounts,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: StudentField.TotalPaid,
                Label: StudentLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: StudentField.TotalRemaining,
                Label: StudentLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: StudentField.TotalOverpaid,
                Label: StudentLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: StudentField.TotalTheoreticalExamsNumber,
                Label: StudentLabel.TotalTheoreticalExamsNumber,
                Type: FieldType.Integer
            }),

            new Field({
                Code: StudentField.TotalLessonsNumber,
                Label: StudentLabel.TotalLessonsNumber,
                Type: FieldType.Integer
            }),

            new Field({
                Code: StudentField.TotalPracticalExamsNumber,
                Label: StudentLabel.TotalPracticalExamsNumber,
                Type: FieldType.Integer
            })
        ];
    }

}
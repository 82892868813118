import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { SubscriptionRequestField } from "../constants/fields/SubscriptionRequestField";
import { SubscriptionRequestLabel } from "../constants/labels/SubscriptionRequestLabel";

export class SubscriptionRequestFields {

    static get(): Field[] {
        return [
            new Field({
                Code: SubscriptionRequestField.SchoolName,
                Label: SubscriptionRequestLabel.SchoolName,
                Type: FieldType.String,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: SubscriptionRequestField.UsersCount,
                Label: SubscriptionRequestLabel.UsersCount,
                Type: FieldType.Integer,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SubscriptionRequestField.Phone,
                Label: SubscriptionRequestLabel.Phone,
                Type: FieldType.String,
                ShowInFilters: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: SubscriptionRequestField.Email,
                Label: SubscriptionRequestLabel.Email,
                Type: FieldType.String,
                ShowInFilters: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: SubscriptionRequestField.City,
                Label: SubscriptionRequestLabel.City,
                Type: FieldType.String,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: SubscriptionRequestField.Address,
                Label: SubscriptionRequestLabel.Address,
                Type: FieldType.String,
                ShowInFilters: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),
            new Field({
                Code: SubscriptionRequestField.RequestDate,
                Label: SubscriptionRequestLabel.RequestDate,
                Type: FieldType.DateTime,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SubscriptionRequestField.Notes,
                Label: SubscriptionRequestLabel.Notes,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            })
        ];
    }

}
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { UserAuthentication } from "../utilities/UserAuthentication";
import { AppToast } from "../utilities/AppToast";
import { PermissionsMapper } from "../utilities/PermissionsMapper";
import { Errors } from "../errors/Errors";

// enum StatusCode {
//     Unauthorized = 401,
//     Forbidden = 403,
//     TooManyRequests = 429,
//     InternalServerError = 500,
// }

const headers: Readonly<Record<string, string | boolean>> = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    //"Access-Control-Allow-Origin": "*",
    //"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // "Access-Control-Allow-Credentials": true,
    //"X-Requested-With": "XMLHttpRequest",
};

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
    let token = UserAuthentication.getToken();

    if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
};

class Http {

    //private baseUrl: string = "http://localhost:5047";

    private instance: AxiosInstance | null = null;
    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp();
    }

    initHttp() {
        const http = axios.create({
            //baseURL: this.baseUrl,
            baseURL: this.getApiUrl(),
            headers//,
            //withCredentials: true,
        });

        http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

        http.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const { response } = error;
                return this.handleError(response);
            }
        );

        this.instance = http;
        return http;
    }

    getApiUrl() {
        let baseUrl = window.origin;
        if (baseUrl && (baseUrl.toLowerCase().includes("localhost") || baseUrl.toLowerCase().includes("127.0.0.1"))) {
            return "http://localhost:5047";
        }
        return baseUrl + "/api";
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config);
    }

    // getFullUrl(url: string): string {
    //     this.setBaseUrl(url);
    //     return this.baseUrl + "/" + url;
    // }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        //this.setBaseUrl(url);
        return this.http.get<T, R>(url, config);
    }

    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        //this.setBaseUrl(url);
        return this.http.post<T, R>(url, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        //this.setBaseUrl(url);
        return this.http.put<T, R>(url, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        //this.setBaseUrl(url);
        return this.http.delete<T, R>(url, config);
    }

    // private setBaseUrl(url: string) {
    //     if (this.isUrlAbsolute(url)) {
    //         this.http.defaults.baseURL = url;
    //     } else {
    //         this.http.defaults.baseURL = this.baseUrl;
    //     }
    // }

    // private isUrlAbsolute(url: string) {
    //     return url.indexOf('//') === 0 ? true :
    //         url.indexOf('://') === -1 ? false :
    //             url.indexOf('.') === -1 ? false :
    //                 url.indexOf('/') === -1 ? false :
    //                     url.indexOf(':') > url.indexOf('/') ? false :
    //                         url.indexOf('://') < url.indexOf('.') ? true : false;
    // }

    private handleError(error: any) {
        //const { status } = error;

        // switch (status) {
        //     case StatusCode.InternalServerError: {
        //         // Handle InternalServerError
        //         //console.log("InternalServerError:" + error);
        //         break;
        //     }
        //     case StatusCode.Forbidden: {
        //         // Handle Forbidden
        //         //console.log("Forbidden:" + error);
        //         break;
        //     }
        //     case StatusCode.Unauthorized: {
        //         //console.log("Unauthorized:" + error);
        //         //UserAuthentication.set(null);
        //         break;
        //     }
        //     case StatusCode.TooManyRequests: {
        //         // Handle TooManyRequests
        //         //console.log("TooManyRequests:" + error);
        //         break;
        //     }
        // }

        if (error &&
            error.data &&
            error.data.title?.toString()?.toLowerCase() !== "One or more validation errors occurred.".toLowerCase() &&
            error.data.message?.toString()?.toLowerCase() !== "Invalid token".toLowerCase()) {

            if (error.data.message?.toString()?.toLowerCase() === "Unauthorized".toLowerCase() &&
                error.data.unauthorizedPermissions && error.data.unauthorizedPermissions.length > 0) {

                let errorMessage = "المستخدم الخاص بك لا يملك الصلاحيات التالية: ";

                error.data.unauthorizedPermissions.forEach((unauthorizedPermission: string) => {
                    let unauthorizedPermissionFullName = PermissionsMapper.getFullName(unauthorizedPermission);
                    if (unauthorizedPermissionFullName) {
                        errorMessage = errorMessage + unauthorizedPermissionFullName + " / ";
                    }
                });

                AppToast.error(this.cleanPermissionsErrorMessage(errorMessage), 3000);
            }
            else if (error.data.message?.toString()?.toLowerCase() === "Unauthorized".toLowerCase()) {
                //UserAuthentication.set(null);
                //.goToLogin();
                //AppToast.error("المستخدم الخاص بك لا يملك صلاحيات كافية. قم بمحاولة تسجيل الخروج والدخول مجددا", 3000);

                AppToast.error("المستخدم الخاص بك لا يملك صلاحيات كافية");

                setTimeout(() => {
                    UserAuthentication.set(null);
                }, 1500);
            }
            else {
                let ignoreNotFoundError = error.config && error.config.headers && error.config.headers["IgnoreNotFoundError"] ? true : false;
                if (error.status !== 404 || !ignoreNotFoundError) {
                    AppToast.error(Errors.get(error.data.message));
                }
            }
        }

        return Promise.reject(error);
    }

    private cleanPermissionsErrorMessage(errorMessage: string) {
        if (errorMessage) {
            if (errorMessage.endsWith(" / ")) {
                return errorMessage.slice(0, -3);
            }
            return errorMessage;
        }
        return null;
    }
}

export const http = new Http();
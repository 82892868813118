import { http } from "../http/Http";
import { StudentPayment } from "../models/student-payment/StudentPayment";
import { CreateStudentPaymentRequest } from "../models/student-payment/CreateStudentPaymentRequest";
import { UpdateStudentPaymentRequest } from "../models/student-payment/UpdateStudentPaymentRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentPaymentService {

    public EntityCode: EntityCode = EntityCode.StudentPayment;

    private Api: string = "StudentPayments";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<StudentPayment>> => {
        const { data } = await http.post<GetByFiltersResponse<StudentPayment>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<StudentPayment> => {
        const { data } = await http.get<StudentPayment>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createStudentPaymentRequest: CreateStudentPaymentRequest): Promise<StudentPayment> => {
        const { data } = await http.post<StudentPayment>(this.Api, createStudentPaymentRequest);
        return data;
    };

    update = async (id: number, updateStudentPaymentRequest: UpdateStudentPaymentRequest): Promise<StudentPayment> => {
        const { data } = await http.put<StudentPayment>(this.Api + "/" + id.toString(), updateStudentPaymentRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
import { v4 as uuidv4 } from "uuid";

export class Generator {

    static guid(): string {
        return uuidv4();
    }

    static random(length: number = 20): string {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { CarInsuranceField } from "../constants/fields/CarInsuranceField";
import { CarField } from "../constants/fields/CarField";
import { CarInsuranceLabel } from "../constants/labels/CarInsuranceLabel";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { Templates } from "../utilities/Templates";
import { CarService } from "../services/CarService";
import { InsuranceCompanyService } from "../services/InsuranceCompanyService";

export class CarInsuranceFields {

    static get(): Field[] {
        return [
            new Field({
                Code: CarInsuranceField.CarId,
                Label: CarInsuranceLabel.CarId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarService(),
                LookupLoadFilters: LookupLoadFilters.car(),
                LookupDisplayTemplate: Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name)
            }),

            new Field({
                Code: CarInsuranceField.Car_CarCompany_Name,
                Label: CarInsuranceLabel.Car_CarCompany_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarInsuranceField.Car_CarType_Name,
                Label: CarInsuranceLabel.Car_CarType_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarInsuranceField.Car_PlateNumber,
                Label: CarInsuranceLabel.Car_PlateNumber,
                Type: FieldType.String,
                ShowInListLookup: true,
                IsLtr: true,
                IsOneLine: true,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: CarInsuranceField.InsuranceCompanyCode,
                Label: CarInsuranceLabel.InsuranceCompanyCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new InsuranceCompanyService()
            }),

            new Field({
                Code: CarInsuranceField.InsuranceCompany_Name,
                Label: CarInsuranceLabel.InsuranceCompany_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarInsuranceField.StartDate,
                Label: CarInsuranceLabel.StartDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: CarInsuranceField.EndDate,
                Label: CarInsuranceLabel.EndDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarInsuranceField.Cost,
                Label: CarInsuranceLabel.Cost,
                Type: FieldType.Decimal,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarInsuranceField.TotalPaid,
                Label: CarInsuranceLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarInsuranceField.TotalOverpaid,
                Label: CarInsuranceLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarInsuranceField.TotalRemaining,
                Label: CarInsuranceLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarInsuranceField.ExpirationStatus,
                Label: CarInsuranceLabel.ExpirationStatus,
                Type: FieldType.String,
                CustomTemplateCode: "ExpirationStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
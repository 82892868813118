import { Template } from "../models/base/Template";
import { FieldMetadata } from "../models/base/FieldMetadata";
import { FieldType } from "../constants/base/FieldType";

export class Templates {

    //private static splitter = "⬥";
    private static splitter = "|";

    static car(plateNumberFieldCode: string, companyNameFieldCode: string, typeNameFieldCode: string): Template {
        if (plateNumberFieldCode && companyNameFieldCode && typeNameFieldCode) {
            return new Template({
                Fields: [
                    new FieldMetadata({ Code: plateNumberFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: companyNameFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: typeNameFieldCode, Type: FieldType.String, IsLtr: true })
                ],
                Expression: `{${plateNumberFieldCode}} ${this.splitter} {${typeNameFieldCode}} ${this.splitter} {${companyNameFieldCode}}`
            });
        }
        return this.empty();
    }

    static carLicenseOrInsurance(plateNumberFieldCode: string, companyNameFieldCode: string, typeNameFieldCode: string, startDateFieldCode: string, endDateFieldCode: string): Template {
        if (plateNumberFieldCode && companyNameFieldCode && typeNameFieldCode && startDateFieldCode && endDateFieldCode) {
            return new Template({
                Fields: [
                    new FieldMetadata({ Code: plateNumberFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: companyNameFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: typeNameFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: startDateFieldCode, Type: FieldType.Date, IsLtr: true }),
                    new FieldMetadata({ Code: endDateFieldCode, Type: FieldType.Date, IsLtr: true })
                ],
                Expression: `{${endDateFieldCode}} - {${startDateFieldCode}} ${this.splitter} {${plateNumberFieldCode}} ${this.splitter} {${typeNameFieldCode}} ${this.splitter} {${companyNameFieldCode}}`
            })
        }
        return this.empty();
    }

    static schoolLicense(startDateFieldCode: string, endDateFieldCode: string): Template {
        if (startDateFieldCode && endDateFieldCode) {
            return new Template({
                Fields: [
                    new FieldMetadata({ Code: startDateFieldCode, Type: FieldType.Date }),
                    new FieldMetadata({ Code: endDateFieldCode, Type: FieldType.Date })
                ],
                Expression: `{${startDateFieldCode}} - {${endDateFieldCode}}`
            });
        }
        return this.empty();
    }

    static trainerLicense(employeeNameFieldCode: string, licenseTypeNameFieldCode: string, startDateFieldCode: string, endDateFieldCode: string): Template {
        if (employeeNameFieldCode && licenseTypeNameFieldCode && startDateFieldCode && endDateFieldCode) {
            return new Template({
                Fields: [
                    new FieldMetadata({ Code: employeeNameFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: licenseTypeNameFieldCode, Type: FieldType.String, IsLtr: true }),
                    new FieldMetadata({ Code: startDateFieldCode, Type: FieldType.Date, IsLtr: true }),
                    new FieldMetadata({ Code: endDateFieldCode, Type: FieldType.Date, IsLtr: true })
                ],
                Expression: `{${endDateFieldCode}} - {${startDateFieldCode}} ${this.splitter} {${licenseTypeNameFieldCode}} ${this.splitter} {${employeeNameFieldCode}}`
            })
        }
        return this.empty();
    }

    private static empty(): Template {
        return new Template({ Fields: [], Expression: "" });
    }

}
import { http } from "../http/Http";
import { Employee } from "../models/employee/Employee";
import { CreateEmployeeRequest } from "../models/employee/CreateEmployeeRequest";
import { UpdateEmployeeRequest } from "../models/employee/UpdateEmployeeRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class EmployeeService {

    public EntityCode: EntityCode = EntityCode.Employee;

    private Api: string = "Employees";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Employee>> => {
        const { data } = await http.post<GetByFiltersResponse<Employee>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Employee>> => {
        const { data } = await http.post<GetByFiltersResponse<Employee>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<Employee> => {
        const { data } = await http.get<Employee>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<Employee> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<Employee>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    create = async (createEmployeeRequest: CreateEmployeeRequest): Promise<Employee> => {
        const { data } = await http.post<Employee>(this.Api, createEmployeeRequest);
        return data;
    };

    update = async (id: number, updateEmployeeRequest: UpdateEmployeeRequest): Promise<Employee> => {
        const { data } = await http.put<Employee>(this.Api + "/" + id.toString(), updateEmployeeRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
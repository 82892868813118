export class CreateCarRequest {
    PlateNumber: string;
    CarCompanyCode: string;
    ManufactureYear: number;
    ColorCode: string;
    CarTypeCode: string;
    CarPowerTypeCode: string;
    Cost: number;

    Initialize() {
        this.Cost = 0;
        return this;
    }
}
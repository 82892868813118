import { FilterOperator } from '../../constants/base/FilterOperator';

export class Filter {
    Property: string;
    Operator: FilterOperator;

    constructor(property: string, operator: FilterOperator) {
        this.Property = property;
        this.Operator = operator;
    }
}
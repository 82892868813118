export class CreateStudentPracticalExamRequest {
    StudentId: number;
    StudyTypeCode: string;
    StudentExamStatusCode: string;
    CarId: number;
    Date: Date;
    Fees: number;

    Initialize() {
        this.Fees = 0;
        return this;
    }
}
import { http } from "../http/Http";
import { CarType } from "../models/car-type/CarType";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class CarTypeService {

    public EntityCode: EntityCode = EntityCode.CarType;

    private Api: string = "CarTypes";

    getAll = async (): Promise<CarType[]> => {
        const { data } = await http.get<CarType[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
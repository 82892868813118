import React, { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { AuditLogService } from "../../services/AuditLogService";
import { GetByFiltersRequestBuilder } from "../../builders/GetByFiltersRequestBuilder";
import { GetByFiltersRequest } from "../../models/base/GetByFiltersRequest";
import { AuditLog } from "../../models/audit-log/AuditLog";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { AuditLogField } from "../../constants/fields/AuditLogField";
import { EntityCode } from "../../constants/closed-lookup/EntityCode";
import { AuditLogActionCode } from "../../constants/closed-lookup/AuditLogActionCode";
import AuditLogDetails from "./AuditLogDetails";

type Props = {
    entityCode: EntityCode,
    entityId: number
};

const DeletedEntityAuditLogDetails = (props: Props) => {

    const auditLogService: AuditLogService = new AuditLogService();

    const [isAuditLogLoading, setIsAuditLogLoading] = useState<boolean>(false);
    const [auditLog, setAuditLog] = useState<AuditLog | null>(null);

    useEffect(() => {
        loadAuditLog();
    }, [props.entityCode, props.entityId]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadAuditLog = () => {
        let getByFiltersRequest: GetByFiltersRequest = new GetByFiltersRequestBuilder()
            .addFilter(AuditLogField.AuditLogActionCode, AuditLogActionCode.Delete, FilterOperator.Equals)
            .addFilter(AuditLogField.EntityCode, props.entityCode, FilterOperator.Equals)
            .addFilter(AuditLogField.EntityId, props.entityId.toString(), FilterOperator.Equals)
            .pageIndex(0)
            .pageSize(1)
            .build();

        setIsAuditLogLoading(true);
        auditLogService.getByFilters(getByFiltersRequest).then((response) => {
            setAuditLog(response && response.Data && response.Data.length > 0 ? response.Data[0] : null);
            setIsAuditLogLoading(false);
        }).catch((_error: any) => {
            setAuditLog(null);
            setIsAuditLogLoading(false);
        });
    }

    return (
        <React.Fragment>
            {isAuditLogLoading &&
                <div className="p-3">
                    <ProgressBar mode="indeterminate" className="m-auto w-9 h-0-7rem" />
                </div>
            }

            {!isAuditLogLoading && auditLog &&
                <AuditLogDetails auditLog={auditLog} />
            }

            {!isAuditLogLoading && !auditLog &&
                <div className="p-3 text-center text-red-600">لم يتم العثور على الحركة</div>
            }
        </React.Fragment>
    );
}

export default DeletedEntityAuditLogDetails;
import { Convertor } from "../../utilities/Convertor";

export class CreateSchoolRequest {
    Name: string;
    Phone: string;
    Address: string;
    CityCode: string;
    DemoExpirationDate: Date | null;
    MaxUsersCount: number;
    IsManagement: boolean;
    MainUserEmail: string;
    MainUserPassword: string;
    MainUserName: string;
    MainUserGenderCode: string;
    Notes: string;

    Initialize() {
        let defaultDate: Date = new Date();
        defaultDate.setDate(defaultDate.getDate() + 10);
        let demoExpirationDate: any = Convertor.datetimeToStringWithoutSeconds(defaultDate);
        this.DemoExpirationDate = demoExpirationDate ? demoExpirationDate : null;
        this.MaxUsersCount = 3;
        this.IsManagement = false;
        return this;
    }
}
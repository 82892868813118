import { http } from "../http/Http";
import { Gender } from "../models/gender/Gender";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class GenderService {

    public EntityCode: EntityCode = EntityCode.Gender;

    private Api: string = "Genders";

    getAll = async (): Promise<Gender[]> => {
        const { data } = await http.get<Gender[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { EmployeeLabel } from "../constants/labels/EmployeeLabel";
import { GenderService } from "../services/GenderService";
import { CityService } from "../services/CityService";
import { EmployeeJobService } from "../services/EmployeeJobService";

export class EmployeeFields {

    static get(): Field[] {
        return [
            new Field({
                Code: EmployeeField.Name,
                Label: EmployeeLabel.Name,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: EmployeeField.IdentityNumber,
                Label: EmployeeLabel.IdentityNumber,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: EmployeeField.GenderCode,
                Label: EmployeeLabel.GenderCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new GenderService()
            }),

            new Field({
                Code: EmployeeField.Gender_Name,
                Label: EmployeeLabel.Gender_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: EmployeeField.EmployeeJobCode,
                Label: EmployeeLabel.EmployeeJobCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new EmployeeJobService()
            }),

            new Field({
                Code: EmployeeField.EmployeeJob_Name,
                Label: EmployeeLabel.EmployeeJob_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: EmployeeField.CityCode,
                Label: EmployeeLabel.CityCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsDropdownFilterEnabled: true,
                LookupService: new CityService()
            }),

            new Field({
                Code: EmployeeField.City_Name,
                Label: EmployeeLabel.City_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: EmployeeField.Address,
                Label: EmployeeLabel.Address,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),
            new Field({
                Code: EmployeeField.Phone,
                Label: EmployeeLabel.Phone,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: EmployeeField.BirthDate,
                Label: EmployeeLabel.BirthDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
import { http } from "../http/Http";
import { SubscriptionRequest } from "../models/subscription-request/SubscriptionRequest";
import { CreateSubscriptionRequestRequest } from "../models/subscription-request/CreateSubscriptionRequestRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class SubscriptionRequestService {

    public EntityCode: EntityCode = EntityCode.SubscriptionRequest;

    private Api: string = "SubscriptionRequests";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<SubscriptionRequest>> => {
        const { data } = await http.post<GetByFiltersResponse<SubscriptionRequest>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    create = async (createSubscriptionRequestRequest: CreateSubscriptionRequestRequest): Promise<SubscriptionRequest> => {
        const { data } = await http.post<SubscriptionRequest>(this.Api, createSubscriptionRequestRequest);
        return data;
    };

}
import { http } from "../http/Http";
import { AuthenticationRequest } from "../models/authentication/AuthenticationRequest";
import { AuthenticationResponse } from "../models/authentication/AuthenticationResponse";

export class AuthenticationService {

    private Api: string = "Authentication";

    authenticate = async (authenticationRequest: AuthenticationRequest): Promise<AuthenticationResponse | null> => {
        const { data } = await http.post<AuthenticationResponse | null>(this.Api + "/Authenticate", authenticationRequest);
        return data;
    };

    authenticateByToken = async (token: string): Promise<AuthenticationResponse | null> => {
        const { data } = await http.post<AuthenticationResponse | null>(this.Api + "/AuthenticateByToken?token=" + token);
        return data;
    };

    authenticateBySchool = async (schoolId: number): Promise<AuthenticationResponse | null> => {
        const { data } = await http.post<AuthenticationResponse | null>(this.Api + "/AuthenticateBySchool?schoolId=" + schoolId);
        return data;
    };

}
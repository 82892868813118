import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { PermissionService } from '../../services/PermissionService';
import { UserService } from '../../services/UserService';
import { Permission } from '../../models/permission/Permission';
import { User } from '../../models/user/User';
import { DropdownOption } from '../../models/base/DropdownOption';
import { PermissionsMapper } from '../../utilities/PermissionsMapper';

const EditUserPermissions = (props: { id: any, callBack: (result: any) => void }) => {

    const [isPermissionsLoading, setIsPermissionsLoading] = useState<boolean>(false);
    const [isUserPermissionsLoading, setIsUserPermissionsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);
    const [permissionGroups, setPermissionGroups] = useState<string[]>([]);
    const [permissionGroupItems, setPermissionGroupItems] = useState<DropdownOption[]>([]);
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<string>("All");

    const permissionService = new PermissionService();
    const userService = new UserService();

    useEffect(() => {
        loadPermissions();
        loadUserPermissions();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const loadPermissions = () => {
        setIsPermissionsLoading(true);
        permissionService.getAll().then((response: Permission[]) => {
            handleLoadPermissionsResponse(response || []);
        }).catch((_error: any) => {
            handleLoadPermissionsError();
        });
    }

    const handleLoadPermissionsResponse = (response: Permission[]) => {
        let groups = response.map((permission) => { return permission.Code.split(".")[0]; })
            .filter((permission, index, permissions) => permissions.indexOf(permission) === index)
            .sort((permissionGroup1, permissionGroup2) => PermissionsMapper.getGroupOrder(permissionGroup1) - PermissionsMapper.getGroupOrder(permissionGroup2));

        let groupItems = groups.map((permissionGroup) => { return new DropdownOption(permissionGroup, PermissionsMapper.getGroupDisplayName(permissionGroup)) });

        groupItems.unshift(new DropdownOption("All", "الكل"));

        setPermissionGroups(groups);
        setPermissionGroupItems(groupItems);
        setPermissions(response);
        setIsPermissionsLoading(false);
    }

    const handleLoadPermissionsError = () => {
        setPermissionGroups([]);
        setPermissionGroupItems([]);
        setPermissions([]);
        setIsPermissionsLoading(false);
    }

    const loadUserPermissions = () => {
        setIsUserPermissionsLoading(true);
        userService.getById(props.id).then((response: User) => {
            handleLoadUserPermissionsResponse(response);
        }).catch((_error: any) => {
            handleLoadUserPermissionsError();
        });
    }

    const handleLoadUserPermissionsResponse = (response: User) => {
        setUserPermissions(response?.Permissions || []);
        setIsUserPermissionsLoading(false);
    }

    const handleLoadUserPermissionsError = () => {
        setUserPermissions([]);
        setIsUserPermissionsLoading(false);
    }

    const setUserPermission = (permissionCode: string, isChecked: boolean) => {
        if (permissionCode) {
            if (isChecked) {
                if (!userPermissions.includes(permissionCode)) {

                    let newPermissionsCodes: string[] = [permissionCode];

                    if (["Create", "Update", "Delete", "Activate", "Deactivate", "UpdatePermissions"].includes(permissionCode.split(".")[1])) {
                        let viewPermission = permissions.find(permission => permission.Code === (permissionCode.split(".")[0] + ".View"));
                        if (viewPermission && !userPermissions.includes(viewPermission.Code)) {
                            newPermissionsCodes.push(viewPermission.Code);
                        }
                    }
                    // else if (permissionCode === "Users.UpdatePermissions") {
                    //     let usersViewPermission = permissions.find(permission => permission.Code === "Users.View");
                    //     if (usersViewPermission) {
                    //         newPermissionsCodes.push(usersViewPermission.Code);
                    //     }
                    // }

                    //setUserPermissions(userPermissions => [...userPermissions, permissionCode]);
                    setUserPermissions(userPermissions => userPermissions.concat(newPermissionsCodes));
                }
            } else {
                if (userPermissions.includes(permissionCode)) {
                    //setUserPermissions(userPermissions.filter(userPermission => userPermission !== permissionCode));
                    setUserPermissions(userPermissions => userPermissions.filter(userPermission => userPermission !== permissionCode));
                }
            }
        }
    }

    const enableAllPermissions = () => {
        setSelectedPermissionGroup("All");
        setUserPermissions(permissions.map((permission) => { return permission.Code; }));
    }

    const disableAllPermissions = () => {
        setSelectedPermissionGroup("All");
        setUserPermissions([]);
    }

    const save = () => {
        setIsSaving(true);
        userService.updatePermissions(props.id, userPermissions).then((response: any) => {
            setIsSaving(false);
            props.callBack(response);
        }).catch((_error: any) => {
            setIsSaving(false);
        });
    }


    return (
        <div className="edit-user-permissions-container">

            {(isPermissionsLoading || isUserPermissionsLoading) &&
                <div className="loading-container">
                    <ProgressBar mode="indeterminate" className="m-auto w-9 h-0-7rem" />
                </div>
            }

            {!isPermissionsLoading && !isUserPermissionsLoading &&
                <React.Fragment>
                    <div className="permissions-header-container">
                        <div className="formgrid grid">
                            <div className="col-12 md:col-4">
                                <Dropdown
                                    value={selectedPermissionGroup}
                                    options={permissionGroupItems}
                                    optionValue="Code"
                                    optionLabel="Name"
                                    className="w-full"
                                    onChange={(event) => setSelectedPermissionGroup(event.value)} />
                            </div>
                            <div className="col-12 md:col-8">
                                <div className="inline-block p-1 pr-0">
                                    <Button
                                        label="تفعيل الكل"
                                        className="p-button-primary p-button-sm w-7rem"
                                        onClick={enableAllPermissions} />
                                </div>
                                <div className="inline-block p-1 pr-0">
                                    <Button
                                        label="تعطيل الكل"
                                        className="p-button-danger p-button-sm w-7rem"
                                        onClick={disableAllPermissions} />
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <Button
                                label={"حفظ"}
                                className="p-button-success p-button-sm w-6rem"
                                disabled={isSaving || isPermissionsLoading || isUserPermissionsLoading}
                                loading={isSaving}
                                onClick={save} />
                        </div>
                    </div>

                    <div className="permissions-container">
                        {permissionGroups.filter(permissionGroup => selectedPermissionGroup === "All" || permissionGroup === selectedPermissionGroup)
                            .map((permissionGroup, index) => (
                                <div key={"PermissionGroup_" + index} className="formgrid grid mb-3 mr-0 ml-0 pt-2 border-1 border-gray-400">
                                    <div className="col-12 mb-2">
                                        <label className="font-bold">
                                            {PermissionsMapper.getGroupDisplayName(permissionGroup)}
                                        </label>
                                    </div>

                                    {permissions.filter(permission => permission.Code.split(".")[0] === permissionGroup)
                                        .sort((permission1, permission2) => permission1.ViewOrder - permission2.ViewOrder)
                                        .map((permission, index) => (
                                            <div key={"Permission_" + index} className="col-12 md:col-6 lg:col-3">
                                                <div className="field-checkbox">
                                                    <Checkbox
                                                        checked={userPermissions.includes(permission.Code)}
                                                        onChange={event => setUserPermission(permission.Code, event.checked)} />

                                                    <label>{permission.Name}</label>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            ))
                        }
                    </div>

                    <div className="mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm w-6rem"
                            disabled={isSaving || isPermissionsLoading || isUserPermissionsLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </React.Fragment>
            }

        </div>
    );
}

export default EditUserPermissions;
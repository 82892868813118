export enum StudentPaymentLabel {
    StudentPaymentTypeCode = "نوع الدفعة",
    StudentId = "الطالب/ة",
    PaymentDate = "تاريخ الدفعة",
    PaymentAmount = "قيمة الدفعة",
    PaymentMethodCode = "طريقة الدفع",
    CheckDate = "تاريخ الشيك",
    IsCheckReturned = "شيك راجع؟",
    Notes = "ملاحظات الدفعة",
    StudentPaymentType_Name = "نوع الدفعة",
    PaymentMethod_Name = "طريقة الدفع",
    Student_FullName = "الطالب/ة",
    CheckDateStatus = "الحالة"
}
import { http } from "../http/Http";
import { StudentPaymentType } from "../models/student-payment-type/StudentPaymentType";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentPaymentTypeService {

    public EntityCode: EntityCode = EntityCode.StudentPaymentType;

    private Api: string = "StudentPaymentTypes";

    getAll = async (): Promise<StudentPaymentType[]> => {
        const { data } = await http.get<StudentPaymentType[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
import { http } from "../http/Http";
import { User } from "../models/user/User";
import { UserCounts } from "../models/user/UserCounts";
import { CreateUserRequest } from "../models/user/CreateUserRequest";
import { UpdateUserRequest } from "../models/user/UpdateUserRequest";
import { UpdateAuthorizedUserRequest } from "../models/user/UpdateAuthorizedUserRequest";
import { ChangePasswordRequest } from "../models/user/ChangePasswordRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class UserService {

    public EntityCode: EntityCode = EntityCode.User;

    private Api: string = "Users";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<User>> => {
        const { data } = await http.post<GetByFiltersResponse<User>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<User>> => {
        const { data } = await http.post<GetByFiltersResponse<User>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<User> => {
        const { data } = await http.get<User>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<User> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<User>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getAuthorized = async (): Promise<User> => {
        const { data } = await http.get<User>(this.Api + "/GetAuthorized");
        return data;
    };

    getCounts = async (): Promise<UserCounts> => {
        const { data } = await http.get<UserCounts>(this.Api + "/GetCounts");
        return data;
    };

    create = async (createUserRequest: CreateUserRequest): Promise<User> => {
        const { data } = await http.post<User>(this.Api, createUserRequest);
        return data;
    };

    update = async (id: number, updateUserRequest: UpdateUserRequest): Promise<User> => {
        const { data } = await http.put<User>(this.Api + "/" + id.toString(), updateUserRequest);
        return data;
    };

    updatePermissions = async (id: number, permissions: string[]): Promise<User> => {
        const { data } = await http.put<User>(this.Api + "/UpdatePermissions/" + id.toString(), permissions);
        return data;
    };

    updateAuthorized = async (updateAuthorizedUserRequest: UpdateAuthorizedUserRequest): Promise<User> => {
        const { data } = await http.put<User>(this.Api + "/UpdateAuthorized", updateAuthorizedUserRequest);
        return data;
    };

    activate = async (id: number): Promise<User> => {
        const { data } = await http.put<User>(this.Api + "/Activate/" + id.toString());
        return data;
    };

    deactivate = async (id: number): Promise<User> => {
        const { data } = await http.put<User>(this.Api + "/Deactivate/" + id.toString());
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

    changePassword = async (changePasswordRequest: ChangePasswordRequest): Promise<any> => {
        const { data } = await http.post<any>(this.Api + "/ChangePassword", changePasswordRequest);
        return data;
    };

}
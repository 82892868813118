import { http } from "../http/Http";
import { ReceivedPayment } from "../models/received-payment/ReceivedPayment";
import { CreateReceivedPaymentRequest } from "../models/received-payment/CreateReceivedPaymentRequest";
import { UpdateReceivedPaymentRequest } from "../models/received-payment/UpdateReceivedPaymentRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class ReceivedPaymentService {

    public EntityCode: EntityCode = EntityCode.ReceivedPayment;

    private Api: string = "ReceivedPayments";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<ReceivedPayment>> => {
        const { data } = await http.post<GetByFiltersResponse<ReceivedPayment>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<ReceivedPayment> => {
        const { data } = await http.get<ReceivedPayment>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createReceivedPaymentRequest: CreateReceivedPaymentRequest): Promise<ReceivedPayment> => {
        const { data } = await http.post<ReceivedPayment>(this.Api, createReceivedPaymentRequest);
        return data;
    };

    update = async (id: number, updateReceivedPaymentRequest: UpdateReceivedPaymentRequest): Promise<ReceivedPayment> => {
        const { data } = await http.put<ReceivedPayment>(this.Api + "/" + id.toString(), updateReceivedPaymentRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
export enum UserLabel {
    Email = "البريد الالكتروني",
    Password = "كلمة المرور",
    Name = "الاسم",
    GenderCode = "الجنس",
    CityCode = "المدينة",
    Phone = "الهاتف",
    Address = "العنوان",
    IsActive = "فعال",
    IsMain = "مستخدم رئيسي",
    Gender_Name = "الجنس",
    City_Name = "المدينة"
}
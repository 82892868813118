import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { UserField } from "../constants/fields/UserField";
import { AuditLogField } from "../constants/fields/AuditLogField";
import { AuditLogLabel } from "../constants/labels/AuditLogLabel";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { UserService } from "../services/UserService";
import { EntityService } from "../services/EntityService";
import { AuditLogActionService } from "../services/AuditLogActionService";

export class AuditLogFields {

    static get(): Field[] {
        return [
            new Field({
                Code: AuditLogField.AuditLogActionCode,
                Label: AuditLogLabel.AuditLogActionCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new AuditLogActionService()
            }),

            new Field({
                Code: AuditLogField.AuditLogAction_Name,
                Label: AuditLogLabel.AuditLogAction_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: AuditLogField.EntityCode,
                Label: AuditLogLabel.EntityCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new EntityService()
            }),

            new Field({
                Code: AuditLogField.Entity_Name,
                Label: AuditLogLabel.Entity_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: AuditLogField.UserId,
                Label: AuditLogLabel.UserId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                LookupService: new UserService(),
                LookupLoadFilters: LookupLoadFilters.user(),
                LookupDisplayFieldCode: UserField.Name
            }),

            new Field({
                Code: AuditLogField.User_Name,
                Label: AuditLogLabel.User_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: AuditLogField.Date,
                Label: AuditLogLabel.Date,
                Type: FieldType.DateTime,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
export class FieldErrors {
    static get(error: string): string {
        if (error && error.endsWith("field is required.")) {
            return "مطلوب";
        }
        else if (error && error.includes("must be a string or array type with a maximum length of")) {
            return "يجب أن لا يزيد عن x حرف".replace("x", (error.split("'")[1].split("'")[0]));
        }
        else if (error && error.includes("must be a string or array type with a minimum length of")) {
            return "يجب أن لا يقل عن x حرف".replace("x", (error.split("'")[1].split("'")[0]));
        }
        else if (error && error.endsWith("field is not a valid e-mail address.")) {
            return "يجب أن يكون بريد الكتروني صحيح";
        }
        else if (error && error.includes("must be between")) {
            let range = this.getRange(error);
            if (range && range.from !== null && range.from !== undefined && range.to !== null && range.to !== undefined) {
                return "يجب أن يكون من " + range.from + " الى " + range.to;
            }
            return error;
        }
        else if (error === "Invalid year") {
            return "سنة غير صحيحة";
        }
        else if (error === "Invalid number") {
            return "رقم غير صحيح";
        }
        else if (error === "Number must be greater than zero") {
            return "يجب أن يكون أكبر من 0";
        }
        else if (error === "Invalid code") {
            return "كود غير صحيح";
        }
        else if (error === "field must be empty.") {
            return "يجب أن يكون فارغ";
        }
        else if (error === "Must be greater than start date") {
            return "يجب أن يكون أكبر من تاريخ البداية";
        }
        else if (error === "Invalid date") {
            return "تاريخ غير صحيح";
        }
        else if (error === "Must be greater than or equal from date") {
            return "يجب أن يكون أكبر من أو يساوي تاريخ البداية";
        }
        else if (error === "Dates range must be at least month") {
            return "يجب أن لا يزيد الفرق بين التواريخ عن شهر";
        }
        else if (error === "Invalid dates range") {
            return "الفرق بين التواريخ غير صحيح";
        }
        else if (error === "The users count must be greater than or equal 3.") {
            return "يجب أن يكون عدد المستخدمين 3 على الأقل";
        }
        else {
            return error;
        }
    }

    private static getRange(error: string): { from: number, to: number } | null {
        if (error) {
            const regex = /(\d+(\.\d+)?)\s*and\s*(\d+(\.\d+)?)/;
            const match = error.match(regex);
            if (match && match.length === 5) {
                const from = parseFloat(match[1]);
                const to = parseFloat(match[3]);
                return { from, to };
            }
        }
        return null;
    }
}
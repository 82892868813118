import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { Employee } from "../../models/employee/Employee";
import { CreateEmployeeRequest } from "../../models/employee/CreateEmployeeRequest";
import { UpdateEmployeeRequest } from "../../models/employee/UpdateEmployeeRequest";
import { EmployeeFields } from "../../fields/EmployeeFields";
import { EmployeeService } from "../../services/EmployeeService";
import { EmployeeJobCode } from "../../constants/closed-lookup/EmployeeJobCode";
import { EmployeesPermissions } from '../../constants/permissions/EmployeesPermissions';
import { StudentLessonsPermissions } from "../../constants/permissions/StudentLessonsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import TrainerLessons from "./TrainerLessons";

const EmployeesList = () => {

    const additionalListButtonsCodes = {
        TrainerLessons: "TrainerLessons"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.TrainerLessons, Icon: "calendar", Severity: "success", Position: "afterAdd", Tooltip: "دروس المدرب" })
    ];

    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

    const [isTrainerLessonsOpened, setIsTrainerLessonsOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.TrainerLessons]: false
    });

    const onRecordSelect = (employee: Employee | null) => {
        setSelectedEmployee(employee);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.TrainerLessons]: showTrainerLessonsButton(employee)
        }));
    }

    const showTrainerLessonsButton = (employee: Employee | null) => {
        return UserAuthentication.hasPermissions(EmployeesPermissions.View) &&
            UserAuthentication.hasPermissions(StudentLessonsPermissions.View) &&
            employee !== null &&
            employee.EmployeeJobCode === EmployeeJobCode.Trainer;
    }

    const onAdditionalListButtonClick = (buttonCode: string, employee: Employee | null) => {
        if (employee) {
            if (buttonCode === additionalListButtonsCodes.TrainerLessons) {
                setIsTrainerLessonsOpened(true);
            }
        }
    }


    return (
        <React.Fragment>
            <List
                title={"الموظفين"}
                service={new EmployeeService()}
                fields={EmployeeFields.get()}
                filters={ListFilters.employee()}
                createSuccessMessage={"تم اضافة الموظف/ة بنجاح"}
                editSuccessMessage={"تم تعديل الموظف/ة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف الموظف/ة ؟"}
                deleteSuccessMessage={"تم حذف الموظف/ة بنجاح"}
                createModel={new CreateEmployeeRequest()}
                editModel={new UpdateEmployeeRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(EmployeesPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(EmployeesPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(EmployeesPermissions.Delete)}
                onRecordSelect={(employee: Employee | null) => onRecordSelect(employee)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />

            <Dialog
                header={"دروس المدرب"}
                style={{ width: "86vw", height: "86vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isTrainerLessonsOpened}
                onHide={() => setIsTrainerLessonsOpened(false)} >

                {selectedEmployee &&
                    <TrainerLessons
                        id={selectedEmployee.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default EmployeesList;
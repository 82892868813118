import React, { useEffect, useState } from 'react';
import { Panel } from "primereact/panel";
import { Badge } from 'primereact/badge';
import { Field } from '../../models/base/Field';
import { AuditLogField as AuditLogFieldModel } from '../../models/audit-log/AuditLogField';
import { AuditLog } from '../../models/audit-log/AuditLog';
import { AuditLogFields } from '../../fields/AuditLogFields';
import { FieldType } from '../../constants/base/FieldType';
import { AuditLogField } from '../../constants/fields/AuditLogField';
import { SchoolPaymentField } from '../../constants/fields/SchoolPaymentField';
import { StudentPaymentField } from '../../constants/fields/StudentPaymentField';
import { AuditLogLabel } from '../../constants/labels/AuditLogLabel';
import { EntityCode } from '../../constants/closed-lookup/EntityCode';
import { SchoolPaymentTypeCode } from '../../constants/closed-lookup/SchoolPaymentTypeCode';
import { PaymentMethodCode } from '../../constants/closed-lookup/PaymentMethodCode';
import { EntityFields } from '../../utilities/EntityFields';
import { PermissionsMapper } from '../../utilities/PermissionsMapper';
import FieldTemplate from '../base/FieldTemplate';
import FieldValue from '../base/FieldValue';

type Props = {
    auditLog: AuditLog
};

const AuditLogDetails = (props: Props) => {

    const [auditLogFields] = useState<Field[]>(AuditLogFields.get());
    const [fields, setFields] = useState<Field[]>([]);
    const [userPermissionsAuditLogField, setUserPermissionsAuditLogField] = useState<AuditLogFieldModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        initializeFields();
        initializeUserPermissionsAuditLogField();

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }, [props.auditLog]) // eslint-disable-line react-hooks/exhaustive-deps


    const getAuditLogField = (fieldCode: string) => {
        return auditLogFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const initializeFields = () => {
        let fieldsFromAuditLog: Field[] = [];
        if (props.auditLog && props.auditLog.EntityCode && props.auditLog.Fields && props.auditLog.Fields.length > 0) {
            let entityFields = EntityFields.getFields(props.auditLog.EntityCode);
            if (entityFields && entityFields.length > 0) {
                props.auditLog.Fields.filter(f => showAuditLogField(f)).forEach(auditLogField => {
                    if (auditLogField) {
                        let entityField = entityFields.find(f => f.Code === auditLogField.Field) || null;
                        if (entityField) {
                            entityField.AuditLogField = auditLogField;
                            fieldsFromAuditLog.push(entityField);
                        }
                    }
                });
                fieldsFromAuditLog = sortFields(fieldsFromAuditLog, entityFields);
            }
        }
        setFields(fieldsFromAuditLog);
    }

    const showAuditLogField = (auditLogField: AuditLogFieldModel) => {
        if (auditLogField && props.auditLog && props.auditLog.EntityCode && props.auditLog.Fields && props.auditLog.Fields.length > 0) {

            if (props.auditLog.EntityCode === EntityCode.SchoolPayment) {

                if (auditLogField.Field === SchoolPaymentField.CarLicenseId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode, [SchoolPaymentTypeCode.CarLicense]);
                }

                if (auditLogField.Field === SchoolPaymentField.SchoolLicenseId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode, [SchoolPaymentTypeCode.SchoolLicense]);
                }

                if (auditLogField.Field === SchoolPaymentField.CarInsuranceId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode, [SchoolPaymentTypeCode.CarInsurance]);
                }

                if (auditLogField.Field === SchoolPaymentField.CarId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode,
                        [SchoolPaymentTypeCode.CarFuel, SchoolPaymentTypeCode.CarMaintenance, SchoolPaymentTypeCode.CarPurchase]);
                }

                if (auditLogField.Field === SchoolPaymentField.EmployeeId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode,
                        [SchoolPaymentTypeCode.EmployeeSalary, SchoolPaymentTypeCode.EmployeeAdvancePayment]);
                }

                if (auditLogField.Field === SchoolPaymentField.TrainerLicenseId) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.SchoolPaymentTypeCode, [SchoolPaymentTypeCode.TrainerLicense]);
                }

                if (auditLogField.Field === SchoolPaymentField.CheckDate || auditLogField.Field === SchoolPaymentField.IsCheckReturned) {
                    return checkDependentAuditLogFieldValues(SchoolPaymentField.PaymentMethodCode, [PaymentMethodCode.Check]);
                }

            }

            if (props.auditLog.EntityCode === EntityCode.StudentPayment) {

                if (auditLogField.Field === StudentPaymentField.CheckDate || auditLogField.Field === StudentPaymentField.IsCheckReturned) {
                    return checkDependentAuditLogFieldValues(StudentPaymentField.PaymentMethodCode, [PaymentMethodCode.Check]);
                }

            }

            return true;
        }

        return false;
    }

    const checkDependentAuditLogFieldValues = (dependentFieldCode: string, values: any[]) => {
        let dependentAuditLogField = props.auditLog?.Fields?.find(f => f.Field === dependentFieldCode) || null;
        if (dependentAuditLogField) {
            let dependentAuditLogFieldValues: any[] = [];
            if (dependentAuditLogField.Value) {
                dependentAuditLogFieldValues.push(dependentAuditLogField.Value);
            }
            if (dependentAuditLogField.UpdatedValue) {
                dependentAuditLogFieldValues.push(dependentAuditLogField.UpdatedValue);
            }
            let result: boolean = false;
            (values || []).forEach(value => {
                result = result || dependentAuditLogFieldValues.includes(value);
            });
            return result;
        }
        return false;
    }

    const sortFields = (fields: Field[], entityFields: Field[]) => {
        if (fields && entityFields) {
            let entityFieldsCodes = entityFields.map(entityField => entityField.Code);
            return fields.sort((a, b) => {
                let indexA = entityFieldsCodes.indexOf(a.Code);
                let indexB = entityFieldsCodes.indexOf(b.Code);
                return indexA - indexB;
            });
        }
        return fields;
    }

    const initializeUserPermissionsAuditLogField = () => {
        let auditLogField: AuditLogFieldModel | null = null;
        if (props.auditLog && props.auditLog.Fields && props.auditLog.EntityCode === EntityCode.User) {
            let permissionsField = props.auditLog.Fields.find(f => f.Field === "Permissions") || null;
            if (permissionsField) {
                auditLogField = permissionsField;
            }
        }
        setUserPermissionsAuditLogField(auditLogField);
    }

    const isValueExists = (value: any) => {
        return value !== null && value !== undefined;
    }

    const getChangedPermissions = (oldPermissions: string | null, newPermissions: string | null, changeType: "common" | "added" | "removed") => {
        let oldPermissionsList: string[] = getPermissions(oldPermissions);
        let newPermissionsList: string[] = getPermissions(newPermissions);
        if (changeType === "common") {
            return oldPermissionsList.filter(permission => newPermissionsList.includes(permission));
        }
        else if (changeType === "added") {
            return newPermissionsList.filter(permission => !oldPermissionsList.includes(permission));
        }
        else if (changeType === "removed") {
            return oldPermissionsList.filter(permission => !newPermissionsList.includes(permission));
        }
        else {
            return [];
        }
    }

    const getPermissions = (permissions: string | null) => {
        let permissionsList: string[] = permissions ? JSON.parse(permissions) : [];
        return permissionsList;
    }

    const auditLogFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-6 p-2">
            <div className="mb-2 font-medium font-size-9">{AuditLogLabel[fieldCode?.replace(/\./g, "_")]}</div>
            <div>{FieldTemplate(props.auditLog, getAuditLogField(fieldCode))}</div>
        </div>;
    }


    return (
        <div className="mt-2">
            <React.Fragment>

                <Panel header="معلومات الحركة" className="mb-5">

                    <div className="grid">
                        {auditLogFieldTemplate(AuditLogField.AuditLogAction_Name)}
                        {auditLogFieldTemplate(AuditLogField.Entity_Name)}
                        {auditLogFieldTemplate(AuditLogField.User_Name)}
                        {auditLogFieldTemplate(AuditLogField.Date)}
                    </div>

                </Panel>

                <Panel header={"تفاصيل" + (props.auditLog?.AuditLogAction?.Name ? (" ال" + props.auditLog.AuditLogAction.Name) : "")} className="mb-6">

                    <div className="pb-3">
                        {fields.length > 0 &&
                            fields.map((field, index) => (
                                <div key={index} className="grid m-0 mt-3">

                                    <div className="col-12 md:col-2 pr-0">
                                        <div title={field.Label} className="audit-log-field-label">{field.Label}</div>
                                    </div>

                                    <div className="col-12 md:col-5 bg-gray-100">
                                        <React.Fragment>
                                            {isValueExists(field.AuditLogField.Value) &&
                                                <FieldValue
                                                    field={field}
                                                    value={field.AuditLogField.Value}
                                                    hideLabel={true}
                                                    hidePlaceholder={true}
                                                    isDisabled={true}
                                                    isLoading={isLoading}
                                                    showLookupNotFoundError={true} />
                                            }
                                            {!isValueExists(field.AuditLogField.Value) &&
                                                <div className="audit-log-field-empty-value"></div>
                                            }
                                        </React.Fragment>
                                    </div>

                                    <div className={"relative col-12 md:col-5" + (field.AuditLogField.IsValueUpdated ? " bg-green-100" : "")}>
                                        {field.AuditLogField.IsValueUpdated &&
                                            <React.Fragment>
                                                <span className="pi pi-arrow-left audit-log-arrow-icon"></span>
                                                {isValueExists(field.AuditLogField.UpdatedValue) &&
                                                    <FieldValue
                                                        field={field}
                                                        value={field.AuditLogField.UpdatedValue}
                                                        hideLabel={true}
                                                        hidePlaceholder={true}
                                                        isDisabled={true}
                                                        isLoading={isLoading}
                                                        showLookupNotFoundError={true} />
                                                }
                                                {!isValueExists(field.AuditLogField.UpdatedValue) &&
                                                    <div className="audit-log-field-empty-value"></div>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>

                                </div>
                            ))
                        }

                        {userPermissionsAuditLogField &&
                            <div className="grid m-0 mt-3">
                                <div className="col-12 md:col-2 pr-0">
                                    <div title={"الصلاحيات"} className="pt-0 audit-log-field-label">{"الصلاحيات"}</div>
                                </div>

                                <div className="col-12 md:col-10 pr-0">

                                    {userPermissionsAuditLogField.IsValueUpdated &&
                                        <div className="mb-2 border-bottom-1 border-300">
                                            <Badge className="m-0-1" severity="info" value={"لا يوجد تعديل"} />
                                            <Badge className="m-0-1" severity="success" value={"تم اضافتها"} />
                                            <Badge className="m-0-1" severity="danger" value={"تم حذفها"} />
                                        </div>
                                    }

                                    {userPermissionsAuditLogField.IsValueUpdated &&
                                        <div>
                                            {getChangedPermissions(userPermissionsAuditLogField.Value, userPermissionsAuditLogField.UpdatedValue, "common")
                                                .map((permission, index) => (
                                                    <Badge key={index} className="m-0-1" severity="info" value={PermissionsMapper.getFullName(permission)} />
                                                ))
                                            }

                                            {getChangedPermissions(userPermissionsAuditLogField.Value, userPermissionsAuditLogField.UpdatedValue, "added")
                                                .map((permission, index) => (
                                                    <Badge key={index} className="m-0-1" severity="success" value={PermissionsMapper.getFullName(permission)} />
                                                ))
                                            }

                                            {getChangedPermissions(userPermissionsAuditLogField.Value, userPermissionsAuditLogField.UpdatedValue, "removed")
                                                .map((permission, index) => (
                                                    <Badge key={index} className="m-0-1" severity="danger" value={PermissionsMapper.getFullName(permission)} />
                                                ))
                                            }
                                        </div>
                                    }

                                    {!userPermissionsAuditLogField.IsValueUpdated &&
                                        <div>
                                            {getPermissions(userPermissionsAuditLogField.Value)
                                                .map((permission, index) => (
                                                    <Badge key={index} className="m-0-1" severity="info" value={PermissionsMapper.getFullName(permission)} />
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </Panel>

            </React.Fragment>
        </div>
    );
}

export default AuditLogDetails;
import { useEffect, useState } from "react";
import { DataTableSortOrderType } from "primereact/datatable";
import { useDebounce } from "use-debounce";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { FilterRequest } from "../models/base/FilterRequest";
import { Filter } from "../models/base/Filter";
import { Field } from "../models/base/Field";
import { SortDirection } from "../constants/base/SortDirection";
import { GetByFiltersRequestBuilder } from "../builders/GetByFiltersRequestBuilder";
import { AppToast } from "../utilities/AppToast";

type Props = {
  fields?: Field[],
  additionalFilters?: FilterRequest[],
  operations?: string[],
  service: any,
  maxPageRecordsCount?: number,
  globalFilters?: Filter[],
  globalFilterValueDebounceDelay?: number,
  createSuccessMessage?: string,
  editSuccessMessage?: string,
  deleteSuccessMessage?: string,
  activateSuccessMessage?: string,
  deactivateSuccessMessage?: string,
  defaultSortByField?: string,
  defaultSortDirection?: SortDirection,
  isLookupList?: boolean,
};

const UseList = (props: Props) => {

  const fields: Field[] = props.fields === undefined ? [] : props.fields;
  const additionalFilters: FilterRequest[] = props.additionalFilters === undefined ? [] : props.additionalFilters;
  const operations: string[] | null = props.operations === undefined ? null : props.operations;
  const service: any = props.service;
  const maxPageRecordsCount: number = props.maxPageRecordsCount === undefined ? 15 : props.maxPageRecordsCount;
  const globalFilters: Filter[] = props.globalFilters === undefined ? [] : props.globalFilters;
  const globalFilterValueDebounceDelay: number = props.globalFilterValueDebounceDelay === undefined ? 700 : props.globalFilterValueDebounceDelay;
  const createSuccessMessage: string | null = props.createSuccessMessage === undefined ? null : props.createSuccessMessage;
  const editSuccessMessage: string | null = props.editSuccessMessage === undefined ? null : props.editSuccessMessage;
  const deleteSuccessMessage: string | null = props.deleteSuccessMessage === undefined ? null : props.deleteSuccessMessage;
  const activateSuccessMessage: string | null = props.activateSuccessMessage === undefined ? null : props.activateSuccessMessage;
  const deactivateSuccessMessage: string | null = props.deactivateSuccessMessage === undefined ? null : props.deactivateSuccessMessage;

  const keyField: string = "Id";

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFiltersOpened, setIsFiltersOpened] = useState<boolean>(false);
  const [isCreateEditOpened, setIsCreateEditOpened] = useState<boolean>(false);
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState<boolean>(false);
  const [isConfirmActivateOpened, setIsConfirmActivateOpened] = useState<boolean>(false);
  const [isConfirmDeactivateOpened, setIsConfirmDeactivateOpened] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [records, setRecords] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [sortField, setSortField] = useState<string | undefined>(props.defaultSortByField ? props.defaultSortByField : undefined);
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(props.defaultSortDirection ? (props.defaultSortDirection === SortDirection.Ascending ? 1 : -1) : undefined);
  const [globalFilterValue, setGlobalFilterValue] = useState<string | null>(null);
  const [getByFiltersRequest, setGetByFiltersRequest] = useState<GetByFiltersRequest>(
    new GetByFiltersRequestBuilder()
      .addAdditionalFilters(additionalFilters)
      .operations(operations)
      .sortBy(props.defaultSortByField ? props.defaultSortByField : keyField)
      .sortDirection(props.defaultSortDirection ? props.defaultSortDirection : SortDirection.Descending)
      .pageIndex(0)
      .pageSize(maxPageRecordsCount)
      .build()
  );

  const [debouncedGlobalFilterValue] = useDebounce(globalFilterValue, globalFilterValueDebounceDelay);


  useEffect(() => {
    loadDatatableRecords();
  }, [getByFiltersRequest]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleGlobalFilterValueChange(debouncedGlobalFilterValue);
  }, [debouncedGlobalFilterValue]) // eslint-disable-line react-hooks/exhaustive-deps


  const loadDatatableRecords = () => {
    let requestMethod = props.isLookupList ? "getLookupByFilters" : "getByFilters";
    if (service && service.hasOwnProperty(requestMethod)) {
      setIsLoading(true);
      service[requestMethod](getByFiltersRequest).then((response: any) => {
        handleLoadDatatableRecordsResponse(response);
      }).catch((_error: any) => {
        handleLoadDatatableRecordsError();
      });
    }
  }

  const handleLoadDatatableRecordsResponse = (response: any) => {
    if (response && response.Data && selectedRecord) {
      let isSelectedRecordInData = response.Data.some((d: any) => d[keyField] === selectedRecord[keyField]);
      if (!isSelectedRecordInData) {
        setSelectedRecord(null);
      }
    }
    setTotalRecordsCount(response && response.Count !== null && response.Count !== undefined ? response.Count : 0);
    setRecords(response && response.Data ? response.Data : []);
    setIsLoading(false);
  }

  const handleLoadDatatableRecordsError = () => {
    setSelectedRecord(null);
    setTotalRecordsCount(0);
    setRecords([]);
    setIsLoading(false);
  }

  const handleGlobalFilterValueChange = (globalFilterValue: string | null) => {
    if (!globalFilterValue && globalFilterValue !== "") {
      return;
    }

    getByFiltersRequest.QuickSearchFilters = [];
    if (globalFilterValue !== "" && globalFilters && globalFilters.length > 0) {
      globalFilters.forEach(globalFilter => {
        if (globalFilter && globalFilter.Property && globalFilter.Operator) {
          let filter = new FilterRequest(globalFilter.Property, globalFilter.Operator, globalFilterValue);
          getByFiltersRequest.QuickSearchFilters.push(filter);
        }
      });
    }
    setGetByFiltersRequest({ ...getByFiltersRequest });
  }

  const create = () => {
    setSelectedRecord(null);
    setIsCreateEditOpened(true);
  }

  const edit = () => {
    if (selectedRecord) {
      setIsCreateEditOpened(true);
    }
  }

  const Delete = () => {
    if (selectedRecord) {
      setIsDeleting(false);
      setIsConfirmDeleteOpened(true);
    }
  }

  const activate = () => {
    if (selectedRecord) {
      setIsActivating(false);
      setIsConfirmActivateOpened(true);
    }
  }

  const deactivate = () => {
    if (selectedRecord) {
      setIsDeactivating(false);
      setIsConfirmDeactivateOpened(true);
    }
  }

  const createEditCallback = (response: any, isCreate: boolean) => {
    if (response) {
      if (!isCreate) {
        setSelectedRecord(response);
      }
      loadDatatableRecords();
      AppToast.success(isCreate ? createSuccessMessage : editSuccessMessage);
    }
    setIsCreateEditOpened(false);
  }

  const acceptDelete = () => {
    if (service && service.hasOwnProperty("delete") && selectedRecord && selectedRecord[keyField]) {
      setIsDeleting(true);
      service.delete(selectedRecord[keyField]).then((_response: any) => {
        handleDeleteResponse();
      }).catch((_error: any) => {
        handleDeleteError();
      });
    }
  }

  const handleDeleteResponse = () => {
    loadDatatableRecords();
    setIsDeleting(false);
    setIsConfirmDeleteOpened(false);
    setSelectedRecord(null);
    AppToast.success(deleteSuccessMessage);
  }

  const handleDeleteError = () => {
    setIsDeleting(false);
    setIsConfirmDeleteOpened(false);
  }

  const acceptActivate = () => {
    if (service && service.hasOwnProperty("activate") && selectedRecord && selectedRecord[keyField]) {
      setIsActivating(true);
      service.activate(selectedRecord[keyField]).then((response: any) => {
        handleActivateResponse(response);
      }).catch((_error: any) => {
        handleActivateError();
      });
    }
  }

  const handleActivateResponse = (response: any) => {
    loadDatatableRecords();
    setIsActivating(false);
    setIsConfirmActivateOpened(false);
    setSelectedRecord(response);
    AppToast.success(activateSuccessMessage);
  }

  const handleActivateError = () => {
    setIsActivating(false);
    setIsConfirmActivateOpened(false);
  }

  const acceptDeactivate = () => {
    if (service && service.hasOwnProperty("deactivate") && selectedRecord && selectedRecord[keyField]) {
      setIsDeactivating(true);
      service.deactivate(selectedRecord[keyField]).then((response: any) => {
        handleDeactivateResponse(response);
      }).catch((_error: any) => {
        handleDeactivateError();
      });
    }
  }

  const handleDeactivateResponse = (response: any) => {
    loadDatatableRecords();
    setIsDeactivating(false);
    setIsConfirmDeactivateOpened(false);
    setSelectedRecord(response);
    AppToast.success(deactivateSuccessMessage);
  }

  const handleDeactivateError = () => {
    setIsDeactivating(false);
    setIsConfirmDeactivateOpened(false);
  }

  const changePage = (event: any) => {
    if (event) {
      getByFiltersRequest.PageIndex = event.first || 0;
      getByFiltersRequest.PageSize = event.rows || maxPageRecordsCount;
      setGetByFiltersRequest({ ...getByFiltersRequest });
    }
  }

  const changeSort = (event: any) => {
    if (event) {

      let sortByField: string | null = null;
      let field = fields.find(f => f.Code === event.sortField);
      if (field && field.DatatableColumn && field.DatatableColumn.SortBy) {
        sortByField = field.DatatableColumn.SortBy;
      }
      else {
        sortByField = event.sortField;
      }

      getByFiltersRequest.SortBy = sortByField || keyField;

      getByFiltersRequest.SortDirection = (event.sortOrder === 1 || event.sortOrder === -1) ?
        (event.sortOrder === 1 ? SortDirection.Ascending : SortDirection.Descending) :
        SortDirection.Descending;

      setGetByFiltersRequest({ ...getByFiltersRequest });
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  }

  const changeFilters = (filters: FilterRequest[], closeFilters: boolean = true) => {
    if (filters) {
      getByFiltersRequest.Filters = filters;
      setGetByFiltersRequest({ ...getByFiltersRequest });
    }

    if (closeFilters) {
      setIsFiltersOpened(false);
    }
  }

  return {
    maxPageRecordsCount,
    isLoading,
    isFiltersOpened,
    setIsFiltersOpened,
    isCreateEditOpened,
    setIsCreateEditOpened,
    isConfirmDeleteOpened,
    setIsConfirmDeleteOpened,
    isConfirmActivateOpened,
    setIsConfirmActivateOpened,
    isConfirmDeactivateOpened,
    setIsConfirmDeactivateOpened,
    isDeleting,
    isActivating,
    isDeactivating,
    records,
    selectedRecord,
    setSelectedRecord,
    totalRecordsCount,
    sortField,
    sortOrder,
    globalFilterValue,
    setGlobalFilterValue,
    getByFiltersRequest,
    loadDatatableRecords,
    create,
    edit,
    Delete,
    activate,
    deactivate,
    createEditCallback,
    acceptDelete,
    acceptActivate,
    acceptDeactivate,
    changePage,
    changeSort,
    changeFilters
  };
};

export default UseList;
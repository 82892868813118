import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Field } from '../../models/base/Field';
import { CreateSubscriptionRequestRequest } from '../../models/subscription-request/CreateSubscriptionRequestRequest';
import { SubscriptionRequestService } from '../../services/SubscriptionRequestService';
import { SubscriptionRequestFields } from '../../fields/SubscriptionRequestFields';
import { FieldType } from '../../constants/base/FieldType';
import { SubscriptionRequestField } from '../../constants/fields/SubscriptionRequestField';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

export type Props = {
    usersCount?: number,
    callBack: (result: any) => void
};

const CreateSubscriptionRequest = (props: Props) => {

    const [subscriptionRequestFields] = useState<Field[]>(SubscriptionRequestFields.get());

    const {
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        model: new CreateSubscriptionRequestRequest(props.usersCount !== undefined ? props.usersCount : null),
        service: new SubscriptionRequestService(),
        saveMethod: "create"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response);
        }
    });


    const getSubscriptionRequestField = (fieldCode: string) => {
        return subscriptionRequestFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.SchoolName)}
                        value={getFieldValue(SubscriptionRequestField.SchoolName)}
                        errors={getFieldModelErrors(SubscriptionRequestField.SchoolName)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.SchoolName, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.UsersCount)}
                        value={getFieldValue(SubscriptionRequestField.UsersCount)}
                        errors={getFieldModelErrors(SubscriptionRequestField.UsersCount)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.UsersCount, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.Phone)}
                        value={getFieldValue(SubscriptionRequestField.Phone)}
                        errors={getFieldModelErrors(SubscriptionRequestField.Phone)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.Phone, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.Email)}
                        value={getFieldValue(SubscriptionRequestField.Email)}
                        errors={getFieldModelErrors(SubscriptionRequestField.Email)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.Email, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.City)}
                        value={getFieldValue(SubscriptionRequestField.City)}
                        errors={getFieldModelErrors(SubscriptionRequestField.City)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.City, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.Address)}
                        value={getFieldValue(SubscriptionRequestField.Address)}
                        errors={getFieldModelErrors(SubscriptionRequestField.Address)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.Address, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSubscriptionRequestField(SubscriptionRequestField.Notes)}
                        value={getFieldValue(SubscriptionRequestField.Notes)}
                        errors={getFieldModelErrors(SubscriptionRequestField.Notes)}
                        onChange={(value: any) => setFieldValue(SubscriptionRequestField.Notes, value)} />
                </div>


                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"إرسال"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CreateSubscriptionRequest;
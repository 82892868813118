import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { SchoolField } from "../constants/fields/SchoolField";
import { SchoolLabel } from "../constants/labels/SchoolLabel";
import { CityService } from "../services/CityService";

export class SchoolFields {

    static get(): Field[] {
        return [
            new Field({
                Code: SchoolField.Name,
                Label: SchoolLabel.Name,
                Type: FieldType.String,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.CityCode,
                Label: SchoolLabel.CityCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                IsDropdownFilterEnabled: true,
                LookupService: new CityService()
            }),

            new Field({
                Code: SchoolField.City_Name,
                Label: SchoolLabel.City_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: SchoolField.Address,
                Label: SchoolLabel.Address,
                Type: FieldType.String,
                ShowInFilters: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),
            new Field({
                Code: SchoolField.Phone,
                Label: SchoolLabel.Phone,
                Type: FieldType.String,
                ShowInFilters: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: SchoolField.DemoExpirationDate,
                Label: SchoolLabel.DemoExpirationDate,
                Type: FieldType.DateTime,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.FinishDemoExpirationDate,
                Label: SchoolLabel.FinishDemoExpirationDate,
                Type: FieldType.DateTime,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.MaxUsersCount,
                Label: SchoolLabel.MaxUsersCount,
                Type: FieldType.Integer,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.IsActive,
                Label: SchoolLabel.IsActive,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.IsManagement,
                Label: SchoolLabel.IsManagement,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolField.Notes,
                Label: SchoolLabel.Notes,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            })
        ];
    }

}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { CarLicenseField } from "../constants/fields/CarLicenseField";
import { CarField } from "../constants/fields/CarField";
import { CarLicenseLabel } from "../constants/labels/CarLicenseLabel";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { Templates } from "../utilities/Templates";
import { CarService } from "../services/CarService";

export class CarLicenseFields {

    static get(): Field[] {
        return [
            new Field({
                Code: CarLicenseField.CarId,
                Label: CarLicenseLabel.CarId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarService(),
                LookupLoadFilters: LookupLoadFilters.car(),
                LookupDisplayTemplate: Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name)
            }),

            new Field({
                Code: CarLicenseField.Car_CarCompany_Name,
                Label: CarLicenseLabel.Car_CarCompany_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarLicenseField.Car_CarType_Name,
                Label: CarLicenseLabel.Car_CarType_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarLicenseField.Car_PlateNumber,
                Label: CarLicenseLabel.Car_PlateNumber,
                Type: FieldType.String,
                ShowInListLookup: true,
                IsLtr: true,
                IsOneLine: true,
                DatatableColumn: new DatatableColumn({})
            }),

            new Field({
                Code: CarLicenseField.StartDate,
                Label: CarLicenseLabel.StartDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: CarLicenseField.EndDate,
                Label: CarLicenseLabel.EndDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarLicenseField.Cost,
                Label: CarLicenseLabel.Cost,
                Type: FieldType.Decimal,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarLicenseField.TotalPaid,
                Label: CarLicenseLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarLicenseField.TotalOverpaid,
                Label: CarLicenseLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarLicenseField.TotalRemaining,
                Label: CarLicenseLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarLicenseField.ExpirationStatus,
                Label: CarLicenseLabel.ExpirationStatus,
                Type: FieldType.String,
                CustomTemplateCode: "ExpirationStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { ProgressBar } from "primereact/progressbar";
import { Panel } from "primereact/panel";
import { Message } from "primereact/message";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { EmployeeService } from "../../services/EmployeeService";
import { StudentLessonService } from "../../services/StudentLessonService";
import { Field } from "../../models/base/Field";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Employee } from "../../models/employee/Employee";
import { StudentLesson } from "../../models/student-lesson/StudentLesson";
import { GetTrainerLessonsRequest } from "../../models/student-lesson/GetTrainerLessonsRequest";
import { Car } from "../../models/car/Car";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { FieldType } from "../../constants/base/FieldType";
import { SortDirection } from "../../constants/base/SortDirection";
import { EmployeeField } from "../../constants/fields/EmployeeField";
import { StudentLessonField } from "../../constants/fields/StudentLessonField";
import { CarField } from "../../constants/fields/CarField";
import { EmployeeLabel } from "../../constants/labels/EmployeeLabel";
import { EmployeeFields } from "../../fields/EmployeeFields";
import { StudentLessonFields } from "../../fields/StudentLessonFields";
import { Templates } from "../../utilities/Templates";
import { Convertor } from "../../utilities/Convertor";
import FieldValue from "../base/FieldValue";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const TrainerLessons = (props: Props) => {

    const employeeService: EmployeeService = new EmployeeService();
    const studentLessonService: StudentLessonService = new StudentLessonService();

    const [employeeFields] = useState<Field[]>(EmployeeFields.get());
    const [isEmployeeLoading, setIsEmployeeLoading] = useState<boolean>(false);
    const [employee, setEmployee] = useState<Employee | null>(null);

    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);

    const [errorsModel, setErrorsModel] = useState<any>({});

    const [cars, setCars] = useState<Car[]>([]);
    const [totalLessonsNumber, setTotalLessonsNumber] = useState<number>(0);
    const [isCarsLoading, setIsCarsLoading] = useState<boolean>(false);
    const [isLessonsLoadedAtLeastOnce, setIsLessonsLoadedAtLeastOnce] = useState<boolean>(false);

    const fromDateField: Field = new Field({ Code: "FromDate", Type: FieldType.Date });
    const toDateField: Field = new Field({ Code: "ToDate", Type: FieldType.Date });


    useEffect(() => {
        loadEmployee();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getEmployeeField = (fieldCode: string) => {
        return employeeFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadEmployee = () => {
        setIsEmployeeLoading(true);
        employeeService.getById(props.id).then((employee) => {
            setEmployee(employee || null);
            setIsEmployeeLoading(false);
        }).catch((_error: any) => {
            setEmployee(null);
            setIsEmployeeLoading(false);
        });
    }

    const loadLessons = (fromDate: any, toDate: any) => {
        let getTrainerLessonsRequest: GetTrainerLessonsRequest = new GetTrainerLessonsRequest();
        getTrainerLessonsRequest.EmployeeId = props.id;
        getTrainerLessonsRequest.FromDate = fromDate;
        getTrainerLessonsRequest.ToDate = toDate;

        setErrorsModel({});
        setIsLessonsLoadedAtLeastOnce(true);
        setIsCarsLoading(true);
        initilizeCars([]);

        studentLessonService.getTrainerLessons(getTrainerLessonsRequest).then((lessons) => {
            setIsCarsLoading(false);
            setTotalLessonsNumber(lessons ? lessons.length : 0);
            initilizeCars(lessons || []);
        }).catch((error: any) => {
            if (error && error.data && error.data.errors && error.data.title?.toString()?.toLowerCase() === "One or more validation errors occurred.".toLowerCase()) {
                setErrorsModel(error.data.errors);
            }
            setIsCarsLoading(false);
            setTotalLessonsNumber(0);
            initilizeCars([]);
        });
    }

    const initilizeCars = (lessons: StudentLesson[]) => {
        if (lessons && lessons.length > 0) {
            let groupedData: any[] = lessons.reduce((acc: any, obj: any) => {
                let existingGroup: any = acc.find((group: any) => group.CarId === obj.CarId);
                if (existingGroup) {
                    existingGroup.Cars.push(obj.Car);
                }
                else {
                    acc.push({ CarId: obj.CarId, Cars: [obj.Car] });
                }
                return acc;
            }, []);

            setCars(groupedData.map(group => group.Cars[0]));
        }
        else {
            setCars([]);
        }
    }

    const onFromDateChange = (value: any) => {
        setFromDate(value);
        loadLessons(value, toDate);
    }

    const onToDateChange = (value: any) => {
        setToDate(value);
        loadLessons(fromDate, value);
    }

    const getCarPanelHeader = (car: Car) => {
        return "المركبة: " + Convertor.templateToValue(car, Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name));
    }

    const getListAdditionalFilters = (carId: number) => {
        let carIdFilterValue = carId !== null && carId !== undefined ? carId.toString() : "";
        let fromDateFilterValue = fromDate ? fromDate.toString() : "";
        let toDateFilterValue = toDate ? toDate.toString() : "";
        return [
            new FilterRequest(StudentLessonField.EmployeeId, FilterOperator.Equals, props.id.toString()),
            new FilterRequest(StudentLessonField.CarId, FilterOperator.Equals, carIdFilterValue),
            new FilterRequest(StudentLessonField.Date, FilterOperator.GreaterThanOrEqual, fromDateFilterValue),
            new FilterRequest(StudentLessonField.Date, FilterOperator.LessThanOrEqual, toDateFilterValue)
        ]
    }

    const employeeFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{EmployeeLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isEmployeeLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(employee, getEmployeeField(fieldCode))}</div>
            }
        </div>;
    }


    return (
        <React.Fragment>

            <Panel header="معلومات المدرب" className="mt-3 mb-4">
                <div className="grid">
                    {employeeFieldTemplate(EmployeeField.Name)}
                    {employeeFieldTemplate(EmployeeField.IdentityNumber)}
                    {employeeFieldTemplate(EmployeeField.City_Name)}
                    {employeeFieldTemplate(EmployeeField.Phone)}
                </div>
            </Panel>

            <div className="mb-4">
                <div className="grid">
                    <div className="col-12 md:col-3">
                        <FieldValue
                            label={"من تاريخ"}
                            field={fromDateField}
                            value={fromDate}
                            errors={errorsModel[fromDateField.Code]}
                            isDisabled={isCarsLoading}
                            isBoldLabel={true}
                            onChange={(value: any) => onFromDateChange(value)} />
                    </div>
                    <div className="col-12 md:col-3">
                        <FieldValue
                            label={"الى تاريخ"}
                            field={toDateField}
                            value={toDate}
                            errors={errorsModel[toDateField.Code]}
                            isDisabled={isCarsLoading}
                            isBoldLabel={true}
                            onChange={(value: any) => onToDateChange(value)} />
                    </div>
                    <div className="col-12 md:col-6 flex align-items-end">
                        {fromDate && toDate && !isCarsLoading && cars.length > 0 &&
                            <Button
                                icon="pi pi-refresh"
                                className="p-button-secondary p-button-sm"
                                onClick={() => loadLessons(fromDate, toDate)} />
                        }
                    </div>
                </div>
            </div>

            {cars.length > 0 &&
                <div className="mb-4">
                    <span className="font-size-9 font-medium ml-1">{"عدد الدروس الكلي"}</span>
                    <Tag severity="info" value={totalLessonsNumber} />
                </div>
            }

            {cars.length > 0 &&
                cars.map((car: Car, index: number) => (
                    <Panel key={index} header={getCarPanelHeader(car)} className="mb-4">
                        <List
                            title={"الدروس"}
                            hideHeaderTitle={true}
                            size={"small"}
                            service={new StudentLessonService()}
                            fields={StudentLessonFields.get()}
                            fieldsCodesExcludedFromList={[
                                StudentLessonField.Employee_Name,
                                StudentLessonField.Car_CarCompany_Name,
                                StudentLessonField.Car_CarType_Name]}
                            additionalFilters={getListAdditionalFilters(car.Id)}
                            maxPageRecordsCount={10}
                            noCardContent={true}
                            showTotalRecordsCount={true}
                            isSelectionDisabled={true}
                            defaultSortByField={StudentLessonField.Date}
                            defaultSortDirection={SortDirection.Descending} />
                    </Panel>
                ))
            }

            {isLessonsLoadedAtLeastOnce && !isCarsLoading && cars.length === 0 &&
                <div className="text-center">
                    <Message severity="warn" text={"لم يتم العثور على دروس"} />
                </div>
            }

            {isCarsLoading &&
                <ProgressBar mode="indeterminate" className="m-auto w-9 h-0-7rem" />
            }

        </React.Fragment>
    );
}

export default TrainerLessons;
export class AppToast {

    private static ToastRef: any = null;

    static initialize(toastRef: any) {
        if (toastRef) {
            this.ToastRef = toastRef;
        }
    }

    static success(message: string | null, lifeTime: number = 2500) {
        if (this.ToastRef && this.ToastRef.current) {
            this.ToastRef.current.show({ severity: "success", summary: "تم بنجاح", detail: message || "تم بنجاح", life: lifeTime });
        }
    }

    static error(message: string | null, lifeTime: number = 2500) {
        if (this.ToastRef && this.ToastRef.current) {
            this.ToastRef.current.show({ severity: "error", summary: "خطأ", detail: message || "خطأ", life: lifeTime });
        }
    }
}
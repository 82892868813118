export enum ReceivedPaymentField {
    CustomerName = "CustomerName",
    PaymentDate = "PaymentDate",
    PaymentAmount = "PaymentAmount",
    PaymentMethodCode = "PaymentMethodCode",
    CheckDate = "CheckDate",
    IsCheckReturned = "IsCheckReturned",
    Notes = "Notes",
    PaymentMethod_Name = "PaymentMethod.Name",
    CheckDateStatus = "CheckDateStatus"
}
type Params = {
    Order?: number,
    Label?: string,
    IsSortable?: boolean,
    SortBy?: string,
    WidthPercent?: number,
    MaxWidthInPx?: number,
    MinWidthInPx?: number
};

export class DatatableColumn {
    Order: number;
    Label: string | null;
    IsSortable: boolean;
    SortBy: string | null;
    Style: any;

    constructor(params: Params) {
        this.Order = params.Order === undefined ? 0 : params.Order;
        this.Label = params.Label === undefined ? null : params.Label;
        this.IsSortable = params.IsSortable === undefined ? false : params.IsSortable;
        this.SortBy = params.SortBy === undefined ? null : params.SortBy;

        let style = {};

        if (params.WidthPercent !== undefined) {
            style["width"] = params.WidthPercent + "%";
        }

        if (params.MaxWidthInPx !== undefined) {
            style["maxWidth"] = params.MaxWidthInPx + "px";
        }

        if (params.MinWidthInPx !== undefined) {
            style["minWidth"] = params.MinWidthInPx + "px";
        }

        this.Style = style;
    }
}
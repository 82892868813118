import { useContext, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AuthenticationContext } from '../../App';
import { DialogInputs } from '../../inputs/DialogInputs';
import { SchoolSettingsPermissions } from '../../constants/permissions/SchoolSettingsPermissions';
import { UserAuthentication } from '../../utilities/UserAuthentication';
import { AppToast } from '../../utilities/AppToast';
import Title from '../base/Title';
import ViewEditSchoolSettings from './ViewEditSchoolSettings';

const SchoolSettings = () => {

    const { authentication } = useContext(AuthenticationContext);

    const loadSchoolSettings = useRef<any>(null);

    const [isEditSchoolSettingsOpened, setIsEditSchoolSettingsOpened] = useState<boolean>(false);

    const handleEditSchoolSettingsCallback = (schoolSetting: any) => {
        setIsEditSchoolSettingsOpened(false);
        if (schoolSetting && authentication) {
            authentication.SchoolName = schoolSetting.Name;
            UserAuthentication.set({ ...authentication });
            AppToast.success("تم تعديل اعدادات المدرسة بنجاح");
            loadSchoolSettings.current();
        }
    }

    return (
        <Card>

            <Title text={"اعدادات المدرسة"} />

            <div className="grid">
                <div className="col-12 md:col-7">
                    <ViewEditSchoolSettings isView={true} loadSchoolSettings={loadSchoolSettings} />
                </div>

                {UserAuthentication.hasPermissions(SchoolSettingsPermissions.Update) &&
                    <div className="col-12 pb-0">
                        <Button
                            label="تعديل"
                            className="p-button-success p-button-sm w-6rem"
                            onClick={() => setIsEditSchoolSettingsOpened(true)} />
                    </div>
                }
            </div>

            <Dialog
                header="تعديل اعدادات المدرسة"
                visible={isEditSchoolSettingsOpened}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                onHide={() => setIsEditSchoolSettingsOpened(false)}>

                <ViewEditSchoolSettings callBack={(response: any) => handleEditSchoolSettingsCallback(response)} />
            </Dialog>
        </Card>
    );
}

export default SchoolSettings;
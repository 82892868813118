import { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { SchoolSettingService } from "../../services/SchoolSettingService";
import { UrlRedirect } from "../../utilities/UrlRedirect";
import { UserAuthentication } from "../../utilities/UserAuthentication";

const FinishSchoolDemoExpiration = () => {

    const [isAgreed, setIsAgreed] = useState(false);

    const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

    const schoolSettingService = new SchoolSettingService();

    const save = () => {
        setIsButtonsDisabled(true);
        schoolSettingService.finishDemoExpiration().then(_response => {
            UrlRedirect.goToApp();
        }).catch(_error => {
            setIsButtonsDisabled(false);
        });
    }

    const logout = () => {
        UserAuthentication.set(null);
    }

    return (
        <div>

            <div className="flex justify-content-center finish-school-demo-expiration-form">

                <div className="form-content top-margin">

                    <div className="form-title">لقد انتهت الفترة التجريبية لاستخدام النظام</div>

                    <div className="form-description font-bold">للاستمرار في استخدام النظام يجب الموافقة على التالي:</div>

                    <div className="form-description font-bold">

                        <div className="mb-2">
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>بعد الموافقة يكون استخدام النظام غير مجاني.</span>
                        </div>

                        <div className="mb-2">
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>تلتزم المدرسة بدفع رسوم الاشتراك شهرياً وبشكل دوري بداية من تاريخ الموافقة.</span>
                        </div>

                        <div className="mb-2">
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>في حال رغبت المدرسة في زيادة عدد مستخدمين النظام فانه يترتب زيادة على رسوم الاشتراك الشهرية.</span>
                        </div>

                        <div className="mb-2">
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>يحق للمدرسة الحصول على جميع بياناتها من النظام في حال رغبت في فسخ الاشتراك.</span>
                        </div>

                        <div className="mb-2">
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>يحق لادارة النظام عدم منح المدرسة جميع بياناتها في حال وجود رسوم مستحقة على المدرسة.</span>
                        </div>

                        <div>
                            <i className="pi pi-circle-fill form-description-icon"></i>
                            <span>يحق لادارة النظام تعطيل حساب المدرسة في حال تخلفت او تاخرت المدرسة عن دفع اي من رسوم الاشتراك الشهرية.</span>
                        </div>

                    </div>

                    <div className="p-fluid mt-6">

                        <div className="mb-4">
                            <Checkbox checked={isAgreed} onChange={(e: any) => setIsAgreed(e.checked)} />
                            <label className="mr-2 blue-color font-bold">أوافق على كل المذكور أعلاه</label>
                        </div>

                        <Button label="موافق" className="p-button-success p-button-sm font-medium text-base" disabled={isButtonsDisabled || !isAgreed} onClick={save} />

                        <Button label="تسجيل الخروج" className="p-button-danger p-button-sm font-medium text-base logut-button" disabled={isButtonsDisabled} onClick={logout} />

                    </div>
                </div>
            </div>

        </div>
    );
}

export default FinishSchoolDemoExpiration;
import React from "react";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FieldErrors } from '../../errors/FieldErrors';
import { ChangePasswordRequest } from "../../models/user/ChangePasswordRequest";
import { ChangePasswordField } from '../../constants/fields/ChangePasswordField';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { UserService } from "../../services/UserService";
import UseModel from '../../hooks/UseModel';

type Props = {
    isForCompleteAuthentication?: boolean
};

const ChangePassword = (props: Props) => {

    const {
        isSaving,
        setIsSaving,
        setFieldValue,
        getFieldValue,
        isFieldHasModelErrors,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        model: new ChangePasswordRequest(),
        service: new UserService(),
        saveMethod: "changePassword"
    });

    saveEvent.subscribe((_result: any) => {
        setIsSaving(true);
        logout();
    });

    const logout = () => {
        UserAuthentication.set(null);
    }

    return (
        <div>

            <div className={"flex justify-content-center change-password-form" + (props.isForCompleteAuthentication ? " padding" : "")}>

                <div className={"form-content" + (props.isForCompleteAuthentication ? " top-margin" : "")}>

                    {props.isForCompleteAuthentication &&
                        <React.Fragment>
                            <div className="form-title font-bold">تغيير كلمة المرور</div>
                            <div className="form-description font-bold">لاستكمال الدخول الى النظام بأمان يجب تغيير كلمة المرور</div>
                        </React.Fragment>
                    }

                    <div className="p-fluid">

                        <div className="field">
                            <label>كلمة المرور الحالية</label>

                            <InputText
                                type="password"
                                value={getFieldValue(ChangePasswordField.CurrentPassword) || ""}
                                className={isFieldHasModelErrors(ChangePasswordField.CurrentPassword) ? "w-full p-invalid" : "w-full"}
                                onChange={(e) => setFieldValue(ChangePasswordField.CurrentPassword, e.target.value)} />

                            {
                                getFieldModelErrors(ChangePasswordField.CurrentPassword).map((error: string, index: number) => {
                                    return <small key={index} className="p-error block">{FieldErrors.get(error)}</small>
                                })
                            }
                        </div>

                        <div className="field">
                            <label>كلمة المرور الجديدة</label>

                            <Password
                                value={getFieldValue(ChangePasswordField.NewPassword) || ""}
                                className={isFieldHasModelErrors(ChangePasswordField.NewPassword) ? "w-full p-invalid" : "w-full"}
                                onChange={(e) => setFieldValue(ChangePasswordField.NewPassword, e.target.value)} />

                            {
                                getFieldModelErrors(ChangePasswordField.NewPassword).map((error: string, index: number) => {
                                    return <small key={index} className="p-error block">{FieldErrors.get(error)}</small>
                                })
                            }
                        </div>


                        <div className="field">
                            <label>تأكيد كلمة المرور الجديدة</label>

                            <Password
                                value={getFieldValue(ChangePasswordField.ConfirmNewPassword) || ""}
                                className={isFieldHasModelErrors(ChangePasswordField.ConfirmNewPassword) ? "w-full p-invalid" : "w-full"}
                                onChange={(e) => setFieldValue(ChangePasswordField.ConfirmNewPassword, e.target.value)} />

                            {
                                getFieldModelErrors(ChangePasswordField.ConfirmNewPassword).map((error: string, index: number) => {
                                    return <small key={index} className="p-error block">{FieldErrors.get(error)}</small>
                                })
                            }
                        </div>

                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm"
                            disabled={isSaving}
                            loading={isSaving}
                            onClick={save} />

                        {props.isForCompleteAuthentication &&
                            <Button
                                label={"تسجيل الخروج"}
                                className="p-button-danger p-button-sm logut-button"
                                disabled={isSaving}
                                onClick={logout} />
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ChangePassword;
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { CreateEditProps } from '../../types/Types';
import { Field } from '../../models/base/Field';
import { CreateStudentDiscountRequest } from '../../models/student-discount/CreateStudentDiscountRequest';
import { UpdateStudentDiscountRequest } from '../../models/student-discount/UpdateStudentDiscountRequest';
import { StudentDiscountService } from '../../services/StudentDiscountService';
import { StudentDiscountFields } from '../../fields/StudentDiscountFields';
import { FieldType } from '../../constants/base/FieldType';
import { StudentDiscountField } from '../../constants/fields/StudentDiscountField';
import { DiscountTypeCode } from '../../constants/closed-lookup/DiscountTypeCode';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEditStudentDiscount = (props: CreateEditProps) => {

    const [studentDiscountFields] = useState<Field[]>(StudentDiscountFields.get());

    const isNew = props.id === null || props.id === undefined;

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        model: isNew ? new CreateStudentDiscountRequest() : new UpdateStudentDiscountRequest(),
        service: new StudentDiscountService(),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });


    const getStudentDiscountField = (fieldCode: string) => {
        return studentDiscountFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentDiscountField(StudentDiscountField.StudentId)}
                        value={getFieldValue(StudentDiscountField.StudentId)}
                        errors={getFieldModelErrors(StudentDiscountField.StudentId)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentDiscountField.StudentId, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentDiscountField(StudentDiscountField.DiscountTypeCode)}
                        value={getFieldValue(StudentDiscountField.DiscountTypeCode)}
                        errors={getFieldModelErrors(StudentDiscountField.DiscountTypeCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentDiscountField.DiscountTypeCode, value)} />
                </div>


                {getFieldValue(StudentDiscountField.DiscountTypeCode) &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getStudentDiscountField(StudentDiscountField.DiscountAmount)}
                            value={getFieldValue(StudentDiscountField.DiscountAmount)}
                            errors={getFieldModelErrors(StudentDiscountField.DiscountAmount)}
                            isLoading={isLoading}
                            isCurrency={getFieldValue(StudentDiscountField.DiscountTypeCode) === DiscountTypeCode.Fixed}
                            isPercent={getFieldValue(StudentDiscountField.DiscountTypeCode) === DiscountTypeCode.Percent}
                            onChange={(value: any) => setFieldValue(StudentDiscountField.DiscountAmount, value)} />
                    </div>
                }


                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CreateEditStudentDiscount;
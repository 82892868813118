import { Button } from 'primereact/button';
import { Field } from '../../models/base/Field';
import { CreateEditProps } from '../../types/Types';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEdit = (props: CreateEditProps) => {

    const isNew: boolean = props.id === null || props.id === undefined;
    const fields: Field[] = props.fields || [];

    const getModel = () => {
        let model = isNew ? props.createModel : props.editModel;
        if (model && "Initialize" in model && typeof model["Initialize"] === "function") {
            model = model.Initialize();
        }
        return model ? { ...model } : null;
    }

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        //model: isNew ? (props.createModel ? { ...props.createModel } : null) : (props.editModel ? { ...props.editModel } : null),
        model: getModel(),
        service: (props.service || null),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });

    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });

    return (
        <div className="formgrid grid">

            {fields.filter(f => isNew ? f.ShowInCreate : f.ShowInEdit).map((field, index) => (
                <div key={index} className={"field col-12 md:col-" + field.InputSize}>
                    <FieldValue
                        field={field}
                        value={getFieldValue(field.Code)}
                        errors={getFieldModelErrors(field.Code)}
                        isLoading={isLoading}
                        isUsedInCreate={isNew}
                        onChange={(value: any) => setFieldValue(field.Code, value)} />
                </div>
            ))}

            <div className="col-12">
                <div className="inline-block ml-1 mb-1">
                    <Button
                        label={"حفظ"}
                        className="p-button-success p-button-sm mt-2 w-6rem"
                        disabled={isSaving || isLoading}
                        loading={isSaving}
                        onClick={save} />
                </div>
            </div>

        </div>
    );
}

export default CreateEdit;
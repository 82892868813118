import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { CreateEditProps } from '../../types/Types';
import { Field } from '../../models/base/Field';
import { CreateReceivedPaymentRequest } from '../../models/received-payment/CreateReceivedPaymentRequest';
import { UpdateReceivedPaymentRequest } from '../../models/received-payment/UpdateReceivedPaymentRequest';
import { ReceivedPaymentService } from '../../services/ReceivedPaymentService';
import { ReceivedPaymentFields } from '../../fields/ReceivedPaymentFields';
import { FieldType } from '../../constants/base/FieldType';
import { ReceivedPaymentField } from '../../constants/fields/ReceivedPaymentField';
import { PaymentMethodCode } from '../../constants/closed-lookup/PaymentMethodCode';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEditReceivedPayment = (props: CreateEditProps) => {

    const [receivedPaymentFields] = useState<Field[]>(ReceivedPaymentFields.get());

    const isNew = props.id === null || props.id === undefined;

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        model: isNew ? new CreateReceivedPaymentRequest() : new UpdateReceivedPaymentRequest(),
        service: new ReceivedPaymentService(),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });


    const getReceivedPaymentField = (fieldCode: string) => {
        return receivedPaymentFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const onPaymentMethodCodeChanged = (value: any) => {
        setFieldValue(ReceivedPaymentField.PaymentMethodCode, value);
        setFieldValue(ReceivedPaymentField.CheckDate, null);
        setFieldValue(ReceivedPaymentField.IsCheckReturned, null);
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                {getFieldValue(ReceivedPaymentField.IsCheckReturned) === true &&
                    <div className="field col-12">
                        <Tag severity="warning" value={"لن يتم احتساب دفعة الشيك الراجع ضمن الدفعات المقبوضة"} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getReceivedPaymentField(ReceivedPaymentField.CustomerName)}
                        value={getFieldValue(ReceivedPaymentField.CustomerName)}
                        errors={getFieldModelErrors(ReceivedPaymentField.CustomerName)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(ReceivedPaymentField.CustomerName, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getReceivedPaymentField(ReceivedPaymentField.PaymentDate)}
                        value={getFieldValue(ReceivedPaymentField.PaymentDate)}
                        errors={getFieldModelErrors(ReceivedPaymentField.PaymentDate)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(ReceivedPaymentField.PaymentDate, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getReceivedPaymentField(ReceivedPaymentField.PaymentAmount)}
                        value={getFieldValue(ReceivedPaymentField.PaymentAmount)}
                        errors={getFieldModelErrors(ReceivedPaymentField.PaymentAmount)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(ReceivedPaymentField.PaymentAmount, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getReceivedPaymentField(ReceivedPaymentField.PaymentMethodCode)}
                        value={getFieldValue(ReceivedPaymentField.PaymentMethodCode)}
                        errors={getFieldModelErrors(ReceivedPaymentField.PaymentMethodCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => onPaymentMethodCodeChanged(value)} />
                </div>

                {getFieldValue(ReceivedPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getReceivedPaymentField(ReceivedPaymentField.CheckDate)}
                            value={getFieldValue(ReceivedPaymentField.CheckDate)}
                            errors={getFieldModelErrors(ReceivedPaymentField.CheckDate)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(ReceivedPaymentField.CheckDate, value)} />
                    </div>
                }

                {getFieldValue(ReceivedPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getReceivedPaymentField(ReceivedPaymentField.IsCheckReturned)}
                            value={getFieldValue(ReceivedPaymentField.IsCheckReturned)}
                            errors={getFieldModelErrors(ReceivedPaymentField.IsCheckReturned)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(ReceivedPaymentField.IsCheckReturned, value)} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getReceivedPaymentField(ReceivedPaymentField.Notes)}
                        value={getFieldValue(ReceivedPaymentField.Notes)}
                        errors={getFieldModelErrors(ReceivedPaymentField.Notes)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(ReceivedPaymentField.Notes, value)} />
                </div>


                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CreateEditReceivedPayment;
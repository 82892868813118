import { AuthenticationResponse } from "../models/authentication/AuthenticationResponse";

export class UserAuthentication {

    private static readonly TokenStorageKey: string = "DrivingSchoolSystemToken";

    private static Authentication: AuthenticationResponse | null = null;
    private static SetContextAuthentication: React.Dispatch<React.SetStateAction<AuthenticationResponse | null>>;

    static initialize(setContextAuthentication: React.Dispatch<React.SetStateAction<AuthenticationResponse | null>>): void {
        this.SetContextAuthentication = setContextAuthentication;
    }

    static getToken(): string | null {
        return this.Authentication?.Token || null;
    }

    static getTokenFromLocalStorage(): string | null {
        return localStorage.getItem(this.TokenStorageKey) || null;
    }

    static set(authenticationResponse: AuthenticationResponse | null): void {
        if (authenticationResponse && authenticationResponse.Token) {
            this.Authentication = authenticationResponse;
            localStorage.setItem(this.TokenStorageKey, authenticationResponse.Token);
            if (this.SetContextAuthentication) {
                this.SetContextAuthentication(authenticationResponse);
            }
        } else {
            this.Authentication = null;
            localStorage.removeItem(this.TokenStorageKey);
            if (this.SetContextAuthentication) {
                this.SetContextAuthentication(null);
            }
        }
    }

    static hasPermissions(permissions: string): boolean {
        if (this.Authentication) {
            if (this.Authentication.IsMain) {
                return true;
            }
            if (permissions && this.Authentication.Permissions) {
                return permissions.split(",").every(permission => this.Authentication && this.Authentication.Permissions.includes(permission))
            }
        }
        return false;
    }
}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { StudentPracticalExamField } from "../constants/fields/StudentPracticalExamField";
import { StudentField } from "../constants/fields/StudentField";
import { CarField } from "../constants/fields/CarField";
import { StudentPracticalExamLabel } from "../constants/labels/StudentPracticalExamLabel";
import { StudentService } from "../services/StudentService";
import { StudyTypeService } from "../services/StudyTypeService";
import { StudentExamStatusService } from "../services/StudentExamStatusService";
import { CarService } from "../services/CarService";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { Templates } from "../utilities/Templates";

export class StudentPracticalExamFields {

    static get(): Field[] {
        return [

            new Field({
                Code: StudentPracticalExamField.StudentId,
                Label: StudentPracticalExamLabel.StudentId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudentService(),
                LookupLoadFilters: LookupLoadFilters.student(),
                LookupDisplayFieldCode: StudentField.FullName
            }),

            new Field({
                Code: StudentPracticalExamField.Student_FullName,
                Label: StudentPracticalExamLabel.Student_FullName,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.StudyTypeCode,
                Label: StudentPracticalExamLabel.StudyTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudyTypeService()
            }),

            new Field({
                Code: StudentPracticalExamField.StudyType_Name,
                Label: StudentPracticalExamLabel.StudyType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.CarId,
                Label: StudentPracticalExamLabel.CarId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarService(),
                LookupLoadFilters: LookupLoadFilters.car(),
                LookupDisplayTemplate: Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name)
            }),

            new Field({
                Code: StudentPracticalExamField.Car_CarCompany_Name,
                Label: StudentPracticalExamLabel.Car_CarCompany_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.Car_CarType_Name,
                Label: StudentPracticalExamLabel.Car_CarType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.StudentExamStatusCode,
                Label: StudentPracticalExamLabel.StudentExamStatusCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudentExamStatusService()
            }),

            new Field({
                Code: StudentPracticalExamField.StudentExamStatus_Name,
                Label: StudentPracticalExamLabel.StudentExamStatus_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.Date,
                Label: StudentPracticalExamLabel.Date,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPracticalExamField.Fees,
                Label: StudentPracticalExamLabel.Fees,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
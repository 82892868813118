import { http } from "../http/Http";
import { School } from "../models/school/School";
import { CreateSchoolRequest } from "../models/school/CreateSchoolRequest";
import { UpdateSchoolRequest } from "../models/school/UpdateSchoolRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class SchoolService {

    public EntityCode: EntityCode = EntityCode.School;

    private Api: string = "Schools";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<School>> => {
        const { data } = await http.post<GetByFiltersResponse<School>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<School> => {
        const { data } = await http.get<School>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createSchoolRequest: CreateSchoolRequest): Promise<School> => {
        const { data } = await http.post<School>(this.Api, createSchoolRequest);
        return data;
    };

    update = async (id: number, updateSchoolRequest: UpdateSchoolRequest): Promise<School> => {
        const { data } = await http.put<School>(this.Api + "/" + id.toString(), updateSchoolRequest);
        return data;
    };

    activate = async (id: number): Promise<School> => {
        const { data } = await http.put<School>(this.Api + "/Activate/" + id.toString());
        return data;
    };

    deactivate = async (id: number): Promise<School> => {
        const { data } = await http.put<School>(this.Api + "/Deactivate/" + id.toString());
        return data;
    };

}
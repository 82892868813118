import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { SchoolFields } from '../../fields/SchoolFields';
import { FieldType } from '../../constants/base/FieldType';
import { SchoolField } from '../../constants/fields/SchoolField';
import { Field } from '../../models/base/Field';
import { SchoolSetting } from '../../models/school-setting/SchoolSetting';
import { UpdateSchoolSettingRequest } from '../../models/school-setting/UpdateSchoolSettingRequest';
import { SchoolSettingService } from '../../services/SchoolSettingService';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

type Props = {
    isView?: boolean,
    callBack?: (response: any) => void,
    loadSchoolSettings?: React.MutableRefObject<any>
};

const ViewEditSchoolSettings = (props: Props) => {

    const [schoolFields] = useState<Field[]>(SchoolFields.get());

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent,
        loadModel
    } = UseModel({
        model: props.isView ? new SchoolSetting() : new UpdateSchoolSettingRequest(),
        service: new SchoolSettingService(),
        loadModelMethod: "get",
        saveMethod: "update"
    });

    useEffect(() => {
        if (props.loadSchoolSettings) {
            props.loadSchoolSettings.current = loadModel;
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    saveEvent.subscribe((result: any) => {
        if (result && props.callBack) {
            props.callBack(result.response);
        }
    });


    const getSchoolField = (fieldCode: string) => {
        return schoolFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }


    return (
        <div className="formgrid grid">

            <div className="field col-12 md:col-6">
                <FieldValue
                    field={getSchoolField(SchoolField.Name)}
                    value={getFieldValue(SchoolField.Name)}
                    errors={getFieldModelErrors(SchoolField.Name)}
                    isLoading={isLoading}
                    isDisabled={props.isView}
                    onChange={(value: any) => setFieldValue(SchoolField.Name, value)} />
            </div>

            <div className="field col-12 md:col-6">
                <FieldValue
                    field={getSchoolField(SchoolField.Phone)}
                    value={getFieldValue(SchoolField.Phone)}
                    errors={getFieldModelErrors(SchoolField.Phone)}
                    isLoading={isLoading}
                    isDisabled={props.isView}
                    onChange={(value: any) => setFieldValue(SchoolField.Phone, value)} />
            </div>

            <div className="field col-12 md:col-6">
                <FieldValue
                    field={getSchoolField(SchoolField.CityCode)}
                    value={getFieldValue(SchoolField.CityCode)}
                    errors={getFieldModelErrors(SchoolField.CityCode)}
                    isLoading={isLoading}
                    isDisabled={props.isView}
                    onChange={(value: any) => setFieldValue(SchoolField.CityCode, value)} />
            </div>

            <div className="field col-12 md:col-6">
                <FieldValue
                    field={getSchoolField(SchoolField.Address)}
                    value={getFieldValue(SchoolField.Address)}
                    errors={getFieldModelErrors(SchoolField.Address)}
                    isLoading={isLoading}
                    isDisabled={props.isView}
                    onChange={(value: any) => setFieldValue(SchoolField.Address, value)} />
            </div>

            {props.isView &&
                <React.Fragment>

                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolField(SchoolField.DemoExpirationDate)}
                            value={getFieldValue(SchoolField.DemoExpirationDate)}
                            isLoading={isLoading}
                            isDisabled={true} />
                    </div>

                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolField(SchoolField.FinishDemoExpirationDate)}
                            value={getFieldValue(SchoolField.FinishDemoExpirationDate)}
                            isLoading={isLoading}
                            isDisabled={true} />
                    </div>

                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolField(SchoolField.MaxUsersCount)}
                            value={getFieldValue(SchoolField.MaxUsersCount)}
                            isLoading={isLoading}
                            isDisabled={true} />
                    </div>

                </React.Fragment>
            }

            {!props.isView &&
                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>
            }

        </div>
    );
}

export default ViewEditSchoolSettings;
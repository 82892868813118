import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { FilterRequest } from "../models/base/FilterRequest";
import { FilterOperator } from "../constants/base/FilterOperator";
import { FiltersExpression } from "../constants/base/FiltersExpression";
import { SortDirection } from "../constants/base/SortDirection";

export class GetByFiltersRequestBuilder {

    private getByFiltersRequest: GetByFiltersRequest = {
        Filters: [],
        AdditionalFilters: [],
        QuickSearchFilters: [],
        FiltersExpression: null,
        AdditionalFiltersExpression: null,
        PageIndex: null,
        PageSize: null,
        SortBy: null,
        SortDirection: null,
        OnlyCount: false,
        Operations: null
    };

    addFilter(filterByField: string, fieldValue: string, filterOperator: FilterOperator): GetByFiltersRequestBuilder {
        let filter = new FilterRequest(filterByField, filterOperator, fieldValue);
        this.getByFiltersRequest.Filters.push(filter);
        return this;
    }

    addFilters(filters: FilterRequest[]): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.Filters.push(...filters);
        return this;
    }

    addQuickSearchFilter(filterByField: string, fieldValue: string, filterOperator: FilterOperator): GetByFiltersRequestBuilder {
        let filter = new FilterRequest(filterByField, filterOperator, fieldValue);
        this.getByFiltersRequest.QuickSearchFilters.push(filter);
        return this;
    }

    addQuickSearchFilters(quickSearchFilters: FilterRequest[]): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.QuickSearchFilters.push(...quickSearchFilters);
        return this;
    }

    addAdditionalFilter(filterByField: string, fieldValue: string, filterOperator: FilterOperator): GetByFiltersRequestBuilder {
        let filter = new FilterRequest(filterByField, filterOperator, fieldValue);
        this.getByFiltersRequest.AdditionalFilters.push(filter);
        return this;
    }

    addAdditionalFilters(additionalFilters: FilterRequest[]): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.AdditionalFilters.push(...additionalFilters);
        return this;
    }

    filtersExpression(filtersExpression: FiltersExpression): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.FiltersExpression = filtersExpression;
        return this;
    }

    additionalFiltersExpression(additionalFiltersExpression: FiltersExpression): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.AdditionalFiltersExpression = additionalFiltersExpression;
        return this;
    }

    pageIndex(pageIndex: number): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.PageIndex = pageIndex;
        return this;
    }

    pageSize(pageSize: number): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.PageSize = pageSize;
        return this;
    }

    sortBy(sortByField: string): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.SortBy = sortByField;
        return this;
    }

    sortDirection(sortDirection: SortDirection): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.SortDirection = sortDirection;
        return this;
    }

    onlyCount(onlyCount: boolean): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.OnlyCount = onlyCount;
        return this;
    }

    operations(operations: string[] | null): GetByFiltersRequestBuilder {
        this.getByFiltersRequest.Operations = operations;
        return this;
    }

    build(): GetByFiltersRequest {
        return this.getByFiltersRequest;
    }
}
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { Car } from "../../models/car/Car";
import { CreateCarRequest } from "../../models/car/CreateCarRequest";
import { UpdateCarRequest } from "../../models/car/UpdateCarRequest";
import { CarFields } from "../../fields/CarFields";
import { CarService } from "../../services/CarService";
import { CarsPermissions } from '../../constants/permissions/CarsPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import CarStatement from "./CarStatement";

const CarsList = () => {

    const additionalListButtonsCodes = {
        CarStatement: "CarStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.CarStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedCar, setSelectedCar] = useState<Car | null>(null);

    const [isCarStatementOpened, setIsCarStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.CarStatement]: false
    });

    const onRecordSelect = (car: Car | null) => {
        setSelectedCar(car);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.CarStatement]: UserAuthentication.hasPermissions(CarsPermissions.View)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, car: Car | null) => {
        if (car) {
            if (buttonCode === additionalListButtonsCodes.CarStatement) {
                setIsCarStatementOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <List
                title={"المركبات"}
                service={new CarService()}
                fields={CarFields.get()}
                filters={ListFilters.car()}
                createSuccessMessage={"تم اضافة المركبة بنجاح"}
                editSuccessMessage={"تم تعديل المركبة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف المركبة ؟"}
                deleteSuccessMessage={"تم حذف المركبة بنجاح"}
                createModel={new CreateCarRequest()}
                editModel={new UpdateCarRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(CarsPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(CarsPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(CarsPermissions.Delete)}
                onRecordSelect={(car: Car | null) => onRecordSelect(car)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />

            <Dialog
                header={"حساب المركبة"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isCarStatementOpened}
                onHide={() => setIsCarStatementOpened(false)} >

                {selectedCar &&
                    <CarStatement
                        id={selectedCar.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default CarsList;
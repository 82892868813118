import { http } from "../http/Http";
import { StudentLesson } from "../models/student-lesson/StudentLesson";
import { GetTrainerLessonsRequest } from "../models/student-lesson/GetTrainerLessonsRequest";
import { CreateStudentLessonRequest } from "../models/student-lesson/CreateStudentLessonRequest";
import { UpdateStudentLessonRequest } from "../models/student-lesson/UpdateStudentLessonRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentLessonService {

    public EntityCode: EntityCode = EntityCode.StudentLesson;

    private Api: string = "StudentLessons";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<StudentLesson>> => {
        const { data } = await http.post<GetByFiltersResponse<StudentLesson>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getTrainerLessons = async (getTrainerLessonsRequest: GetTrainerLessonsRequest): Promise<StudentLesson[]> => {
        const { data } = await http.post<StudentLesson[]>(this.Api + "/GetTrainerLessons", getTrainerLessonsRequest);
        return data;
    };

    getById = async (id: number): Promise<StudentLesson> => {
        const { data } = await http.get<StudentLesson>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createStudentLessonRequest: CreateStudentLessonRequest): Promise<StudentLesson[]> => {
        const { data } = await http.post<StudentLesson[]>(this.Api, createStudentLessonRequest);
        return data;
    };

    update = async (id: number, updateStudentLessonRequest: UpdateStudentLessonRequest): Promise<StudentLesson> => {
        const { data } = await http.put<StudentLesson>(this.Api + "/" + id.toString(), updateStudentLessonRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
import { Filter } from "./Filter";

type Params = {
    Filters: Filter[],
    Placeholder?: string
};

export class LookupFilters {

    Filters: Filter[];
    Placeholder: string | null;

    constructor(params: Params) {
        this.Filters = params.Filters;
        this.Placeholder = params.Placeholder === undefined ? null : params.Placeholder;
    }

}
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { CreateSchoolPaymentRequest } from "../../models/school-payment/CreateSchoolPaymentRequest";
import { UpdateSchoolPaymentRequest } from "../../models/school-payment/UpdateSchoolPaymentRequest";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { SchoolPaymentsPermissions } from "../../constants/permissions/SchoolPaymentsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import CreateEditSchoolPayment from "./CreateEditSchoolPayment";
import List from "../base/List";

const SchoolPaymentsList = () => {

    return (
        <List
            title={"دفعات المدرسة"}
            service={new SchoolPaymentService()}
            fields={SchoolPaymentFields.get()}
            fieldsCodesExcludedFromList={[SchoolPaymentField.CheckDateStatus]}
            filters={ListFilters.schoolPayment()}
            createSuccessMessage={"تم اضافة الدفعة بنجاح"}
            editSuccessMessage={"تم تعديل الدفعة بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الدفعة ؟"}
            deleteSuccessMessage={"تم حذف الدفعة بنجاح"}
            createModel={new CreateSchoolPaymentRequest()}
            editModel={new UpdateSchoolPaymentRequest()}
            createEdit={CreateEditSchoolPayment}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(SchoolPaymentsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(SchoolPaymentsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(SchoolPaymentsPermissions.Delete)} />
    );
}

export default SchoolPaymentsList;
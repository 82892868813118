import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { CarInsuranceService } from "../../services/CarInsuranceService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Field } from "../../models/base/Field";
import { CarInsuranceStatement as CarInsuranceStatementModel } from "../../models/car-insurance/CarInsuranceStatement";
import { CarInsuranceFields } from "../../fields/CarInsuranceFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { CarInsuranceField } from "../../constants/fields/CarInsuranceField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { CarInsuranceLabel } from "../../constants/labels/CarInsuranceLabel";
import { FieldType } from "../../constants/base/FieldType";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { SortDirection } from "../../constants/base/SortDirection";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const CarInsuranceStatement = (props: Props) => {

    const carInsuranceService: CarInsuranceService = new CarInsuranceService();

    const [carInsuranceFields] = useState<Field[]>(CarInsuranceFields.get());
    const [isCarInsuranceStatementLoading, setIsCarInsuranceStatementLoading] = useState<boolean>(false);
    const [carInsuranceStatement, setCarInsuranceStatement] = useState<CarInsuranceStatementModel | null>(null);


    useEffect(() => {
        loadCarInsuranceStatement();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getCarInsuranceField = (fieldCode: string) => {
        return carInsuranceFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadCarInsuranceStatement = () => {
        setIsCarInsuranceStatementLoading(true);
        carInsuranceService.getCarInsuranceStatement(props.id).then((carInsuranceStatement) => {
            setCarInsuranceStatement(carInsuranceStatement || null);
            setIsCarInsuranceStatementLoading(false);
        }).catch((_error: any) => {
            setCarInsuranceStatement(null);
            setIsCarInsuranceStatementLoading(false);
        });
    }

    const carInsuranceFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{CarInsuranceLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isCarInsuranceStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(carInsuranceStatement, getCarInsuranceField(fieldCode))}</div>
            }
        </div>;
    }

    const getListAdditionalFiltersFilters = () => {
        return [
            new FilterRequest(SchoolPaymentField.CarInsuranceId, FilterOperator.Equals, props.id.toString())
        ]
    }


    return (
        <React.Fragment>

            <Panel header="حساب تأمين المركبة" className="mt-3 mb-4">
                <div className="grid">
                    {carInsuranceFieldTemplate(CarInsuranceField.Car_PlateNumber)}
                    {carInsuranceFieldTemplate(CarInsuranceField.Car_CarCompany_Name)}
                    {carInsuranceFieldTemplate(CarInsuranceField.Car_CarType_Name)}
                    {carInsuranceFieldTemplate(CarInsuranceField.InsuranceCompany_Name)}
                    {carInsuranceFieldTemplate(CarInsuranceField.StartDate)}
                    {carInsuranceFieldTemplate(CarInsuranceField.EndDate)}
                    {carInsuranceFieldTemplate(CarInsuranceField.Cost)}
                    {carInsuranceFieldTemplate(CarInsuranceField.TotalPaid)}
                    {carInsuranceFieldTemplate(CarInsuranceField.TotalRemaining)}
                    {carInsuranceFieldTemplate(CarInsuranceField.TotalOverpaid)}
                </div>
            </Panel>

            <Panel header={"الدفعات"} className="mb-4">
                <List
                    title={"الدفعات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new SchoolPaymentService()}
                    fields={SchoolPaymentFields.get()}
                    fieldsCodesExcludedFromList={[
                        SchoolPaymentField.CheckDateStatus,
                        SchoolPaymentField.Notes,
                        SchoolPaymentField.Car_PlateNumber,
                        SchoolPaymentField.Car_CarCompany_Name,
                        SchoolPaymentField.Car_CarType_Name,
                        SchoolPaymentField.CarLicense_StartDate,
                        SchoolPaymentField.CarLicense_EndDate,
                        SchoolPaymentField.CarLicense_Car_PlateNumber,
                        SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                        SchoolPaymentField.CarLicense_Car_CarType_Name,
                        SchoolPaymentField.CarInsurance_StartDate,
                        SchoolPaymentField.CarInsurance_EndDate,
                        SchoolPaymentField.CarInsurance_Car_PlateNumber,
                        SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                        SchoolPaymentField.CarInsurance_Car_CarType_Name,
                        SchoolPaymentField.Employee_Name,
                        SchoolPaymentField.SchoolLicense_StartDate,
                        SchoolPaymentField.SchoolLicense_EndDate,
                        SchoolPaymentField.SchoolPaymentType_Name,
                        SchoolPaymentField.TrainerLicense_StartDate,
                        SchoolPaymentField.TrainerLicense_EndDate,
                        SchoolPaymentField.TrainerLicense_Employee_Name,
                        SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name
                    ]}
                    additionalFilters={getListAdditionalFiltersFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={SchoolPaymentField.PaymentDate}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

        </React.Fragment>
    );
}

export default CarInsuranceStatement;
export enum CarInsuranceLabel {
    StartDate = "تاريخ البداية",
    EndDate = "تاريخ النهاية",
    CarId = "المركبة",
    InsuranceCompanyCode = "شركة التأمين",
    Cost = "التكلفة",
    TotalPaid = "المبلغ المدفوع",
    TotalOverpaid = "المبلغ الفائض",
    TotalRemaining = "المبلغ المتبقي",
    Car_PlateNumber = "رقم المركبة",
    Car_CarCompany_Name = "المركبة",
    Car_CarType_Name = "نوع المركبة",
    InsuranceCompany_Name = "شركة التأمين",
    ExpirationStatus = "الحالة"
}
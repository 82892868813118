import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { AuthenticationContext } from "../../App";
import { AuditLog } from "../../models/audit-log/AuditLog";
import { ListButton } from "../../models/base/ListButton";
import { AuditLogFields } from "../../fields/AuditLogFields";
import { AuditLogService } from "../../services/AuditLogService";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import AuditLogDetails from "./AuditLogDetails";

const AuditLogsList = () => {

    const { authentication } = useContext(AuthenticationContext);

    const additionalListButtonsCodes = {
        AuditLogDetails: "AuditLogDetails"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.AuditLogDetails, Icon: "bars", Severity: "success", Position: "afterFilters", Tooltip: "تفاصيل الحركة" })
    ];

    const [selectedAuditLog, setSelectedAuditLog] = useState<AuditLog | null>(null);

    const [isAuditLogDetailsOpened, setIsAuditLogDetailsOpened] = useState<boolean>(false);

    const [showAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.AuditLogDetails]: (authentication && authentication.IsMain) === true
    });

    const onRecordSelect = (auditLog: AuditLog | null) => {
        setSelectedAuditLog(auditLog);
    }

    const onAdditionalListButtonClick = (buttonCode: string, auditLog: AuditLog | null) => {
        if (auditLog) {
            if (buttonCode === additionalListButtonsCodes.AuditLogDetails) {
                setIsAuditLogDetailsOpened(true);
            }
        }
    }


    return (
        <React.Fragment>
            <List
                title={"حركات النظام"}
                service={new AuditLogService()}
                fields={AuditLogFields.get()}
                filters={ListFilters.auditLog()}
                showRefreshButton={true}
                showFilterButton={true}
                onRecordSelect={(auditLog: AuditLog | null) => onRecordSelect(auditLog)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />

            <Dialog
                header={"تفاصيل الحركة"}
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isAuditLogDetailsOpened}
                onHide={() => setIsAuditLogDetailsOpened(false)} >

                {selectedAuditLog &&
                    <AuditLogDetails
                        auditLog={selectedAuditLog} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default AuditLogsList;
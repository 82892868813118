import { FieldMetadata } from "./FieldMetadata";

type Params = {
    Fields: FieldMetadata[],
    Expression: string
};

export class Template {
    Fields: FieldMetadata[];
    Expression: string;

    constructor(params: Params) {
        this.Fields = params.Fields;
        this.Expression = params.Expression;
    }
}
import { http } from "../http/Http";
import { InsuranceCompany } from "../models/insurance-company/InsuranceCompany";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class InsuranceCompanyService {

    public EntityCode: EntityCode = EntityCode.InsuranceCompany;

    private Api: string = "InsuranceCompanies";

    getAll = async (): Promise<InsuranceCompany[]> => {
        const { data } = await http.get<InsuranceCompany[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
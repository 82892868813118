import { http } from "../http/Http";
import { Permission } from "../models/permission/Permission";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class PermissionService {

    public EntityCode: EntityCode = EntityCode.Permission;

    private Api: string = "Permissions";

    getAll = async (): Promise<Permission[]> => {
        const { data } = await http.get<Permission[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
import { useState } from "react";
import { FilterRequest } from "../models/base/FilterRequest";
import { Filter } from "../models/base/Filter";
import { FilterOperator } from "../constants/base/FilterOperator";

type Props = {
    defaultFilter?: Filter,
    currentFilters: FilterRequest[]
};

const UseListFilters = (props: Props) => {

    const currentFilters: FilterRequest[] = props.currentFilters;
    const defaultFilter: Filter | null = props.defaultFilter === undefined ? null : props.defaultFilter;

    const [filters, setFilters] = useState<FilterRequest[]>(currentFilters && currentFilters.length > 0 ?
        currentFilters :
        (defaultFilter ? [new FilterRequest(defaultFilter.Property, defaultFilter.Operator)] : []));


    const setFilterProperty = (filter: FilterRequest, property: string) => {
        if (filter) {
            filter.Property = property;
            filter.Operator = FilterOperator.Equals;
            filter.Value = null;
            setFilters([...filters]);
        }
    }

    const setFilterOperator = (filter: FilterRequest, operator: FilterOperator) => {
        if (filter) {
            filter.Operator = operator;
            setFilters([...filters]);
        }
    }

    const setFilterValue = (filter: FilterRequest, value: string | null) => {
        if (filter) {
            filter.Value = value === undefined ? null : value;
            setFilters([...filters]);
        }
    }

    const addFilter = () => {
        if (defaultFilter) {
            filters.push(new FilterRequest(defaultFilter.Property, defaultFilter.Operator));
            setFilters([...filters]);
        }
    }

    const deleteFilter = (filter: FilterRequest) => {
        if (filter) {
            let newFilters = filters.filter(f => f.Id !== filter.Id);
            setFilters([...newFilters]);
        }
    }

    return {
        filters,
        setFilterProperty,
        setFilterOperator,
        setFilterValue,
        addFilter,
        deleteFilter
    };
};

export default UseListFilters;
import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { CarLicenseField } from "../../constants/fields/CarLicenseField";
import { CarLicense } from "../../models/car-license/CarLicense";
import { CreateCarLicenseRequest } from "../../models/car-license/CreateCarLicenseRequest";
import { UpdateCarLicenseRequest } from "../../models/car-license/UpdateCarLicenseRequest";
import { CarLicenseFields } from "../../fields/CarLicenseFields";
import { CarLicenseService } from "../../services/CarLicenseService";
import { CarLicensesPermissions } from '../../constants/permissions/CarLicensesPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import CarLicenseStatement from "./CarLicenseStatement";

const CarLicensesList = () => {

    const additionalListButtonsCodes = {
        CarLicenseStatement: "CarLicenseStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.CarLicenseStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedCarLicense, setSelectedCarLicense] = useState<CarLicense | null>(null);

    const [isCarLicenseStatementOpened, setIsCarLicenseStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.CarLicenseStatement]: false
    });

    const onRecordSelect = (carLicense: CarLicense | null) => {
        setSelectedCarLicense(carLicense);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.CarLicenseStatement]: UserAuthentication.hasPermissions(CarLicensesPermissions.View)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, carLicense: CarLicense | null) => {
        if (carLicense) {
            if (buttonCode === additionalListButtonsCodes.CarLicenseStatement) {
                setIsCarLicenseStatementOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <List
                title={"ترخيص المركبات"}
                hints={["عند تجديد ترخيص مركبة قم بإضافة ترخيص جديد بدلاً من تعديل القديم للحفاظ على معلومات التراخيص القديمة"]}
                service={new CarLicenseService()}
                fields={CarLicenseFields.get()}
                fieldsCodesExcludedFromList={[CarLicenseField.ExpirationStatus]}
                filters={ListFilters.carLicense()}
                createSuccessMessage={"تم اضافة ترخيص المركبة بنجاح"}
                editSuccessMessage={"تم تعديل ترخيص المركبة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف ترخيص المركبة ؟"}
                deleteSuccessMessage={"تم حذف ترخيص المركبة بنجاح"}
                createModel={new CreateCarLicenseRequest()}
                editModel={new UpdateCarLicenseRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(CarLicensesPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(CarLicensesPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(CarLicensesPermissions.Delete)}
                onRecordSelect={(carLicense: CarLicense | null) => onRecordSelect(carLicense)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />


            <Dialog
                header={"حساب ترخيص المركبة"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isCarLicenseStatementOpened}
                onHide={() => setIsCarLicenseStatementOpened(false)} >

                {selectedCarLicense &&
                    <CarLicenseStatement
                        id={selectedCarLicense.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default CarLicensesList;
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { StudentDiscountField } from "../constants/fields/StudentDiscountField";
import { StudentField } from "../constants/fields/StudentField";
import { StudentDiscountLabel } from "../constants/labels/StudentDiscountLabel";
import { DiscountTypeService } from "../services/DiscountTypeService";
import { StudentService } from "../services/StudentService";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";

export class StudentDiscountFields {

    static get(): Field[] {
        return [

            new Field({
                Code: StudentDiscountField.StudentId,
                Label: StudentDiscountLabel.StudentId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                LookupService: new StudentService(),
                LookupLoadFilters: LookupLoadFilters.student(),
                LookupDisplayFieldCode: StudentField.FullName
            }),

            new Field({
                Code: StudentDiscountField.Student_FullName,
                Label: StudentDiscountLabel.Student_FullName,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentDiscountField.DiscountTypeCode,
                Label: StudentDiscountLabel.DiscountTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new DiscountTypeService()
            }),

            new Field({
                Code: StudentDiscountField.DiscountType_Name,
                Label: StudentDiscountLabel.DiscountType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentDiscountField.DiscountAmount,
                Label: StudentDiscountLabel.DiscountAmount,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                CustomTemplateCode: "StudentDiscountAmount",
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
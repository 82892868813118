import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { AuthenticationContext } from "../../App";
import { User } from "../../models/user/User";
import { ListButton } from "../../models/base/ListButton";
import { CreateUserRequest } from "../../models/user/CreateUserRequest";
import { UpdateUserRequest } from "../../models/user/UpdateUserRequest";
import { UserFields } from "../../fields/UserFields";
import { UserService } from "../../services/UserService";
import { UsersPermissions } from '../../constants/permissions/UsersPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { AppToast } from "../../utilities/AppToast";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import EditUserPermissions from "./EditUserPermissions";

const UsersList = () => {

    const { authentication } = useContext(AuthenticationContext);

    const additionalListButtonsCodes = {
        EditPermissions: "EditPermissions"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.EditPermissions, Icon: "key", Severity: "success", Position: "afterEdit", Tooltip: "تعديل الصلاحيات" })
    ];

    const [selectedRecord, setSelectedRecord] = useState<User | null>(null);

    const [isEditPermissionsOpened, setIsEditPermissionsOpened] = useState<boolean>(false);

    //const [loadDatatableRecordsTrigger, setLoadDatatableRecordsTrigger] = useState<number>(0);
    //setLoadDatatableRecordsTrigger((loadDatatableRecordsTrigger) => loadDatatableRecordsTrigger + 1);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.EditPermissions]: false
    });

    const onRecordSelect = (record: User | null) => {
        setSelectedRecord(record);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.EditPermissions]: showEditPermissionsButton(record)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, record: User | null) => {
        if (record) {
            if (buttonCode === additionalListButtonsCodes.EditPermissions) {
                setIsEditPermissionsOpened(true);
            }
        }
    }

    const showEditPermissionsButton = (record: User | null) => {
        return UserAuthentication.hasPermissions(UsersPermissions.UpdatePermissions) &&
            record !== null &&
            !record.IsMain &&
            record.Id !== authentication?.UserId;
    }

    const showAddButton = () => {
        return UserAuthentication.hasPermissions(UsersPermissions.Create);
    }

    const showEditButton = () => {
        return UserAuthentication.hasPermissions(UsersPermissions.Update) &&
            selectedRecord !== null &&
            !selectedRecord.IsMain &&
            selectedRecord.Id !== authentication?.UserId;
    }

    const showDeleteButton = () => {
        return UserAuthentication.hasPermissions(UsersPermissions.Delete) &&
            selectedRecord !== null &&
            !selectedRecord.IsMain &&
            selectedRecord.Id !== authentication?.UserId;
    }

    const showActivateButton = () => {
        return UserAuthentication.hasPermissions(UsersPermissions.Activate) &&
            selectedRecord !== null &&
            !selectedRecord.IsMain &&
            selectedRecord.Id !== authentication?.UserId;
    }

    const showDeactivateButton = () => {
        return UserAuthentication.hasPermissions(UsersPermissions.Deactivate) &&
            selectedRecord !== null &&
            !selectedRecord.IsMain &&
            selectedRecord.Id !== authentication?.UserId;
    }

    const handleEditUserPermissionsCallback = () => {
        setIsEditPermissionsOpened(false);
        AppToast.success("تم تعديل صلاحيات المستخدم بنجاح");
    }

    return (
        <React.Fragment>

            <List
                title={"مستخدمين النظام"}
                service={new UserService()}
                fields={UserFields.get()}
                filters={ListFilters.user()}
                createSuccessMessage={"تم اضافة المستخدم بنجاح"}
                editSuccessMessage={"تم تعديل المستخدم بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف المستخدم ؟"}
                deleteSuccessMessage={"تم حذف المستخدم بنجاح"}
                confirmActivateMessage={"هل أنت متأكد من تفعيل حساب المستخدم ؟"}
                activateSuccessMessage={"تم تفعيل حساب المستخدم بنجاح"}
                confirmDeactivateMessage={"هل أنت متأكد من تعطيل حساب المستخدم ؟"}
                deactivateSuccessMessage={"تم تعطيل حساب المستخدم بنجاح"}
                createModel={new CreateUserRequest()}
                editModel={new UpdateUserRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={showAddButton()}
                showEditButton={showEditButton()}
                showDeleteButton={showDeleteButton()}
                showActivateButton={showActivateButton()}
                showDeactivateButton={showDeactivateButton()}
                onRecordSelect={(record: User | null) => onRecordSelect(record)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />


            <Dialog
                header={"تعديل الصلاحيات"}
                style={{ width: "60vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isEditPermissionsOpened}
                onHide={() => setIsEditPermissionsOpened(false)} >

                <EditUserPermissions
                    id={selectedRecord ? selectedRecord.Id : null}
                    callBack={(_result: any) => handleEditUserPermissionsCallback()} />
            </Dialog>

        </React.Fragment>
    );
}

export default UsersList;
export enum TrainerLicenseLabel {
    StartDate = "تاريخ البداية",
    EndDate = "تاريخ النهاية",
    EmployeeId = "المدرب/ة",
    TrainerLicenseTypeCode = "نوع الرخصة",
    Cost = "التكلفة",
    TotalPaid = "المبلغ المدفوع",
    TotalOverpaid = "المبلغ الفائض",
    TotalRemaining = "المبلغ المتبقي",
    Employee_Name = "المدرب/ة",
    TrainerLicenseType_Name = "نوع الرخصة",
    ExpirationStatus = "الحالة"
}
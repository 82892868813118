export enum ReceivedPaymentLabel {
    CustomerName = "الشخص/الشركة",
    PaymentDate = "تاريخ الدفعة",
    PaymentAmount = "قيمة الدفعة",
    PaymentMethodCode = "طريقة الدفع",
    CheckDate = "تاريخ الشيك",
    IsCheckReturned = "شيك راجع؟",
    Notes = "ملاحظات الدفعة",
    PaymentMethod_Name = "طريقة الدفع",
    CheckDateStatus = "الحالة"
}
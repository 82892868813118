export class PermissionsMapper {

    static getGroupOrder(permissionGroup: string) {
        switch (permissionGroup) {
            case "SchoolSettings":
                return 12;
            case "Users":
                return 1;
            case "Cars":
                return 3;
            case "CarLicenses":
                return 4;
            case "CarInsurances":
                return 5;
            case "Employees":
                return 2;
            case "SchoolLicenses":
                return 13;
            case "SchoolPayments":
                return 14;
            case "Students":
                return 6;
            case "StudentPayments":
                return 11;
            case "StudentDiscounts":
                return 10;
            case "StudentTheoreticalExams":
                return 7;
            case "StudentPracticalExams":
                return 8;
            case "StudentLessons":
                return 9;
            case "ReceivedPayments":
                return 15;
            case "TrainerLicenses":
                return 16;
            default:
                return 100;
        }
    }

    static getGroupDisplayName(permissionGroup: string) {
        switch (permissionGroup) {
            case "SchoolSettings":
                return "اعدادات المدرسة";
            case "Users":
                return "مستخدمين النظام";
            case "Cars":
                return "المركبات";
            case "CarLicenses":
                return "تراخيص المركبات";
            case "CarInsurances":
                return "تأمينات المركبات";
            case "Employees":
                return "الموظفين";
            case "SchoolLicenses":
                return "تراخيص المدرسة";
            case "SchoolPayments":
                return "دفعات المدرسة";
            case "Students":
                return "الطلاب";
            case "StudentPayments":
                return "دفعات الطلاب";
            case "StudentDiscounts":
                return "خصومات الطلاب";
            case "StudentTheoreticalExams":
                return "الاختبارات النظرية";
            case "StudentPracticalExams":
                return "الاختبارات العملية";
            case "StudentLessons":
                return "الدروس";
            case "ReceivedPayments":
                return "الدفعات المقبوضة";
            case "TrainerLicenses":
                return "رخص المدربين";
            default:
                return permissionGroup;
        }
    }

    static getPermissionDisplayName(permission: string) {
        switch (permission) {
            case "View":
                return "عرض";
            case "Create":
                return "اضافة";
            case "Update":
                return "تعديل";
            case "Delete":
                return "حذف";
            case "Activate":
                return "تفعيل";
            case "Deactivate":
                return "تعطيل";
            case "UpdatePermissions":
                return "تعديل صلاحيات";
            default:
                return permission;
        }
    }

    static getFullName(permissionWithGroup: string) {
        if (permissionWithGroup && permissionWithGroup.indexOf(".") !== -1) {
            let group = permissionWithGroup.split(".")[0];
            let permission = permissionWithGroup.split(".")[1];
            if (group && permission) {
                return this.getPermissionDisplayName(permission) + " " + this.getGroupDisplayName(group);
            }
        }
        return null;
    }

}
export enum SchoolField {
    Name = "Name",
    CityCode = "CityCode",
    Phone = "Phone",
    Address = "Address",
    IsActive = "IsActive",
    DemoExpirationDate = "DemoExpirationDate",
    FinishDemoExpirationDate = "FinishDemoExpirationDate",
    MaxUsersCount = "MaxUsersCount",
    IsManagement = "IsManagement",
    MainUserEmail = "MainUserEmail",
    MainUserPassword = "MainUserPassword",
    MainUserName = "MainUserName",
    MainUserGenderCode = "MainUserGenderCode",
    Notes = "Notes",
    City_Name = "City.Name"
}
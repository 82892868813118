import { StudentPaymentField } from "../../constants/fields/StudentPaymentField";
import { CreateStudentPaymentRequest } from "../../models/student-payment/CreateStudentPaymentRequest";
import { UpdateStudentPaymentRequest } from "../../models/student-payment/UpdateStudentPaymentRequest";
import { StudentPaymentFields } from "../../fields/StudentPaymentFields";
import { StudentPaymentService } from "../../services/StudentPaymentService";
import { StudentPaymentsPermissions } from "../../constants/permissions/StudentPaymentsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import CreateEditStudentPayment from "./CreateEditStudentPayment";
import List from "../base/List";

const StudentPaymentsList = () => {

    return (
        <List
            title={"دفعات الطلاب"}
            service={new StudentPaymentService()}
            fields={StudentPaymentFields.get()}
            fieldsCodesExcludedFromList={[StudentPaymentField.CheckDateStatus]}
            filters={ListFilters.studentPayment()}
            createSuccessMessage={"تم اضافة الدفعة بنجاح"}
            editSuccessMessage={"تم تعديل الدفعة بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الدفعة ؟"}
            deleteSuccessMessage={"تم حذف الدفعة بنجاح"}
            createModel={new CreateStudentPaymentRequest()}
            editModel={new UpdateStudentPaymentRequest()}
            createEdit={CreateEditStudentPayment}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(StudentPaymentsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(StudentPaymentsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(StudentPaymentsPermissions.Delete)} />
    );
}

export default StudentPaymentsList;
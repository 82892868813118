import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { StudentTheoreticalExamField } from "../constants/fields/StudentTheoreticalExamField";
import { StudentField } from "../constants/fields/StudentField";
import { StudentTheoreticalExamLabel } from "../constants/labels/StudentTheoreticalExamLabel";
import { StudentService } from "../services/StudentService";
import { StudyTypeService } from "../services/StudyTypeService";
import { StudentExamStatusService } from "../services/StudentExamStatusService";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";

export class StudentTheoreticalExamFields {

    static get(): Field[] {
        return [

            new Field({
                Code: StudentTheoreticalExamField.StudentId,
                Label: StudentTheoreticalExamLabel.StudentId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudentService(),
                LookupLoadFilters: LookupLoadFilters.student(),
                LookupDisplayFieldCode: StudentField.FullName
            }),

            new Field({
                Code: StudentTheoreticalExamField.Student_FullName,
                Label: StudentTheoreticalExamLabel.Student_FullName,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentTheoreticalExamField.StudyTypeCode,
                Label: StudentTheoreticalExamLabel.StudyTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudyTypeService()
            }),

            new Field({
                Code: StudentTheoreticalExamField.StudyType_Name,
                Label: StudentTheoreticalExamLabel.StudyType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentTheoreticalExamField.StudentExamStatusCode,
                Label: StudentTheoreticalExamLabel.StudentExamStatusCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudentExamStatusService()
            }),

            new Field({
                Code: StudentTheoreticalExamField.StudentExamStatus_Name,
                Label: StudentTheoreticalExamLabel.StudentExamStatus_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentTheoreticalExamField.Date,
                Label: StudentTheoreticalExamLabel.Date,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentTheoreticalExamField.Fees,
                Label: StudentTheoreticalExamLabel.Fees,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
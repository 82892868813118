export class CreateCarInsuranceRequest {
    StartDate: Date;
    EndDate: Date;
    CarId: number;
    InsuranceCompanyCode: string;
    Cost: number;

    Initialize() {
        this.Cost = 0;
        return this;
    }
}
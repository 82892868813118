import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { CarService } from "../../services/CarService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Field } from "../../models/base/Field";
import { CarStatement as CarStatementModel } from "../../models/car/CarStatement";
import { CarFields } from "../../fields/CarFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { CarField } from "../../constants/fields/CarField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { CarLabel } from "../../constants/labels/CarLabel";
import { FieldType } from "../../constants/base/FieldType";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { SortDirection } from "../../constants/base/SortDirection";
import { SchoolPaymentTypeCode } from "../../constants/closed-lookup/SchoolPaymentTypeCode";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const CarStatement = (props: Props) => {

    const carService: CarService = new CarService();

    const [carFields] = useState<Field[]>(CarFields.get());
    const [isCarStatementLoading, setIsCarStatementLoading] = useState<boolean>(false);
    const [carStatement, setCarStatement] = useState<CarStatementModel | null>(null);


    useEffect(() => {
        loadCarStatement();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getCarField = (fieldCode: string) => {
        return carFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadCarStatement = () => {
        setIsCarStatementLoading(true);
        carService.getCarStatement(props.id).then((carStatement) => {
            setCarStatement(carStatement || null);
            setIsCarStatementLoading(false);
        }).catch((_error: any) => {
            setCarStatement(null);
            setIsCarStatementLoading(false);
        });
    }

    const carFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{CarLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isCarStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(carStatement, getCarField(fieldCode))}</div>
            }
        </div>;
    }

    const getListAdditionalFiltersFilters = () => {
        return [
            new FilterRequest(SchoolPaymentField.CarId, FilterOperator.Equals, props.id.toString()),
            new FilterRequest(SchoolPaymentField.SchoolPaymentTypeCode, FilterOperator.Equals, SchoolPaymentTypeCode.CarPurchase)
        ]
    }


    return (
        <React.Fragment>

            <Panel header="حساب المركبة" className="mt-3 mb-4">
                <div className="grid">
                    {carFieldTemplate(CarField.PlateNumber)}
                    {carFieldTemplate(CarField.CarCompany_Name)}
                    {carFieldTemplate(CarField.CarType_Name)}
                    {carFieldTemplate(CarField.Cost)}
                    {carFieldTemplate(CarField.TotalPaid)}
                    {carFieldTemplate(CarField.TotalRemaining)}
                    {carFieldTemplate(CarField.TotalOverpaid)}
                </div>
            </Panel>

            <Panel header={"الدفعات"} className="mb-4">
                <List
                    title={"الدفعات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new SchoolPaymentService()}
                    fields={SchoolPaymentFields.get()}
                    fieldsCodesExcludedFromList={[
                        SchoolPaymentField.CheckDateStatus,
                        SchoolPaymentField.Notes,
                        SchoolPaymentField.Car_PlateNumber,
                        SchoolPaymentField.Car_CarCompany_Name,
                        SchoolPaymentField.Car_CarType_Name,
                        SchoolPaymentField.CarLicense_StartDate,
                        SchoolPaymentField.CarLicense_EndDate,
                        SchoolPaymentField.CarLicense_Car_PlateNumber,
                        SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                        SchoolPaymentField.CarLicense_Car_CarType_Name,
                        SchoolPaymentField.CarInsurance_StartDate,
                        SchoolPaymentField.CarInsurance_EndDate,
                        SchoolPaymentField.CarInsurance_Car_PlateNumber,
                        SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                        SchoolPaymentField.CarInsurance_Car_CarType_Name,
                        SchoolPaymentField.Employee_Name,
                        SchoolPaymentField.SchoolLicense_StartDate,
                        SchoolPaymentField.SchoolLicense_EndDate,
                        SchoolPaymentField.SchoolPaymentType_Name,
                        SchoolPaymentField.TrainerLicense_StartDate,
                        SchoolPaymentField.TrainerLicense_EndDate,
                        SchoolPaymentField.TrainerLicense_Employee_Name,
                        SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name
                    ]}
                    additionalFilters={getListAdditionalFiltersFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={SchoolPaymentField.PaymentDate}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

        </React.Fragment>
    );
}

export default CarStatement;
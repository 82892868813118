import { FieldType } from "../../constants/base/FieldType";
import { FieldInputSize, CustomTemplateCode } from "../../types/Types";
import { DatatableColumn } from "./DatatableColumn";
import { DetailsDatatableColumn } from "./DetailsDatatableColumn";
import { EntityFilters } from "./EntityFilters";
import { FilterRequest } from "./FilterRequest";
import { LookupFilters } from "./LookupFilters";
import { Template } from "./Template";
import { AuditLogField } from "../audit-log/AuditLogField";

type Params = {
    Code: string,
    Type: FieldType,
    Label?: string,
    Hint?: string,
    ShowHintOnlyInCreate?: boolean,
    LookupService?: any,
    LookupDisplayFieldCode?: string,
    LookupDisplayTemplate?: Template,
    LookupLoadFilters?: LookupFilters,
    LookupFilters?: FilterRequest[],
    ListLookupFields?: Field[],
    ListLookupFilters?: EntityFilters,
    ShowInFilters?: boolean,
    ShowInCreate?: boolean,
    ShowInEdit?: boolean,
    ShowInListLookup?: boolean,
    YearsRange?: number,
    IsDropdownFilterEnabled?: boolean,
    IsLtr?: boolean,
    IsOneLine?: boolean,
    IsCurrency?: boolean,
    IsPercent?: boolean,
    InputSize?: FieldInputSize,
    CustomTemplateCode?: CustomTemplateCode,
    DatatableColumn?: DatatableColumn,
    DetailsDatatableColumn?: DetailsDatatableColumn
};

export class Field {
    Code: string;
    Type: FieldType;
    Label: string;
    Hint: string | null;
    ShowHintOnlyInCreate: boolean;
    LookupService: any;
    LookupDisplayFieldCode: string | null;
    LookupDisplayTemplate: Template | null;
    LookupLoadFilters: LookupFilters | null;
    LookupFilters: FilterRequest[];
    ListLookupFields: Field[];
    ListLookupFilters: EntityFilters | null;
    ShowInFilters: boolean;
    ShowInCreate: boolean;
    ShowInEdit: boolean;
    ShowInListLookup: boolean;
    YearsRange: number;
    IsDropdownFilterEnabled: boolean;
    IsLtr: boolean;
    IsOneLine: boolean;
    IsCurrency: boolean;
    IsPercent: boolean;
    InputSize: FieldInputSize;
    CustomTemplateCode: CustomTemplateCode | null;
    DatatableColumn: DatatableColumn | null;
    DetailsDatatableColumn: DetailsDatatableColumn | null;
    AuditLogField: AuditLogField;

    constructor(params: Params) {
        this.Code = params.Code;
        this.Type = params.Type;
        this.Label = params.Label === undefined ? params.Code : params.Label;
        this.Hint = params.Hint === undefined ? null : params.Hint;
        this.ShowHintOnlyInCreate = params.ShowHintOnlyInCreate === undefined ? false : params.ShowHintOnlyInCreate;
        this.LookupService = params.LookupService === undefined ? null : params.LookupService;
        this.LookupDisplayFieldCode = params.LookupDisplayFieldCode === undefined ? null : params.LookupDisplayFieldCode;
        this.LookupDisplayTemplate = params.LookupDisplayTemplate === undefined ? null : params.LookupDisplayTemplate;
        this.LookupLoadFilters = params.LookupLoadFilters === undefined ? null : params.LookupLoadFilters;
        this.LookupFilters = params.LookupFilters === undefined ? [] : params.LookupFilters;
        this.ListLookupFields = params.ListLookupFields === undefined ? [] : params.ListLookupFields;
        this.ListLookupFilters = params.ListLookupFilters === undefined ? null : params.ListLookupFilters;
        this.ShowInFilters = params.ShowInFilters === undefined ? false : params.ShowInFilters;
        this.ShowInCreate = params.ShowInCreate === undefined ? false : params.ShowInCreate;
        this.ShowInEdit = params.ShowInEdit === undefined ? false : params.ShowInEdit;
        this.ShowInListLookup = params.ShowInListLookup === undefined ? false : params.ShowInListLookup;
        this.YearsRange = params.YearsRange === undefined ? 20 : params.YearsRange;
        this.IsDropdownFilterEnabled = params.IsDropdownFilterEnabled === undefined ? false : params.IsDropdownFilterEnabled;
        this.IsLtr = params.IsLtr === undefined ? false : params.IsLtr;
        this.IsOneLine = params.IsOneLine === undefined ? false : params.IsOneLine;
        this.IsCurrency = params.IsCurrency === undefined ? false : params.IsCurrency;
        this.IsPercent = params.IsPercent === undefined ? false : params.IsPercent;
        this.InputSize = params.InputSize === undefined ? "6" : params.InputSize;
        this.CustomTemplateCode = params.CustomTemplateCode === undefined ? null : params.CustomTemplateCode;
        this.DatatableColumn = params.DatatableColumn === undefined ? null : params.DatatableColumn;
        this.DetailsDatatableColumn = params.DetailsDatatableColumn === undefined ? null : params.DetailsDatatableColumn;
    }
}
import { http } from "../http/Http";
import { TrainerLicenseType } from "../models/trainer-license-type/TrainerLicenseType";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class TrainerLicenseTypeService {

    public EntityCode: EntityCode = EntityCode.TrainerLicenseType;

    private Api: string = "TrainerLicenseTypes";

    getAll = async (): Promise<TrainerLicenseType[]> => {
        const { data } = await http.get<TrainerLicenseType[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
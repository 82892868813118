type Props = {
    text: string
};

const Title = (props: Props) => {
    return (
        <div className="mb-3">
            <div className="pb-1 text-800 font-semibold border-bottom-1 surface-border">
                {props.text}
            </div>
        </div>
    );
}

export default Title;
import moment from "moment";
import { Template } from "../models/base/Template";
import { ValueGetter } from "./ValueGetter";
import { FieldType } from "../constants/base/FieldType";
import { DurationStatus } from "../models/shared/DurationStatus";

export class Convertor {

    static dateToString(date: Date | null, isDisplay: boolean = false): string | null {
        if (date) {
            if (isDisplay) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return this.numberToMinTwoDigitsString(date.getFullYear()) +
                    "-" +
                    this.numberToMinTwoDigitsString((date.getMonth() + 1)) +
                    "-" +
                    this.numberToMinTwoDigitsString(date.getDate());
            }
        }
        return null;
    }

    static datetimeToString(date: Date | null, isDisplay: boolean = false): string | null {
        if (date) {
            if (isDisplay) {
                return moment(date).format("DD/MM/YYYY hh:mm A");
            } else {
                return this.numberToMinTwoDigitsString(date.getFullYear()) +
                    "-" +
                    this.numberToMinTwoDigitsString((date.getMonth() + 1)) +
                    "-" +
                    this.numberToMinTwoDigitsString(date.getDate()) +
                    "T" +
                    this.numberToMinTwoDigitsString(date.getHours()) +
                    ":" +
                    this.numberToMinTwoDigitsString(date.getMinutes()) +
                    ":" +
                    this.numberToMinTwoDigitsString(date.getSeconds());
            }
        }
        return null;
    }

    static datetimeToStringWithoutSeconds(date: Date): string | null {
        if (date) {
            return this.numberToMinTwoDigitsString(date.getFullYear()) +
                "-" +
                this.numberToMinTwoDigitsString((date.getMonth() + 1)) +
                "-" +
                this.numberToMinTwoDigitsString(date.getDate()) +
                "T" +
                this.numberToMinTwoDigitsString(date.getHours()) +
                ":" +
                this.numberToMinTwoDigitsString(date.getMinutes());
        }
        return null;
    }

    static numberToMinTwoDigitsString(number: number): string | null {
        if (number !== null && number !== undefined) {
            return (number < 10 ? "0" : "") + number;
        }
        return null;
    }

    static stringToLtrString(value: string): string | null {
        if (value) {
            return "\u202A" + value + "\u202C";
        }
        return null;
    }

    static templateToValue(model: any, template: Template): string | null {
        if (model && template && template.Fields && template.Fields.length > 0 && template.Expression) {
            let valueExpression = template.Expression;
            template.Fields.forEach(templateField => {
                let templateFieldValue = ValueGetter.get(model, templateField.Code);

                let formattedTemplateFieldValue: string = "";

                if (templateField.Type === FieldType.Date) {
                    formattedTemplateFieldValue = this.dateToString(templateFieldValue ? new Date(templateFieldValue) : null, true) || "";
                }
                else if (templateField.Type === FieldType.DateTime) {
                    formattedTemplateFieldValue = this.datetimeToString(templateFieldValue ? new Date(templateFieldValue) : null, true) || "";
                }
                else {
                    formattedTemplateFieldValue = templateFieldValue ? templateFieldValue.toString() : "";
                }

                if (templateField.IsLtr) {
                    formattedTemplateFieldValue = this.stringToLtrString(formattedTemplateFieldValue) || "";
                }

                valueExpression = valueExpression.replace(new RegExp("{" + templateField.Code + "}", "g"), formattedTemplateFieldValue);

            });
            return valueExpression;
        }
        return null;
    }

    static durationStatusToDescription(durationStatus: DurationStatus, defaultDescription: string | null = null): string | null {
        if (durationStatus) {
            let result = "";
            if (durationStatus.Years !== null && durationStatus.Years !== undefined && durationStatus.Years > 0) {
                if (durationStatus.Years === 1) {
                    result += "سنة";
                } else if (durationStatus.Years === 2) {
                    result += "سنتين";
                } else if (durationStatus.Years >= 3 && durationStatus.Years <= 10) {
                    result += `${durationStatus.Years} سنوات`;
                } else {
                    result += `${durationStatus.Years} سنة`;
                }
            }
            if (durationStatus.Months !== null && durationStatus.Months !== undefined && durationStatus.Months > 0) {
                if (result !== "") {
                    result += " و ";
                }
                if (durationStatus.Months === 1) {
                    result += "شهر";
                } else if (durationStatus.Months === 2) {
                    result += "شهرين";
                } else if (durationStatus.Months >= 3 && durationStatus.Months <= 10) {
                    result += `${durationStatus.Months} أشهر`;
                } else {
                    result += `${durationStatus.Months} شهر`;
                }
            }
            if (durationStatus.Days !== null && durationStatus.Days !== undefined && durationStatus.Days > 0) {
                if (result !== "") {
                    result += " و ";
                }
                if (durationStatus.Days === 1) {
                    result += "يوم";
                } else if (durationStatus.Days === 2) {
                    result += "يومين";
                } else if (durationStatus.Days >= 3 && durationStatus.Days <= 10) {
                    result += `${durationStatus.Days} أيام`;
                } else {
                    result += `${durationStatus.Days} يوم`;
                }
            }
            return result === "" ? (defaultDescription ? defaultDescription : null) : result;
        }
        return defaultDescription ? defaultDescription : null;
    }
}
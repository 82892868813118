export enum SchoolPaymentTypeCode {
    CarLicense = "CLI",
    CarInsurance = "CIN",
    CarFuel = "CFU",
    CarMaintenance = "CMA",
    CarPurchase = "CPU",
    CarParking = "CPS",
    SetTheoreticalExams = "STE",
    SetPracticalExams = "SPE",
    ElectricityBill = "EBI",
    WaterBill = "WBI",
    InternetBill = "IBI",
    GasBill = "GBI",
    Tax = "TAX",
    EmployeeSalary = "ESA",
    EmployeeAdvancePayment = "EAD",
    SchoolLicense = "SLI",
    TrainerLicense = "TLI",
    Purchases = "PUR",
    Other = "OTH"
}
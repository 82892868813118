import { http } from "../http/Http";
import { City } from "../models/city/City";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class CityService {

    public EntityCode: EntityCode = EntityCode.City;

    private Api: string = "Cities";

    getAll = async (): Promise<City[]> => {
        const { data } = await http.get<City[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { CarLicenseService } from "../../services/CarLicenseService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Field } from "../../models/base/Field";
import { CarLicenseStatement as CarLicenseStatementModel } from "../../models/car-license/CarLicenseStatement";
import { CarLicenseFields } from "../../fields/CarLicenseFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { CarLicenseField } from "../../constants/fields/CarLicenseField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { CarLicenseLabel } from "../../constants/labels/CarLicenseLabel";
import { FieldType } from "../../constants/base/FieldType";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { SortDirection } from "../../constants/base/SortDirection";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const CarLicenseStatement = (props: Props) => {

    const carLicenseService: CarLicenseService = new CarLicenseService();

    const [carLicenseFields] = useState<Field[]>(CarLicenseFields.get());
    const [isCarLicenseStatementLoading, setIsCarLicenseStatementLoading] = useState<boolean>(false);
    const [carLicenseStatement, setCarLicenseStatement] = useState<CarLicenseStatementModel | null>(null);


    useEffect(() => {
        loadCarLicenseStatement();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getCarLicenseField = (fieldCode: string) => {
        return carLicenseFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadCarLicenseStatement = () => {
        setIsCarLicenseStatementLoading(true);
        carLicenseService.getCarLicenseStatement(props.id).then((carLicenseStatement) => {
            setCarLicenseStatement(carLicenseStatement || null);
            setIsCarLicenseStatementLoading(false);
        }).catch((_error: any) => {
            setCarLicenseStatement(null);
            setIsCarLicenseStatementLoading(false);
        });
    }

    const carLicenseFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{CarLicenseLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isCarLicenseStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(carLicenseStatement, getCarLicenseField(fieldCode))}</div>
            }
        </div>;
    }

    const getListAdditionalFiltersFilters = () => {
        return [
            new FilterRequest(SchoolPaymentField.CarLicenseId, FilterOperator.Equals, props.id.toString())
        ]
    }


    return (
        <React.Fragment>

            <Panel header="حساب ترخيص المركبة" className="mt-3 mb-4">
                <div className="grid">
                    {carLicenseFieldTemplate(CarLicenseField.Car_PlateNumber)}
                    {carLicenseFieldTemplate(CarLicenseField.Car_CarCompany_Name)}
                    {carLicenseFieldTemplate(CarLicenseField.Car_CarType_Name)}
                    {carLicenseFieldTemplate(CarLicenseField.StartDate)}
                    {carLicenseFieldTemplate(CarLicenseField.EndDate)}
                    {carLicenseFieldTemplate(CarLicenseField.Cost)}
                    {carLicenseFieldTemplate(CarLicenseField.TotalPaid)}
                    {carLicenseFieldTemplate(CarLicenseField.TotalRemaining)}
                    {carLicenseFieldTemplate(CarLicenseField.TotalOverpaid)}
                </div>
            </Panel>

            <Panel header={"الدفعات"} className="mb-4">
                <List
                    title={"الدفعات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new SchoolPaymentService()}
                    fields={SchoolPaymentFields.get()}
                    fieldsCodesExcludedFromList={[
                        SchoolPaymentField.CheckDateStatus,
                        SchoolPaymentField.Notes,
                        SchoolPaymentField.Car_PlateNumber,
                        SchoolPaymentField.Car_CarCompany_Name,
                        SchoolPaymentField.Car_CarType_Name,
                        SchoolPaymentField.CarLicense_StartDate,
                        SchoolPaymentField.CarLicense_EndDate,
                        SchoolPaymentField.CarLicense_Car_PlateNumber,
                        SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                        SchoolPaymentField.CarLicense_Car_CarType_Name,
                        SchoolPaymentField.CarInsurance_StartDate,
                        SchoolPaymentField.CarInsurance_EndDate,
                        SchoolPaymentField.CarInsurance_Car_PlateNumber,
                        SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                        SchoolPaymentField.CarInsurance_Car_CarType_Name,
                        SchoolPaymentField.Employee_Name,
                        SchoolPaymentField.SchoolLicense_StartDate,
                        SchoolPaymentField.SchoolLicense_EndDate,
                        SchoolPaymentField.SchoolPaymentType_Name
                    ]}
                    additionalFilters={getListAdditionalFiltersFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={SchoolPaymentField.PaymentDate}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

        </React.Fragment>
    );
}

export default CarLicenseStatement;
export enum StudentPracticalExamLabel {
    StudentId = "الطالب/ة",
    StudyTypeCode = "نوع الاختبار",
    StudentExamStatusCode = "الحالة",
    CarId = "المركبة",
    Date = "التاريخ",
    Fees = "الرسوم",
    Student_FullName = "الطالب/ة",
    StudyType_Name = "نوع الاختبار",
    StudentExamStatus_Name = "الحالة",
    Car_PlateNumber = "رقم المركبة",
    Car_CarCompany_Name = "المركبة",
    Car_CarType_Name = "نوع المركبة"
}
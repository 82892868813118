import { http } from "../http/Http";
import { SchoolPaymentType } from "../models/school-payment-type/SchoolPaymentType";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class SchoolPaymentTypeService {

    public EntityCode: EntityCode = EntityCode.SchoolPaymentType;

    private Api: string = "SchoolPaymentTypes";

    getAll = async (): Promise<SchoolPaymentType[]> => {
        const { data } = await http.get<SchoolPaymentType[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
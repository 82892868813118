export enum StudentField {
    FirstName = "FirstName",
    SecondName = "SecondName",
    ThirdName = "ThirdName",
    LastName = "LastName",
    FullName = "FullName",
    IdentityNumber = "IdentityNumber",
    GenderCode = "GenderCode",
    CityCode = "CityCode",
    Phone = "Phone",
    Address = "Address",
    BirthDate = "BirthDate",
    Email = "Email",
    TotalPaid = "TotalPaid",
    TotalFees = "TotalFees",
    TotalFeesAfterDiscounts = "TotalFeesAfterDiscounts",
    TotalRemaining = "TotalRemaining",
    TotalOverpaid = "TotalOverpaid",
    TotalTheoreticalExamsNumber = "TotalTheoreticalExamsNumber",
    TotalPracticalExamsNumber = "TotalPracticalExamsNumber",
    TotalLessonsNumber = "TotalLessonsNumber",
    Gender_Name = "Gender.Name",
    City_Name = "City.Name"
}
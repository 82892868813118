import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { ListButton } from "../../models/base/ListButton";
import { CarInsuranceField } from "../../constants/fields/CarInsuranceField";
import { CarInsurance } from "../../models/car-insurance/CarInsurance";
import { CreateCarInsuranceRequest } from "../../models/car-insurance/CreateCarInsuranceRequest";
import { UpdateCarInsuranceRequest } from "../../models/car-insurance/UpdateCarInsuranceRequest";
import { CarInsuranceFields } from "../../fields/CarInsuranceFields";
import { CarInsuranceService } from "../../services/CarInsuranceService";
import { CarInsurancesPermissions } from '../../constants/permissions/CarInsurancesPermissions';
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import CarInsuranceStatement from "./CarInsuranceStatement";

const CarInsurancesList = () => {

    const additionalListButtonsCodes = {
        CarInsuranceStatement: "CarInsuranceStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.CarInsuranceStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedCarInsurance, setSelectedCarInsurance] = useState<CarInsurance | null>(null);

    const [isCarInsuranceStatementOpened, setIsCarInsuranceStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.CarInsuranceStatement]: false
    });

    const onRecordSelect = (carInsurance: CarInsurance | null) => {
        setSelectedCarInsurance(carInsurance);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.CarInsuranceStatement]: UserAuthentication.hasPermissions(CarInsurancesPermissions.View)
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, carInsurance: CarInsurance | null) => {
        if (carInsurance) {
            if (buttonCode === additionalListButtonsCodes.CarInsuranceStatement) {
                setIsCarInsuranceStatementOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <List
                title={"تأمين المركبات"}
                hints={["عند تجديد تأمين مركبة قم بإضافة تأمين جديد بدلاً من تعديل القديم للحفاظ على معلومات التأمينات القديمة"]}
                service={new CarInsuranceService()}
                fields={CarInsuranceFields.get()}
                fieldsCodesExcludedFromList={[CarInsuranceField.ExpirationStatus]}
                filters={ListFilters.carInsurance()}
                createSuccessMessage={"تم اضافة تأمين المركبة بنجاح"}
                editSuccessMessage={"تم تعديل تأمين المركبة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف تأمين المركبة ؟"}
                deleteSuccessMessage={"تم حذف تأمين المركبة بنجاح"}
                createModel={new CreateCarInsuranceRequest()}
                editModel={new UpdateCarInsuranceRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(CarInsurancesPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(CarInsurancesPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(CarInsurancesPermissions.Delete)}
                onRecordSelect={(carInsurance: CarInsurance | null) => onRecordSelect(carInsurance)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />

            <Dialog
                header={"حساب تأمين المركبة"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isCarInsuranceStatementOpened}
                onHide={() => setIsCarInsuranceStatementOpened(false)} >

                {selectedCarInsurance &&
                    <CarInsuranceStatement
                        id={selectedCarInsurance.Id} />
                }
            </Dialog>
        </React.Fragment>
    );
}

export default CarInsurancesList;
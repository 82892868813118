export enum StudentLabel {
    FirstName = "الاسم الاول",
    SecondName = "الاسم الثاني",
    ThirdName = "الاسم الثالث",
    LastName = "الاسم الاخير",
    FullName = "الاسم",
    IdentityNumber = "رقم الهوية",
    GenderCode = "الجنس",
    CityCode = "المدينة",
    Phone = "الهاتف",
    Address = "العنوان",
    BirthDate = "تاريخ الميلاد",
    Email = "البريد الالكتروني",
    TotalPaid = "المبلغ المدفوع",
    TotalFees = "المبلغ المطلوب",
    TotalFeesAfterDiscounts = "المبلغ المطلوب بعد الخصم",
    TotalRemaining = "المبلغ المتبقي",
    TotalOverpaid = "المبلغ الفائض",
    TotalTheoreticalExamsNumber = "عدد الاختبارات النظرية",
    TotalPracticalExamsNumber = "عدد الاختبارات العملية",
    TotalLessonsNumber = "عدد الدروس",
    Gender_Name = "الجنس",
    City_Name = "المدينة"
}
import { Field } from "../models/base/Field";
import { FieldType } from "../constants/base/FieldType";
import { SchoolStatementField } from "../constants/fields/SchoolStatementField";
import { SchoolStatementLabel } from "../constants/labels/SchoolStatementLabel";

export class SchoolStatementFields {

    static get(): Field[] {
        return [
            new Field({
                Code: SchoolStatementField.TotalReceived,
                Label: SchoolStatementLabel.TotalReceived,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),
            new Field({
                Code: SchoolStatementField.TotalPaid,
                Label: SchoolStatementLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),
            new Field({
                Code: SchoolStatementField.TotalProfit,
                Label: SchoolStatementLabel.TotalProfit,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),
            new Field({
                Code: SchoolStatementField.TotalLoss,
                Label: SchoolStatementLabel.TotalLoss,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),
        ];
    }

}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FilterRequest } from "../models/base/FilterRequest";
import { FieldType } from "../constants/base/FieldType";
import { FilterOperator } from "../constants/base/FilterOperator";
import { StudentLessonField } from "../constants/fields/StudentLessonField";
import { StudentField } from "../constants/fields/StudentField";
import { CarField } from "../constants/fields/CarField";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { StudentLessonLabel } from "../constants/labels/StudentLessonLabel";
import { EmployeeJobCode } from "../constants/closed-lookup/EmployeeJobCode";
import { StudentService } from "../services/StudentService";
import { StudyTypeService } from "../services/StudyTypeService";
import { CarService } from "../services/CarService";
import { EmployeeService } from "../services/EmployeeService";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { Templates } from "../utilities/Templates";

export class StudentLessonFields {

    static get(): Field[] {
        return [
            new Field({
                Code: StudentLessonField.LessonsNumber,
                Label: StudentLessonLabel.LessonsNumber,
                Type: FieldType.Integer,
                ShowInCreate: true
            }),

            new Field({
                Code: StudentLessonField.StudentId,
                Label: StudentLessonLabel.StudentId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudentService(),
                LookupLoadFilters: LookupLoadFilters.student(),
                LookupDisplayFieldCode: StudentField.FullName
            }),

            new Field({
                Code: StudentLessonField.Student_FullName,
                Label: StudentLessonLabel.Student_FullName,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.StudyTypeCode,
                Label: StudentLessonLabel.StudyTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new StudyTypeService()
            }),

            new Field({
                Code: StudentLessonField.StudyType_Name,
                Label: StudentLessonLabel.StudyType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.EmployeeId,
                Label: StudentLessonLabel.EmployeeId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new EmployeeService(),
                LookupLoadFilters: LookupLoadFilters.employee(),
                LookupDisplayFieldCode: EmployeeField.Name,
                LookupFilters: [
                    new FilterRequest(EmployeeField.EmployeeJobCode, FilterOperator.Equals, EmployeeJobCode.Trainer)
                ]
            }),

            new Field({
                Code: StudentLessonField.Employee_Name,
                Label: StudentLessonLabel.Employee_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.CarId,
                Label: StudentLessonLabel.CarId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarService(),
                LookupLoadFilters: LookupLoadFilters.car(),
                LookupDisplayTemplate: Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name)
            }),

            new Field({
                Code: StudentLessonField.Car_CarCompany_Name,
                Label: StudentLessonLabel.Car_CarCompany_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.Car_CarType_Name,
                Label: StudentLessonLabel.Car_CarType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.Date,
                Label: StudentLessonLabel.Date,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentLessonField.Fees,
                Label: StudentLessonLabel.Fees,
                Hint: "رسوم الدرس الواحد",
                ShowHintOnlyInCreate: true,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
type Params = {
    Order?: number,
    Label?: string,
    IsHiddenIfEmpty?: boolean,
    WidthPercent?: number,
    MaxWidthInPx?: number,
    MinWidthInPx?: number
};

export class DetailsDatatableColumn {
    Order: number;
    Label: string | null;
    IsHiddenIfEmpty: boolean;
    Style: any;

    constructor(params: Params) {
        this.Order = params.Order === undefined ? 0 : params.Order;
        this.Label = params.Label === undefined ? null : params.Label;
        this.IsHiddenIfEmpty = params.IsHiddenIfEmpty === undefined ? false : params.IsHiddenIfEmpty;

        let style = {};

        if (params.WidthPercent !== undefined) {
            style["width"] = params.WidthPercent + "%";
        }

        if (params.MaxWidthInPx !== undefined) {
            style["maxWidth"] = params.MaxWidthInPx + "px";
        }

        if (params.MinWidthInPx !== undefined) {
            style["minWidth"] = params.MinWidthInPx + "px";
        }

        this.Style = style;
    }
}
import { http } from "../http/Http";
import { CarLicense } from "../models/car-license/CarLicense";
import { CarLicenseStatement } from "../models/car-license/CarLicenseStatement";
import { CreateCarLicenseRequest } from "../models/car-license/CreateCarLicenseRequest";
import { UpdateCarLicenseRequest } from "../models/car-license/UpdateCarLicenseRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class CarLicenseService {

    public EntityCode: EntityCode = EntityCode.CarLicense;

    private Api: string = "CarLicenses";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<CarLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<CarLicense>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<CarLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<CarLicense>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<CarLicense> => {
        const { data } = await http.get<CarLicense>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<CarLicense> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<CarLicense>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getCarLicenseStatement = async (id: number): Promise<CarLicenseStatement> => {
        const { data } = await http.get<CarLicenseStatement>(this.Api + "/GetCarLicenseStatement/" + id.toString());
        return data;
    };

    create = async (createCarLicenseRequest: CreateCarLicenseRequest): Promise<CarLicense> => {
        const { data } = await http.post<CarLicense>(this.Api, createCarLicenseRequest);
        return data;
    };

    update = async (id: number, updateCarLicenseRequest: UpdateCarLicenseRequest): Promise<CarLicense> => {
        const { data } = await http.put<CarLicense>(this.Api + "/" + id.toString(), updateCarLicenseRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
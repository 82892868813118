import { ReceivedPaymentField } from "../../constants/fields/ReceivedPaymentField";
import { CreateReceivedPaymentRequest } from "../../models/received-payment/CreateReceivedPaymentRequest";
import { UpdateReceivedPaymentRequest } from "../../models/received-payment/UpdateReceivedPaymentRequest";
import { ReceivedPaymentFields } from "../../fields/ReceivedPaymentFields";
import { ReceivedPaymentService } from "../../services/ReceivedPaymentService";
import { ReceivedPaymentsPermissions } from "../../constants/permissions/ReceivedPaymentsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import CreateEditReceivedPayment from "./CreateEditReceivedPayment";
import List from "../base/List";

const ReceivedPaymentsList = () => {

    return (
        <List
            title={"الدفعات المقبوضة"}
            service={new ReceivedPaymentService()}
            fields={ReceivedPaymentFields.get()}
            fieldsCodesExcludedFromList={[ReceivedPaymentField.CheckDateStatus]}
            filters={ListFilters.receivedPayment()}
            createSuccessMessage={"تم اضافة الدفعة بنجاح"}
            editSuccessMessage={"تم تعديل الدفعة بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الدفعة ؟"}
            deleteSuccessMessage={"تم حذف الدفعة بنجاح"}
            createModel={new CreateReceivedPaymentRequest()}
            editModel={new UpdateReceivedPaymentRequest()}
            createEdit={CreateEditReceivedPayment}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.Delete)} />
    );
}

export default ReceivedPaymentsList;
export class CreateStudentTheoreticalExamRequest {
    StudentId: number;
    StudyTypeCode: string;
    StudentExamStatusCode: string;
    Date: Date;
    Fees: number;

    Initialize() {
        this.Fees = 0;
        return this;
    }
}
export enum SchoolPaymentLabel {
    SchoolPaymentTypeCode = "نوع الدفعة",
    CarLicenseId = "ترخيص المركبة",
    TrainerLicenseId = "رخصة المدرب/ة",
    CarInsuranceId = "تأمين المركبة",
    CarId = "المركبة",
    EmployeeId = "الموظف/ة",
    SchoolLicenseId = "ترخيص المدرسة",
    PaymentDate = "تاريخ الدفعة",
    PaymentAmount = "قيمة الدفعة",
    PaymentMethodCode = "طريقة الدفع",
    CheckDate = "تاريخ الشيك",
    IsCheckReturned = "شيك راجع؟",
    Notes = "ملاحظات الدفعة",
    SchoolPaymentType_Name = "نوع الدفعة",
    PaymentMethod_Name = "طريقة الدفع",

    CarLicense_Car_PlateNumber = "رقم المركبة",
    CarLicense_Car_CarCompany_Name = "المركبة",
    CarLicense_Car_CarType_Name = "نوع المركبة",

    CarInsurance_Car_PlateNumber = "رقم المركبة",
    CarInsurance_Car_CarCompany_Name = "المركبة",
    CarInsurance_Car_CarType_Name = "نوع المركبة",


    Car_PlateNumber = "رقم المركبة",
    Car_CarCompany_Name = "المركبة",
    Car_CarType_Name = "نوع المركبة",

    CarLicense_StartDate = "تاريخ بداية الترخيص",
    CarLicense_EndDate = "تاريخ نهاية الترخيص",

    CarInsurance_StartDate = "تاريخ بداية التأمين",
    CarInsurance_EndDate = "تاريخ نهاية التأمين",

    SchoolLicense_StartDate = "تاريخ بداية الترخيص",
    SchoolLicense_EndDate = "تاريخ نهاية الترخيص",

    Employee_Name = "الموظف/ة",
    
    TrainerLicense_StartDate = "تاريخ بداية الرخصة",
    TrainerLicense_EndDate = "تاريخ نهاية الرخصة",
    TrainerLicense_Employee_Name = "المدرب/ة",
    TrainerLicense_TrainerLicenseType_Name = "نوع الرخصة",

    CheckDateStatus = "الحالة"
}
export class Errors {
    static get(error: string): string {
        if (error === "Unauthorized") {
            return "المستخدم الخاص بك لا يملك صلاحيات كافية";
        }
        else if (error === "Invalid email or password") {
            return "خطأ في البريد الالكتروني أو كلمة مرور";
        }
        else if (error === "User not found") {
            return "لم يتم العثور على المستخدم";
        }
        else if (error && error.startsWith("User with email") && error.endsWith("already exists")) {
            return "البريد الالكتروني موجود على النظام";
        }
        else if (error === "Cannot delete used user") {
            return "بيانات المستخدم مستخدمة على النظام";
        }
        else if (error === "New password does not match confirm password") {
            return "كلمة المرور الجديدة لا تطابق تأكيد كلمة المرور";
        }
        else if (error === "Invalid current password") {
            return "كلمة المرور الحالية غير صحيحة";
        }
        else if (error === "New password cannot be same current password") {
            return "كلمة المرور الجديدة مشابهة لكلمة المرور الحالية";
        }
        else if (error === "Maximum number of users exceeded") {
            return "لقد تجاوزت الحد الأقصى لعدد مستخدمين النظام";
        }
        else if (error === "School license not found") {
            return "لم يتم العثور على ترخيص المدرسة";
        }
        else if (error === "Cannot delete used school license") {
            return "بيانات ترخيص المدرسة مستخدمة على النظام";
        }
        else if (error === "School license with same date range already exists") {
            return "ترخيص المدرسة على التواريخ المختارة مضاف مسبقا";
        }
        else if (error === "School not found") {
            return "لم يتم العثور على المدرسة";
        }
        else if (error === "Car with same plate number already exists") {
            return "رقم لوحة المركبة مضاف مسبقا";
        }
        else if (error === "Car not found") {
            return "لم يتم العثور على المركبة";
        }
        else if (error === "Cannot delete used car") {
            return "بيانات المركبة مستخدمة على النظام";
        }
        else if (error === "Car license with same date range already exists") {
            return "ترخيص المركبة على التواريخ المختارة مضاف مسبقا";
        }
        else if (error === "Car license not found") {
            return "لم يتم العثور على ترخيص المركبة";
        }
        else if (error === "Cannot delete used car license") {
            return "بيانات ترخيص المركبة مستخدمة على النظام";
        }
        else if (error === "Car insurance with same date range already exists") {
            return "تأمين المركبة على التواريخ المختارة مضاف مسبقا";
        }
        else if (error === "Car insurance not found") {
            return "لم يتم العثور على تأمين المركبة";
        }
        else if (error === "Cannot delete used car insurance") {
            return "بيانات تأمين المركبة مستخدمة على النظام";
        }
        else if (error === "Employee with same identity number already exists") {
            return "رقم هوية الموظف/ة مضاف مسبقا";
        }
        else if (error === "Cannot delete used employee") {
            return "بيانات الموظف/ة مستخدمة على النظام";
        }
        else if (error === "Employee not found") {
            return "لم يتم العثور على الموظف/ة";
        }
        else if (error === "Trainer not found") {
            return "لم يتم العثور على المدرب/ة";
        }
        else if (error === "Secretary not found") {
            return "لم يتم العثور على السكرتير/ة";
        }
        else if (error === "Cannot delete used school payment") {
            return "بيانات دفعة المدرسة مستخدمة على النظام";
        }
        else if (error === "School payment not found") {
            return "لم يتم العثور على دفعة المدرسة";
        }
        else if (error === "Total payments cannot be greater than cost") {
            return "يجب أن لا يزيد مجموع الدفعات عن التكلفة";
        }
        else if (error === "The payment method is not of type check") {
            return "الدفعة ليست من نوع شيك";
        }
        else if (error === "Student with same identity number already exists") {
            return "رقم هوية الطالب/ة مضاف مسبقا";
        }
        else if (error === "Cannot delete used student") {
            return "بيانات الطالب/ة مستخدمة على النظام";
        }
        else if (error === "Student not found") {
            return "لم يتم العثور على الطالب/ة";
        }
        else if (error === "Student payment not found") {
            return "لم يتم العثور على دفعة الطالب/ة";
        }
        else if (error === "Cannot delete used student payment") {
            return "بيانات دفعة الطالب/ة مستخدمة على النظام";
        }
        else if (error === "Student discount not found") {
            return "لم يتم العثور على خصم الطالب/ة";
        }
        else if (error === "Cannot delete used student discount") {
            return "بيانات خصم الطالب/ة مستخدمة على النظام";
        }
        else if (error === "Student theoretical exam not found") {
            return "لم يتم العثور على الاختبار";
        }
        else if (error === "Cannot delete used student theoretical exam") {
            return "بيانات الاختبار مستخدمة على النظام";
        }
        else if (error === "Student practical exam not found") {
            return "لم يتم العثور على الاختبار";
        }
        else if (error === "Cannot delete used student practical exam") {
            return "بيانات الاختبار مستخدمة على النظام";
        }
        else if (error === "Student lesson not found") {
            return "لم يتم العثور على الدرس";
        }
        else if (error === "Cannot delete used student lesson") {
            return "بيانات الدرس مستخدمة على النظام";
        }
        else if (error === "Cannot update employee job to be not trainer, cause there are lessons for") {
            return "لا يمكن تغيير وظيفة المدرب/ة بسبب وجود دروس مضافة";
        }
        else if (error === "Cannot update employee job to be not trainer, cause there are licenses for") {
            return "لا يمكن تغيير وظيفة المدرب/ة بسبب وجود رخص مضافة";
        }
        else if (error === "Received payment not found") {
            return "لم يتم العثور على الدفعة";
        }
        else if (error === "Cannot delete used received payment") {
            return "بيانات الدفعة مستخدمة على النظام";
        }
        else if (error === "Trainer license with same date range already exists") {
            return "رخصة المدرب على التواريخ المختارة مضافة مسبقا";
        }
        else if (error === "Trainer license not found") {
            return "لم يتم العثور على رخصة المدرب";
        }
        else if (error === "Cannot delete used trainer license") {
            return "بيانات رخصة المدرب مستخدمة على النظام";
        }
        else {
            return error || "Error";
        }
    }
}
import { Filter } from "./Filter";

type Params = {
    DefaultFilter: Filter,
    GlobalFilters?: Filter[],
    GlobalFilterPlaceholder?: string,
    IsLtrGlobalFilterInput?: boolean
};

export class EntityFilters {

    DefaultFilter: Filter;
    GlobalFilters: Filter[];
    GlobalFilterPlaceholder: string | null;
    IsLtrGlobalFilterInput: boolean;

    constructor(params: Params) {
        this.DefaultFilter = params.DefaultFilter;
        this.GlobalFilters = params.GlobalFilters === undefined ? [] : params.GlobalFilters;
        this.GlobalFilterPlaceholder = params.GlobalFilterPlaceholder === undefined ? null : params.GlobalFilterPlaceholder;
        this.IsLtrGlobalFilterInput = params.IsLtrGlobalFilterInput === undefined ? false : params.IsLtrGlobalFilterInput;
    }

}
import { http } from "../http/Http";
import { StudentPracticalExam } from "../models/student-practical-exam/StudentPracticalExam";
import { CreateStudentPracticalExamRequest } from "../models/student-practical-exam/CreateStudentPracticalExamRequest";
import { UpdateStudentPracticalExamRequest } from "../models/student-practical-exam/UpdateStudentPracticalExamRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentPracticalExamService {

    public EntityCode: EntityCode = EntityCode.StudentPracticalExam;

    private Api: string = "StudentPracticalExams";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<StudentPracticalExam>> => {
        const { data } = await http.post<GetByFiltersResponse<StudentPracticalExam>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<StudentPracticalExam> => {
        const { data } = await http.get<StudentPracticalExam>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createStudentPracticalExamRequest: CreateStudentPracticalExamRequest): Promise<StudentPracticalExam> => {
        const { data } = await http.post<StudentPracticalExam>(this.Api, createStudentPracticalExamRequest);
        return data;
    };

    update = async (id: number, updateStudentPracticalExamRequest: UpdateStudentPracticalExamRequest): Promise<StudentPracticalExam> => {
        const { data } = await http.put<StudentPracticalExam>(this.Api + "/" + id.toString(), updateStudentPracticalExamRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
export enum EmployeeLabel {
    Name = "الاسم",
    IdentityNumber = "رقم الهوية",
    GenderCode = "الجنس",
    EmployeeJobCode = "الوظيفة",
    CityCode = "المدينة",
    Phone = "الهاتف",
    Address = "العنوان",
    BirthDate = "تاريخ الميلاد",
    Gender_Name = "الجنس",
    EmployeeJob_Name = "الوظيفة",
    City_Name = "المدينة"
}
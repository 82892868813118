import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { SchoolLicenseField } from "../constants/fields/SchoolLicenseField";
import { SchoolLicenseLabel } from "../constants/labels/SchoolLicenseLabel";

export class SchoolLicenseFields {

    static get(): Field[] {
        return [
            new Field({
                Code: SchoolLicenseField.StartDate,
                Label: SchoolLicenseLabel.StartDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolLicenseField.EndDate,
                Label: SchoolLicenseLabel.EndDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: SchoolLicenseField.Cost,
                Label: SchoolLicenseLabel.Cost,
                Type: FieldType.Decimal,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: SchoolLicenseField.TotalPaid,
                Label: SchoolLicenseLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: SchoolLicenseField.TotalOverpaid,
                Label: SchoolLicenseLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: SchoolLicenseField.TotalRemaining,
                Label: SchoolLicenseLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: SchoolLicenseField.ExpirationStatus,
                Label: SchoolLicenseLabel.ExpirationStatus,
                Type: FieldType.String,
                CustomTemplateCode: "ExpirationStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
export enum EmployeeField {
    Name = "Name",
    IdentityNumber = "IdentityNumber",
    GenderCode = "GenderCode",
    EmployeeJobCode = "EmployeeJobCode",
    CityCode = "CityCode",
    Phone = "Phone",
    Address = "Address",
    BirthDate = "BirthDate",
    Gender_Name = "Gender.Name",
    EmployeeJob_Name = "EmployeeJob.Name",
    City_Name = "City.Name"
}
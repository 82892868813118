import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { CreateEditProps } from '../../types/Types';
import { Field } from '../../models/base/Field';
import { CreateSchoolRequest } from '../../models/school/CreateSchoolRequest';
import { UpdateSchoolRequest } from '../../models/school/UpdateSchoolRequest';
import { SchoolService } from '../../services/SchoolService';
import { SchoolFields } from '../../fields/SchoolFields';
import { UserFields } from '../../fields/UserFields';
import { FieldType } from '../../constants/base/FieldType';
import { SchoolField } from '../../constants/fields/SchoolField';
import { UserField } from '../../constants/fields/UserField';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEditSchool = (props: CreateEditProps) => {

    const [schoolFields] = useState<Field[]>(SchoolFields.get());
    const [userFields] = useState<Field[]>(UserFields.get());

    const isNew = props.id === null || props.id === undefined;

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        model: isNew ? new CreateSchoolRequest() : new UpdateSchoolRequest(),
        service: new SchoolService(),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });


    const getSchoolField = (fieldCode: string) => {
        return schoolFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const getUserField = (fieldCode: string) => {
        return userFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                {isNew &&
                    <div className="field col-12 pt-1">
                        <span className="font-bold">بيانات المدرسة</span>
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.Name)}
                        value={getFieldValue(SchoolField.Name)}
                        errors={getFieldModelErrors(SchoolField.Name)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.Name, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.CityCode)}
                        value={getFieldValue(SchoolField.CityCode)}
                        errors={getFieldModelErrors(SchoolField.CityCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.CityCode, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.Address)}
                        value={getFieldValue(SchoolField.Address)}
                        errors={getFieldModelErrors(SchoolField.Address)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.Address, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.Phone)}
                        value={getFieldValue(SchoolField.Phone)}
                        errors={getFieldModelErrors(SchoolField.Phone)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.Phone, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.DemoExpirationDate)}
                        value={getFieldValue(SchoolField.DemoExpirationDate)}
                        errors={getFieldModelErrors(SchoolField.DemoExpirationDate)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.DemoExpirationDate, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.MaxUsersCount)}
                        value={getFieldValue(SchoolField.MaxUsersCount)}
                        errors={getFieldModelErrors(SchoolField.MaxUsersCount)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.MaxUsersCount, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.IsManagement)}
                        value={getFieldValue(SchoolField.IsManagement)}
                        errors={getFieldModelErrors(SchoolField.IsManagement)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.IsManagement, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolField(SchoolField.Notes)}
                        value={getFieldValue(SchoolField.Notes)}
                        errors={getFieldModelErrors(SchoolField.Notes)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolField.Notes, value)} />
                </div>


                {isNew &&
                    <React.Fragment>

                        <div className="field col-12 pt-4">
                            <span className="font-bold">بيانات المستخدم الرئيسي</span>
                        </div>

                        <div className="field col-12 md:col-6">
                            <FieldValue
                                field={getUserField(UserField.Email)}
                                value={getFieldValue(SchoolField.MainUserEmail)}
                                errors={getFieldModelErrors(SchoolField.MainUserEmail)}
                                isLoading={isLoading}
                                onChange={(value: any) => setFieldValue(SchoolField.MainUserEmail, value)} />
                        </div>

                        <div className="field col-12 md:col-6">
                            <FieldValue
                                field={getUserField(UserField.Password)}
                                value={getFieldValue(SchoolField.MainUserPassword)}
                                errors={getFieldModelErrors(SchoolField.MainUserPassword)}
                                isLoading={isLoading}
                                onChange={(value: any) => setFieldValue(SchoolField.MainUserPassword, value)} />
                        </div>

                        <div className="field col-12 md:col-6">
                            <FieldValue
                                field={getUserField(UserField.Name)}
                                value={getFieldValue(SchoolField.MainUserName)}
                                errors={getFieldModelErrors(SchoolField.MainUserName)}
                                isLoading={isLoading}
                                onChange={(value: any) => setFieldValue(SchoolField.MainUserName, value)} />
                        </div>

                        <div className="field col-12 md:col-6">
                            <FieldValue
                                field={getUserField(UserField.GenderCode)}
                                value={getFieldValue(SchoolField.MainUserGenderCode)}
                                errors={getFieldModelErrors(SchoolField.MainUserGenderCode)}
                                isLoading={isLoading}
                                onChange={(value: any) => setFieldValue(SchoolField.MainUserGenderCode, value)} />
                        </div>
                    </React.Fragment>
                }

                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default CreateEditSchool;
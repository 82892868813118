import React from "react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterRequest } from "../../models/base/FilterRequest";
import { Filter } from "../../models/base/Filter";
import { Field } from "../../models/base/Field";
import { DropdownOption } from "../../models/base/DropdownOption";
import { FilterOperatorOptions } from '../../utilities/FilterOperatorOptions';
import FieldValue from "./FieldValue";
import UseListFilters from "../../hooks/UseListFilters";

type Props = {
  defaultFilter?: Filter,
  fields?: Field[],
  filters: FilterRequest[],
  onChange: (filters: FilterRequest[]) => void
};

const ListFilters = (props: Props) => {

  const {
    filters,
    setFilterProperty,
    setFilterOperator,
    setFilterValue,
    addFilter,
    deleteFilter
  } = UseListFilters({
    defaultFilter: props.defaultFilter,
    currentFilters: props.filters
  });


  const filterOperatorInput = (filter: FilterRequest) => {
    if (filter) {
      let field: Field | null = getField(filter.Property);
      if (field) {
        return <Dropdown
          value={filter.Operator}
          options={FilterOperatorOptions.get(field.Type)}
          optionValue="Code"
          optionLabel="Name"
          className="w-full"
          onChange={(event) => setFilterOperator(filter, event.value)} />;
      }
    }
    return null;
  }

  const filterValueInput = (filter: FilterRequest) => {
    if (filter) {
      let field: Field | null = getField(filter.Property);
      if (field) {
        return <FieldValue
          field={field}
          value={filter.Value}
          hideLabel={true}
          handleBooleanAsString={true}
          onChange={(value: any) => setFilterValue(filter, value)} />;
      }
    }
    return null;
  }

  const getField = (filterProperty: string) => {
    if (props.fields && props.fields.length > 0) {
      let field: Field | null = props.fields.find(f => f.Code === filterProperty) || null;
      return field;
    }
    return null;
  }

  const getFilterPropertyDropdownOptions = () => {
    if (props.fields && props.fields.length > 0) {
      let filterPropertyDropdownOptions: DropdownOption[] = props.fields
        .filter((field: Field) => field.ShowInFilters)
        .map((field: Field) => {
          return new DropdownOption(field.Code, field.Label);
        });
      return filterPropertyDropdownOptions;
    }
    return [];
  }

  return (
    <React.Fragment>
      <div className="p-1 filters-container">
        {
          filters.map((filter: FilterRequest, index: number) => {
            return (
              <div key={filter.Id || index} className="grid">
                <div className="col-3">
                  <Dropdown
                    value={filter.Property}
                    onChange={(event) => setFilterProperty(filter, event.value)}
                    options={getFilterPropertyDropdownOptions()}
                    optionValue="Code"
                    optionLabel="Name"
                    className="w-full" />
                </div>

                <div className="col-3">
                  {filterOperatorInput(filter)}
                </div>

                <div className="col-5">
                  {filterValueInput(filter)}
                </div>

                <div className="col-1">
                  {filters.length > 1 &&
                    <Button icon="pi pi-trash"
                      className="p-button-danger p-button-sm delete-filter-button"
                      onClick={() => deleteFilter(filter)} />
                  }
                </div>

              </div>
            );
          })
        }
      </div>

      <div className="mt-2">
        <div className="inline-block p-1">
          <Button
            label="بحث"
            className="p-button-success p-button-sm w-4rem"
            onClick={() => props.onChange(filters)} />
        </div>
        <div className="inline-block p-1">
          <Button icon="pi pi-plus"
            className="p-button-info p-button-sm"
            onClick={() => addFilter()} />
        </div>
      </div>

    </React.Fragment>
  );
}

export default ListFilters;
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { CreateEditProps } from '../../types/Types';
import { Field } from '../../models/base/Field';
import { CreateStudentPaymentRequest } from '../../models/student-payment/CreateStudentPaymentRequest';
import { UpdateStudentPaymentRequest } from '../../models/student-payment/UpdateStudentPaymentRequest';
import { StudentPaymentService } from '../../services/StudentPaymentService';
import { StudentPaymentFields } from '../../fields/StudentPaymentFields';
import { FieldType } from '../../constants/base/FieldType';
import { StudentPaymentField } from '../../constants/fields/StudentPaymentField';
import { PaymentMethodCode } from '../../constants/closed-lookup/PaymentMethodCode';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEditStudentPayment = (props: CreateEditProps) => {

    const [studentPaymentFields] = useState<Field[]>(StudentPaymentFields.get());

    const isNew = props.id === null || props.id === undefined;

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        model: isNew ? new CreateStudentPaymentRequest() : new UpdateStudentPaymentRequest(),
        service: new StudentPaymentService(),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });


    const getStudentPaymentField = (fieldCode: string) => {
        return studentPaymentFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const onPaymentMethodCodeChanged = (value: any) => {
        setFieldValue(StudentPaymentField.PaymentMethodCode, value);
        setFieldValue(StudentPaymentField.CheckDate, null);
        setFieldValue(StudentPaymentField.IsCheckReturned, null);
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                {getFieldValue(StudentPaymentField.IsCheckReturned) === true &&
                    <div className="field col-12">
                        <Tag severity="warning" value={"لن يتم احتساب دفعة الشيك الراجع ضمن دفعات الطالب"} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.StudentId)}
                        value={getFieldValue(StudentPaymentField.StudentId)}
                        errors={getFieldModelErrors(StudentPaymentField.StudentId)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentPaymentField.StudentId, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.StudentPaymentTypeCode)}
                        value={getFieldValue(StudentPaymentField.StudentPaymentTypeCode)}
                        errors={getFieldModelErrors(StudentPaymentField.StudentPaymentTypeCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentPaymentField.StudentPaymentTypeCode, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.PaymentDate)}
                        value={getFieldValue(StudentPaymentField.PaymentDate)}
                        errors={getFieldModelErrors(StudentPaymentField.PaymentDate)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentPaymentField.PaymentDate, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.PaymentAmount)}
                        value={getFieldValue(StudentPaymentField.PaymentAmount)}
                        errors={getFieldModelErrors(StudentPaymentField.PaymentAmount)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentPaymentField.PaymentAmount, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.PaymentMethodCode)}
                        value={getFieldValue(StudentPaymentField.PaymentMethodCode)}
                        errors={getFieldModelErrors(StudentPaymentField.PaymentMethodCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => onPaymentMethodCodeChanged(value)} />
                </div>

                {getFieldValue(StudentPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getStudentPaymentField(StudentPaymentField.CheckDate)}
                            value={getFieldValue(StudentPaymentField.CheckDate)}
                            errors={getFieldModelErrors(StudentPaymentField.CheckDate)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(StudentPaymentField.CheckDate, value)} />
                    </div>
                }

                {getFieldValue(StudentPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getStudentPaymentField(StudentPaymentField.IsCheckReturned)}
                            value={getFieldValue(StudentPaymentField.IsCheckReturned)}
                            errors={getFieldModelErrors(StudentPaymentField.IsCheckReturned)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(StudentPaymentField.IsCheckReturned, value)} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getStudentPaymentField(StudentPaymentField.Notes)}
                        value={getFieldValue(StudentPaymentField.Notes)}
                        errors={getFieldModelErrors(StudentPaymentField.Notes)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(StudentPaymentField.Notes, value)} />
                </div>


                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CreateEditStudentPayment;
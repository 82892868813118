import { http } from "../http/Http";
import { StudentTheoreticalExam } from "../models/student-theoretical-exam/StudentTheoreticalExam";
import { CreateStudentTheoreticalExamRequest } from "../models/student-theoretical-exam/CreateStudentTheoreticalExamRequest";
import { UpdateStudentTheoreticalExamRequest } from "../models/student-theoretical-exam/UpdateStudentTheoreticalExamRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentTheoreticalExamService {

    public EntityCode: EntityCode = EntityCode.StudentTheoreticalExam;

    private Api: string = "StudentTheoreticalExams";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<StudentTheoreticalExam>> => {
        const { data } = await http.post<GetByFiltersResponse<StudentTheoreticalExam>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<StudentTheoreticalExam> => {
        const { data } = await http.get<StudentTheoreticalExam>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createStudentTheoreticalExamRequest: CreateStudentTheoreticalExamRequest): Promise<StudentTheoreticalExam> => {
        const { data } = await http.post<StudentTheoreticalExam>(this.Api, createStudentTheoreticalExamRequest);
        return data;
    };

    update = async (id: number, updateStudentTheoreticalExamRequest: UpdateStudentTheoreticalExamRequest): Promise<StudentTheoreticalExam> => {
        const { data } = await http.put<StudentTheoreticalExam>(this.Api + "/" + id.toString(), updateStudentTheoreticalExamRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
export enum StudentLessonLabel {
    LessonsNumber = "عدد الدروس",
    StudentId = "الطالب/ة",
    StudyTypeCode = "نوع الدرس",
    CarId = "المركبة",
    EmployeeId = "المدرب/ة",
    Date = "التاريخ",
    Fees = "الرسوم",
    Student_FullName = "الطالب/ة",
    StudyType_Name = "نوع الدرس",
    Car_PlateNumber = "رقم المركبة",
    Car_CarCompany_Name = "المركبة",
    Car_CarType_Name = "نوع المركبة",
    Employee_Name = "المدرب/ة"
}
import { EntityFilters } from "../models/base/EntityFilters";
import { Filter } from "../models/base/Filter";
import { FilterOperator } from "../constants/base/FilterOperator";
import { CarField } from "../constants/fields/CarField";
import { CarInsuranceField } from "../constants/fields/CarInsuranceField";
import { CarLicenseField } from "../constants/fields/CarLicenseField";
import { TrainerLicenseField } from "../constants/fields/TrainerLicenseField";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { StudentField } from "../constants/fields/StudentField";
import { SchoolField } from "../constants/fields/SchoolField";
import { SchoolLicenseField } from "../constants/fields/SchoolLicenseField";
import { SchoolPaymentField } from "../constants/fields/SchoolPaymentField";
import { UserField } from "../constants/fields/UserField";
import { StudentPaymentField } from "../constants/fields/StudentPaymentField";
import { StudentDiscountField } from "../constants/fields/StudentDiscountField";
import { StudentTheoreticalExamField } from "../constants/fields/StudentTheoreticalExamField";
import { StudentPracticalExamField } from "../constants/fields/StudentPracticalExamField";
import { StudentLessonField } from "../constants/fields/StudentLessonField";
import { SubscriptionRequestField } from "../constants/fields/SubscriptionRequestField";
import { AuditLogField } from "../constants/fields/AuditLogField";
import { ReceivedPaymentField } from "../constants/fields/ReceivedPaymentField";


export class ListFilters {

    static car() {
        return new EntityFilters({
            DefaultFilter: new Filter(CarField.PlateNumber, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(CarField.PlateNumber, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "رقم اللوحة",
            IsLtrGlobalFilterInput: true
        });
    }

    static carInsurance() {
        return new EntityFilters({
            DefaultFilter: new Filter(CarInsuranceField.CarId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(CarInsuranceField.Car_PlateNumber, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "رقم لوحة المركبة",
            IsLtrGlobalFilterInput: true
        });
    }

    static carLicense() {
        return new EntityFilters({
            DefaultFilter: new Filter(CarLicenseField.CarId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(CarLicenseField.Car_PlateNumber, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "رقم لوحة المركبة",
            IsLtrGlobalFilterInput: true
        });
    }

    static employee() {
        return new EntityFilters({
            DefaultFilter: new Filter(EmployeeField.Name, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(EmployeeField.Name, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "الاسم"
        });
    }

    static student() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentField.FullName, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(StudentField.FullName, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "الاسم"
        });
    }

    static school() {
        return new EntityFilters({
            DefaultFilter: new Filter(SchoolField.Name, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(SchoolField.Name, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "الاسم"
        });
    }

    static schoolLicense() {
        return new EntityFilters({
            DefaultFilter: new Filter(SchoolLicenseField.StartDate, FilterOperator.Equals)
        });
    }

    static schoolPayment() {
        return new EntityFilters({
            DefaultFilter: new Filter(SchoolPaymentField.SchoolPaymentTypeCode, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(SchoolPaymentField.Car_PlateNumber, FilterOperator.Contains),
                new Filter(SchoolPaymentField.CarLicense_Car_PlateNumber, FilterOperator.Contains),
                new Filter(SchoolPaymentField.CarInsurance_Car_PlateNumber, FilterOperator.Contains),
                new Filter(SchoolPaymentField.Employee_Name, FilterOperator.Contains),
                new Filter(SchoolPaymentField.TrainerLicense_Employee_Name, FilterOperator.Contains),
                new Filter(SchoolPaymentField.Notes, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "رقم لوحة المركبة أو اسم الموظف/ة أو ملاحظات",
            IsLtrGlobalFilterInput: true
        });
    }

    static studentPayment() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentPaymentField.StudentId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(StudentPaymentField.Student_FullName, FilterOperator.Contains),
                new Filter(StudentPaymentField.Notes, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم الطالب/ة أو ملاحظات"
        });
    }

    static studentDiscount() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentDiscountField.StudentId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(StudentDiscountField.Student_FullName, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم الطالب/ة"
        });
    }

    static studentTheoreticalExam() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentTheoreticalExamField.StudentId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(StudentTheoreticalExamField.Student_FullName, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم الطالب/ة"
        });
    }

    static studentPracticalExam() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentPracticalExamField.StudentId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(StudentPracticalExamField.Student_FullName, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم الطالب/ة"
        });
    }

    static studentLesson() {
        return new EntityFilters({
            DefaultFilter: new Filter(StudentLessonField.StudentId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(StudentLessonField.Student_FullName, FilterOperator.Contains),
                new Filter(StudentLessonField.Employee_Name, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم الطالب/ة أو اسم المدرب/ة"
        });
    }

    static user() {
        return new EntityFilters({
            DefaultFilter: new Filter(UserField.Name, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(UserField.Name, FilterOperator.Contains),
                new Filter(UserField.Email, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "الاسم أو البريد الالكتروني",
            IsLtrGlobalFilterInput: true
        });
    }

    static subscriptionRequest() {
        return new EntityFilters({
            DefaultFilter: new Filter(SubscriptionRequestField.SchoolName, FilterOperator.Contains),
            GlobalFilters: [
                new Filter(SubscriptionRequestField.SchoolName, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم المدرسة"
        });
    }

    static auditLog() {
        return new EntityFilters({
            DefaultFilter: new Filter(AuditLogField.AuditLogActionCode, FilterOperator.Equals)
        });
    }

    static receivedPayment() {
        return new EntityFilters({
            DefaultFilter: new Filter(ReceivedPaymentField.CustomerName, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(ReceivedPaymentField.CustomerName, FilterOperator.Contains),
                new Filter(ReceivedPaymentField.Notes, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "الشخص/الشركة أو ملاحظات"
        });
    }

    static trainerLicense() {
        return new EntityFilters({
            DefaultFilter: new Filter(TrainerLicenseField.EmployeeId, FilterOperator.Equals),
            GlobalFilters: [
                new Filter(TrainerLicenseField.Employee_Name, FilterOperator.Contains)
            ],
            GlobalFilterPlaceholder: "اسم المدرب",
            IsLtrGlobalFilterInput: true
        });
    }

}
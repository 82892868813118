import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { UserService } from '../../services/UserService';
import { UserFields } from '../../fields/UserFields';
import { FieldType } from '../../constants/base/FieldType';
import { UserField } from '../../constants/fields/UserField';
import { GenderCode } from '../../constants/closed-lookup/GenderCode';
import { Field } from '../../models/base/Field';
import { UpdateAuthorizedUserRequest } from '../../models/user/UpdateAuthorizedUserRequest';
import { User } from '../../models/user/User';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

type Props = {
    callBack: (user: User | null) => void
};

const UserProfile = (props: Props) => {

    const [userFields] = useState<Field[]>(UserFields.get());

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        model: new UpdateAuthorizedUserRequest(),
        service: new UserService(),
        loadModelMethod: "getAuthorized",
        saveMethod: "updateAuthorized"
    });

    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response);
        }
    });


    const getUserField = (fieldCode: string) => {
        return userFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }


    return (
        <React.Fragment>

            <div className="formgrid grid user-profile-container">

                <div className="field col-12 text-center mb-4">
                    {isLoading && <Skeleton shape="circle" size="100px" className="user-image-skeleton"></Skeleton>}
                    {!isLoading &&
                        <React.Fragment>
                            {getFieldValue(UserField.GenderCode) === GenderCode.Male &&
                                <img src="/images/male-user.png" className="avatar-image" alt="user" />
                            }

                            {getFieldValue(UserField.GenderCode) === GenderCode.Female &&
                                <img src="/images/female-user.png" className="avatar-image" alt="user" />
                            }
                        </React.Fragment>
                    }
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getUserField(UserField.Name)}
                        value={getFieldValue(UserField.Name)}
                        errors={getFieldModelErrors(UserField.Name)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(UserField.Name, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getUserField(UserField.Email)}
                        value={getFieldValue(UserField.Email)}
                        errors={getFieldModelErrors(UserField.Email)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(UserField.Email, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getUserField(UserField.GenderCode)}
                        value={getFieldValue(UserField.GenderCode)}
                        errors={getFieldModelErrors(UserField.GenderCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(UserField.GenderCode, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getUserField(UserField.Phone)}
                        value={getFieldValue(UserField.Phone)}
                        errors={getFieldModelErrors(UserField.Phone)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(UserField.Phone, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getUserField(UserField.Address)}
                        value={getFieldValue(UserField.Address)}
                        errors={getFieldModelErrors(UserField.Address)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(UserField.Address, value)} />
                </div>

                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default UserProfile;
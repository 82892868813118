import { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthenticationService } from '../../services/AuthenticationService';
import { UserAuthentication } from '../../utilities/UserAuthentication';
import { AuthenticationRequest } from '../../models/authentication/AuthenticationRequest';

const Login = () => {

  const navigate = useNavigate();

  const year: number = moment().year();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const login = () => {
    if (email && email !== "" && password && password !== "" && !isLoggingIn) {
      setIsLoggingIn(true);

      const authenticationService = new AuthenticationService();

      let authauthenticateRequest: AuthenticationRequest = {
        Email: email,
        Password: password
      };

      authenticationService.authenticate(authauthenticateRequest).then(authenticateResponse => {
        if (authenticateResponse && authenticateResponse.Token) {
          UserAuthentication.set(authenticateResponse);
        } else {
          setIsLoggingIn(false);
        }
      }).catch(_error => {
        setIsLoggingIn(false);
      });
    }
  }

  const onInputKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      login();
    }
  }

  const isValueFilled = (value: any) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return true;
  }

  const goToLandingPage = () => {
    navigate("/");
  }


  return (
    <div className="login-page-container">
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-md-center h-100">
            <div className="card-wrapper">
              <div className="card fat">
                <div className="card-body">
                  <div className="brand">
                    <img src="/images/logo.png" alt="logo" onClick={goToLandingPage} />
                  </div>
                  {/* <h4 className="card-title">تسجيل الدخول</h4> */}
                  <div className="pt-5">
                    <div className="form-group pb-2">
                      <label className="font-weight-bold email-label">البريد الإلكتروني</label>
                      <input type="text" className="form-control email-input" value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        onKeyDown={(e: any) => onInputKeyPress(e)} />
                    </div>

                    <div className="form-group pb-4">
                      <label className="font-weight-bold password-label">كلمة المرور</label>
                      <input type="password" className="form-control password-input" value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                        onKeyDown={(e: any) => onInputKeyPress(e)} />
                    </div>

                    <div className="form-group m-0">
                      <button type="button" className="btn btn-primary btn-block"
                        disabled={isLoggingIn || !isValueFilled(email) || !isValueFilled(password)}
                        onClick={() => login()}>
                        <span className="font-weight-bold sign-in-label">تسجيل الدخول</span>
                        {isLoggingIn && <i className="pi pi-spin pi-spinner loading-icon"></i>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">

                <div className="pb-1">
                  <span>{"جميع الحقوق محفوظة"}</span>
                </div>

                <div className="direction-ltr">
                  <span>{"© " + (year && year > 2024 ? ("2024-" + year) : "2024") + " DSMS"}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

}

export default Login;
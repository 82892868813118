export enum StudentPaymentField {
    StudentPaymentTypeCode = "StudentPaymentTypeCode",
    StudentId = "StudentId",
    PaymentDate = "PaymentDate",
    PaymentAmount = "PaymentAmount",
    PaymentMethodCode = "PaymentMethodCode",
    CheckDate = "CheckDate",
    IsCheckReturned = "IsCheckReturned",
    Notes = "Notes",
    StudentPaymentType_Name = "StudentPaymentType.Name",
    PaymentMethod_Name = "PaymentMethod.Name",
    Student_FullName = "Student.FullName",
    CheckDateStatus = "CheckDateStatus"
}
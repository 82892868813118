import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { CarField } from "../constants/fields/CarField";
import { CarLabel } from "../constants/labels/CarLabel";
import { CarCompanyService } from "../services/CarCompanyService";
import { ColorService } from "../services/ColorService";
import { CarTypeService } from "../services/CarTypeService";
import { CarPowerTypeService } from "../services/CarPowerTypeService";

export class CarFields {

    static get(): Field[] {
        return [
            new Field({
                Code: CarField.PlateNumber,
                Label: CarLabel.PlateNumber,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                IsOneLine: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: CarField.CarCompanyCode,
                Label: CarLabel.CarCompanyCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarCompanyService(),
                IsDropdownFilterEnabled: true
            }),

            new Field({
                Code: CarField.CarCompany_Name,
                Label: CarLabel.CarCompany_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarField.CarTypeCode,
                Label: CarLabel.CarTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarTypeService()
            }),

            new Field({
                Code: CarField.CarType_Name,
                Label: CarLabel.CarType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarField.ManufactureYear,
                Label: CarLabel.ManufactureYear,
                Type: FieldType.Year,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                YearsRange: 40,
                IsDropdownFilterEnabled: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarField.ColorCode,
                Label: CarLabel.ColorCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new ColorService(),
                IsDropdownFilterEnabled: true
            }),

            new Field({
                Code: CarField.Color_Name,
                Label: CarLabel.Color_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            
            new Field({
                Code: CarField.CarPowerTypeCode,
                Label: CarLabel.CarPowerTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new CarPowerTypeService()
            }),

            new Field({
                Code: CarField.CarPowerType_Name,
                Label: CarLabel.CarPowerType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarField.Cost,
                Label: CarLabel.Cost,
                Type: FieldType.Decimal,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: CarField.TotalPaid,
                Label: CarLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarField.TotalOverpaid,
                Label: CarLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: CarField.TotalRemaining,
                Label: CarLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            })

        ];
    }

}
import { FilterOperator } from '../../constants/base/FilterOperator';
import { Generator } from '../../utilities/Generator';

export class FilterRequest {
    Id: string;
    Property: string;
    Operator: FilterOperator;
    Value: string | null;

    constructor(property: string, operator: FilterOperator, value: string | null = null) {
        this.Id = Generator.guid();
        this.Property = property;
        this.Operator = operator;
        this.Value = value;
    }
}
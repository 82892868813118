import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { CreateEditProps } from '../../types/Types';
import { Field } from '../../models/base/Field';
import { CreateSchoolPaymentRequest } from '../../models/school-payment/CreateSchoolPaymentRequest';
import { UpdateSchoolPaymentRequest } from '../../models/school-payment/UpdateSchoolPaymentRequest';
import { SchoolPaymentService } from '../../services/SchoolPaymentService';
import { SchoolPaymentFields } from '../../fields/SchoolPaymentFields';
import { FieldType } from '../../constants/base/FieldType';
import { SchoolPaymentField } from '../../constants/fields/SchoolPaymentField';
import { SchoolPaymentTypeCode } from '../../constants/closed-lookup/SchoolPaymentTypeCode';
import { PaymentMethodCode } from '../../constants/closed-lookup/PaymentMethodCode';
import FieldValue from '../base/FieldValue';
import UseModel from '../../hooks/UseModel';

const CreateEditSchoolPayment = (props: CreateEditProps) => {

    const [schoolPaymentFields] = useState<Field[]>(SchoolPaymentFields.get());

    const isNew = props.id === null || props.id === undefined;

    const {
        isLoading,
        isSaving,
        setFieldValue,
        getFieldValue,
        getFieldModelErrors,
        save,
        saveEvent
    } = UseModel({
        key: props.id,
        model: isNew ? new CreateSchoolPaymentRequest() : new UpdateSchoolPaymentRequest(),
        service: new SchoolPaymentService(),
        loadModelMethod: isNew ? null : "getById",
        saveMethod: isNew ? "create" : "update"
    });


    saveEvent.subscribe((result: any) => {
        if (result) {
            props.callBack(result.response, result.isCreate);
        }
    });


    const getSchoolPaymentField = (fieldCode: string) => {
        return schoolPaymentFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const onSchoolPaymentTypeCodeChanged = (value: any) => {
        setFieldValue(SchoolPaymentField.SchoolPaymentTypeCode, value);
        setFieldValue(SchoolPaymentField.CarLicenseId, null);
        setFieldValue(SchoolPaymentField.CarInsuranceId, null);
        setFieldValue(SchoolPaymentField.CarId, null);
        setFieldValue(SchoolPaymentField.EmployeeId, null);
        setFieldValue(SchoolPaymentField.SchoolLicenseId, null);
        setFieldValue(SchoolPaymentField.TrainerLicenseId, null);
    }

    const onPaymentMethodCodeChanged = (value: any) => {
        setFieldValue(SchoolPaymentField.PaymentMethodCode, value);
        setFieldValue(SchoolPaymentField.CheckDate, null);
        setFieldValue(SchoolPaymentField.IsCheckReturned, null);
    }


    return (
        <React.Fragment>

            <div className="formgrid grid">

                {getFieldValue(SchoolPaymentField.IsCheckReturned) === true &&
                    <div className="field col-12">
                        <Tag severity="warning" value={"لن يتم احتساب دفعة الشيك الراجع ضمن دفعات المدرسة"} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolPaymentField(SchoolPaymentField.SchoolPaymentTypeCode)}
                        value={getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode)}
                        errors={getFieldModelErrors(SchoolPaymentField.SchoolPaymentTypeCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => onSchoolPaymentTypeCodeChanged(value)} />
                </div>

                {getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.CarLicense &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.CarLicenseId)}
                            value={getFieldValue(SchoolPaymentField.CarLicenseId)}
                            errors={getFieldModelErrors(SchoolPaymentField.CarLicenseId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.CarLicenseId, value)} />
                    </div>
                }

                {getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.CarInsurance &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.CarInsuranceId)}
                            value={getFieldValue(SchoolPaymentField.CarInsuranceId)}
                            errors={getFieldModelErrors(SchoolPaymentField.CarInsuranceId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.CarInsuranceId, value)} />
                    </div>
                }

                {(getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.CarFuel ||
                    getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.CarMaintenance ||
                    getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.CarPurchase) &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.CarId)}
                            value={getFieldValue(SchoolPaymentField.CarId)}
                            errors={getFieldModelErrors(SchoolPaymentField.CarId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.CarId, value)} />
                    </div>
                }

                {(getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.EmployeeSalary ||
                    getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.EmployeeAdvancePayment) &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.EmployeeId)}
                            value={getFieldValue(SchoolPaymentField.EmployeeId)}
                            errors={getFieldModelErrors(SchoolPaymentField.EmployeeId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.EmployeeId, value)} />
                    </div>
                }

                {getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.SchoolLicense &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.SchoolLicenseId)}
                            value={getFieldValue(SchoolPaymentField.SchoolLicenseId)}
                            errors={getFieldModelErrors(SchoolPaymentField.SchoolLicenseId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.SchoolLicenseId, value)} />
                    </div>
                }

                {getFieldValue(SchoolPaymentField.SchoolPaymentTypeCode) === SchoolPaymentTypeCode.TrainerLicense &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.TrainerLicenseId)}
                            value={getFieldValue(SchoolPaymentField.TrainerLicenseId)}
                            errors={getFieldModelErrors(SchoolPaymentField.TrainerLicenseId)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.TrainerLicenseId, value)} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolPaymentField(SchoolPaymentField.PaymentDate)}
                        value={getFieldValue(SchoolPaymentField.PaymentDate)}
                        errors={getFieldModelErrors(SchoolPaymentField.PaymentDate)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolPaymentField.PaymentDate, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolPaymentField(SchoolPaymentField.PaymentAmount)}
                        value={getFieldValue(SchoolPaymentField.PaymentAmount)}
                        errors={getFieldModelErrors(SchoolPaymentField.PaymentAmount)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolPaymentField.PaymentAmount, value)} />
                </div>

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolPaymentField(SchoolPaymentField.PaymentMethodCode)}
                        value={getFieldValue(SchoolPaymentField.PaymentMethodCode)}
                        errors={getFieldModelErrors(SchoolPaymentField.PaymentMethodCode)}
                        isLoading={isLoading}
                        onChange={(value: any) => onPaymentMethodCodeChanged(value)} />
                </div>

                {getFieldValue(SchoolPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.CheckDate)}
                            value={getFieldValue(SchoolPaymentField.CheckDate)}
                            errors={getFieldModelErrors(SchoolPaymentField.CheckDate)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.CheckDate, value)} />
                    </div>
                }

                {getFieldValue(SchoolPaymentField.PaymentMethodCode) === PaymentMethodCode.Check &&
                    <div className="field col-12 md:col-6">
                        <FieldValue
                            field={getSchoolPaymentField(SchoolPaymentField.IsCheckReturned)}
                            value={getFieldValue(SchoolPaymentField.IsCheckReturned)}
                            errors={getFieldModelErrors(SchoolPaymentField.IsCheckReturned)}
                            isLoading={isLoading}
                            onChange={(value: any) => setFieldValue(SchoolPaymentField.IsCheckReturned, value)} />
                    </div>
                }

                <div className="field col-12 md:col-6">
                    <FieldValue
                        field={getSchoolPaymentField(SchoolPaymentField.Notes)}
                        value={getFieldValue(SchoolPaymentField.Notes)}
                        errors={getFieldModelErrors(SchoolPaymentField.Notes)}
                        isLoading={isLoading}
                        onChange={(value: any) => setFieldValue(SchoolPaymentField.Notes, value)} />
                </div>


                <div className="col-12">
                    <div className="inline-block ml-1 mb-1">
                        <Button
                            label={"حفظ"}
                            className="p-button-success p-button-sm mt-2 w-6rem"
                            disabled={isSaving || isLoading}
                            loading={isSaving}
                            onClick={save} />
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CreateEditSchoolPayment;
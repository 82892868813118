import { SubscriptionRequestFields } from "../../fields/SubscriptionRequestFields";
import { SubscriptionRequestService } from "../../services/SubscriptionRequestService";
import { ListFilters } from "../../utilities/ListFilters";
import List from "../base/List";

const SubscriptionRequestsList = () => {

    return (
        <List
            title={"طلبات الإشتراك"}
            service={new SubscriptionRequestService()}
            fields={SubscriptionRequestFields.get()}
            filters={ListFilters.subscriptionRequest()}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            isSelectionDisabled={true} />
    );
}

export default SubscriptionRequestsList;
export enum CarField {
    PlateNumber = "PlateNumber",
    CarCompanyCode = "CarCompanyCode",
    ManufactureYear = "ManufactureYear",
    ColorCode = "ColorCode",
    CarTypeCode = "CarTypeCode",
    CarPowerTypeCode = "CarPowerTypeCode",
    Cost = "Cost",
    TotalPaid = "TotalPaid",
    TotalOverpaid = "TotalOverpaid",
    TotalRemaining = "TotalRemaining",
    CarCompany_Name = "CarCompany.Name",
    Color_Name = "Color.Name",
    CarType_Name = "CarType.Name",
    CarPowerType_Name = "CarPowerType.Name"
}
import { CreateStudentPracticalExamRequest } from "../../models/student-practical-exam/CreateStudentPracticalExamRequest";
import { UpdateStudentPracticalExamRequest } from "../../models/student-practical-exam/UpdateStudentPracticalExamRequest";
import { StudentPracticalExamFields } from "../../fields/StudentPracticalExamFields";
import { StudentPracticalExamService } from "../../services/StudentPracticalExamService";
import { StudentPracticalExamsPermissions } from "../../constants/permissions/StudentPracticalExamsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import List from "../base/List";

const StudentPracticalExamsList = () => {

    return (
        <List
            title={"الاختبارات العملية"}
            service={new StudentPracticalExamService()}
            fields={StudentPracticalExamFields.get()}
            filters={ListFilters.studentPracticalExam()}
            createSuccessMessage={"تم اضافة الاختبار بنجاح"}
            editSuccessMessage={"تم تعديل الاختبار بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الاختبار ؟"}
            deleteSuccessMessage={"تم حذف الاختبار بنجاح"}
            createModel={new CreateStudentPracticalExamRequest()}
            editModel={new UpdateStudentPracticalExamRequest()}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.Delete)} />
    );
}

export default StudentPracticalExamsList;
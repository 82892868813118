import { http } from "../http/Http";
import { StudentDiscount } from "../models/student-discount/StudentDiscount";
import { CreateStudentDiscountRequest } from "../models/student-discount/CreateStudentDiscountRequest";
import { UpdateStudentDiscountRequest } from "../models/student-discount/UpdateStudentDiscountRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentDiscountService {

    public EntityCode: EntityCode = EntityCode.StudentDiscount;

    private Api: string = "StudentDiscounts";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<StudentDiscount>> => {
        const { data } = await http.post<GetByFiltersResponse<StudentDiscount>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<StudentDiscount> => {
        const { data } = await http.get<StudentDiscount>(this.Api + "/" + id.toString());
        return data;
    };

    create = async (createStudentDiscountRequest: CreateStudentDiscountRequest): Promise<StudentDiscount> => {
        const { data } = await http.post<StudentDiscount>(this.Api, createStudentDiscountRequest);
        return data;
    };

    update = async (id: number, updateStudentDiscountRequest: UpdateStudentDiscountRequest): Promise<StudentDiscount> => {
        const { data } = await http.put<StudentDiscount>(this.Api + "/" + id.toString(), updateStudentDiscountRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { Field } from "../../models/base/Field";
import { DropdownOption } from "../../models/base/DropdownOption";
import { GetByFiltersRequest } from "../../models/base/GetByFiltersRequest";
import { GetByFiltersRequestBuilder } from "../../builders/GetByFiltersRequestBuilder";
import { FieldType } from "../../constants/base/FieldType";
import { SortDirection } from "../../constants/base/SortDirection";
import { Errors } from "../../errors/Errors";
import { FieldErrors } from "../../errors/FieldErrors";
import { Convertor } from "../../utilities/Convertor";
import { Generator } from "../../utilities/Generator";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "./List";
import DeletedEntityAuditLogDetails from "../audit-log/DeletedEntityAuditLogDetails";

type Props = {
    field: Field,
    value?: any,
    label?: string,
    hideLabel?: boolean,
    errors?: string[],
    isLoading?: boolean,
    handleBooleanAsString?: boolean,
    isDisabled?: boolean,
    isUsedInCreate?: boolean,
    isBoldLabel?: boolean,
    isCurrency?: boolean,
    isPercent?: boolean,
    showLookupNotFoundError?: boolean,
    hidePlaceholder?: boolean,
    onChange?: (value: any) => void
};

const FieldValue = (props: Props) => {

    const lookupKeyField: string = "Id";

    const emptyFilterMessage: string = "لا يوجد بيانات";

    const [inputId] = useState<string>(Generator.random());
    const [isInternalValueChange, setIsInternalValueChange] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [closedLookupItems, setClosedLookupItems] = useState<any[]>([]);
    const [lookupItems, setLookupItems] = useState<any[]>([]);
    const [yearItems, setYearItems] = useState<DropdownOption[]>([]);
    const [isListLookupOpened, setIsListLookupOpened] = useState<boolean>(false);
    const [isDeletedEntityAuditLogDetailsOpened, setIsDeletedEntityAuditLogDetailsOpened] = useState<boolean>(false);
    const [lookupNotFoundErrorMessage, setLookupNotFoundErrorMessage] = useState<string | null>(null);

    const booleanItems = [
        new DropdownOption("True", "نعم"),
        new DropdownOption("False", "لا")
    ];

    useEffect(() => {
        assignCurrentValue();
    }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadClosedLookupItems();
        loadYearItems();
    }, [props.field]) // eslint-disable-line react-hooks/exhaustive-deps

    const assignCurrentValue = () => {
        if (props.value !== null && props.value !== undefined) {
            if (props.field.Type === FieldType.Date || props.field.Type === FieldType.DateTime) {
                setCurrentValue(new Date(props.value));
            }
            else if (props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) {
                if (isInternalValueChange) {
                    setIsInternalValueChange(false);
                } else {
                    assignLookupCurrentValue();
                }
            }
            else if (!props.handleBooleanAsString && props.field.Type === FieldType.Boolean) {
                setCurrentValue(props.value ? "True" : "False");
            }
            else if (props.field.Type === FieldType.Year) {
                setCurrentValue(props.value.toString());
            }
            else {
                setCurrentValue(props.value);
            }
        } else {
            setCurrentValue(null);
        }
    }

    const assignLookupCurrentValue = () => {
        if (props.value !== null && props.value !== undefined &&
            (props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) &&
            props.field.LookupService &&
            props.field.LookupService.hasOwnProperty("getLookupById")) {

            setLookupNotFoundErrorMessage(null);
            setIsLoading(true);
            let ignoreNotFoundError = props.showLookupNotFoundError ? true : false;
            props.field.LookupService.getLookupById(props.value, ignoreNotFoundError).then((response: any) => {
                if (props.field.LookupDisplayTemplate) {
                    if (response) {
                        let templateValue = Convertor.templateToValue(response, props.field.LookupDisplayTemplate);
                        response["LookupDisplayTemplate"] = templateValue || "";
                    }
                    setCurrentValue(response || null);
                }
                else {
                    setCurrentValue(response || null);
                }
                setIsLoading(false);
            }).catch((error: any) => {
                if (props.showLookupNotFoundError && error && error.status === 404 && error.data && error.data.message) {
                    setLookupNotFoundErrorMessage(error.data.message);
                } else {
                    setLookupNotFoundErrorMessage(null);
                }
                setCurrentValue(null);
                setIsLoading(false);
            });
        }
    }

    const loadClosedLookupItems = () => {
        if (props.field.Type === FieldType.ClosedLookup &&
            props.field.LookupService &&
            props.field.LookupService.hasOwnProperty("getAll")) {

            setIsLoading(true);
            props.field.LookupService.getAll().then((response: any) => {
                setClosedLookupItems(response || []);
                setIsLoading(false);
            }).catch((_error: any) => {
                setClosedLookupItems([]);
                setIsLoading(false);
            });
        } else {
            setClosedLookupItems([]);
        }
    }

    const loadYearItems = () => {
        if (props.field.Type === FieldType.Year) {
            let items: DropdownOption[] = [];
            let range = props.field.YearsRange > 100 ? 100 : props.field.YearsRange;
            let currentYear = moment().year();
            for (let i = 0; i < (range + 1); i++) {
                let yearValue = (currentYear - i).toString();
                items.push(new DropdownOption(yearValue, yearValue));
            }
            setYearItems(items);
        } else {
            setYearItems([]);
        }
    }

    const loadLookupItems = (event: any) => {
        if (props.field.LookupService && props.field.LookupService.hasOwnProperty("getLookupByFilters")) {
            let searchTerm = event && event.query ? event.query.trim() : null;
            let getByFiltersRequestBuilder: GetByFiltersRequestBuilder = new GetByFiltersRequestBuilder()
                .sortBy(lookupKeyField)
                .sortDirection(SortDirection.Descending)
                .pageIndex(0)
                .pageSize(50);

            if (props.field.LookupFilters && props.field.LookupFilters.length > 0) {
                getByFiltersRequestBuilder.addFilters(props.field.LookupFilters);
            }

            if (props.field.LookupLoadFilters && props.field.LookupLoadFilters.Filters && props.field.LookupLoadFilters.Filters.length > 0) {
                props.field.LookupLoadFilters.Filters.forEach(filter => {
                    getByFiltersRequestBuilder.addQuickSearchFilter(filter.Property, searchTerm, filter.Operator);
                });
            }

            let getByFiltersRequest: GetByFiltersRequest = getByFiltersRequestBuilder.build();

            props.field.LookupService.getLookupByFilters(getByFiltersRequest).then((response: any) => {
                if (response && response.Data) {
                    if (props.field.LookupDisplayTemplate) {
                        let data = response.Data.map((dataObject: any) => {
                            let templateValue = Convertor.templateToValue(dataObject, props.field.LookupDisplayTemplate!);
                            return {
                                ...dataObject,
                                LookupDisplayTemplate: (templateValue || "")
                            };
                        });
                        setLookupItems(data);
                    }
                    else {
                        setLookupItems(response.Data);
                    }
                } else {
                    setLookupItems([]);
                }
            }).catch((_error: any) => {
                setLookupItems([]);
            });
        }
    }

    const getClassName = (handleInvalid: boolean, handleLtr: boolean) => {
        let className = "w-full";
        if (props.field.Type === FieldType.Lookup) {
            className = className + " lookup-field-value";
        }
        if (props.field.Type === FieldType.ListLookup) {
            className = className + " list-lookup-field-value" + (props.isDisabled ? " cursor-auto" : " pl-5 cursor-pointer");
        }
        if (handleLtr && props.field.IsLtr) {
            className = className + " ltr-input";
        }
        if (handleInvalid && props.errors && props.errors.length > 0) {
            className = className + " p-invalid";
        }
        return className;
    }

    const onChange = (value: any) => {
        if (!props.isDisabled) {

            setIsInternalValueChange(true);

            if (props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) {
                if (props.field.Type === FieldType.ListLookup) {
                    if (isObject(value) && props.field.LookupDisplayTemplate) {
                        let templateValue = Convertor.templateToValue(value, props.field.LookupDisplayTemplate);
                        value["LookupDisplayTemplate"] = templateValue || "";
                    }
                    setCurrentValue(value);
                }
                else {
                    setCurrentValue(value);
                }

                if (props.field.Type === FieldType.ListLookup && isListLookupOpened) {
                    setIsListLookupOpened(false);
                }

                if (props.onChange !== undefined) {
                    if (value === null || value === undefined) {
                        props.onChange(null);
                    }
                    else {
                        if (isObject(value)) {
                            props.onChange(getValue(value));
                        }
                    }
                }
            }
            else {
                if (props.onChange !== undefined) {
                    props.onChange(getValue(value));
                }
            }

        }
    }

    const isObject = (value: any) => {
        return typeof value === "object" && value !== null && value !== undefined && !Array.isArray(value) && !(value instanceof Function);
    }

    const getValue = (value: any) => {
        if (value !== null && value !== undefined) {
            if (props.field.Type === FieldType.Date) {
                return Convertor.dateToString(value);
            }
            else if (props.field.Type === FieldType.DateTime) {
                return Convertor.datetimeToString(value);
            }
            else if (props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) {
                return value[lookupKeyField] ? value[lookupKeyField].toString() : null;
            }
            else if (props.field.Type === FieldType.Boolean) {
                return !props.handleBooleanAsString ? (value.toString() === "True" ? true : false) : value.toString();
            }
            return value.toString();
        }
        return null;
    }

    const onListLookupInputClick = () => {
        if (!props.isDisabled) {
            setIsListLookupOpened(true);
        }
    }

    const getLookupDisplayValue = () => {
        return currentValue ?
            (currentValue[props.field.LookupDisplayTemplate ? "LookupDisplayTemplate" : (props.field.LookupDisplayFieldCode || lookupKeyField)] || null) :
            null;
    }

    const getLabel = () => {
        let label = props.label || props.field.Label;
        if (props.field.Hint && (!props.field.ShowHintOnlyInCreate || props.isUsedInCreate)) {
            label = label + " (" + props.field.Hint + ")";
        }
        return label;
    }

    const openDeletedEntityAuditLogDetails = () => {
        if ((props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) &&
            getLookupEntityCode() && props.value !== null && props.value !== undefined) {
            setIsDeletedEntityAuditLogDetailsOpened(true);
        }
    }

    const getLookupEntityCode = () => {
        return props.field.LookupService ? (props.field.LookupService["EntityCode"] || null) : null;
    }


    return <React.Fragment>

        {!props.hideLabel &&
            <label className="field-value-label">
                <span title={getLabel()} className={props.isBoldLabel ? "font-medium" : ""}>
                    {getLabel()}
                </span>
            </label>
        }

        {props.isLoading || isLoading ? <Skeleton width="100%" height="35px"></Skeleton> :

            <React.Fragment>

                <form autoComplete="off">

                    {props.field.Type === FieldType.String &&
                        <InputText
                            id={inputId}
                            value={currentValue || ""}
                            onChange={(event) => onChange(event.target.value)}
                            className={getClassName(true, true)}
                            disabled={props.isDisabled} />
                    }

                    {props.field.Type === FieldType.Password &&
                        <Password
                            id={inputId}
                            value={currentValue || ""}
                            onChange={(event) => onChange(event.target.value)}
                            inputClassName={getClassName(false, false)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled} />
                    }

                    {props.field.Type === FieldType.ClosedLookup &&
                        <Dropdown
                            id={inputId}
                            value={currentValue}
                            options={closedLookupItems}
                            optionValue={"Code"}
                            optionLabel={"Name"}
                            onChange={(event) => onChange(event.value)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled}
                            filter={props.field.IsDropdownFilterEnabled}
                            showClear={true}
                            resetFilterOnHide={true}
                            emptyFilterMessage={emptyFilterMessage} />
                    }

                    {props.field.Type === FieldType.Lookup &&
                        <AutoComplete
                            id={inputId}
                            value={currentValue}
                            suggestions={lookupItems}
                            completeMethod={loadLookupItems}
                            field={props.field.LookupDisplayTemplate ? "LookupDisplayTemplate" : (props.field.LookupDisplayFieldCode || lookupKeyField)}
                            dropdown={true}
                            forceSelection={true}
                            onChange={(event) => onChange(event.value)}
                            inputClassName={getClassName(false, false)}
                            className={getClassName(true, false)}
                            placeholder={!props.hidePlaceholder && props.field.LookupLoadFilters && props.field.LookupLoadFilters.Placeholder ? props.field.LookupLoadFilters.Placeholder : undefined}
                            disabled={props.isDisabled} />
                    }

                    {props.field.Type === FieldType.ListLookup &&
                        <div id={inputId} className="list-lookup-field-value-container">
                            <InputText
                                value={getLookupDisplayValue() || ""}
                                onClick={onListLookupInputClick}
                                className={getClassName(true, false)}
                                disabled={props.isDisabled} />

                            {getLookupDisplayValue() && !props.isDisabled &&
                                <i className="pi pi-times list-lookup-field-value-clear" onClick={() => onChange(null)}></i>
                            }
                        </div>
                    }

                    {props.field.Type === FieldType.Boolean &&
                        <Dropdown
                            id={inputId}
                            value={currentValue}
                            options={booleanItems}
                            optionValue={"Code"}
                            optionLabel={"Name"}
                            onChange={(event) => onChange(event.value)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled}
                            showClear={true}
                            emptyFilterMessage={emptyFilterMessage} />
                    }

                    {(props.field.Type === FieldType.Date || props.field.Type === FieldType.DateTime) &&
                        <Calendar
                            id={inputId}
                            value={currentValue}
                            onChange={(event) => onChange(event.value)}
                            dateFormat={"dd/mm/yy"}
                            hourFormat={props.field.Type === FieldType.DateTime ? "12" : undefined}
                            showTime={props.field.Type === FieldType.DateTime}
                            inputClassName={getClassName(false, false)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled} />
                    }

                    {(props.field.Type === FieldType.Integer || props.field.Type === FieldType.Decimal) &&
                        <InputNumber
                            id={inputId}
                            value={currentValue}
                            onChange={(event) => onChange(event.value)}
                            useGrouping={false}
                            maxFractionDigits={props.field.Type === FieldType.Decimal ? 2 : undefined}
                            mode={props.field.IsCurrency || props.isCurrency ? "currency" : undefined}
                            currency={props.field.IsCurrency || props.isCurrency ? "NIS" : undefined}
                            prefix={props.field.IsPercent || props.isPercent ? "%" : undefined}
                            inputClassName={getClassName(false, false)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled} />
                    }

                    {props.field.Type === FieldType.Year &&
                        <Dropdown
                            id={inputId}
                            value={currentValue}
                            options={yearItems}
                            optionValue={"Code"}
                            optionLabel={"Name"}
                            onChange={(event) => onChange(event.value)}
                            className={getClassName(true, false)}
                            disabled={props.isDisabled}
                            filter={props.field.IsDropdownFilterEnabled}
                            showClear={true}
                            resetFilterOnHide={true}
                            emptyFilterMessage={emptyFilterMessage} />
                    }

                </form>

                {props.showLookupNotFoundError && lookupNotFoundErrorMessage && !isInternalValueChange &&
                    <small className="p-error block">
                        {Errors.get(lookupNotFoundErrorMessage)}
                        <span
                            className="mr-2 text-blue-500 cursor-pointer"
                            onClick={openDeletedEntityAuditLogDetails}>
                            {"(حركة الحذف)"}
                        </span>
                    </small>
                }

                {props.errors && props.errors.length > 0 &&
                    props.errors.map((error: string, index: number) => (
                        <small key={index} className="p-error block">{FieldErrors.get(error)}</small>
                    ))
                }

                {(props.field.Type === FieldType.Lookup || props.field.Type === FieldType.ListLookup) && getLookupDisplayValue() &&
                    <Tooltip target={"#" + inputId} position="top" showDelay={100}>
                        {getLookupDisplayValue()}
                    </Tooltip>
                }

            </React.Fragment>
        }

        <Dialog
            header={props.label || props.field.Label}
            style={{ width: "85vw", height: "85vh" }}
            breakpoints={DialogInputs.BreakPoints}
            modal={true}
            rtl={true}
            draggable={false}
            resizable={false}
            focusOnShow={false}
            visible={isListLookupOpened}
            onHide={() => setIsListLookupOpened(false)} >

            <div className="pb-2">
                <List
                    title={props.label || props.field.Label}
                    hideHeaderTitle={true}
                    service={props.field.LookupService}
                    fields={props.field.ListLookupFields}
                    filters={props.field.ListLookupFilters ? props.field.ListLookupFilters : undefined}
                    additionalFilters={props.field.LookupFilters && props.field.LookupFilters.length > 0 ? props.field.LookupFilters : undefined}
                    showGlobalFilter={true}
                    showFilterButton={true}
                    noCardContent={true}
                    isLookupList={true}
                    onRecordSelect={(record: any) => onChange(record)} />
            </div>

        </Dialog>


        <Dialog
            header={"تفاصيل الحركة"}
            style={{ width: "50vw" }}
            breakpoints={DialogInputs.BreakPoints}
            modal={true}
            rtl={true}
            draggable={false}
            resizable={false}
            focusOnShow={false}
            visible={isDeletedEntityAuditLogDetailsOpened}
            onHide={() => setIsDeletedEntityAuditLogDetailsOpened(false)} >

            <DeletedEntityAuditLogDetails
                entityCode={getLookupEntityCode()}
                entityId={props.value} />
        </Dialog>


    </React.Fragment>
};

export default FieldValue;
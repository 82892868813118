import './App.scss';

import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import EmptyLayout from './components/layout/EmptyLayout';
import Login from './components/authentication/Login';

import ChangePassword from './components/user/ChangePassword';

import FullScreenLoader from './components/base/FullScreenLoader';

import { UserAuthentication } from './utilities/UserAuthentication';

import PrimeArLocale from "./resources/PrimeArLocale";

import { locale, addLocale } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { AuthenticationService } from './services/AuthenticationService';
import { AuthenticationResponse } from './models/authentication/AuthenticationResponse';
import { UsersPermissions } from './constants/permissions/UsersPermissions';
import { SchoolSettingsPermissions } from './constants/permissions/SchoolSettingsPermissions';
import { CarsPermissions } from './constants/permissions/CarsPermissions';
import { SchoolLicensesPermissions } from './constants/permissions/SchoolLicensesPermissions';
import { CarLicensesPermissions } from './constants/permissions/CarLicensesPermissions';
import { CarInsurancesPermissions } from './constants/permissions/CarInsurancesPermissions';
import { EmployeesPermissions } from './constants/permissions/EmployeesPermissions';
import { StudentsPermissions } from './constants/permissions/StudentsPermissions';
import { SchoolPaymentsPermissions } from './constants/permissions/SchoolPaymentsPermissions';
import { StudentPaymentsPermissions } from './constants/permissions/StudentPaymentsPermissions';
import { StudentDiscountsPermissions } from './constants/permissions/StudentDiscountsPermissions';
import { StudentTheoreticalExamsPermissions } from './constants/permissions/StudentTheoreticalExamsPermissions';
import { StudentPracticalExamsPermissions } from './constants/permissions/StudentPracticalExamsPermissions';
import { StudentLessonsPermissions } from './constants/permissions/StudentLessonsPermissions';
import { ReceivedPaymentsPermissions } from './constants/permissions/ReceivedPaymentsPermissions';
import { TrainerLicensesPermissions } from './constants/permissions/TrainerLicensesPermissions';
import { AppToast } from './utilities/AppToast';
import LandingPage from './components/landing-page/LandingPage';
import FinishSchoolDemoExpiration from './components/school-setting/FinishSchoolDemoExpiration';
import SchoolDemoExpiredWarning from './components/school-setting/SchoolDemoExpiredWarning';
import Dashboard from './components/dashboard/Dashboard';
import UsersList from './components/user/UsersList';
import SchoolsList from './components/school/SchoolsList';
import CarsList from './components/car/CarsList';
import SchoolLicensesList from './components/school-license/SchoolLicensesList';
import CarLicensesList from './components/car-license/CarLicensesList';
import CarInsurancesList from './components/car-insurance/CarInsurancesList';
import SchoolSettings from './components/school-setting/SchoolSettings';
import EmployeesList from './components/employee/EmployeesList';
import StudentsList from './components/student/StudentsList';
import SchoolPaymentsList from './components/school-payment/SchoolPaymentsList';
import StudentPaymentsList from './components/student-payment/StudentPaymentsList';
import StudentDiscountsList from './components/student-discount/StudentDiscountsList';
import StudentTheoreticalExamsList from './components/student-theoretical-exam/StudentTheoreticalExamsList';
import StudentPracticalExamsList from './components/student-practical-exam/StudentPracticalExamsList';
import StudentLessonsList from './components/student-lesson/StudentLessonsList';
import SchoolStatement from './components/school-statement/SchoolStatement';
import SubscriptionRequestsList from './components/subscription-request/SubscriptionRequestsList';
import AuditLogsList from './components/audit-log/AuditLogsList';
import ReceivedPaymentsList from './components/received-payment/ReceivedPaymentsList';
import TrainerLicensesList from './components/trainer-license/TrainerLicensesList';


export const AuthenticationContext = React.createContext<any>(null);

function App() {

    const toastRef = useRef<any>();

    const [isInitialized] = useState(true);
    const [validateTokenCompleted, setValidateTokenCompleted] = useState(false);

    const [authentication, setAuthentication] = useState<AuthenticationResponse | null>(null);

    useEffect(() => {
        if (isInitialized) {
            AppToast.initialize(toastRef);
            UserAuthentication.initialize(setAuthentication);
        }
    }, [isInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        addLocale("ar", PrimeArLocale);
        locale("ar");

        validateToken();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const validateToken = () => {
        let tokenFromLocalStorage = UserAuthentication.getTokenFromLocalStorage();
        if (tokenFromLocalStorage) {
            const authenticationService = new AuthenticationService();
            authenticationService.authenticateByToken(tokenFromLocalStorage).then(authenticationResponse => {
                if (authenticationResponse && authenticationResponse.Token) {
                    completeValidateToken(authenticationResponse);
                } else {
                    completeValidateToken(null);
                }
            }).catch(_error => {
                completeValidateToken(null);
            });
        } else {
            completeValidateToken(null);
        }
    }

    const completeValidateToken = (authenticationResponse: AuthenticationResponse | null) => {
        UserAuthentication.set(authenticationResponse);
        setTimeout(() => {
            setValidateTokenCompleted(true);
        }, 1000);
    }

    return (
        <React.Fragment>

            <Toast ref={toastRef} />

            <AuthenticationContext.Provider value={{ authentication }}>

                {validateTokenCompleted &&
                    <BrowserRouter>
                        <Routes>

                            <Route path='/' element={<EmptyLayout />}>
                                <Route path='/' element={<LandingPage />} />
                            </Route>

                            {authentication && authentication.IsPasswordChanged && !authentication.IsSchoolDemoExpired &&
                                <Route path='/app' element={<AppLayout />}>

                                    <Route path='/app' element={<Dashboard />} />

                                    {authentication.IsMain &&
                                        <Route path='/app/audit-logs' element={<AuditLogsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(SchoolSettingsPermissions.View) &&
                                        <Route path='/app/school-settings' element={<SchoolSettings />} />
                                    }

                                    {UserAuthentication.hasPermissions(UsersPermissions.View) &&
                                        <Route path='/app/users' element={<UsersList />} />
                                    }

                                    {UserAuthentication.hasPermissions(CarsPermissions.View) &&
                                        <Route path='/app/cars' element={<CarsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(CarLicensesPermissions.View) &&
                                        <Route path='/app/car-licenses' element={<CarLicensesList />} />
                                    }

                                    {UserAuthentication.hasPermissions(CarInsurancesPermissions.View) &&
                                        <Route path='/app/car-insurances' element={<CarInsurancesList />} />
                                    }

                                    {UserAuthentication.hasPermissions(EmployeesPermissions.View) &&
                                        <Route path='/app/employees' element={<EmployeesList />} />
                                    }

                                    {UserAuthentication.hasPermissions(SchoolLicensesPermissions.View) &&
                                        <Route path='/app/school-licenses' element={<SchoolLicensesList />} />
                                    }

                                    {UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) &&
                                        <Route path='/app/school-payments' element={<SchoolPaymentsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentsPermissions.View) &&
                                        <Route path='/app/students' element={<StudentsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) &&
                                        <Route path='/app/student-payments' element={<StudentPaymentsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentDiscountsPermissions.View) &&
                                        <Route path='/app/student-discounts' element={<StudentDiscountsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.View) &&
                                        <Route path='/app/student-theoretical-exams' element={<StudentTheoreticalExamsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.View) &&
                                        <Route path='/app/student-practical-exams' element={<StudentPracticalExamsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentLessonsPermissions.View) &&
                                        <Route path='/app/student-lessons' element={<StudentLessonsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View) &&
                                        <Route path='/app/received-payments' element={<ReceivedPaymentsList />} />
                                    }

                                    {UserAuthentication.hasPermissions(TrainerLicensesPermissions.View) &&
                                        <Route path='/app/trainer-licenses' element={<TrainerLicensesList />} />
                                    }

                                    {UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) &&
                                        UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) &&
                                        UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View) &&
                                        <Route path='/app/school-statement' element={<SchoolStatement />} />
                                    }

                                    {authentication.IsManagementSchool &&
                                        <React.Fragment>
                                            <Route path='/app/schools' element={<SchoolsList />} />
                                            <Route path='/app/subscription-requests' element={<SubscriptionRequestsList />} />
                                        </React.Fragment>
                                    }

                                </Route>
                            }

                            {authentication && !authentication.IsPasswordChanged && !authentication.IsSchoolDemoExpired &&
                                <Route path='/change-password' element={<EmptyLayout />}>
                                    <Route path='/change-password' element={<ChangePassword isForCompleteAuthentication={true} />} />
                                </Route>
                            }

                            {authentication && authentication.IsSchoolDemoExpired && authentication.IsMain &&
                                <Route path='/finish-school-demo-expiration' element={<EmptyLayout />}>
                                    <Route path='/finish-school-demo-expiration' element={<FinishSchoolDemoExpiration />} />
                                </Route>
                            }

                            {authentication && authentication.IsSchoolDemoExpired && !authentication.IsMain &&
                                <Route path='/school-demo-expired-warning' element={<EmptyLayout />}>
                                    <Route path='/school-demo-expired-warning' element={<SchoolDemoExpiredWarning />} />
                                </Route>
                            }

                            {!authentication &&
                                <Route path='/login' element={<EmptyLayout />}>
                                    <Route path='/login' element={<Login />} />
                                </Route>
                            }

                            <Route path='*' element={authentication ?

                                (!authentication.IsSchoolDemoExpired ?
                                    (authentication.IsPasswordChanged ?
                                        <Navigate replace to='/app' /> :
                                        <Navigate replace to='/change-password' />) :
                                    (authentication.IsMain ?
                                        <Navigate replace to='/finish-school-demo-expiration' /> :
                                        <Navigate replace to='/school-demo-expired-warning' />)) :

                                <Navigate replace to='/login' />} />
                        </Routes>
                    </BrowserRouter>
                }

                {!validateTokenCompleted &&
                    <FullScreenLoader />
                }

            </AuthenticationContext.Provider>

        </React.Fragment>
    );
}

export default App;
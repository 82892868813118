import { CreateStudentDiscountRequest } from "../../models/student-discount/CreateStudentDiscountRequest";
import { UpdateStudentDiscountRequest } from "../../models/student-discount/UpdateStudentDiscountRequest";
import { StudentDiscountFields } from "../../fields/StudentDiscountFields";
import { StudentDiscountService } from "../../services/StudentDiscountService";
import { StudentDiscountsPermissions } from "../../constants/permissions/StudentDiscountsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import CreateEditStudentDiscount from "./CreateEditStudentDiscount";
import List from "../base/List";

const StudentDiscountsList = () => {

    return (
        <List
            title={"خصومات الطلاب"}
            service={new StudentDiscountService()}
            fields={StudentDiscountFields.get()}
            filters={ListFilters.studentDiscount()}
            createSuccessMessage={"تم اضافة الخصم بنجاح"}
            editSuccessMessage={"تم تعديل الخصم بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الخصم ؟"}
            deleteSuccessMessage={"تم حذف الخصم بنجاح"}
            createModel={new CreateStudentDiscountRequest()}
            editModel={new UpdateStudentDiscountRequest()}
            createEdit={CreateEditStudentDiscount}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(StudentDiscountsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(StudentDiscountsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(StudentDiscountsPermissions.Delete)} />
    );
}

export default StudentDiscountsList;
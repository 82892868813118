import { CarLicenseFields } from "./CarLicenseFields";
import { TrainerLicenseFields } from "./TrainerLicenseFields";
import { CarInsuranceFields } from "./CarInsuranceFields";
import { SchoolLicenseFields } from "./SchoolLicenseFields";
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { SchoolPaymentField } from "../constants/fields/SchoolPaymentField";
import { CarLicenseField } from "../constants/fields/CarLicenseField";
import { TrainerLicenseField } from "../constants/fields/TrainerLicenseField";
import { CarInsuranceField } from "../constants/fields/CarInsuranceField";
import { CarField } from "../constants/fields/CarField";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { SchoolLicenseField } from "../constants/fields/SchoolLicenseField";
import { SchoolPaymentLabel } from "../constants/labels/SchoolPaymentLabel";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { Templates } from "../utilities/Templates";
import { ListFilters } from "../utilities/ListFilters";
import { SchoolPaymentTypeService } from "../services/SchoolPaymentTypeService";
import { CarLicenseService } from "../services/CarLicenseService";
import { TrainerLicenseService } from "../services/TrainerLicenseService";
import { CarInsuranceService } from "../services/CarInsuranceService";
import { CarService } from "../services/CarService";
import { EmployeeService } from "../services/EmployeeService";
import { SchoolLicenseService } from "../services/SchoolLicenseService";
import { PaymentMethodService } from "../services/PaymentMethodService";

export class SchoolPaymentFields {

    static get(): Field[] {
        return [
            new Field({
                Code: SchoolPaymentField.SchoolPaymentTypeCode,
                Label: SchoolPaymentLabel.SchoolPaymentTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new SchoolPaymentTypeService()
            }),

            new Field({
                Code: SchoolPaymentField.SchoolPaymentType_Name,
                Label: SchoolPaymentLabel.SchoolPaymentType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarLicenseId,
                Label: SchoolPaymentLabel.CarLicenseId,
                Type: FieldType.ListLookup,
                ShowInFilters: true,
                LookupService: new CarLicenseService(),
                ListLookupFields: CarLicenseFields.get(),
                ListLookupFilters: ListFilters.carLicense(),
                LookupDisplayTemplate: Templates.carLicenseOrInsurance(CarLicenseField.Car_PlateNumber, CarLicenseField.Car_CarCompany_Name, CarLicenseField.Car_CarType_Name, CarLicenseField.StartDate, CarLicenseField.EndDate)
            }),

            new Field({
                Code: SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                Label: SchoolPaymentLabel.CarLicense_Car_CarCompany_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarLicense_Car_CarType_Name,
                Label: SchoolPaymentLabel.CarLicense_Car_CarType_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarLicense_Car_PlateNumber,
                Label: SchoolPaymentLabel.CarLicense_Car_PlateNumber,
                Type: FieldType.String,
                IsLtr: true,
                IsOneLine: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarLicense_StartDate,
                Label: SchoolPaymentLabel.CarLicense_StartDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarLicense_EndDate,
                Label: SchoolPaymentLabel.CarLicense_EndDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarInsuranceId,
                Label: SchoolPaymentLabel.CarInsuranceId,
                Type: FieldType.ListLookup,
                ShowInFilters: true,
                LookupService: new CarInsuranceService(),
                ListLookupFields: CarInsuranceFields.get(),
                ListLookupFilters: ListFilters.carInsurance(),
                LookupDisplayTemplate: Templates.carLicenseOrInsurance(CarInsuranceField.Car_PlateNumber, CarInsuranceField.Car_CarCompany_Name, CarInsuranceField.Car_CarType_Name, CarInsuranceField.StartDate, CarInsuranceField.EndDate)
            }),

            new Field({
                Code: SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                Label: SchoolPaymentLabel.CarInsurance_Car_CarCompany_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarInsurance_Car_CarType_Name,
                Label: SchoolPaymentLabel.CarInsurance_Car_CarType_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarInsurance_Car_PlateNumber,
                Label: SchoolPaymentLabel.CarInsurance_Car_PlateNumber,
                Type: FieldType.String,
                IsLtr: true,
                IsOneLine: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarInsurance_StartDate,
                Label: SchoolPaymentLabel.CarInsurance_StartDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarInsurance_EndDate,
                Label: SchoolPaymentLabel.CarInsurance_EndDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.CarId,
                Label: SchoolPaymentLabel.CarId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                LookupService: new CarService(),
                LookupLoadFilters: LookupLoadFilters.car(),
                LookupDisplayTemplate: Templates.car(CarField.PlateNumber, CarField.CarCompany_Name, CarField.CarType_Name)
            }),

            new Field({
                Code: SchoolPaymentField.Car_CarCompany_Name,
                Label: SchoolPaymentLabel.Car_CarCompany_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.Car_CarType_Name,
                Label: SchoolPaymentLabel.Car_CarType_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.Car_PlateNumber,
                Label: SchoolPaymentLabel.Car_PlateNumber,
                Type: FieldType.String,
                IsLtr: true,
                IsOneLine: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.EmployeeId,
                Label: SchoolPaymentLabel.EmployeeId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                LookupService: new EmployeeService(),
                LookupLoadFilters: LookupLoadFilters.employee(),
                LookupDisplayFieldCode: EmployeeField.Name
            }),

            new Field({
                Code: SchoolPaymentField.Employee_Name,
                Label: SchoolPaymentLabel.Employee_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.SchoolLicenseId,
                Label: SchoolPaymentLabel.SchoolLicenseId,
                Type: FieldType.ListLookup,
                ShowInFilters: true,
                LookupService: new SchoolLicenseService(),
                ListLookupFields: SchoolLicenseFields.get(),
                ListLookupFilters: ListFilters.schoolLicense(),
                LookupDisplayTemplate: Templates.schoolLicense(SchoolLicenseField.StartDate, SchoolLicenseField.EndDate)
            }),

            new Field({
                Code: SchoolPaymentField.SchoolLicense_StartDate,
                Label: SchoolPaymentLabel.SchoolLicense_StartDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.SchoolLicense_EndDate,
                Label: SchoolPaymentLabel.SchoolLicense_EndDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.TrainerLicenseId,
                Label: SchoolPaymentLabel.TrainerLicenseId,
                Type: FieldType.ListLookup,
                ShowInFilters: true,
                LookupService: new TrainerLicenseService(),
                ListLookupFields: TrainerLicenseFields.get(),
                ListLookupFilters: ListFilters.trainerLicense(),
                LookupDisplayTemplate: Templates.trainerLicense(TrainerLicenseField.Employee_Name, TrainerLicenseField.TrainerLicenseType_Name, TrainerLicenseField.StartDate, TrainerLicenseField.EndDate)
            }),

            new Field({
                Code: SchoolPaymentField.TrainerLicense_Employee_Name,
                Label: SchoolPaymentLabel.TrainerLicense_Employee_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name,
                Label: SchoolPaymentLabel.TrainerLicense_TrainerLicenseType_Name,
                Type: FieldType.String,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.TrainerLicense_StartDate,
                Label: SchoolPaymentLabel.TrainerLicense_StartDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.TrainerLicense_EndDate,
                Label: SchoolPaymentLabel.TrainerLicense_EndDate,
                Type: FieldType.Date,
                DetailsDatatableColumn: new DetailsDatatableColumn({ IsHiddenIfEmpty: true })
            }),

            new Field({
                Code: SchoolPaymentField.PaymentDate,
                Label: SchoolPaymentLabel.PaymentDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolPaymentField.PaymentAmount,
                Label: SchoolPaymentLabel.PaymentAmount,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolPaymentField.PaymentMethodCode,
                Label: SchoolPaymentLabel.PaymentMethodCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new PaymentMethodService()
            }),

            new Field({
                Code: SchoolPaymentField.PaymentMethod_Name,
                Label: SchoolPaymentLabel.PaymentMethod_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: SchoolPaymentField.CheckDate,
                Label: SchoolPaymentLabel.CheckDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolPaymentField.IsCheckReturned,
                Label: SchoolPaymentLabel.IsCheckReturned,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: SchoolPaymentField.Notes,
                Label: SchoolPaymentLabel.Notes,
                Type: FieldType.String,
                ShowInFilters: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({ WidthPercent: 50 })
            }),
            new Field({
                Code: SchoolPaymentField.CheckDateStatus,
                Label: SchoolPaymentLabel.CheckDateStatus,
                Type: FieldType.String,
                CustomTemplateCode: "CheckDateStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
export enum SchoolPaymentField {
    SchoolPaymentTypeCode = "SchoolPaymentTypeCode",
    CarLicenseId = "CarLicenseId",
    TrainerLicenseId = "TrainerLicenseId",
    CarInsuranceId = "CarInsuranceId",
    CarId = "CarId",
    EmployeeId = "EmployeeId",
    SchoolLicenseId = "SchoolLicenseId",
    PaymentDate = "PaymentDate",
    PaymentAmount = "PaymentAmount",
    PaymentMethodCode = "PaymentMethodCode",
    CheckDate = "CheckDate",
    IsCheckReturned = "IsCheckReturned",
    Notes = "Notes",
    SchoolPaymentType_Name = "SchoolPaymentType.Name",
    PaymentMethod_Name = "PaymentMethod.Name",
    Car_PlateNumber = "Car.PlateNumber",
    Car_CarCompany_Name = "Car.CarCompany.Name",
    Car_CarType_Name = "Car.CarType.Name",
    CarLicense_StartDate = "CarLicense.StartDate",
    CarLicense_EndDate = "CarLicense.EndDate",
    CarLicense_Car_PlateNumber = "CarLicense.Car.PlateNumber",
    CarLicense_Car_CarCompany_Name = "CarLicense.Car.CarCompany.Name",
    CarLicense_Car_CarType_Name = "CarLicense.Car.CarType.Name",
    CarInsurance_StartDate = "CarInsurance.StartDate",
    CarInsurance_EndDate = "CarInsurance.EndDate",
    CarInsurance_Car_PlateNumber = "CarInsurance.Car.PlateNumber",
    CarInsurance_Car_CarCompany_Name = "CarInsurance.Car.CarCompany.Name",
    CarInsurance_Car_CarType_Name = "CarInsurance.Car.CarType.Name",
    Employee_Name = "Employee.Name",
    SchoolLicense_StartDate = "SchoolLicense.StartDate",
    SchoolLicense_EndDate = "SchoolLicense.EndDate",
    TrainerLicense_StartDate = "TrainerLicense.StartDate",
    TrainerLicense_EndDate = "TrainerLicense.EndDate",
    TrainerLicense_Employee_Name = "TrainerLicense.Employee.Name",
    TrainerLicense_TrainerLicenseType_Name = "TrainerLicense.TrainerLicenseType.Name",
    CheckDateStatus = "CheckDateStatus"
}
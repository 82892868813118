import { http } from "../http/Http";
import { CarInsurance } from "../models/car-insurance/CarInsurance";
import { CarInsuranceStatement } from "../models/car-insurance/CarInsuranceStatement";
import { CreateCarInsuranceRequest } from "../models/car-insurance/CreateCarInsuranceRequest";
import { UpdateCarInsuranceRequest } from "../models/car-insurance/UpdateCarInsuranceRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class CarInsuranceService {

    public EntityCode: EntityCode = EntityCode.CarInsurance;

    private Api: string = "CarInsurances";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<CarInsurance>> => {
        const { data } = await http.post<GetByFiltersResponse<CarInsurance>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<CarInsurance>> => {
        const { data } = await http.post<GetByFiltersResponse<CarInsurance>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<CarInsurance> => {
        const { data } = await http.get<CarInsurance>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<CarInsurance> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<CarInsurance>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getCarInsuranceStatement = async (id: number): Promise<CarInsuranceStatement> => {
        const { data } = await http.get<CarInsuranceStatement>(this.Api + "/GetCarInsuranceStatement/" + id.toString());
        return data;
    };

    create = async (createCarInsuranceRequest: CreateCarInsuranceRequest): Promise<CarInsurance> => {
        const { data } = await http.post<CarInsurance>(this.Api, createCarInsuranceRequest);
        return data;
    };

    update = async (id: number, updateCarInsuranceRequest: UpdateCarInsuranceRequest): Promise<CarInsurance> => {
        const { data } = await http.put<CarInsurance>(this.Api + "/" + id.toString(), updateCarInsuranceRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
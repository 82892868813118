export class ClosedLookupDataMapper {

    static map(data: any) {
        if (data) {
            return data.map((d: any) => ({
                ...d,
                Name: d.Code === "OTH" ? "غير ذلك" : d.Name
            }));
        }
        return data;
    }

}
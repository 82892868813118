import { CreateStudentTheoreticalExamRequest } from "../../models/student-theoretical-exam/CreateStudentTheoreticalExamRequest";
import { UpdateStudentTheoreticalExamRequest } from "../../models/student-theoretical-exam/UpdateStudentTheoreticalExamRequest";
import { StudentTheoreticalExamFields } from "../../fields/StudentTheoreticalExamFields";
import { StudentTheoreticalExamService } from "../../services/StudentTheoreticalExamService";
import { StudentTheoreticalExamsPermissions } from "../../constants/permissions/StudentTheoreticalExamsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import List from "../base/List";

const StudentTheoreticalExamsList = () => {

    return (
        <List
            title={"الاختبارات النظرية"}
            service={new StudentTheoreticalExamService()}
            fields={StudentTheoreticalExamFields.get()}
            filters={ListFilters.studentTheoreticalExam()}
            createSuccessMessage={"تم اضافة الاختبار بنجاح"}
            editSuccessMessage={"تم تعديل الاختبار بنجاح"}
            confirmDeleteMessage={"هل أنت متأكد من حذف الاختبار ؟"}
            deleteSuccessMessage={"تم حذف الاختبار بنجاح"}
            createModel={new CreateStudentTheoreticalExamRequest()}
            editModel={new UpdateStudentTheoreticalExamRequest()}
            showRefreshButton={true}
            showTotalRecordsCount={true}
            showGlobalFilter={true}
            showFilterButton={true}
            showAddButton={UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.Create)}
            showEditButton={UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.Update)}
            showDeleteButton={UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.Delete)} />
    );
}

export default StudentTheoreticalExamsList;
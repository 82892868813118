export enum TrainerLicenseField {
    StartDate = "StartDate",
    EndDate = "EndDate",
    EmployeeId = "EmployeeId",
    TrainerLicenseTypeCode = "TrainerLicenseTypeCode",
    Cost = "Cost",
    TotalPaid = "TotalPaid",
    TotalOverpaid = "TotalOverpaid",
    TotalRemaining = "TotalRemaining",
    Employee_Name = "Employee.Name",
    TrainerLicenseType_Name = "TrainerLicenseType.Name",
    ExpirationStatus = "ExpirationStatus"
}
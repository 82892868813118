import { http } from "../http/Http";
import { TrainerLicense } from "../models/trainer-license/TrainerLicense";
import { TrainerLicenseStatement } from "../models/trainer-license/TrainerLicenseStatement";
import { CreateTrainerLicenseRequest } from "../models/trainer-license/CreateTrainerLicenseRequest";
import { UpdateTrainerLicenseRequest } from "../models/trainer-license/UpdateTrainerLicenseRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class TrainerLicenseService {

    public EntityCode: EntityCode = EntityCode.TrainerLicense;

    private Api: string = "TrainerLicenses";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<TrainerLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<TrainerLicense>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<TrainerLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<TrainerLicense>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<TrainerLicense> => {
        const { data } = await http.get<TrainerLicense>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<TrainerLicense> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<TrainerLicense>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getTrainerLicenseStatement = async (id: number): Promise<TrainerLicenseStatement> => {
        const { data } = await http.get<TrainerLicenseStatement>(this.Api + "/GetTrainerLicenseStatement/" + id.toString());
        return data;
    };

    create = async (createTrainerLicenseRequest: CreateTrainerLicenseRequest): Promise<TrainerLicense> => {
        const { data } = await http.post<TrainerLicense>(this.Api, createTrainerLicenseRequest);
        return data;
    };

    update = async (id: number, updateTrainerLicenseRequest: UpdateTrainerLicenseRequest): Promise<TrainerLicense> => {
        const { data } = await http.put<TrainerLicense>(this.Api + "/" + id.toString(), updateTrainerLicenseRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
import { Filter } from "../models/base/Filter";
import { LookupFilters } from "../models/base/LookupFilters";
import { FilterOperator } from "../constants/base/FilterOperator";
import { CarField } from "../constants/fields/CarField";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { StudentField } from "../constants/fields/StudentField";
import { UserField } from "../constants/fields/UserField";

export class LookupLoadFilters {

    static car() {
        return new LookupFilters({
            Filters: [
                new Filter(CarField.PlateNumber, FilterOperator.Contains),
                new Filter(CarField.CarCompany_Name, FilterOperator.Contains),
                new Filter(CarField.CarType_Name, FilterOperator.Contains)
            ],
            Placeholder: "الشركة - النوع - رقم اللوحة"
        });
    }

    static employee() {
        return new LookupFilters({
            Filters: [
                new Filter(EmployeeField.Name, FilterOperator.Contains)
            ],
            Placeholder: "الاسم"
        });
    }

    static student() {
        return new LookupFilters({
            Filters: [
                new Filter(StudentField.FullName, FilterOperator.Contains)
            ],
            Placeholder: "الاسم"
        });
    }

    static user() {
        return new LookupFilters({
            Filters: [
                new Filter(UserField.Name, FilterOperator.Contains)
            ],
            Placeholder: "الاسم"
        });
    }

}
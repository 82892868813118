import { http } from "../http/Http";
import { PaymentMethod } from "../models/payment-method/PaymentMethod";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class PaymentMethodService {

    public EntityCode: EntityCode = EntityCode.PaymentMethod;

    private Api: string = "PaymentMethods";

    getAll = async (): Promise<PaymentMethod[]> => {
        const { data } = await http.get<PaymentMethod[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
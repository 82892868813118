import { http } from "../http/Http";
import { AuditLog } from "../models/audit-log/AuditLog";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class AuditLogService {

    public EntityCode: EntityCode = EntityCode.AuditLog;

    private Api: string = "AuditLogs";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<AuditLog>> => {
        const { data } = await http.post<GetByFiltersResponse<AuditLog>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

}
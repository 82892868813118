import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { School } from "../../models/school/School";
import { ListButton } from "../../models/base/ListButton";
import { SchoolFields } from "../../fields/SchoolFields";
import { SchoolService } from "../../services/SchoolService";
import { AuthenticationContext } from "../../App";
import { AuthenticationService } from "../../services/AuthenticationService";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import List from "../base/List";
import CreateEditSchool from "./CreateEditSchool";
import ConfirmDialog from "../base/ConfirmDialog";

const SchoolsList = () => {

    const navigate = useNavigate();

    const { authentication } = useContext(AuthenticationContext);

    const additionalListButtonsCodes = {
        Login: "Login"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.Login, Icon: "key", Severity: "info", Position: "last", Tooltip: "تسجيل الدخول" })
    ];

    const [selectedRecord, setSelectedRecord] = useState<School | null>(null);
    const [isConfirmLoginOpened, setIsConfirmLoginOpened] = useState<boolean>(false);
    const [isLoginProcessing, setIsLoginProcessing] = useState<boolean>(false);

    const [showAdditionalListButtons, setShowAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.Login]: false
    });


    const onRecordSelect = (record: School | null) => {
        setSelectedRecord(record);
        setShowAdditionalListButtons(showAdditionalListButtons => ({
            ...showAdditionalListButtons,
            [additionalListButtonsCodes.Login]: record !== null && record.Id !== authentication?.SchoolId
        }));
    }

    const onAdditionalListButtonClick = (buttonCode: string, record: School | null) => {
        if (record) {
            if (buttonCode === additionalListButtonsCodes.Login) {
                setIsConfirmLoginOpened(true);
            }
        }
    }

    const acceptLogin = () => {
        if (selectedRecord) {
            setIsLoginProcessing(true);
            let authenticationService = new AuthenticationService();
            authenticationService.authenticateBySchool(selectedRecord.Id).then((response: any) => {
                if (response && response.Token) {
                    UserAuthentication.set(response);
                    navigate("/app");
                } else {
                    setIsLoginProcessing(false);
                    setIsConfirmLoginOpened(false);
                }
            }).catch((_error: any) => {
                setIsLoginProcessing(false);
                setIsConfirmLoginOpened(false);
            });
        }
    }

    return (
        <React.Fragment>
            <List
                title={"المدارس"}
                service={new SchoolService()}
                fields={SchoolFields.get()}
                filters={ListFilters.school()}
                createSuccessMessage={"تم اضافة المدرسة بنجاح"}
                editSuccessMessage={"تم تعديل المدرسة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف المدرسة ؟"}
                deleteSuccessMessage={"تم حذف المدرسة بنجاح"}
                confirmActivateMessage={"هل أنت متأكد من تفعيل المدرسة ؟"}
                activateSuccessMessage={"تم تفعيل المدرسة بنجاح"}
                confirmDeactivateMessage={"هل أنت متأكد من تعطيل المدرسة ؟"}
                deactivateSuccessMessage={"تم تعطيل المدرسة بنجاح"}
                createEdit={CreateEditSchool}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={true}
                showEditButton={true}
                showActivateButton={selectedRecord !== null && selectedRecord.Id !== authentication?.SchoolId}
                showDeactivateButton={selectedRecord !== null && selectedRecord.Id !== authentication?.SchoolId}
                onRecordSelect={(record: School | null) => onRecordSelect(record)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, record: any) => onAdditionalListButtonClick(buttonCode, record)} />

            <ConfirmDialog
                message={"هل انت متأكد من تسجيل الدخول للمدرسة ؟"}
                isOpened={isConfirmLoginOpened}
                isLoading={isLoginProcessing}
                onAccept={() => acceptLogin()}
                onHide={() => setIsConfirmLoginOpened(false)} />
        </React.Fragment>
    );
}

export default SchoolsList;
import { http } from "../http/Http";
import { Entity } from "../models/entity/Entity";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class EntityService {

    public EntityCode: EntityCode = EntityCode.Entity;

    private Api: string = "Entities";

    getAll = async (): Promise<Entity[]> => {
        const { data } = await http.get<Entity[]>(this.Api + "/GetAll");
        return data;
    };

}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { UserField } from "../constants/fields/UserField";
import { UserLabel } from "../constants/labels/UserLabel";
import { GenderService } from "../services/GenderService";
import { CityService } from "../services/CityService";

export class UserFields {

    static get(): Field[] {
        return [
            new Field({
                Code: UserField.Name,
                Label: UserLabel.Name,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: UserField.Email,
                Label: UserLabel.Email,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: UserField.Password,
                Label: UserLabel.Password,
                Type: FieldType.Password,
                ShowInCreate: true,
            }),
            new Field({
                Code: UserField.GenderCode,
                Label: UserLabel.GenderCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new GenderService()
            }),

            new Field({
                Code: UserField.Gender_Name,
                Label: UserLabel.Gender_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: UserField.CityCode,
                Label: UserLabel.CityCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsDropdownFilterEnabled: true,
                LookupService: new CityService()
            }),

            new Field({
                Code: UserField.City_Name,
                Label: UserLabel.City_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: UserField.Address,
                Label: UserLabel.Address,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),
            new Field({
                Code: UserField.Phone,
                Label: UserLabel.Phone,
                Type: FieldType.String,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                IsLtr: true,
                DatatableColumn: new DatatableColumn({})
            }),
            new Field({
                Code: UserField.IsActive,
                Label: UserLabel.IsActive,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: UserField.IsMain,
                Label: UserLabel.IsMain,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            })
        ];
    }

}
import React, { useContext, useState, useEffect, useRef } from "react";
import { Tooltip } from "primereact/tooltip";
import { ProgressBar } from 'primereact/progressbar';
import { AuthenticationContext } from "../../App";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { SortDirection } from "../../constants/base/SortDirection";
import { EmployeeJobCode } from "../../constants/closed-lookup/EmployeeJobCode";
import { StudentTheoreticalExamField } from "../../constants/fields/StudentTheoreticalExamField";
import { StudentPracticalExamField } from "../../constants/fields/StudentPracticalExamField";
import { StudentPaymentField } from "../../constants/fields/StudentPaymentField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { CarLicenseField } from "../../constants/fields/CarLicenseField";
import { TrainerLicenseField } from "../../constants/fields/TrainerLicenseField";
import { CarInsuranceField } from "../../constants/fields/CarInsuranceField";
import { SchoolLicenseField } from "../../constants/fields/SchoolLicenseField";
import { EmployeeField } from "../../constants/fields/EmployeeField";
import { ReceivedPaymentField } from "../../constants/fields/ReceivedPaymentField";
import { UsersPermissions } from '../../constants/permissions/UsersPermissions';
import { StudentsPermissions } from '../../constants/permissions/StudentsPermissions';
import { EmployeesPermissions } from '../../constants/permissions/EmployeesPermissions';
import { CarsPermissions } from '../../constants/permissions/CarsPermissions';
import { StudentTheoreticalExamsPermissions } from '../../constants/permissions/StudentTheoreticalExamsPermissions';
import { StudentPracticalExamsPermissions } from '../../constants/permissions/StudentPracticalExamsPermissions';
import { StudentPaymentsPermissions } from '../../constants/permissions/StudentPaymentsPermissions';
import { SchoolPaymentsPermissions } from '../../constants/permissions/SchoolPaymentsPermissions';
import { CarLicensesPermissions } from '../../constants/permissions/CarLicensesPermissions';
import { TrainerLicensesPermissions } from '../../constants/permissions/TrainerLicensesPermissions';
import { CarInsurancesPermissions } from '../../constants/permissions/CarInsurancesPermissions';
import { SchoolLicensesPermissions } from '../../constants/permissions/SchoolLicensesPermissions';
import { ReceivedPaymentsPermissions } from "../../constants/permissions/ReceivedPaymentsPermissions";
import { SchoolSettingsPermissions } from "../../constants/permissions/SchoolSettingsPermissions";
import { UpdateStudentTheoreticalExamRequest } from "../../models/student-theoretical-exam/UpdateStudentTheoreticalExamRequest";
import { UpdateStudentPracticalExamRequest } from "../../models/student-practical-exam/UpdateStudentPracticalExamRequest";
import { UpdateStudentPaymentRequest } from "../../models/student-payment/UpdateStudentPaymentRequest";
import { UpdateSchoolPaymentRequest } from "../../models/school-payment/UpdateSchoolPaymentRequest";
import { CreateCarLicenseRequest } from "../../models/car-license/CreateCarLicenseRequest";
import { CreateTrainerLicenseRequest } from "../../models/trainer-license/CreateTrainerLicenseRequest";
import { CreateCarInsuranceRequest } from "../../models/car-insurance/CreateCarInsuranceRequest";
import { CreateSchoolLicenseRequest } from "../../models/school-license/CreateSchoolLicenseRequest";
import { UpdateReceivedPaymentRequest } from "../../models/received-payment/UpdateReceivedPaymentRequest";
import { UserCounts } from "../../models/user/UserCounts";
import { SchoolStatement } from "../../models/school-statement/SchoolStatement";
import { StudentTheoreticalExamFields } from "../../fields/StudentTheoreticalExamFields";
import { StudentPracticalExamFields } from "../../fields/StudentPracticalExamFields";
import { StudentPaymentFields } from "../../fields/StudentPaymentFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { CarLicenseFields } from "../../fields/CarLicenseFields";
import { TrainerLicenseFields } from "../../fields/TrainerLicenseFields";
import { CarInsuranceFields } from "../../fields/CarInsuranceFields";
import { SchoolLicenseFields } from "../../fields/SchoolLicenseFields";
import { ReceivedPaymentFields } from "../../fields/ReceivedPaymentFields";
import { GetByFiltersRequestBuilder } from "../../builders/GetByFiltersRequestBuilder";
import { Convertor } from "../../utilities/Convertor";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { UserService } from "../../services/UserService";
import { StudentService } from "../../services/StudentService";
import { EmployeeService } from "../../services/EmployeeService";
import { CarService } from "../../services/CarService";
import { SchoolStatementService } from "../../services/SchoolStatementService";
import { StudentTheoreticalExamService } from "../../services/StudentTheoreticalExamService";
import { StudentPracticalExamService } from "../../services/StudentPracticalExamService";
import { StudentPaymentService } from "../../services/StudentPaymentService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { CarLicenseService } from "../../services/CarLicenseService";
import { TrainerLicenseService } from "../../services/TrainerLicenseService";
import { CarInsuranceService } from "../../services/CarInsuranceService";
import { SchoolLicenseService } from "../../services/SchoolLicenseService";
import { ReceivedPaymentService } from "../../services/ReceivedPaymentService";
import List from "../base/List";
import CreateEditStudentPayment from "../student-payment/CreateEditStudentPayment";
import CreateEditSchoolPayment from "../school-payment/CreateEditSchoolPayment";
import CreateEditReceivedPayment from "../received-payment/CreateEditReceivedPayment";


const Dashboard = () => {

    const containerRef = useRef<any>(null);

    const { authentication } = useContext(AuthenticationContext);

    const userService: UserService = new UserService();
    const studentService: StudentService = new StudentService();
    const employeeService: EmployeeService = new EmployeeService();
    const carService: CarService = new CarService();
    const schoolStatementService: SchoolStatementService = new SchoolStatementService();

    const [containerResizeToggle, setContainerResizeToggle] = useState<number>(0);

    const [isUserCountsLoading, setIsUserCountsLoading] = useState<boolean>(false);
    const [userCounts, setUserCounts] = useState<UserCounts | null>(null);

    const [isUsersCountLoading, setIsUsersCountLoading] = useState<boolean>(false);
    const [usersCount, setUsersCount] = useState<number | null>(null);

    const [isStudentsCountLoading, setIsStudentsCountLoading] = useState<boolean>(false);
    const [studentsCount, setStudentsCount] = useState<number | null>(null);

    const [isTrainersCountLoading, setIsTrainersCountLoading] = useState<boolean>(false);
    const [trainersCount, setTrainersCount] = useState<number | null>(null);

    const [isCarsCountLoading, setIsCarsCountLoading] = useState<boolean>(false);
    const [carsCount, setCarsCount] = useState<number | null>(null);

    const [isSchoolStatementLoading, setIsSchoolStatementLoading] = useState<boolean>(false);
    const [schoolStatement, setSchoolStatement] = useState<SchoolStatement | null>(null);


    useEffect(() => {
        if (!containerRef || !containerRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            setContainerResizeToggle((toggle) => toggle + 1);
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        loadUserCounts();
        loadUsersCount();
        loadStudentsCount();
        loadTrainersCount();
        loadCarsCount();
        loadSchoolStatement();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const loadUserCounts = () => {
        if (UserAuthentication.hasPermissions(UsersPermissions.View) &&
            UserAuthentication.hasPermissions(SchoolSettingsPermissions.View)) {
            setIsUserCountsLoading(true);
            userService.getCounts().then((userCounts) => {
                setUserCounts(userCounts || null);
                setIsUserCountsLoading(false);
            }).catch((_error: any) => {
                setUserCounts(null);
                setIsUserCountsLoading(false);
            });
        }
    }

    const loadUsersCount = () => {
        if (UserAuthentication.hasPermissions(UsersPermissions.View)) {
            setIsUsersCountLoading(true);
            let getByFiltersRequest = new GetByFiltersRequestBuilder().onlyCount(true).build();
            userService.getByFilters(getByFiltersRequest).then((response) => {
                setUsersCount(response && response.Count !== null && response.Count !== undefined ? response.Count : null);
                setIsUsersCountLoading(false);
            }).catch((_error: any) => {
                setUsersCount(null);
                setIsUsersCountLoading(false);
            });
        }
    }

    const loadStudentsCount = () => {
        if (UserAuthentication.hasPermissions(StudentsPermissions.View)) {
            setIsStudentsCountLoading(true);
            let getByFiltersRequest = new GetByFiltersRequestBuilder().onlyCount(true).build();
            studentService.getByFilters(getByFiltersRequest).then((response) => {
                setStudentsCount(response && response.Count !== null && response.Count !== undefined ? response.Count : null);
                setIsStudentsCountLoading(false);
            }).catch((_error: any) => {
                setStudentsCount(null);
                setIsStudentsCountLoading(false);
            });
        }
    }

    const loadTrainersCount = () => {
        if (UserAuthentication.hasPermissions(EmployeesPermissions.View)) {
            setIsTrainersCountLoading(true);
            let getByFiltersRequest = new GetByFiltersRequestBuilder()
                .addFilter(EmployeeField.EmployeeJobCode, EmployeeJobCode.Trainer, FilterOperator.Equals).onlyCount(true).build();
            employeeService.getByFilters(getByFiltersRequest).then((response) => {
                setTrainersCount(response && response.Count !== null && response.Count !== undefined ? response.Count : null);
                setIsTrainersCountLoading(false);
            }).catch((_error: any) => {
                setTrainersCount(null);
                setIsTrainersCountLoading(false);
            });
        }
    }

    const loadCarsCount = () => {
        if (UserAuthentication.hasPermissions(CarsPermissions.View)) {
            setIsCarsCountLoading(true);
            let getByFiltersRequest = new GetByFiltersRequestBuilder().onlyCount(true).build();
            carService.getByFilters(getByFiltersRequest).then((response) => {
                setCarsCount(response && response.Count !== null && response.Count !== undefined ? response.Count : null);
                setIsCarsCountLoading(false);
            }).catch((_error: any) => {
                setCarsCount(null);
                setIsCarsCountLoading(false);
            });
        }
    }

    const loadSchoolStatement = () => {
        if (UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) &&
            UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) &&
            UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View)) {
            setIsSchoolStatementLoading(true);
            schoolStatementService.getCurrentMonthSchoolStatement().then((schoolStatement) => {
                setSchoolStatement(schoolStatement || null);
                setIsSchoolStatementLoading(false);
            }).catch((_error: any) => {
                setSchoolStatement(null);
                setIsSchoolStatementLoading(false);
            });
        }
    }

    const countTemplate = (isLoading: boolean, count: number | null, label: string, icon: string) => {
        return <div className="col-12 md:col-6 lg:col-3">
            <div className="p-2 border-round shadow-2 flex align-items-center surface-card">
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round w-2rem h-2rem ml-1">
                    <i className={"pi pi-" + icon + " text-blue-500 text-lg"}></i>
                </div>
                <div className="small-grid-column-content">
                    <span className="block text-700 font-medium font-size-9 pr-1">{label}</span>
                    {isLoading &&
                        <i className="pi pi-spin pi-spinner text-blue-500 mr-1"></i>
                    }
                    {!isLoading &&
                        <span className="block text-teal-600 font-medium font-size-9 pr-1">{count || "0"}</span>
                    }
                </div>
            </div>
        </div>;
    }

    const userGridColumnContentTemplate = (isForTooltip: boolean) => {
        if (authentication) {
            return <React.Fragment>
                <span className={isForTooltip ? "block font-medium font-size-9 word-break-break-word white-space-normal" : "block text-700 font-medium font-size-9"}>{authentication.UserName}</span>
                <span className={isForTooltip ? "block font-size-9 word-break-break-word white-space-normal" : "block text-teal-600 font-size-9"}>{authentication.UserEmail}</span>
            </React.Fragment>;
        }
        return null;
    }

    const schoolGridColumnContentTemplate = (isForTooltip: boolean) => {
        if (authentication) {
            return <React.Fragment>
                <span className={isForTooltip ? "block font-medium font-size-9 word-break-break-word white-space-normal" : "block text-700 font-medium font-size-9"}>{authentication.SchoolName}</span>
                {UserAuthentication.hasPermissions(SchoolSettingsPermissions.View) && authentication.DemoRemainingDays !== null && authentication.DemoRemainingDays !== undefined && authentication.DemoRemainingDays > 0 &&
                    <span className={isForTooltip ? "block font-size-9 word-break-break-word white-space-normal" : "block text-orange-600 font-size-9"}>
                        {getDemoRemainingDaysDescription(authentication.DemoRemainingDays)}
                    </span>
                }
                {UserAuthentication.hasPermissions(SchoolSettingsPermissions.View) && authentication.DemoRemainingDays !== null && authentication.DemoRemainingDays !== undefined && authentication.DemoRemainingDays === 0 &&
                    <span className={isForTooltip ? "block font-size-9 word-break-break-word white-space-normal" : "block text-teal-600 font-size-9"}>
                        {
                            "إشتراك دائم" +
                            (authentication.FinishDemoExpirationDate ?
                                (" منذ " + Convertor.dateToString(new Date(authentication.FinishDemoExpirationDate), true)) :
                                "")
                        }
                    </span>
                }
            </React.Fragment>;
        }
        return null;
    }

    const getDemoRemainingDaysDescription = (demoRemainingDays: number) => {
        if (demoRemainingDays !== null && demoRemainingDays !== undefined && demoRemainingDays > 0) {
            let remainingDescription = "";
            if (demoRemainingDays === 1) {
                remainingDescription = "يوم";
            }
            else if (demoRemainingDays === 2) {
                remainingDescription = "يومان";
            }
            else if (demoRemainingDays > 2 && demoRemainingDays <= 10) {
                remainingDescription = demoRemainingDays + " أيام";
            }
            else {
                remainingDescription = demoRemainingDays + " يوم";
            }
            return "متبقي على الفترة التجريبية " + remainingDescription;
        }
        return null;
    }

    const userCountsValueTemplate = (value: number) => {
        if (value !== null && value !== undefined && userCounts) {
            let factor = Math.pow(10, 10);
            let usedUsersCount = Math.round(((value / 100) * userCounts.Maximum) * factor) / factor;
            return (
                <React.Fragment>
                    <span className="direction-ltr">{usedUsersCount} / {userCounts.Maximum}</span>
                </React.Fragment>
            );
        }
        return <span></span>;
    };

    const getElementWidth = (selector: string) => {
        if (selector) {
            let element = document.querySelector(selector);
            if (element) {
                return (element as any).offsetWidth;
            }
        }
        return 220;
    }

    const onCreateEditStudentPayment = (response: any, isCreate: boolean) => {
        if (response && !isCreate) {
            loadSchoolStatement();
        }
    }

    const onCreateEditSchoolPayment = (response: any, isCreate: boolean) => {
        if (response && !isCreate) {
            loadSchoolStatement();
        }
    }

    const onCreateEditReceivedPayment = (response: any, isCreate: boolean) => {
        if (response && !isCreate) {
            loadSchoolStatement();
        }
    }

    return (
        <div className="dashboard-container" ref={containerRef}>

            <div className="mt-2"></div>

            <div className="grid mb-3">

                {authentication &&
                    <React.Fragment>
                        {containerResizeToggle >= 0 &&
                            <Tooltip
                                target="#user-grid-column"
                                position="top"
                                style={{ maxWidth: getElementWidth("#user-grid-column>div") + "px" }}>
                                {userGridColumnContentTemplate(true)}
                            </Tooltip>
                        }
                        <div id="user-grid-column" className="col-12 md:col-6 lg:col-3">
                            <div className="p-2 border-round shadow-2 flex align-items-center surface-card">
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round w-2rem h-2rem">
                                    <i className="pi pi-id-card text-blue-500 text-lg"></i>
                                </div>
                                <div className="small-grid-column-content pr-2">
                                    {userGridColumnContentTemplate(false)}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                {authentication &&
                    <React.Fragment>
                        {containerResizeToggle >= 0 &&
                            <Tooltip
                                target="#school-grid-column"
                                position="top"
                                style={{ maxWidth: getElementWidth("#school-grid-column>div") + "px" }}>
                                {schoolGridColumnContentTemplate(true)}
                            </Tooltip>
                        }
                        <div id="school-grid-column" className="col-12 md:col-6 lg:col-3">
                            <div className="p-2 border-round shadow-2 flex align-items-center surface-card">
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round w-2rem h-2rem">
                                    <i className="pi pi-flag text-blue-500 text-lg"></i>
                                </div>
                                <div className="small-grid-column-content pr-2">
                                    {schoolGridColumnContentTemplate(false)}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                {UserAuthentication.hasPermissions(UsersPermissions.View) &&
                    countTemplate(isUsersCountLoading, usersCount, "المستخدمين", "user")
                }

                {UserAuthentication.hasPermissions(StudentsPermissions.View) &&
                    countTemplate(isStudentsCountLoading, studentsCount, "الطلاب", "user")
                }

                {UserAuthentication.hasPermissions(EmployeesPermissions.View) &&
                    countTemplate(isTrainersCountLoading, trainersCount, "المدربين", "user")
                }

                {UserAuthentication.hasPermissions(CarsPermissions.View) &&
                    countTemplate(isCarsCountLoading, carsCount, "المركبات", "car")
                }

                {UserAuthentication.hasPermissions(UsersPermissions.View) &&
                    UserAuthentication.hasPermissions(SchoolSettingsPermissions.View) &&
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="p-2 border-round shadow-2 flex align-items-center surface-card">
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round w-2rem h-2rem">
                                <i className="pi pi-users text-blue-500 text-lg"></i>
                            </div>
                            <div className="small-grid-column-content pr-2">
                                <span className="block text-700 font-medium font-size-9">
                                    <span>{"المستخدمين المتاحين"}</span>
                                    {isUserCountsLoading &&
                                        <i className="pi pi-spin pi-spinner mr-1 text-blue-500"></i>
                                    }
                                    {!isUserCountsLoading &&
                                        <span className="mr-1 text-teal-600 font-medium font-size-9">{userCounts ? userCounts.Available : null}</span>
                                    }
                                </span>

                                <ProgressBar className="user-counts-progress-bar"
                                    value={userCounts ? ((userCounts.Used / userCounts.Maximum) * 100) : undefined}
                                    mode={isUserCountsLoading ? "indeterminate" : "determinate"}
                                    displayValueTemplate={userCountsValueTemplate}>
                                </ProgressBar>
                            </div>
                        </div>
                    </div>
                }


                {UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) &&
                    UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) &&
                    UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View) &&

                    <React.Fragment>
                        {!isSchoolStatementLoading && schoolStatement && containerResizeToggle >= 0 &&
                            <Tooltip target="#current-month-statement-grid-column"
                                position="top"
                                style={{ maxWidth: getElementWidth("#current-month-statement-grid-column>div") + "px" }}>
                                <span className="word-break-break-word white-space-normal">
                                    <span>
                                        <span className="font-medium font-size-9">{"المقبوض "}</span>
                                        <span>{schoolStatement.TotalReceived}</span>
                                    </span>
                                    <span> | </span>
                                    <span>
                                        <span className="font-medium font-size-9">{"المدفوع "}</span>
                                        <span>{schoolStatement.TotalPaid}</span>
                                    </span>
                                    <span> | </span>
                                    <span>
                                        <span className="font-medium font-size-9">{"الربح "}</span>
                                        <span>{schoolStatement.TotalProfit}</span>
                                    </span>
                                </span>
                            </Tooltip>
                        }
                        <div id="current-month-statement-grid-column" className="col-12 md:col-6 lg:col-3">
                            <div className="p-2 border-round shadow-2 flex align-items-center surface-card">
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round w-2rem h-2rem ml-1">
                                    <i className="pi pi-dollar text-blue-500 text-lg"></i>
                                </div>
                                <div className="small-grid-column-content">
                                    <span className="block pr-1">
                                        <span className="text-700 font-medium font-size-9">{"كشف الشهر"}</span>
                                        {isSchoolStatementLoading &&
                                            <i className="pi pi-spin pi-spinner text-blue-500 mr-1"></i>
                                        }
                                        {!isSchoolStatementLoading &&
                                            <span className="text-700 font-medium font-size-9 pr-1">
                                                {schoolStatement ? ("(" + schoolStatement.Month + "/" + schoolStatement.Year + ")") : null}
                                            </span>
                                        }
                                    </span>
                                    {isSchoolStatementLoading &&
                                        <i className="pi pi-spin pi-spinner text-blue-500 mr-1"></i>
                                    }
                                    {!isSchoolStatementLoading && schoolStatement &&
                                        <span className="block direction-ltr text-right pr-1">
                                            <span>
                                                <span
                                                    className={"font-size-9 font-medium" + (schoolStatement.TotalProfit === 0 && schoolStatement.TotalLoss > 0 ? " text-red-600" : " text-green-600")}>
                                                    {"NIS " + schoolStatement.TotalProfit}
                                                </span>
                                                <i className="pi pi-cart-arrow-down text-gray-500"></i>
                                            </span>
                                            <span className="pl-2">
                                                <span className="text-orange-600 font-size-9 font-medium">
                                                    {"NIS " + schoolStatement.TotalPaid}
                                                </span>
                                                <i className="pi pi-cart-minus text-gray-500"></i>
                                            </span>
                                            <span className="pl-2">
                                                <span className="text-blue-600 font-size-9 font-medium">
                                                    {"NIS " + schoolStatement.TotalReceived}
                                                </span>
                                                <i className="pi pi-cart-plus text-gray-500"></i>
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

            </div>


            {UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"الاختبارات النظرية لهذا اليوم"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new StudentTheoreticalExamService()}
                        fields={StudentTheoreticalExamFields.get()}
                        fieldsCodesExcludedFromList={[StudentTheoreticalExamField.Date, StudentTheoreticalExamField.Fees]}
                        editSuccessMessage={"تم تعديل الاختبار بنجاح"}
                        editModel={new UpdateStudentTheoreticalExamRequest()}
                        showEditButton={UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.Update)}
                        showTotalRecordsCount={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        operations={["GetOnlyTodayExams"]} />
                </div>
            }

            {UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"الاختبارات العملية لهذا اليوم"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new StudentPracticalExamService()}
                        fields={StudentPracticalExamFields.get()}
                        fieldsCodesExcludedFromList={[StudentPracticalExamField.Date, StudentPracticalExamField.Fees]}
                        editSuccessMessage={"تم تعديل الاختبار بنجاح"}
                        editModel={new UpdateStudentPracticalExamRequest()}
                        showEditButton={UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.Update)}
                        showTotalRecordsCount={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        operations={["GetOnlyTodayExams"]} />
                </div>
            }

            {UserAuthentication.hasPermissions(StudentPaymentsPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات شيكات الطلاب"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new StudentPaymentService()}
                        fields={StudentPaymentFields.get()}
                        fieldsCodesExcludedFromList={[
                            StudentPaymentField.PaymentMethod_Name,
                            StudentPaymentField.Notes
                        ]}
                        editSuccessMessage={"تم تعديل الدفعة بنجاح"}
                        editModel={new UpdateStudentPaymentRequest()}
                        createEdit={CreateEditStudentPayment}
                        showEditButton={UserAuthentication.hasPermissions(StudentPaymentsPermissions.Update)}
                        showTotalRecordsCount={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={StudentPaymentField.CheckDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyNearbyChecks", "ReturnCheckDateStatus"]}
                        onCreateEdit={(response: any, isCreate: boolean) => onCreateEditStudentPayment(response, isCreate)} />
                </div>
            }

            {UserAuthentication.hasPermissions(SchoolPaymentsPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات شيكات المدرسة"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new SchoolPaymentService()}
                        fields={SchoolPaymentFields.get()}
                        fieldsCodesExcludedFromList={[
                            SchoolPaymentField.PaymentMethod_Name,
                            SchoolPaymentField.Notes,
                            SchoolPaymentField.Car_PlateNumber,
                            SchoolPaymentField.Car_CarCompany_Name,
                            SchoolPaymentField.Car_CarType_Name,
                            SchoolPaymentField.CarLicense_StartDate,
                            SchoolPaymentField.CarLicense_EndDate,
                            SchoolPaymentField.CarLicense_Car_PlateNumber,
                            SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                            SchoolPaymentField.CarLicense_Car_CarType_Name,
                            SchoolPaymentField.CarInsurance_StartDate,
                            SchoolPaymentField.CarInsurance_EndDate,
                            SchoolPaymentField.CarInsurance_Car_PlateNumber,
                            SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                            SchoolPaymentField.CarInsurance_Car_CarType_Name,
                            SchoolPaymentField.Employee_Name,
                            SchoolPaymentField.SchoolLicense_StartDate,
                            SchoolPaymentField.SchoolLicense_EndDate,
                            SchoolPaymentField.TrainerLicense_StartDate,
                            SchoolPaymentField.TrainerLicense_EndDate,
                            SchoolPaymentField.TrainerLicense_Employee_Name,
                            SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name
                        ]}
                        editSuccessMessage={"تم تعديل الدفعة بنجاح"}
                        editModel={new UpdateSchoolPaymentRequest()}
                        createEdit={CreateEditSchoolPayment}
                        showEditButton={UserAuthentication.hasPermissions(SchoolPaymentsPermissions.Update)}
                        showTotalRecordsCount={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={SchoolPaymentField.CheckDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyNearbyChecks", "ReturnCheckDateStatus"]}
                        onCreateEdit={(response: any, isCreate: boolean) => onCreateEditSchoolPayment(response, isCreate)} />
                </div>
            }

            {UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات الشيكات المقبوضة"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new ReceivedPaymentService()}
                        fields={ReceivedPaymentFields.get()}
                        fieldsCodesExcludedFromList={[
                            ReceivedPaymentField.PaymentMethod_Name,
                            ReceivedPaymentField.Notes
                        ]}
                        editSuccessMessage={"تم تعديل الدفعة بنجاح"}
                        editModel={new UpdateReceivedPaymentRequest()}
                        createEdit={CreateEditReceivedPayment}
                        showEditButton={UserAuthentication.hasPermissions(ReceivedPaymentsPermissions.Update)}
                        showTotalRecordsCount={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={ReceivedPaymentField.CheckDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyNearbyChecks", "ReturnCheckDateStatus"]}
                        onCreateEdit={(response: any, isCreate: boolean) => onCreateEditReceivedPayment(response, isCreate)} />
                </div>
            }

            {UserAuthentication.hasPermissions(CarLicensesPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات تراخيص المركبات"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new CarLicenseService()}
                        fields={CarLicenseFields.get()}
                        fieldsCodesExcludedFromList={[
                            CarLicenseField.StartDate,
                            CarLicenseField.Cost
                        ]}
                        createSuccessMessage={"تم اضافة ترخيص المركبة بنجاح"}
                        createModel={new CreateCarLicenseRequest()}
                        showAddButton={UserAuthentication.hasPermissions(CarLicensesPermissions.Create)}
                        showAddButtonOnlyIfRecordsExists={true}
                        showTotalRecordsCount={true}
                        isSelectionDisabled={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={CarLicenseField.EndDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyCloseToExpirationOrHasExpired", "ReturnExpirationStatus"]} />
                </div>
            }

            {UserAuthentication.hasPermissions(CarInsurancesPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات تأمينات المركبات"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new CarInsuranceService()}
                        fields={CarInsuranceFields.get()}
                        fieldsCodesExcludedFromList={[
                            CarInsuranceField.StartDate,
                            CarInsuranceField.Cost,
                            CarInsuranceField.InsuranceCompany_Name
                        ]}
                        createSuccessMessage={"تم اضافة تأمين المركبة بنجاح"}
                        createModel={new CreateCarInsuranceRequest()}
                        showAddButton={UserAuthentication.hasPermissions(CarInsurancesPermissions.Create)}
                        showAddButtonOnlyIfRecordsExists={true}
                        showTotalRecordsCount={true}
                        isSelectionDisabled={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={CarInsuranceField.EndDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyCloseToExpirationOrHasExpired", "ReturnExpirationStatus"]} />
                </div>
            }

            {UserAuthentication.hasPermissions(SchoolLicensesPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات ترخيص المدرسة"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new SchoolLicenseService()}
                        fields={SchoolLicenseFields.get()}
                        fieldsCodesExcludedFromList={[
                            SchoolLicenseField.StartDate,
                            SchoolLicenseField.Cost
                        ]}
                        createSuccessMessage={"تم اضافة ترخيص المدرسة بنجاح"}
                        createModel={new CreateSchoolLicenseRequest()}
                        showAddButton={UserAuthentication.hasPermissions(SchoolLicensesPermissions.Create)}
                        showAddButtonOnlyIfRecordsExists={true}
                        showTotalRecordsCount={true}
                        isSelectionDisabled={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={SchoolLicenseField.EndDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyCloseToExpirationOrHasExpired", "ReturnExpirationStatus"]} />
                </div>
            }

            {UserAuthentication.hasPermissions(TrainerLicensesPermissions.View) &&
                <div className="mb-3">
                    <List
                        title={"اشعارات رخص المدربين"}
                        titleClassName={"text-700 font-medium font-size-9"}
                        size={"small"}
                        service={new TrainerLicenseService()}
                        fields={TrainerLicenseFields.get()}
                        fieldsCodesExcludedFromList={[
                            TrainerLicenseField.StartDate,
                            TrainerLicenseField.Cost
                        ]}
                        createSuccessMessage={"تم اضافة رخصة المدرب بنجاح"}
                        createModel={new CreateTrainerLicenseRequest()}
                        showAddButton={UserAuthentication.hasPermissions(TrainerLicensesPermissions.Create)}
                        showAddButtonOnlyIfRecordsExists={true}
                        showTotalRecordsCount={true}
                        isSelectionDisabled={true}
                        isSortableDisabled={true}
                        isAccordion={true}
                        maxPageRecordsCount={5}
                        defaultSortByField={TrainerLicenseField.EndDate}
                        defaultSortDirection={SortDirection.Ascending}
                        operations={["GetOnlyCloseToExpirationOrHasExpired", "ReturnExpirationStatus"]} />
                </div>
            }

        </div>
    );
};

export default Dashboard;
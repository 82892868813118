import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Student } from "../../models/student/Student";
import { CreateStudentRequest } from "../../models/student/CreateStudentRequest";
import { UpdateStudentRequest } from "../../models/student/UpdateStudentRequest";
import { ListButton } from "../../models/base/ListButton";
import { StudentFields } from "../../fields/StudentFields";
import { StudentService } from "../../services/StudentService";
import { StudentsPermissions } from '../../constants/permissions/StudentsPermissions';
import { StudentTheoreticalExamsPermissions } from "../../constants/permissions/StudentTheoreticalExamsPermissions";
import { StudentLessonsPermissions } from "../../constants/permissions/StudentLessonsPermissions";
import { StudentPracticalExamsPermissions } from "../../constants/permissions/StudentPracticalExamsPermissions";
import { StudentDiscountsPermissions } from "../../constants/permissions/StudentDiscountsPermissions";
import { StudentPaymentsPermissions } from "../../constants/permissions/StudentPaymentsPermissions";
import { UserAuthentication } from "../../utilities/UserAuthentication";
import { ListFilters } from "../../utilities/ListFilters";
import { DialogInputs } from "../../inputs/DialogInputs";
import List from "../base/List";
import StudentStatement from "./StudentStatement";

const StudentsList = () => {

    const additionalListButtonsCodes = {
        StudentStatement: "StudentStatement"
    };

    const additionalListButtons: ListButton[] = [
        new ListButton({ Code: additionalListButtonsCodes.StudentStatement, Icon: "wallet", Severity: "success", Position: "afterAdd", Tooltip: "الحساب" })
    ];

    const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

    const [isStudentStatementOpened, setIsStudentStatementOpened] = useState<boolean>(false);

    const [showAdditionalListButtons] = useState<{ [code: string]: boolean }>({
        [additionalListButtonsCodes.StudentStatement]: (
            UserAuthentication.hasPermissions(StudentsPermissions.View) &&
            UserAuthentication.hasPermissions(StudentTheoreticalExamsPermissions.View) &&
            UserAuthentication.hasPermissions(StudentLessonsPermissions.View) &&
            UserAuthentication.hasPermissions(StudentPracticalExamsPermissions.View) &&
            UserAuthentication.hasPermissions(StudentDiscountsPermissions.View) &&
            UserAuthentication.hasPermissions(StudentPaymentsPermissions.View)
        )
    });

    const onAdditionalListButtonClick = (buttonCode: string, student: Student | null) => {
        if (student) {
            if (buttonCode === additionalListButtonsCodes.StudentStatement) {
                setIsStudentStatementOpened(true);
            }
        }
    }

    return (

        <React.Fragment>

            <List
                title={"الطلاب"}
                service={new StudentService()}
                fields={StudentFields.get()}
                filters={ListFilters.student()}
                createSuccessMessage={"تم اضافة الطالب/ة بنجاح"}
                editSuccessMessage={"تم تعديل الطالب/ة بنجاح"}
                confirmDeleteMessage={"هل أنت متأكد من حذف الطالب/ة ؟"}
                deleteSuccessMessage={"تم حذف الطالب/ة بنجاح"}
                createModel={new CreateStudentRequest()}
                editModel={new UpdateStudentRequest()}
                showRefreshButton={true}
                showTotalRecordsCount={true}
                showGlobalFilter={true}
                showFilterButton={true}
                showAddButton={UserAuthentication.hasPermissions(StudentsPermissions.Create)}
                showEditButton={UserAuthentication.hasPermissions(StudentsPermissions.Update)}
                showDeleteButton={UserAuthentication.hasPermissions(StudentsPermissions.Delete)}
                onRecordSelect={(student: Student | null) => setSelectedStudent(student)}
                additionalListButtons={additionalListButtons}
                showAdditionalListButtons={showAdditionalListButtons}
                onAdditionalListButtonClick={(buttonCode: string, student: Student | null) => onAdditionalListButtonClick(buttonCode, student)} />


            <Dialog
                header={selectedStudent ? ("ملف الطالب " + selectedStudent.FullName) : "ملف الطالب"}
                style={{ width: "70vw", height: "80vh" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isStudentStatementOpened}
                onHide={() => setIsStudentStatementOpened(false)} >

                {selectedStudent &&
                    <StudentStatement
                        id={selectedStudent.Id} />
                }
            </Dialog>

        </React.Fragment>
    );
}

export default StudentsList;
import { http } from "../http/Http";
import { SchoolSetting } from "../models/school-setting/SchoolSetting";
import { UpdateSchoolSettingRequest } from "../models/school-setting/UpdateSchoolSettingRequest";

export class SchoolSettingService {

    private Api: string = "SchoolSettings";

    get = async (): Promise<SchoolSetting> => {
        const { data } = await http.get<SchoolSetting>(this.Api);
        return data;
    };

    update = async (updateSchoolRequest: UpdateSchoolSettingRequest): Promise<SchoolSetting> => {
        const { data } = await http.put<SchoolSetting>(this.Api, updateSchoolRequest);
        return data;
    };

    finishDemoExpiration = async (): Promise<any> => {
        const { data } = await http.put<any>(this.Api + "/FinishDemoExpiration");
        return data;
    };

}
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { UserAuthentication } from '../../utilities/UserAuthentication';

const SchoolDemoExpiredWarning = () => {

    const logout = () => {
        UserAuthentication.set(null);
    }

    return (
        <div className="school-demo-expired-warning-container p-6">
            <div className="grid">
                <div className="col-12 text-center mb-2">
                    <Message
                        severity="warn"
                        text="لقد إنتهت الفترة التجريبية لإستخدام النظام، يرجى إبلاغ الشخص المسؤول عن المستخدم الرئيسي" />
                </div>

                <div className="col-12 text-center">
                    <Button label="تسجيل الخروج" className="p-button-danger p-button-sm" onClick={logout} />
                </div>
            </div>
        </div>
    );
}

export default SchoolDemoExpiredWarning;
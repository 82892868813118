export class UpdateSchoolPaymentRequest {
    SchoolPaymentTypeCode: string;
    CarLicenseId: number;
    TrainerLicenseId: number;
    CarInsuranceId: number;
    CarId: number;
    EmployeeId: number;
    SchoolLicenseId: number;
    PaymentDate: Date;
    PaymentAmount: number;
    PaymentMethodCode: string;
    CheckDate: Date;
    IsCheckReturned: boolean;
    Notes: string;
}
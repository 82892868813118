import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";

type Props = {
    message?: string,
    isOpened: boolean,
    isLoading: boolean,
    onAccept: () => void,
    onHide: () => void
};

const ConfirmDialog = (props: Props) => {
    return (
        <Dialog header="تأكيد"
            position="top"
            visible={props.isOpened}
            modal={true}
            rtl={true}
            draggable={false}
            resizable={false}
            focusOnShow={false}
            closable={false}
            style={{ width: "360px" }}
            onHide={() => props.onHide()}>

            <div><span>{props.message || "تأكيد ؟"}</span></div>

            <div className="mt-4">
                <Button label="نعم"
                    className="p-button-danger p-button-sm"
                    disabled={props.isLoading}
                    loading={props.isLoading}
                    onClick={() => props.onAccept()} />
                <Button label="لا"
                    className="p-button-secondary p-button-sm mr-2"
                    disabled={props.isLoading}
                    onClick={() => props.onHide()} />
            </div>
        </Dialog>
    );
}

export default ConfirmDialog;
import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { TrainerLicenseService } from "../../services/TrainerLicenseService";
import { SchoolPaymentService } from "../../services/SchoolPaymentService";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Field } from "../../models/base/Field";
import { TrainerLicenseStatement as TrainerLicenseStatementModel } from "../../models/trainer-license/TrainerLicenseStatement";
import { TrainerLicenseFields } from "../../fields/TrainerLicenseFields";
import { SchoolPaymentFields } from "../../fields/SchoolPaymentFields";
import { TrainerLicenseField } from "../../constants/fields/TrainerLicenseField";
import { SchoolPaymentField } from "../../constants/fields/SchoolPaymentField";
import { TrainerLicenseLabel } from "../../constants/labels/TrainerLicenseLabel";
import { FieldType } from "../../constants/base/FieldType";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { SortDirection } from "../../constants/base/SortDirection";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const TrainerLicenseStatement = (props: Props) => {

    const trainerLicenseService: TrainerLicenseService = new TrainerLicenseService();

    const [trainerLicenseFields] = useState<Field[]>(TrainerLicenseFields.get());
    const [isTrainerLicenseStatementLoading, setIsTrainerLicenseStatementLoading] = useState<boolean>(false);
    const [trainerLicenseStatement, setTrainerLicenseStatement] = useState<TrainerLicenseStatementModel | null>(null);


    useEffect(() => {
        loadTrainerLicenseStatement();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getTrainerLicenseField = (fieldCode: string) => {
        return trainerLicenseFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadTrainerLicenseStatement = () => {
        setIsTrainerLicenseStatementLoading(true);
        trainerLicenseService.getTrainerLicenseStatement(props.id).then((trainerLicenseStatement) => {
            setTrainerLicenseStatement(trainerLicenseStatement || null);
            setIsTrainerLicenseStatementLoading(false);
        }).catch((_error: any) => {
            setTrainerLicenseStatement(null);
            setIsTrainerLicenseStatementLoading(false);
        });
    }

    const trainerLicenseFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{TrainerLicenseLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isTrainerLicenseStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(trainerLicenseStatement, getTrainerLicenseField(fieldCode))}</div>
            }
        </div>;
    }

    const getListAdditionalFiltersFilters = () => {
        return [
            new FilterRequest(SchoolPaymentField.TrainerLicenseId, FilterOperator.Equals, props.id.toString())
        ]
    }


    return (
        <React.Fragment>

            <Panel header="حساب رخصة المدرب" className="mt-3 mb-4">
                <div className="grid">
                    {trainerLicenseFieldTemplate(TrainerLicenseField.Employee_Name)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.TrainerLicenseType_Name)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.StartDate)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.EndDate)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.Cost)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.TotalPaid)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.TotalRemaining)}
                    {trainerLicenseFieldTemplate(TrainerLicenseField.TotalOverpaid)}
                </div>
            </Panel>

            <Panel header={"الدفعات"} className="mb-4">
                <List
                    title={"الدفعات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new SchoolPaymentService()}
                    fields={SchoolPaymentFields.get()}
                    fieldsCodesExcludedFromList={[
                        SchoolPaymentField.CheckDateStatus,
                        SchoolPaymentField.Notes,
                        SchoolPaymentField.Car_PlateNumber,
                        SchoolPaymentField.Car_CarCompany_Name,
                        SchoolPaymentField.Car_CarType_Name,
                        SchoolPaymentField.CarLicense_StartDate,
                        SchoolPaymentField.CarLicense_EndDate,
                        SchoolPaymentField.CarLicense_Car_PlateNumber,
                        SchoolPaymentField.CarLicense_Car_CarCompany_Name,
                        SchoolPaymentField.CarLicense_Car_CarType_Name,
                        SchoolPaymentField.CarInsurance_StartDate,
                        SchoolPaymentField.CarInsurance_EndDate,
                        SchoolPaymentField.CarInsurance_Car_PlateNumber,
                        SchoolPaymentField.CarInsurance_Car_CarCompany_Name,
                        SchoolPaymentField.CarInsurance_Car_CarType_Name,
                        SchoolPaymentField.Employee_Name,
                        SchoolPaymentField.SchoolLicense_StartDate,
                        SchoolPaymentField.SchoolLicense_EndDate,
                        SchoolPaymentField.SchoolPaymentType_Name,
                        SchoolPaymentField.TrainerLicense_StartDate,
                        SchoolPaymentField.TrainerLicense_EndDate,
                        SchoolPaymentField.TrainerLicense_Employee_Name,
                        SchoolPaymentField.TrainerLicense_TrainerLicenseType_Name
                    ]}
                    additionalFilters={getListAdditionalFiltersFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={SchoolPaymentField.PaymentDate}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

        </React.Fragment>
    );
}

export default TrainerLicenseStatement;
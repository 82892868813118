import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable, DataTableSizeType } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { SortDirection } from "../../constants/base/SortDirection";
import { EntityFilters } from "../../models/base/EntityFilters";
import { FilterRequest } from "../../models/base/FilterRequest";
import { Field } from "../../models/base/Field";
import { ListButton } from "../../models/base/ListButton";
import { CreateEditProps } from "../../types/Types";
import { DialogInputs } from "../../inputs/DialogInputs";
import { ValueGetter } from "../../utilities/ValueGetter";
import { Generator } from "../../utilities/Generator";
import UseList from "../../hooks/UseList";
import ConfirmDialog from "../base/ConfirmDialog";
import ListFilters from "./ListFilters";
import FieldTemplate from "./FieldTemplate";
import CreateEdit from "./CreateEdit";

type Props = {
    title?: string,
    titleClassName?: string,
    hideHeaderTitle?: boolean,
    hints?: string[];
    size?: DataTableSizeType,
    service: any,
    fields?: Field[],
    fieldsCodesExcludedFromList?: string[],
    filters?: EntityFilters,
    additionalFilters?: FilterRequest[],
    operations?: string[],
    maxPageRecordsCount?: number,
    createTitle?: string,
    editTitle?: string,
    createSuccessMessage?: string,
    editSuccessMessage?: string,
    confirmDeleteMessage?: string,
    confirmActivateMessage?: string,
    confirmDeactivateMessage?: string,
    deleteSuccessMessage?: string,
    activateSuccessMessage?: string,
    deactivateSuccessMessage?: string,
    noCardContent?: boolean,
    showTotalRecordsCount?: boolean,
    showRefreshButton?: boolean,
    showGlobalFilter?: boolean,
    showFilterButton?: boolean,
    showAddButton?: boolean,
    showEditButton?: boolean,
    showDeleteButton?: boolean,
    showActivateButton?: boolean,
    showDeactivateButton?: boolean,
    showAdditionalListButtons?: { [code: string]: boolean },
    additionalListButtons?: ListButton[],
    isLookupList?: boolean,
    isSelectionDisabled?: boolean,
    isSortableDisabled?: boolean,
    showAddButtonOnlyIfRecordsExists?: boolean,
    loadDatatableRecordsTrigger?: number,
    defaultSortByField?: string,
    defaultSortDirection?: SortDirection,
    isAccordion?: boolean,
    createEdit?: (props: CreateEditProps) => JSX.Element,
    createModel?: any,
    editModel?: any,
    onRecordSelect?: (record: any) => void,
    onAdditionalListButtonClick?: (buttonCode: string, record: any) => void,
    onCreateEdit?: (response: any, isCreate: boolean) => void
};

const List = (props: Props) => {

    const keyField: string = "Id";

    const additionalListButtons: ListButton[] = props.additionalListButtons && props.additionalListButtons.length > 0 ? props.additionalListButtons : [];

    const fieldsCodesExcludedFromList: string[] = props.fieldsCodesExcludedFromList ? props.fieldsCodesExcludedFromList : [];

    const datatableFields: Field[] = props.fields && props.fields.length > 0
        ? props.fields
            .filter((f: Field) => f.DatatableColumn !== null && (!props.isLookupList || f.ShowInListLookup) && !fieldsCodesExcludedFromList.includes(f.Code))
            .sort((f1: Field, f2: Field) => (f1.DatatableColumn?.Order || 0) - (f2.DatatableColumn?.Order || 0)) :
        [];


    const detailsFields: Field[] = props.fields && props.fields.length > 0
        ? props.fields
            .filter((f: Field) => f.DetailsDatatableColumn !== null && (!props.isLookupList || f.ShowInListLookup) && !fieldsCodesExcludedFromList.includes(f.Code))
            .sort((f1: Field, f2: Field) => (f1.DetailsDatatableColumn?.Order || 0) - (f2.DetailsDatatableColumn?.Order || 0)) :
        [];

    const [listId] = useState<string>(Generator.random());

    const {
        maxPageRecordsCount,
        isLoading,
        isFiltersOpened,
        setIsFiltersOpened,
        isCreateEditOpened,
        setIsCreateEditOpened,
        isConfirmDeleteOpened,
        setIsConfirmDeleteOpened,
        isConfirmActivateOpened,
        setIsConfirmActivateOpened,
        isConfirmDeactivateOpened,
        setIsConfirmDeactivateOpened,
        isDeleting,
        isActivating,
        isDeactivating,
        records,
        selectedRecord,
        setSelectedRecord,
        totalRecordsCount,
        sortField,
        sortOrder,
        globalFilterValue,
        setGlobalFilterValue,
        getByFiltersRequest,
        loadDatatableRecords,
        create,
        edit,
        Delete,
        activate,
        deactivate,
        createEditCallback,
        acceptDelete,
        acceptActivate,
        acceptDeactivate,
        changePage,
        changeSort,
        changeFilters
    } = UseList({
        fields: props.fields,
        additionalFilters: props.additionalFilters,
        operations: props.operations,
        service: props.service,
        maxPageRecordsCount: props.maxPageRecordsCount,
        globalFilters: props.filters ? props.filters.GlobalFilters : undefined,
        createSuccessMessage: props.createSuccessMessage,
        editSuccessMessage: props.editSuccessMessage,
        deleteSuccessMessage: props.deleteSuccessMessage,
        activateSuccessMessage: props.activateSuccessMessage,
        deactivateSuccessMessage: props.deactivateSuccessMessage,
        defaultSortByField: props.defaultSortByField,
        defaultSortDirection: props.defaultSortDirection,
        isLookupList: props.isLookupList
    });


    useEffect(() => {
        if (props.loadDatatableRecordsTrigger) {
            loadDatatableRecords();
        }
    }, [props.loadDatatableRecordsTrigger]) // eslint-disable-line react-hooks/exhaustive-deps

    const [expandedRows, setExpandedRows] = useState<any>(null);


    const datatableHeader = () => {
        return (
            <React.Fragment>
                <div className="flex justify-content-start global-filter-input-container">
                    <form autoComplete="off">
                        <span className="p-input-icon-right">
                            <i className="pi pi-search" />
                            <InputText
                                value={globalFilterValue || ""}
                                placeholder={props.filters ? (props.filters.GlobalFilterPlaceholder || "بحث") : "بحث"}
                                className={props.filters && props.filters.IsLtrGlobalFilterInput ? "ltr-input" : ""}
                                onChange={(event: any) => setGlobalFilterValue(event.target.value || "")} />
                        </span>
                    </form>
                </div>
                {props.showFilterButton && globalFilterValue &&
                    <Tag
                        severity="warning"
                        value="قم بإستخدام البحث المتقدم للحصول على نتائج أفضل"
                        className="mt-1-5" />
                }
            </React.Fragment>
        );
    };

    const createEdit = () => {
        if (props.createEdit) {
            const CustomCreateEdit = props.createEdit;
            return <CustomCreateEdit
                id={selectedRecord ? selectedRecord.Id : null}
                callBack={(result: any, isCreate: boolean) => {
                    if (props.onCreateEdit) {
                        props.onCreateEdit(result, isCreate);
                    }
                    createEditCallback(result, isCreate);
                }} />;
        }

        return <CreateEdit
            id={selectedRecord ? selectedRecord.Id : null}
            fields={props.fields}
            createModel={props.createModel}
            editModel={props.editModel}
            service={props.service}
            callBack={(result: any, isCreate: boolean) => {
                if (props.onCreateEdit) {
                    props.onCreateEdit(result, isCreate);
                }
                createEditCallback(result, isCreate);
            }} />;
    }

    const onSelectionChange = (record: any) => {
        setSelectedRecord(record);
        if (props.onRecordSelect) {
            props.onRecordSelect(record);
        }
    }

    const additionalListButton = (listButton: ListButton, index: number) => {
        if (listButton && props.showAdditionalListButtons && props.showAdditionalListButtons[listButton.Code] && (!listButton.ShowForSelectedRecord || selectedRecord)) {
            return (
                <div key={index} className="inline-block p-1">
                    {listButton.Tooltip !== null && <Tooltip target={"#" + listId + "-" + listButton.Code + "-button"} position="top" showDelay={100}>{listButton.Tooltip}</Tooltip>}
                    <Button
                        id={listId + "-" + listButton.Code + "-button"}
                        icon={"pi pi-" + listButton.Icon}
                        disabled={isLoading}
                        className={"p-button-" + listButton.Severity + " p-button-sm " + listButton.Code + "-button" + (props.size === "small" ? " small-list-button" : "")}
                        onClick={() => onAdditionalListButtonClick(listButton.Code)} />
                </div>
            );
        }
        return null;
    };

    const onAdditionalListButtonClick = (buttonCode: string) => {
        if (props.onAdditionalListButtonClick) {
            props.onAdditionalListButtonClick(buttonCode, selectedRecord);
        }
    }

    const rowExpansionTemplate = (record: any) => {
        if (record) {
            return (
                <React.Fragment>
                    <div className="font-medium mb-1">معلومات اضافية:</div>
                    <DataTable
                        value={[record]}
                        className="details-table"
                        responsiveLayout="scroll">

                        {detailsFields.map((field, index) => {
                            let value = ValueGetter.get(record, field.Code);
                            return <Column
                                key={index}
                                field={field.Code}
                                header={field.DetailsDatatableColumn?.Label || field.Label}
                                style={field.DetailsDatatableColumn?.Style || {}}
                                hidden={field.DetailsDatatableColumn?.IsHiddenIfEmpty ? (value === null || value === undefined || value === "") : false}
                                body={(record: any) => FieldTemplate(record, field)} />
                        })}
                    </DataTable>
                </React.Fragment>
            );
        }
        return null;
    };

    const onRowExpand = (event: any) => {
        if (event && event.data && event.data[keyField]) {
            setExpandedRows({ [event.data[keyField]]: true });
        }
        else {
            setExpandedRows(null);
        }
    }

    const onRowCollapse = () => {
        setExpandedRows(null);
    }

    const headerTemplate = () => {
        return (
            <React.Fragment>
                {!props.hideHeaderTitle &&
                    <div className={!props.isAccordion ? "mb-2" : ""}>
                        <div className={(props.titleClassName ? props.titleClassName : "text-800 font-semibold") + (!props.isAccordion ? " pb-1 border-bottom-1 surface-border" : "")}>
                            {props.title &&
                                <span>{props.title}</span>
                            }
                            {props.showTotalRecordsCount &&
                                <React.Fragment>
                                    {!isLoading &&
                                        <Tag className={props.title ? "mr-2" : ""} severity="info" value={totalRecordsCount} />
                                    }
                                    {isLoading &&
                                        <i className={"pi pi-spin pi-spinner" + (props.title ? " mr-2" : "")}></i>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                }

                {props.hideHeaderTitle && props.showTotalRecordsCount &&
                    <div className="mb-1 mr-1">
                        <React.Fragment>
                            {!isLoading &&
                                <Tag severity="info" value={totalRecordsCount} />
                            }
                            {isLoading &&
                                <i className="pi pi-spin pi-spinner"></i>
                            }
                        </React.Fragment>
                    </div>
                }
            </React.Fragment>
        );
    }

    const listTemplate = () => {
        return <React.Fragment>
            <div>

                {additionalListButtons.filter(b => b.Position === "first").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showRefreshButton &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-refresh-button"} position="top" showDelay={100}>{"تحديث"}</Tooltip>
                        <Button
                            id={listId + "-refresh-button"}
                            icon="pi pi-refresh"
                            disabled={isLoading}
                            className={"p-button-secondary p-button-sm refresh-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => loadDatatableRecords()} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "afterRefresh").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showFilterButton &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-filters-button"} position="top" showDelay={100}>{"بحث متقدم"}</Tooltip>
                        <Button
                            id={listId + "-filters-button"}
                            icon="pi pi-filter"
                            disabled={isLoading}
                            className={"p-button-secondary p-button-sm filters-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => setIsFiltersOpened(true)} />
                    </div>
                }

                {props.showFilterButton && getByFiltersRequest.Filters && getByFiltersRequest.Filters.length > 0 &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-clear-filters-button"} position="top" showDelay={100}>{"مسح البحث"}</Tooltip>
                        <Button
                            id={listId + "-clear-filters-button"}
                            icon="pi pi-filter-slash"
                            disabled={isLoading}
                            className={"p-button-secondary p-button-sm clear-filters-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => changeFilters([])} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "afterFilters").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showAddButton && (props.showAddButtonOnlyIfRecordsExists ? (totalRecordsCount > 0) : true) &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-add-button"} position="top" showDelay={100}>{"اضافة"}</Tooltip>
                        <Button
                            id={listId + "-add-button"}
                            icon="pi pi-plus"
                            disabled={isLoading}
                            className={"p-button-info p-button-sm add-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => create()} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "afterAdd").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showEditButton && selectedRecord &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-edit-button"} position="top" showDelay={100}>{"تعديل"}</Tooltip>
                        <Button
                            id={listId + "-edit-button"}
                            icon="pi pi-pencil"
                            disabled={isLoading}
                            className={"p-button-success p-button-sm edit-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => edit()} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "afterEdit").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showDeleteButton && selectedRecord &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-delete-button"} position="top" showDelay={100}>{"حذف"}</Tooltip>
                        <Button
                            id={listId + "-delete-button"}
                            icon="pi pi-trash"
                            disabled={isLoading}
                            className={"p-button-danger p-button-sm delete-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => Delete()} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "afterDelete").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

                {props.showActivateButton && selectedRecord && !selectedRecord["IsActive"] &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-activate-button"} position="top" showDelay={100}>{"تفعيل"}</Tooltip>
                        <Button
                            id={listId + "-activate-button"}
                            icon="pi pi-check"
                            disabled={isLoading}
                            className={"p-button-success p-button-sm activate-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => activate()} />
                    </div>
                }

                {props.showDeactivateButton && selectedRecord && selectedRecord["IsActive"] &&
                    <div className="inline-block p-1">
                        <Tooltip target={"#" + listId + "-deactivate-button"} position="top" showDelay={100}>{"تعطيل"}</Tooltip>
                        <Button
                            id={listId + "-deactivate-button"}
                            icon="pi pi-times"
                            disabled={isLoading}
                            className={"p-button-danger p-button-sm deactivate-button" + (props.size === "small" ? " small-list-button" : "")}
                            onClick={() => deactivate()} />
                    </div>
                }

                {additionalListButtons.filter(b => b.Position === "last").map((listButton, index) => (
                    additionalListButton(listButton, index)
                ))}

            </div>


            {props.hints && props.hints.length > 0 &&
                <div className="p-1">
                    {props.hints.map((hint: string, index: number) => (
                        <div key={index} className={index === 0 ? "" : "mt-0-5"}>
                            <Tag severity="warning" value={hint} />
                        </div>
                    ))}
                </div>
            }


            <div className="p-1">
                <DataTable
                    value={records}
                    dataKey={keyField}
                    selectionMode={props.isSelectionDisabled ? undefined : "single"}
                    responsiveLayout={"scroll"}
                    lazy={true}
                    removableSort={true}
                    metaKeySelection={true}
                    showGridlines={true}
                    alwaysShowPaginator={false}
                    size={props.size}
                    selection={selectedRecord}
                    loading={isLoading}
                    paginator={totalRecordsCount > 0}
                    first={getByFiltersRequest.PageIndex || 0}
                    rows={maxPageRecordsCount}
                    totalRecords={totalRecordsCount}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    expandedRows={expandedRows}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowExpand={onRowExpand}
                    onRowCollapse={onRowCollapse}
                    header={props.showGlobalFilter ? datatableHeader : undefined}
                    onPage={changePage}
                    onSort={changeSort}
                    onSelectionChange={(event) => onSelectionChange(event.value)} >

                    {detailsFields.length > 0 && records.length > 0 &&
                        <Column expander={true} className="expander" />
                    }

                    {datatableFields.map((field, index) => (
                        <Column
                            key={index}
                            field={field.Code}
                            header={field.DatatableColumn?.Label || field.Label}
                            sortable={props.isSortableDisabled ? undefined : (field.DatatableColumn?.IsSortable || false)}
                            style={field.DatatableColumn?.Style || {}}
                            body={(record: any) => FieldTemplate(record, field)} />
                    ))}
                </DataTable>
            </div>

        </React.Fragment>
    }

    return (
        <React.Fragment>

            {!props.isAccordion &&
                <React.Fragment>
                    {props.noCardContent &&
                        <React.Fragment>
                            {headerTemplate()}
                            {listTemplate()}
                        </React.Fragment>
                    }

                    {!props.noCardContent &&
                        <Card>
                            {headerTemplate()}
                            {listTemplate()}
                        </Card>
                    }
                </React.Fragment>
            }

            {props.isAccordion &&
                <Accordion>
                    <AccordionTab headerTemplate={headerTemplate}>
                        {listTemplate()}
                    </AccordionTab>
                </Accordion>
            }


            <Dialog
                header={"بحث متقدم" + (props.title ? (" - " + props.title) : "")}
                position="top"
                className="filters-dialog"
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isFiltersOpened}
                onHide={() => setIsFiltersOpened(false)} >

                <ListFilters
                    defaultFilter={props.filters ? props.filters.DefaultFilter : undefined}
                    fields={props.fields}
                    filters={getByFiltersRequest.Filters}
                    onChange={(filters: FilterRequest[]) => changeFilters(filters)} />
            </Dialog>

            <Dialog
                header={selectedRecord ? (props.editTitle || "تعديل") : (props.createTitle || "اضافة")}
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                visible={isCreateEditOpened}
                onHide={() => setIsCreateEditOpened(false)} >

                {createEdit()}
            </Dialog>

            <ConfirmDialog
                message={props.confirmDeleteMessage}
                isOpened={isConfirmDeleteOpened}
                isLoading={isDeleting}
                onAccept={() => acceptDelete()}
                onHide={() => setIsConfirmDeleteOpened(false)} />

            <ConfirmDialog
                message={props.confirmActivateMessage}
                isOpened={isConfirmActivateOpened}
                isLoading={isActivating}
                onAccept={() => acceptActivate()}
                onHide={() => setIsConfirmActivateOpened(false)} />

            <ConfirmDialog
                message={props.confirmDeactivateMessage}
                isOpened={isConfirmDeactivateOpened}
                isLoading={isDeactivating}
                onAccept={() => acceptDeactivate()}
                onHide={() => setIsConfirmDeactivateOpened(false)} />

        </React.Fragment>
    );
}

export default List;
export enum StudentPracticalExamField {
    StudentId = "StudentId",
    StudyTypeCode = "StudyTypeCode",
    StudentExamStatusCode = "StudentExamStatusCode",
    CarId = "CarId",
    Date = "Date",
    Fees = "Fees",
    Student_FullName = "Student.FullName",
    StudyType_Name = "StudyType.Name",
    StudentExamStatus_Name = "StudentExamStatus.Name",
    Car_PlateNumber = "Car.PlateNumber",
    Car_CarCompany_Name = "Car.CarCompany.Name",
    Car_CarType_Name = "Car.CarType.Name"
}
export class CreateSubscriptionRequestRequest {
    SchoolName: string;
    Phone: string;
    Email: string;
    City: string;
    UsersCount: number;
    Address: string;
    Notes: string;

    constructor(usersCount: number | null = null) {
        if (usersCount !== null) {
            this.UsersCount = usersCount;
        }
    }
}
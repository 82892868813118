import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { FilterRequest } from "../models/base/FilterRequest";
import { FieldType } from "../constants/base/FieldType";
import { FilterOperator } from "../constants/base/FilterOperator";
import { TrainerLicenseField } from "../constants/fields/TrainerLicenseField";
import { EmployeeField } from "../constants/fields/EmployeeField";
import { TrainerLicenseLabel } from "../constants/labels/TrainerLicenseLabel";
import { EmployeeJobCode } from "../constants/closed-lookup/EmployeeJobCode";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";
import { EmployeeService } from "../services/EmployeeService";
import { TrainerLicenseTypeService } from "../services/TrainerLicenseTypeService";

export class TrainerLicenseFields {

    static get(): Field[] {
        return [
            new Field({
                Code: TrainerLicenseField.EmployeeId,
                Label: TrainerLicenseLabel.EmployeeId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new EmployeeService(),
                LookupLoadFilters: LookupLoadFilters.employee(),
                LookupDisplayFieldCode: EmployeeField.Name,
                LookupFilters: [
                    new FilterRequest(EmployeeField.EmployeeJobCode, FilterOperator.Equals, EmployeeJobCode.Trainer)
                ]
            }),

            new Field({
                Code: TrainerLicenseField.TrainerLicenseTypeCode,
                Label: TrainerLicenseLabel.TrainerLicenseTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                LookupService: new TrainerLicenseTypeService()
            }),

            new Field({
                Code: TrainerLicenseField.Employee_Name,
                Label: TrainerLicenseLabel.Employee_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: TrainerLicenseField.TrainerLicenseType_Name,
                Label: TrainerLicenseLabel.TrainerLicenseType_Name,
                Type: FieldType.String,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: TrainerLicenseField.StartDate,
                Label: TrainerLicenseLabel.StartDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: TrainerLicenseField.EndDate,
                Label: TrainerLicenseLabel.EndDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                ShowInCreate: true,
                ShowInEdit: true,
                ShowInListLookup: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: TrainerLicenseField.Cost,
                Label: TrainerLicenseLabel.Cost,
                Type: FieldType.Decimal,
                ShowInCreate: true,
                ShowInEdit: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: TrainerLicenseField.TotalPaid,
                Label: TrainerLicenseLabel.TotalPaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: TrainerLicenseField.TotalOverpaid,
                Label: TrainerLicenseLabel.TotalOverpaid,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: TrainerLicenseField.TotalRemaining,
                Label: TrainerLicenseLabel.TotalRemaining,
                Type: FieldType.Decimal,
                IsCurrency: true
            }),

            new Field({
                Code: TrainerLicenseField.ExpirationStatus,
                Label: TrainerLicenseLabel.ExpirationStatus,
                Type: FieldType.String,
                CustomTemplateCode: "ExpirationStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
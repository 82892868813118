import { http } from "../http/Http";
import { AuditLogAction } from "../models/audit-log-action/AuditLogAction";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class AuditLogActionService {

    public EntityCode: EntityCode = EntityCode.AuditLogAction;

    private Api: string = "AuditLogActions";

    getAll = async (): Promise<AuditLogAction[]> => {
        const { data } = await http.get<AuditLogAction[]>(this.Api + "/GetAll");
        return data;
    };

}
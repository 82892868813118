export enum UserField {
    Email = "Email",
    Password = "Password",
    Name = "Name",
    GenderCode = "GenderCode",
    CityCode = "CityCode",
    Phone = "Phone",
    Address = "Address",
    IsActive = "IsActive",
    IsMain = "IsMain",
    Gender_Name = "Gender.Name",
    City_Name = "City.Name"
}
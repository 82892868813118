import { useEffect, useState } from "react";
import { Subject } from "rxjs";

type Props = {
  key?: any,
  model?: any,
  service?: any,
  loadModelMethod?: string | null,
  saveMethod?: string
};

const UseModel = (props: Props) => {

  const [model, setModel] = useState<any>(props.model || null);
  const [errorsModel, setErrorsModel] = useState<any>({});
  const [isSaveSubmitted, setIsSaveSubmitted] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveEventSubject = new Subject<any>();
  const saveEvent = saveEventSubject.asObservable();

  useEffect(() => {
    if (model && "Initialize" in model && typeof model["Initialize"] === "function") {
      setModel({...model.Initialize()});
    }
    loadModel();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const loadModel = () => {
    if (props.service && props.loadModelMethod && props.service.hasOwnProperty(props.loadModelMethod)) {
      setIsLoading(true);
      let servicePromise: any;
      if (props.key) {
        servicePromise = props.service[props.loadModelMethod](props.key);
      } else {
        servicePromise = props.service[props.loadModelMethod]();
      }
      servicePromise.then((response: any) => {
        handleLoadModelResponse(response);
        setIsLoading(false);
      }).catch((_error: any) => {
        setIsLoading(false);
      });
    }
  }

  const handleLoadModelResponse = (response: any) => {
    if (response) {
      let responseProperties = Object.keys(response);
      if (responseProperties && responseProperties.length > 0) {
        responseProperties.forEach(property => {
          setFieldValue(property, response[property]);
        });
      }
    }
  }

  const setFieldValue = (fieldName: string, fieldValue: any) => {
    if (fieldName && model && model.hasOwnProperty(fieldName)) {
      model[fieldName] = (fieldValue === undefined || fieldValue === "") ? null : fieldValue;
      setModel({ ...model });
      resetFieldModelErrors(fieldName);
    }
  }

  const getFieldValue = (fieldName: string) => {
    if (fieldName && model && model.hasOwnProperty(fieldName)) {
      return model[fieldName];
    }
    return null;
  }

  const isFieldHasModelErrors = (fieldName: string, shouldSaveBeSubmitted: boolean = true) => {
    if (fieldName && errorsModel && errorsModel[fieldName] && (!shouldSaveBeSubmitted || isSaveSubmitted)) {
      return true;
    }
    return false;
  }

  const getFieldModelErrors = (fieldName: string) => {
    if (fieldName && errorsModel) {
      let errors = errorsModel[fieldName];
      if (errors) {
        return errors;
      }
    }
    return [];
  }

  const resetFieldModelErrors = (fieldName: string) => {
    if (fieldName && errorsModel) {
      let errors = errorsModel[fieldName];
      if (errors) {
        errorsModel[fieldName] = undefined;
        setErrorsModel({ ...errorsModel });
      }
    }
  }

  const resetModelErrors = () => {
    setErrorsModel({});
  }

  const save = () => {
    if (props.service && props.saveMethod && props.service.hasOwnProperty(props.saveMethod) && model) {
      setIsSaving(true);
      setIsSaveSubmitted(true);
      resetModelErrors();
      let isCreate: boolean;
      let servicePromise: any;
      if (props.key) {
        isCreate = false;
        servicePromise = props.service[props.saveMethod](props.key, model);
      } else {
        isCreate = true;
        servicePromise = props.service[props.saveMethod](model);
      }
      servicePromise.then((response: any) => {
        setIsSaving(false);
        saveEventSubject.next({ response, isCreate });
      }).catch((error: any) => {
        handleSaveError(error);
        setIsSaving(false);
      });
    }
  }

  const handleSaveError = (error: any) => {
    if (error && error.data && error.data.errors && error.data.title?.toString()?.toLowerCase() === "One or more validation errors occurred.".toLowerCase()) {
      setErrorsModel(error.data.errors);
    }
  }

  return {
    isLoading,
    isSaving,
    setIsSaving,
    setFieldValue,
    getFieldValue,
    isFieldHasModelErrors,
    getFieldModelErrors,
    save,
    saveEvent,
    loadModel
  };
};

export default UseModel;
import { http } from "../http/Http";
import { Student } from "../models/student/Student";
import { StudentStatement } from "../models/student/StudentStatement";
import { CreateStudentRequest } from "../models/student/CreateStudentRequest";
import { UpdateStudentRequest } from "../models/student/UpdateStudentRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentService {

    public EntityCode: EntityCode = EntityCode.Student;

    private Api: string = "Students";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Student>> => {
        const { data } = await http.post<GetByFiltersResponse<Student>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<Student>> => {
        const { data } = await http.post<GetByFiltersResponse<Student>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<Student> => {
        const { data } = await http.get<Student>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<Student> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<Student>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getStudentStatement = async (id: number): Promise<StudentStatement> => {
        const { data } = await http.get<StudentStatement>(this.Api + "/GetStudentStatement/" + id.toString());
        return data;
    };

    create = async (createStudentRequest: CreateStudentRequest): Promise<Student> => {
        const { data } = await http.post<Student>(this.Api, createStudentRequest);
        return data;
    };

    update = async (id: number, updateStudentRequest: UpdateStudentRequest): Promise<Student> => {
        const { data } = await http.put<Student>(this.Api + "/" + id.toString(), updateStudentRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DialogInputs } from "../../inputs/DialogInputs";
import { AppToast } from "../../utilities/AppToast";
import CreateSubscriptionRequest from "../subscription-request/CreateSubscriptionRequest";


const LandingPage = () => {

    const navigate = useNavigate();

    const year: number = moment().year();

    const [selectedUsersCount, setSelectedUsersCount] = useState<number | undefined>(undefined);
    const [isSubscriptionDialogOpened, setIsSubscriptionDialogOpened] = useState<boolean>(false);

    const openSubscriptionDialog = (usersCount: number | undefined = undefined) => {
        setSelectedUsersCount(usersCount);
        setIsSubscriptionDialogOpened(true);
    }

    const handleCreateSubscriptionRequestCallback = (subscriptionRequest: any) => {
        setIsSubscriptionDialogOpened(false);
        if (subscriptionRequest) {
            AppToast.success("تم إرسال الطلب بنجاح. سيتم التواصل معكم في أقرب وقت ممكن", 3600);
        }
    }

    const goToLogin = () => {
        navigate("/login");
    }

    return (
        <React.Fragment>

            <div className="landing-page-container">

                <div className="grid grid-nogutter surface-50 text-800 mb-6">
                    <div className="col-12 md:col-6 p-6 flex align-items-center ">
                        <section>

                            <div className="text-4xl font-bold mb-2">
                                {"نظام إدارة مدارس تعليم السياقة"}
                            </div>

                            <div className="text-xl font-medium text-blue-600 mb-2">
                                {"خدمات متكاملة وشاملة، سهولة الإستخدام، فترة تجريبية"}
                            </div>

                            <div className="text-lg text-blue-600 mb-3">
                                {"إشترك واحصل على 10 أيام فترة تجريبية"}
                            </div>

                            {/* <p className="mt-0 mb-4 text-700 line-height-3">
                                {"يمكنك التطبيق من إدارة الطلاب بما في ذلك التسجيل، والمعلومات الشخصية، والدروس، والإختبارات النظرية والعملية وتسجيل نتائجها، والحسابات المالية للطلاب كالمبالغ المدفوعة والمتبقية وغيرها. وتنظيم بيانات الموظفين والمدربين، ودروسهم، ورواتبهم. ويتيح تسجيل بيانات المركبات، والتراخيص، والتأمينات. وكذلك تسجيل دفعات المدرسة ومعرفة نسبة الأرباح. بالإضافة إلى إشعارات الإختبارات والدفعات والتراخيص والتأمينات. وأيضاً مراقبة حركات المستخدمين على النظام. بإستخدام هذا التطبيق، تصبح عملية إدارة مدرستك أكثر كفاءة وسهولة وفعالية، مما يساعد في تحسين جودة الخدمات المقدمة."}
                            </p> */}

                            <div className="text-700">
                                <ul className="-mr-4">
                                    <li className="pb-1">{"إدارة الطلاب، ودروسهم، وإختباراتهم النظرية والعملية، وحساباتهم المالية."}</li>
                                    <li className="pb-1">{"تنظيم بيانات الموظفين والمدربين، ودروسهم، ورواتبهم، ورخصهم."}</li>
                                    <li className="pb-1">{"تسجيل بيانات المركبات، والتراخيص، والتأمينات."}</li>
                                    <li className="pb-1">{"تسجيل دفعات المدرسة ومعرفة نسبة الأرباح."}</li>
                                    <li className="pb-1">{"مراقبة حركات المستخدمين على النظام."}</li>
                                    <li>{"إشعارات الإختبارات، والدفعات، والتراخيص، والتأمينات."}</li>
                                </ul>
                            </div>

                            <p className="mt-0 mb-4 text-700 line-height-3">
                                {"بإستخدام هذا التطبيق، تصبح عملية إدارة مدرستك أكثر كفاءة وسهولة وفعالية، مما يساعد في تحسين جودة الخدمات المقدمة."}
                            </p>

                            <div className="text-left">
                                <Button
                                    label={"إشترك الآن"}
                                    className="m-1 w-8rem"
                                    onClick={() => openSubscriptionDialog()} />

                                <Button
                                    label={"تسجيل الدخول"}
                                    className="p-button-outlined m-1"
                                    onClick={goToLogin} />
                            </div>

                        </section>
                    </div>
                    <div className="col-12 md:col-6 overflow-hidden">
                        <img src="/images/landing-page-header.jpg" alt="-" className="landing-page-image md:mr-auto block md:h-full" />
                    </div>
                </div>


                <div className="surface-50 text-center p-5 mb-6">
                    <div className="mb-3 font-bold text-3xl mb-4">
                        <span className="text-blue-600">{"نظام واحد، "}</span>
                        <span className="text-blue-600">{"عدة حلول"}</span>
                    </div>
                    <div className="grid pt-2">
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-check-circle text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"سهولة الإستخدام"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-shield text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"آمن"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-globe text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"دعم فني"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-wrench text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"صيانة فورية"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-user-edit text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"إدارة صلاحيات المستخدمين"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-users text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"إدارة الطلاب والموظفين"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-car text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"إدارة المركبات"}</div>
                        </div>
                        <div className="col-12 md:col-3 mb-4 px-5">
                            <span className="surface-0 p-3 shadow-2 mb-3 inline-block border-round-lg">
                                <i className="pi pi-dollar text-4xl text-blue-500"></i>
                            </span>
                            <div className="text-900 text-xl mb-3 font-medium">{"إدارة مالية"}</div>
                        </div>
                    </div>
                </div>


                <div className="surface-50 p-5 mb-6">
                    <div className="text-blue-600 font-bold text-3xl mb-4 text-center">{"تكلفة الإشتراك"}</div>
                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex flex-column border-round-lg">
                                    <div className="text-900 font-medium text-xl mb-2">{"5 مستخدمين"}</div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center mb-5">
                                        <span className="font-bold text-2xl text-900">₪150</span>
                                        <span className="mr-1 font-medium text-600">/ شهر</span>
                                    </div>
                                    <Button
                                        label={"إشترك الآن"}
                                        className="p-3 w-full mt-auto p-button-outlined"
                                        onClick={() => openSubscriptionDialog(5)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex flex-column border-round-lg">
                                    <div className="text-900 font-medium text-xl mb-2">{"3 مستخدمين"}</div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center mb-5">
                                        <span className="font-bold text-2xl text-900">₪100</span>
                                        <span className="mr-1 font-medium text-600">/ شهر</span>
                                    </div>
                                    <Button
                                        label={"إشترك الآن"}
                                        className="p-3 w-full mt-auto"
                                        onClick={() => openSubscriptionDialog(3)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex flex-column border-round-lg">
                                    <div className="text-900 font-medium text-xl mb-2">{"10 مستخدمين"}</div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center mb-5">
                                        <span className="font-bold text-2xl text-900">₪250</span>
                                        <span className="mr-1 font-medium text-600">/ شهر</span>
                                    </div>
                                    <Button
                                        label={"إشترك الآن"}
                                        className="p-3 w-full mt-auto p-button-outlined"
                                        onClick={() => openSubscriptionDialog(10)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="surface-50 p-5 mb-6">
                    <div className="text-blue-600 font-bold text-3xl mb-4 text-center">{"تواصل معنا"}</div>
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex align-items-center border-round-lg">
                                    <div className="flex align-items-center justify-content-center w-2rem h-2rem">
                                        <i className="pi pi-whatsapp text-5xl text-green-500"></i>
                                    </div>
                                    <div className="pr-3">
                                        <span className="block text-900 font-medium text-lg direction-ltr">{"+970598628038"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex align-items-center border-round-lg">
                                    <div className="flex align-items-center justify-content-center w-2rem h-2rem">
                                        <i className="pi pi-facebook text-5xl text-blue-500"></i>
                                    </div>
                                    <div className="pr-3">
                                        <span className="block text-900 font-medium text-lg">{"غير متوفر"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex align-items-center border-round-lg">
                                    <div className="flex align-items-center justify-content-center w-2rem h-2rem">
                                        <i className="pi pi-linkedin text-5xl text-blue-700"></i>
                                    </div>
                                    <div className="pr-3">
                                        <span className="block text-900 font-medium text-lg direction-ltr">{"غير متوفر"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="p-3 h-full">
                                <div className="surface-0 shadow-2 p-3 h-full flex align-items-center border-round-lg">
                                    <div className="flex align-items-center justify-content-center w-2rem h-2rem">
                                        <i className="pi pi-at text-5xl text-purple-600"></i>
                                    </div>
                                    <div className="pr-3">
                                        <span className="block text-900 font-medium text-lg direction-ltr">{"غير متوفر"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="surface-section px-4 py-3 md:px-6 lg:px-8 text-center">
                    <div className="pb-1 font-medium text-blue-800">
                        <span>{"جميع الحقوق محفوظة"}</span>
                    </div>

                    <div className="direction-ltr font-medium text-blue-800">
                        <span>{"© " + (year && year > 2024 ? ("2024-" + year) : "2024") + " DSMS"}</span>
                    </div>
                </div>

            </div>


            <Dialog
                header="طلب إشتراك"
                visible={isSubscriptionDialogOpened}
                modal={true}
                rtl={true}
                draggable={false}
                resizable={false}
                focusOnShow={false}
                style={{ width: "50vw" }}
                breakpoints={DialogInputs.BreakPoints}
                onHide={() => setIsSubscriptionDialogOpened(false)}>

                <CreateSubscriptionRequest
                    usersCount={selectedUsersCount}
                    callBack={(subscriptionRequest: any) => handleCreateSubscriptionRequestCallback(subscriptionRequest)} />
            </Dialog>

        </React.Fragment>
    );

}

export default LandingPage;
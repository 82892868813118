import { ListButtonPosition, PrimeSeverity } from "../../types/Types";

type Params = {
    Code: string,
    Icon: string,
    ShowForSelectedRecord?: boolean,
    Position?: ListButtonPosition,
    Severity?: PrimeSeverity,
    Tooltip?: string
};

export class ListButton {
    Code: string;
    Icon: string;
    ShowForSelectedRecord: boolean;
    Position: ListButtonPosition;
    Severity: PrimeSeverity;
    Tooltip: string | null;

    constructor(params: Params) {
        this.Code = params.Code;
        this.Icon = params.Icon;
        this.ShowForSelectedRecord = params.ShowForSelectedRecord === undefined ? true : params.ShowForSelectedRecord;
        this.Position = params.Position === undefined ? "last" : params.Position;
        this.Severity = params.Severity === undefined ? "primary" : params.Severity;
        this.Tooltip = params.Tooltip === undefined ? null : params.Tooltip;
    }
}
import { http } from "../http/Http";
import { StudentExamStatus } from "../models/student-exam-status/StudentExamStatus";
import { ClosedLookupDataMapper } from "../utilities/ClosedLookupDataMapper";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class StudentExamStatusService {

    public EntityCode: EntityCode = EntityCode.StudentExamStatus;

    private Api: string = "StudentExamStatuses";

    getAll = async (): Promise<StudentExamStatus[]> => {
        const { data } = await http.get<StudentExamStatus[]>(this.Api + "/GetAll");
        return ClosedLookupDataMapper.map(data);
    };

}
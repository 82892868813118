export class CreateTrainerLicenseRequest {
    EmployeeId: number;
    TrainerLicenseTypeCode: string;
    StartDate: Date;
    EndDate: Date;
    Cost: number;

    Initialize() {
        this.Cost = 0;
        return this;
    }
}
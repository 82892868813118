export enum SchoolLabel {
    Name = "الاسم",
    CityCode = "المدينة",
    Phone = "الهاتف",
    Address = "العنوان",
    IsActive = "فعال",
    DemoExpirationDate = "تاريخ انتهاء الفترة التجريبية",
    FinishDemoExpirationDate = "تاريخ انهاء الفترة التجريبية",
    MaxUsersCount = "اقصى عدد للمستخدمين",
    IsManagement = "ادارة",
    Notes = "ملاحظات",
    City_Name = "المدينة"
}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { StudentPaymentField } from "../constants/fields/StudentPaymentField";
import { StudentField } from "../constants/fields/StudentField";
import { StudentPaymentLabel } from "../constants/labels/StudentPaymentLabel";
import { StudentPaymentTypeService } from "../services/StudentPaymentTypeService";
import { StudentService } from "../services/StudentService";
import { PaymentMethodService } from "../services/PaymentMethodService";
import { LookupLoadFilters } from "../utilities/LookupLoadFilters";

export class StudentPaymentFields {

    static get(): Field[] {
        return [

            new Field({
                Code: StudentPaymentField.StudentId,
                Label: StudentPaymentLabel.StudentId,
                Type: FieldType.Lookup,
                ShowInFilters: true,
                LookupService: new StudentService(),
                LookupLoadFilters: LookupLoadFilters.student(),
                LookupDisplayFieldCode: StudentField.FullName
            }),

            new Field({
                Code: StudentPaymentField.Student_FullName,
                Label: StudentPaymentLabel.Student_FullName,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPaymentField.StudentPaymentTypeCode,
                Label: StudentPaymentLabel.StudentPaymentTypeCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new StudentPaymentTypeService()
            }),

            new Field({
                Code: StudentPaymentField.StudentPaymentType_Name,
                Label: StudentPaymentLabel.StudentPaymentType_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPaymentField.PaymentDate,
                Label: StudentPaymentLabel.PaymentDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: StudentPaymentField.PaymentAmount,
                Label: StudentPaymentLabel.PaymentAmount,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: StudentPaymentField.PaymentMethodCode,
                Label: StudentPaymentLabel.PaymentMethodCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new PaymentMethodService()
            }),

            new Field({
                Code: StudentPaymentField.PaymentMethod_Name,
                Label: StudentPaymentLabel.PaymentMethod_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: StudentPaymentField.CheckDate,
                Label: StudentPaymentLabel.CheckDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: StudentPaymentField.IsCheckReturned,
                Label: StudentPaymentLabel.IsCheckReturned,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: StudentPaymentField.Notes,
                Label: StudentPaymentLabel.Notes,
                Type: FieldType.String,
                ShowInFilters: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),

            new Field({
                Code: StudentPaymentField.CheckDateStatus,
                Label: StudentPaymentLabel.CheckDateStatus,
                Type: FieldType.String,
                CustomTemplateCode: "CheckDateStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
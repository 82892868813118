import { http } from "../http/Http";
import { SchoolLicense } from "../models/school-license/SchoolLicense";
import { SchoolLicenseStatement } from "../models/school-license/SchoolLicenseStatement";
import { CreateSchoolLicenseRequest } from "../models/school-license/CreateSchoolLicenseRequest";
import { UpdateSchoolLicenseRequest } from "../models/school-license/UpdateSchoolLicenseRequest";
import { GetByFiltersRequest } from "../models/base/GetByFiltersRequest";
import { GetByFiltersResponse } from "../models/base/GetByFiltersResponse";
import { EntityCode } from "../constants/closed-lookup/EntityCode";

export class SchoolLicenseService {

    public EntityCode: EntityCode = EntityCode.SchoolLicense;

    private Api: string = "SchoolLicenses";

    getByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<SchoolLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<SchoolLicense>>(this.Api + "/GetByFilters", getByFiltersRequest);
        return data;
    };

    getLookupByFilters = async (getByFiltersRequest: GetByFiltersRequest): Promise<GetByFiltersResponse<SchoolLicense>> => {
        const { data } = await http.post<GetByFiltersResponse<SchoolLicense>>(this.Api + "/GetLookupByFilters", getByFiltersRequest);
        return data;
    };

    getById = async (id: number): Promise<SchoolLicense> => {
        const { data } = await http.get<SchoolLicense>(this.Api + "/" + id.toString());
        return data;
    };

    getLookupById = async (id: number, ignoreNotFoundError: boolean = false): Promise<SchoolLicense> => {
        let config = { headers: { "IgnoreNotFoundError": ignoreNotFoundError } };
        const { data } = await http.get<SchoolLicense>(this.Api + "/GetLookupById/" + id.toString(), config);
        return data;
    };

    getSchoolLicenseStatement = async (id: number): Promise<SchoolLicenseStatement> => {
        const { data } = await http.get<SchoolLicenseStatement>(this.Api + "/GetSchoolLicenseStatement/" + id.toString());
        return data;
    };

    create = async (createSchoolLicenseRequest: CreateSchoolLicenseRequest): Promise<SchoolLicense> => {
        const { data } = await http.post<SchoolLicense>(this.Api, createSchoolLicenseRequest);
        return data;
    };

    update = async (id: number, updateSchoolLicenseRequest: UpdateSchoolLicenseRequest): Promise<SchoolLicense> => {
        const { data } = await http.put<SchoolLicense>(this.Api + "/" + id.toString(), updateSchoolLicenseRequest);
        return data;
    };

    delete = async (id: number): Promise<any> => {
        const { data } = await http.delete<any>(this.Api + "/" + id.toString());
        return data;
    };

}
export class UrlRedirect {

    // static goToLandingPage() {
    //     window.location.href = "/";
    // }

    static goToApp() {
        window.location.href = "/app";
    }

    // static goToLogin() {
    //     window.location.href = "/login";
    // }

    // static goTo(path: string) {
    //     if (path !== null && path !== undefined) {
    //         path = path.startsWith("/") ? path : ("/" + path);
    //         window.location.href = path;
    //     }
    // };

}
import { http } from "../http/Http";
import { GetSchoolStatementRequest } from "../models/school-statement/GetSchoolStatementRequest";
import { SchoolStatement } from "../models/school-statement/SchoolStatement";

export class SchoolStatementService {

    private Api: string = "SchoolStatements";

    getCurrentMonthSchoolStatement = async (): Promise<SchoolStatement> => {
        const { data } = await http.get<SchoolStatement>(this.Api + "/GetCurrentMonthSchoolStatement");
        return data;
    };

    getSchoolStatement = async (getSchoolStatementRequest: GetSchoolStatementRequest): Promise<SchoolStatement> => {
        const { data } = await http.post<SchoolStatement>(this.Api + "/GetSchoolStatement", getSchoolStatementRequest);
        return data;
    };

}
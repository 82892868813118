import { City } from "../city/City";

export class SchoolSetting {
    Id: number;
    Name: string;
    Phone: string;
    Address: string;
    CityCode: string;
    City: City;
    DemoExpirationDate: Date | null;
    FinishDemoExpirationDate: Date | null;
    MaxUsersCount: number;
}
import { Field } from "../models/base/Field";
import { DatatableColumn } from "../models/base/DatatableColumn";
import { DetailsDatatableColumn } from "../models/base/DetailsDatatableColumn";
import { FieldType } from "../constants/base/FieldType";
import { ReceivedPaymentField } from "../constants/fields/ReceivedPaymentField";
import { ReceivedPaymentLabel } from "../constants/labels/ReceivedPaymentLabel";
import { PaymentMethodService } from "../services/PaymentMethodService";

export class ReceivedPaymentFields {

    static get(): Field[] {
        return [

            new Field({
                Code: ReceivedPaymentField.CustomerName,
                Label: ReceivedPaymentLabel.CustomerName,
                Type: FieldType.String,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: ReceivedPaymentField.PaymentDate,
                Label: ReceivedPaymentLabel.PaymentDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: ReceivedPaymentField.PaymentAmount,
                Label: ReceivedPaymentLabel.PaymentAmount,
                Type: FieldType.Decimal,
                ShowInFilters: true,
                IsCurrency: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: ReceivedPaymentField.PaymentMethodCode,
                Label: ReceivedPaymentLabel.PaymentMethodCode,
                Type: FieldType.ClosedLookup,
                ShowInFilters: true,
                LookupService: new PaymentMethodService()
            }),

            new Field({
                Code: ReceivedPaymentField.PaymentMethod_Name,
                Label: ReceivedPaymentLabel.PaymentMethod_Name,
                Type: FieldType.String,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),

            new Field({
                Code: ReceivedPaymentField.CheckDate,
                Label: ReceivedPaymentLabel.CheckDate,
                Type: FieldType.Date,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: ReceivedPaymentField.IsCheckReturned,
                Label: ReceivedPaymentLabel.IsCheckReturned,
                Type: FieldType.Boolean,
                ShowInFilters: true,
                DatatableColumn: new DatatableColumn({ IsSortable: true })
            }),
            new Field({
                Code: ReceivedPaymentField.Notes,
                Label: ReceivedPaymentLabel.Notes,
                Type: FieldType.String,
                ShowInFilters: true,
                DetailsDatatableColumn: new DetailsDatatableColumn({})
            }),

            new Field({
                Code: ReceivedPaymentField.CheckDateStatus,
                Label: ReceivedPaymentLabel.CheckDateStatus,
                Type: FieldType.String,
                CustomTemplateCode: "CheckDateStatus",
                DatatableColumn: new DatatableColumn({})
            })
        ];
    }

}
export enum EntityCode {
    AuditLogAction = "AuditLogAction",
    AuditLog = "AuditLog",
    CarCompany = "CarCompany",
    CarInsurance = "CarInsurance",
    CarLicense = "CarLicense",
    CarPowerType = "CarPowerType",
    Car = "Car",
    CarType = "CarType",
    City = "City",
    Color = "Color",
    DiscountType = "DiscountType",
    EmployeeJob = "EmployeeJob",
    Employee = "Employee",
    Entity = "Entity",
    Gender = "Gender",
    InsuranceCompany = "InsuranceCompany",
    PaymentMethod = "PaymentMethod",
    Permission = "Permission",
    SchoolLicense = "SchoolLicense",
    SchoolPayment = "SchoolPayment",
    SchoolPaymentType = "SchoolPaymentType",
    School = "School",
    SchoolSetting = "SchoolSetting",
    StudentDiscount = "StudentDiscount",
    StudentExamStatus = "StudentExamStatus",
    StudentLesson = "StudentLesson",
    StudentPayment = "StudentPayment",
    StudentPaymentType = "StudentPaymentType",
    StudentPracticalExam = "StudentPracticalExam",
    Student = "Student",
    StudentTheoreticalExam = "StudentTheoreticalExam",
    StudyType = "StudyType",
    SubscriptionRequest = "SubscriptionRequest",
    User = "User",
    ReceivedPayment = "ReceivedPayment",
    TrainerLicenseType = "TrainerLicenseType",
    TrainerLicense = "TrainerLicense"
}
import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { StudentService } from "../../services/StudentService";
import { StudentTheoreticalExamService } from "../../services/StudentTheoreticalExamService";
import { StudentLessonService } from "../../services/StudentLessonService";
import { StudentPracticalExamService } from "../../services/StudentPracticalExamService";
import { StudentDiscountService } from "../../services/StudentDiscountService";
import { StudentPaymentService } from "../../services/StudentPaymentService";
import { Field } from "../../models/base/Field";
import { FilterRequest } from "../../models/base/FilterRequest";
import { StudentStatement as StudentStatementModel } from "../../models/student/StudentStatement";
import { FilterOperator } from "../../constants/base/FilterOperator";
import { FieldType } from "../../constants/base/FieldType";
import { SortDirection } from "../../constants/base/SortDirection";
import { StudentField } from "../../constants/fields/StudentField";
import { StudentTheoreticalExamField } from "../../constants/fields/StudentTheoreticalExamField";
import { StudentLessonField } from "../../constants/fields/StudentLessonField";
import { StudentPracticalExamField } from "../../constants/fields/StudentPracticalExamField";
import { StudentDiscountField } from "../../constants/fields/StudentDiscountField";
import { StudentPaymentField } from "../../constants/fields/StudentPaymentField";
import { StudentLabel } from "../../constants/labels/StudentLabel";
import { StudentFields } from "../../fields/StudentFields";
import { StudentTheoreticalExamFields } from "../../fields/StudentTheoreticalExamFields";
import { StudentLessonFields } from "../../fields/StudentLessonFields";
import { StudentPracticalExamFields } from "../../fields/StudentPracticalExamFields";
import { StudentDiscountFields } from "../../fields/StudentDiscountFields";
import { StudentPaymentFields } from "../../fields/StudentPaymentFields";
import FieldTemplate from "../base/FieldTemplate";
import List from "../base/List";

type Props = {
    id: number
};

const StudentStatement = (props: Props) => {

    const studentService: StudentService = new StudentService();

    const [studentFields] = useState<Field[]>(StudentFields.get());
    const [isStudentStatementLoading, setIsStudentStatementLoading] = useState<boolean>(false);
    const [studentStatement, setStudentStatement] = useState<StudentStatementModel | null>(null);


    useEffect(() => {
        loadStudentStatement();
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const getStudentField = (fieldCode: string) => {
        return studentFields.find(f => f.Code === fieldCode) || new Field({ Code: "", Type: FieldType.NoType });
    }

    const loadStudentStatement = () => {
        setIsStudentStatementLoading(true);
        studentService.getStudentStatement(props.id).then((studentStatement) => {
            setStudentStatement(studentStatement || null);
            setIsStudentStatementLoading(false);
        }).catch((_error: any) => {
            setStudentStatement(null);
            setIsStudentStatementLoading(false);
        });
    }

    const studentFieldTemplate = (fieldCode: string) => {
        return <div className="col-12 md:col-3 p-2">
            <div className="mb-2 font-medium font-size-9">{StudentLabel[fieldCode?.replace(/\./g, "_")]}</div>
            {isStudentStatementLoading ? <Skeleton className="h-2rem"></Skeleton> :
                <div>{FieldTemplate(studentStatement, getStudentField(fieldCode))}</div>
            }
        </div>;
    }

    const getListAdditionalFilters = () => {
        return [
            new FilterRequest("StudentId", FilterOperator.Equals, props.id.toString())
        ]
    }


    return (
        <React.Fragment>

            <Panel header="حساب الطالب" className="mt-3 mb-4">
                <div className="grid">
                    {studentFieldTemplate(StudentField.FullName)}
                    {studentFieldTemplate(StudentField.IdentityNumber)}
                    {studentFieldTemplate(StudentField.City_Name)}
                    {studentFieldTemplate(StudentField.Phone)}
                    {studentFieldTemplate(StudentField.TotalTheoreticalExamsNumber)}
                    {studentFieldTemplate(StudentField.TotalLessonsNumber)}
                    {studentFieldTemplate(StudentField.TotalPracticalExamsNumber)}
                    {studentFieldTemplate(StudentField.TotalFees)}
                    {studentFieldTemplate(StudentField.TotalFeesAfterDiscounts)}
                    {studentFieldTemplate(StudentField.TotalPaid)}
                    {studentFieldTemplate(StudentField.TotalRemaining)}
                    {studentFieldTemplate(StudentField.TotalOverpaid)}
                </div>
            </Panel>

            <Panel header="الاختبارات النظرية" className="mb-4">
                <List
                    title={"الاختبارات النظرية"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new StudentTheoreticalExamService()}
                    fields={StudentTheoreticalExamFields.get()}
                    fieldsCodesExcludedFromList={[StudentTheoreticalExamField.Student_FullName]}
                    additionalFilters={getListAdditionalFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={StudentTheoreticalExamField.Date}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

            <Panel header="الاختبارات العملية" className="mb-4">
                <List
                    title={"الاختبارات العملية"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new StudentPracticalExamService()}
                    fields={StudentPracticalExamFields.get()}
                    fieldsCodesExcludedFromList={[StudentPracticalExamField.Student_FullName]}
                    additionalFilters={getListAdditionalFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={StudentPracticalExamField.Date}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

            <Panel header="الدروس" className="mb-4">
                <List
                    title={"الدروس"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new StudentLessonService()}
                    fields={StudentLessonFields.get()}
                    fieldsCodesExcludedFromList={[StudentLessonField.Student_FullName]}
                    additionalFilters={getListAdditionalFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={StudentLessonField.Date}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

            <Panel header="الخصومات" className="mb-4">
                <List
                    title={"الخصومات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new StudentDiscountService()}
                    fields={StudentDiscountFields.get()}
                    fieldsCodesExcludedFromList={[StudentDiscountField.Student_FullName]}
                    additionalFilters={getListAdditionalFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true} />
            </Panel>

            <Panel header="الدفعات" className="mb-4">
                <List
                    title={"الدفعات"}
                    hideHeaderTitle={true}
                    size={"small"}
                    service={new StudentPaymentService()}
                    fields={StudentPaymentFields.get()}
                    fieldsCodesExcludedFromList={[StudentPaymentField.Student_FullName, StudentPaymentField.CheckDateStatus, StudentPaymentField.Notes]}
                    additionalFilters={getListAdditionalFilters()}
                    maxPageRecordsCount={10}
                    noCardContent={true}
                    showTotalRecordsCount={true}
                    isSelectionDisabled={true}
                    defaultSortByField={StudentPaymentField.PaymentDate}
                    defaultSortDirection={SortDirection.Descending} />
            </Panel>

        </React.Fragment>
    );
}

export default StudentStatement;
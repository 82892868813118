const PrimeArLocale =
{
    "startsWith": "Starts with",
    "contains": "Contains",
    "notContains": "Not contains",
    "endsWith": "Ends with",
    "equals": "Equals",
    "notEquals": "Not equals",
    "noFilter": "No Filter",
    "lt": "Less than",
    "lte": "Less than or equal to",
    "gt": "Greater than",
    "gte": "Greater than or equal to",
    "dateIs": "Date is",
    "dateIsNot": "Date is not",
    "dateBefore": "Date is before",
    "dateAfter": "Date is after",
    "custom": "Custom",
    "clear": "مسح",
    "apply": "Apply",
    "matchAll": "Match All",
    "matchAny": "Match Any",
    "addRule": "Add Rule",
    "removeRule": "Remove Rule",
    "accept": "Yes",
    "reject": "No",
    "choose": "Choose",
    "upload": "Upload",
    "cancel": "Cancel",
    // "dayNames":["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    // "dayNamesShort":["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "dayNames": ["أحد", "إثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
    "dayNamesShort": ["أحد", "إثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
    "dayNamesMin": ["أحد", "إثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
    "monthNames": ["شهر 1", "شهر 2", "شهر 3", "شهر 4", "شهر 5", "شهر 6", "شهر 7", "شهر 8", "شهر 9", "شهر 10", "شهر 11", "شهر 12"],
    //"monthNamesShort":["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    "monthNamesShort": ["شهر 1", "شهر 2", "شهر 3", "شهر 4", "شهر 5", "شهر 6", "شهر 7", "شهر 8", "شهر 9", "شهر 10", "شهر 11", "شهر 12"],
    "today": "اليوم",
    "weekHeader": "Wk",
    "firstDayOfWeek": 0,
    "dateFormat": "mm/dd/yy",
    "weak": "ضعيفة",
    "medium": "متوسطة",
    "strong": "قوية",
    "passwordPrompt": "أدخل كلمة المرور",
    "emptyFilterMessage": "No available options",
    "emptyMessage": "لا يوجد بيانات"
};

export default PrimeArLocale;
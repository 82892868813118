export enum CarLabel {
    PlateNumber = "رقم اللوحة",
    CarCompanyCode = "الشركة",
    ManufactureYear = "سنة الصنع",
    ColorCode = "اللون",
    CarTypeCode = "النوع",
    CarPowerTypeCode = "المحرك",
    Cost = "التكلفة",
    TotalPaid = "المبلغ المدفوع",
    TotalOverpaid = "المبلغ الفائض",
    TotalRemaining = "المبلغ المتبقي",
    CarCompany_Name = "الشركة",
    CarType_Name = "النوع",
    Color_Name = "اللون",
    CarPowerType_Name = "المحرك"
}
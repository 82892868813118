import { AuditLogFields } from "../fields/AuditLogFields";
import { CarFields } from "../fields/CarFields";
import { CarInsuranceFields } from "../fields/CarInsuranceFields";
import { CarLicenseFields } from "../fields/CarLicenseFields";
import { TrainerLicenseFields } from "../fields/TrainerLicenseFields";
import { EmployeeFields } from "../fields/EmployeeFields";
import { ReceivedPaymentFields } from "../fields/ReceivedPaymentFields";
import { SchoolFields } from "../fields/SchoolFields";
import { SchoolLicenseFields } from "../fields/SchoolLicenseFields";
import { SchoolPaymentFields } from "../fields/SchoolPaymentFields";
import { StudentDiscountFields } from "../fields/StudentDiscountFields";
import { StudentFields } from "../fields/StudentFields";
import { StudentLessonFields } from "../fields/StudentLessonFields";
import { StudentPaymentFields } from "../fields/StudentPaymentFields";
import { StudentPracticalExamFields } from "../fields/StudentPracticalExamFields";
import { StudentTheoreticalExamFields } from "../fields/StudentTheoreticalExamFields";
import { SubscriptionRequestFields } from "../fields/SubscriptionRequestFields";
import { UserFields } from "../fields/UserFields";

export class EntityFields {

    static getField(entity: string, fieldCode: string) {
        if (entity && fieldCode) {
            return this.getFields(entity).find(f => f.Code === fieldCode) || null;
        }
        return null;
    }

    static getFields(entity: string) {
        if (entity) {
            switch (entity) {
                case "AuditLog":
                    return AuditLogFields.get();
                case "Car":
                    return CarFields.get();
                case "CarInsurance":
                    return CarInsuranceFields.get();
                case "CarLicense":
                    return CarLicenseFields.get();
                case "TrainerLicense":
                    return TrainerLicenseFields.get();
                case "Employee":
                    return EmployeeFields.get();
                case "School":
                case "SchoolSetting":
                    return SchoolFields.get();
                case "SchoolLicense":
                    return SchoolLicenseFields.get();
                case "SchoolPayment":
                    return SchoolPaymentFields.get();
                case "StudentDiscount":
                    return StudentDiscountFields.get();
                case "Student":
                    return StudentFields.get();
                case "StudentLesson":
                    return StudentLessonFields.get();
                case "StudentPayment":
                    return StudentPaymentFields.get();
                case "StudentPracticalExam":
                    return StudentPracticalExamFields.get();
                case "StudentTheoreticalExam":
                    return StudentTheoreticalExamFields.get();
                case "SubscriptionRequest":
                    return SubscriptionRequestFields.get();
                case "User":
                    return UserFields.get();
                case "ReceivedPayment":
                    return ReceivedPaymentFields.get();
                default:
                    return [];
            }
        }
        return [];
    }

}
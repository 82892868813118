export class CreateStudentRequest {
    FirstName: string;
    SecondName: string;
    ThirdName: string;
    LastName: string;
    IdentityNumber: string;
    GenderCode: string;
    CityCode: string;
    Phone: string;
    Email: string;
    Address: string;
    BirthDate: Date;
}